import React from 'react';
import Navbar from './Navbar/Navbar';
import { Card, Typography } from 'antd';
const { Title, Text } = Typography;

export default function TeamNotConfiguredPage() {
  return (
    <div>
      {/* Skipping navbar here because it would redirect not logged in users,
      Could be nice to have navbar work with non logged in state in future */}
      {/* <Navbar /> */}
      <div style={{ padding: '20px', marginTop: '20px' }}>
        <Card bordered={false}>
          <Title>Team not configured</Title>
          <div style={{ marginTop: '10px' }}></div>
          <Text>
            The workspace you tried to authenticate with is not fully configured for signing into the web application.
            If your company uses two workspaces you can try to log into a different one. Please{' '}
            <a target="_blank" href={`/app/login`}>
              click here
            </a>{' '}
            to try authenticating and picking a workspace again or contact support@getculturebot.com if issues persist.{' '}
          </Text>
        </Card>
      </div>
    </div>
  );
}
