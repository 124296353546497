import { Space, Table, Popconfirm, Tag } from "antd";
import { useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import moment from "moment";
import { useState } from "react";

export default function ExperiencesTable({ getCBExperiencesQuery, editClickedOnExperience, deletedClickedOnExperience, cloneExperience, pauseExperience, setExpId }) {

  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const columns = [
    {
      title: "Experience name",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (_, record) => {
        return <div>{record.name} {record.recurring_sequence ? (<Tag color="blue">Recurring</Tag>) : null}</div>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "30%",
      render: (_, record) => {
        return (
          <Space size="middle">
            <a onClick={() => editClickedOnExperience(record)}>
              Edit
            </a>
            <a onClick={() => cloneExperience(record)}>Clone</a>
            <a onClick={() => setExpId(record.id)}>Messages Sent</a>
            <Popconfirm
            title={
                <div>
                Are you sure you want to delete this experience?
                <br />
                You will not be able to undo this action.
                </div>
            }
            onConfirm={() => deletedClickedOnExperience(record)}
            okText="Yes"
            cancelText="No"
            >
            <a>Delete</a>
            </Popconfirm>
            <Popconfirm
              title={
                record.paused ? (
                  <div>Users will start to receive messages again.</div>
                ) : (
                  <div>
                    New messages will not send until you unpause the
                    experience again.
                  </div>
                )
              }
              onConfirm={() => pauseExperience(record)}
              okText={record.paused ? "Unpause" : "Pause"}
              cancelText={record.paused ? "Don't Unpause" : "Don't Pause"}
            >
              <a>{record.paused ? "Unpause" : "Pause"}</a>
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (_, record) => {
        return <div>{moment.unix(record.created_at).format("MM/DD/YYYY")}</div>;
      },
    },
  ];

  var tableArea;
  if (getCBExperiencesQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (
    getCBExperiencesQuery.isError ||
    getCBExperiencesQuery.data.error
  ) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getCBExperiencesQuery.data.allExperiences.map((currentExp) => {
      return {
        id: currentExp.id,
        name: currentExp.name,
        created_at: currentExp.created_at,
        anchor_time_type: currentExp.anchor_time_type,
        anchor_date_time: currentExp.anchor_date_time_gmt,
        anchor_channel: currentExp.anchor_channel,
        anchor_time_value: currentExp.anchor_time_value,
        anchor_timeframe: currentExp.anchor_timeframe,
        recurring_sequence: currentExp.recurring_sequence,
        recurring_sequence_days: currentExp.recurring_sequence_days,
        steps: currentExp.steps,
        var_names: currentExp.var_names,
        paused: currentExp.paused,
        running: currentExp.running,
        users_active: currentExp.users_active
      };
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    );
  }
  return tableArea;
}
