import "./CustomShoutoutPage.css";
import {
  Button,
  Typography,
  Image,
  Card,
  Table,
  Space,
  Modal,
  Select,
  Input,
  Row,
  Col
} from "antd";
import { PlusOutlined, NotificationOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { useState, useRef } from "react";
import ColorSelector from "./ColorSelector";
import MainContainer from "../Container/MainContainer";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import {
  toBase64,
  getImageSize,
  capitalizeFirstLetter,
  getNiceName,
} from "../../helpers/utilityHelpers";
import companyOffice from "../../images/company-rainbow.jpg";
import pixarValues from "../../images/pixar-values-example.png";
import patagoniaValues from "../../images/patagonia-job-req-example.png";
import hubspotValues from "../../images/hubspot-example.png";
import paintedBuilding from "../../images/painted-building.jpg";

const { Option } = Select;
const { Text, Title } = Typography;

const IMAGE_MUCH_DIFFERENT_TEXT =
  "Your image is much different than the recommended 1:1 dimensions and will appear distorted.";

function App() {
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCustomShoutout, setCurrentCustomShoutout] = useState();
  const [addCustomIconErrorText, setAddCustomIconErrorText] = useState(null);
  const [nameInputStatusText, setNameInputStatusText] = useState("");
  const [imageUploadStatusText, setImageUploadStatusText] = useState(null);
  const [explainValuesModalOpen, setExplainValuesModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const inputFile = useRef(null);

  // Networking requests for custom shoutouts
  const getCustomShoutoutsQuery = useQuery(
    ["getCustomShoutouts"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCustomShoutouts`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  const getCustomShoutoutsQueryRefetch = getCustomShoutoutsQuery.refetch;

  async function saveCustomShoutoutClicked() {
    setNameInputStatusText("");
    if (!currentCustomShoutout.shoutout_name) {
      setNameInputStatusText("error");
      return;
    }

    setIsModalOpen(false);
    const jsonToSend = {
      shoutout_name: currentCustomShoutout.shoutout_name,
      shoutout_description:currentCustomShoutout.shoutout_description,
      shoutout_color: currentCustomShoutout.shoutout_color,
      shoutout_icon: currentCustomShoutout.shoutout_icon,
    };
    if (currentCustomShoutout.shoutout_custom_icon_file) {
      // Adding a file from file system
      const base64Version = await toBase64(
        currentCustomShoutout.shoutout_custom_icon_file
      );
      jsonToSend.shoutout_custom_icon_file = base64Version;
      jsonToSend.shoutout_custom_icon_filename =
        currentCustomShoutout.shoutout_custom_icon_file.name;
      jsonToSend.shoutout_icon = null; // Do not send selected icon in this case
    } else if (currentCustomShoutout.shoutout_custom_icon_path) {
      // Wants to keep using the same custom file path
      jsonToSend.shoutout_custom_icon_path =
        currentCustomShoutout.shoutout_custom_icon_path;
    }
    if (currentCustomShoutout.id) {
      //is an edit
      jsonToSend.id = currentCustomShoutout.id;
    }
    const response = await doPostRequest(
      `${getBaseUrl()}/api/addOrEditCustomShoutout`,
      jsonToSend,
      setTokenErrorType
    );
    // todo check response?
    getCustomShoutoutsQueryRefetch();
  }

  async function deleteShoutoutClicked(shoutoutToDelete) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteCustomShoutout`,
      { custom_shoutout_id: shoutoutToDelete.id },
      setTokenErrorType
    );
    if(response["error"]) {
      window.alert(response["error"]);
    } else {
      getCustomShoutoutsQueryRefetch();
    }
  }

  // Form handlers
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // handlers
  const handleExplainerModalCancel = () => {
    setIsModalOpen(false);
    setExplainValuesModalOpen(false);
  };

  const openEditModalWithShoutout = (customShoutout) => {
    setNameInputStatusText("");
    setAddCustomIconErrorText(null);
    setImageUploadStatusText(null);
    if (customShoutout) {
      setCurrentCustomShoutout({
        id: customShoutout.id,
        shoutout_name: customShoutout.shoutout_name,
        shoutout_description:customShoutout.shoutout_description,
        shoutout_color: customShoutout.shoutout_color,
        shoutout_icon: customShoutout.shoutout_icon || "welcome", // Default here as well so dropdown always has something even if they are editing a custom
        shoutout_custom_icon_file: null,
        shoutout_custom_icon_path: customShoutout.shoutout_custom_icon_path,
      });
    } else {
      setCurrentCustomShoutout({
        shoutout_name: "",
        shoutout_description:"",
        shoutout_color: "#FCBC54",
        shoutout_icon: "welcome",
        shoutout_custom_icon_file: null,
        shoutout_custom_icon_path: null,
      }); /*if changing shoutout_color make sure it is one in colorSelector.js*/
    }
    setIsModalOpen(true);
  };

  const customShoutoutIconChanged = (newColor) => {
    const clonedShoutout = Object.assign({}, currentCustomShoutout);
    clonedShoutout.shoutout_icon = newColor;
    setCurrentCustomShoutout(clonedShoutout);
  };

  const customShoutoutNameChanged = (newNameEvent) => {
    const clonedShoutout = Object.assign({}, currentCustomShoutout);
    clonedShoutout.shoutout_name = newNameEvent.target.value;
    setCurrentCustomShoutout(clonedShoutout);
  };

  const customShoutoutDescriptionChanged = (newDescriptionEvent) => {
    const clonedShoutout = Object.assign({}, currentCustomShoutout);
    clonedShoutout.shoutout_description = newDescriptionEvent.target.value;
    setCurrentCustomShoutout(clonedShoutout);
  };

  const customShoutoutColorChanged = (newColor) => {
    const clonedShoutout = Object.assign({}, currentCustomShoutout);
    clonedShoutout.shoutout_color = newColor;
    setCurrentCustomShoutout(clonedShoutout);
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onCustomIconAdded = async (evt) => {
    setAddCustomIconErrorText(null);
    setImageUploadStatusText(null);
    const selectedFile = evt.target.files[0];
    const maxAllowedSize = 10 * 1024 * 1024; //10MB limit

    const clonedShoutout = Object.assign({}, currentCustomShoutout);
    clonedShoutout.shoutout_custom_icon_file = null; //Clear the current file always, on success and errors
    if (selectedFile.size > maxAllowedSize) {
      setAddCustomIconErrorText(
        "Image was over 10MB, please select a smaller image and try again."
      );
    } else {
      const imageUrl = URL.createObjectURL(selectedFile);
      const dimensions = await getImageSize(imageUrl);
      const ratio =
        Math.max(dimensions.height, dimensions.width) /
        Math.min(dimensions.height, dimensions.width);
      clonedShoutout.shoutout_custom_icon_file = selectedFile;

      const imageDimensionsText = `Your image is ${dimensions.width} by ${dimensions.height}.`;
      if (ratio > 1.6) {
        // Give a more hardcore warning
        setImageUploadStatusText(
          <Text type="danger">{`${IMAGE_MUCH_DIFFERENT_TEXT} ${imageDimensionsText}`}</Text>
        );
      } else if (ratio > 1) {
        // Show less hardcore warning
        setImageUploadStatusText(
          <Text
            style={{ color: "#003eb3" }}
          >{`Your image does not match the recommended 1:1 dimensions and may appear somewhat distorted. ${imageDimensionsText}`}</Text>
        );
      }
    }
    setCurrentCustomShoutout(clonedShoutout);
  };

  const currentShoutoutHasALocalOrWebCustomIcon = () => {
    return (
      !!currentCustomShoutout.shoutout_custom_icon_file ||
      !!currentCustomShoutout.shoutout_custom_icon_path
    );
  };

  const removeCustomAddedIcon = () => {
    const clonedShoutout = Object.assign({}, currentCustomShoutout);
    // Clear everything related to custom icons (local or url)
    clonedShoutout.shoutout_custom_icon_file = null;
    clonedShoutout.shoutout_custom_icon_path = null;
    setCurrentCustomShoutout(clonedShoutout);
  };

  const getFullUrlForShoutoutIcon = (shoutout) => {
    if (shoutout.shoutout_custom_icon_file) {
      //local image which has not been saved yet
      return URL.createObjectURL(shoutout.shoutout_custom_icon_file);
    } else if (shoutout.shoutout_custom_icon_path) {
      return `${getBaseUrl()}/api/${shoutout.shoutout_custom_icon_path}`;
    } else {
      return `${getBaseUrl()}/api/images/shoutoutCustomIcons/${
        shoutout.shoutout_icon
      }.png`;
    }
  };

  const getPreviewIconImageForShoutout = (shoutout) => {
    return (
      <Image
        style={{
          marginRight: "30px",
          backgroundColor: shoutout.shoutout_color,
        }}
        preview={false}
        width={35}
        src={getFullUrlForShoutoutIcon(shoutout)}
      />
    );
  };

  // Modal to create shoutout
  const createShoutoutModal = (
    <Modal
      title="Custom Shoutout"
      okText="Save"
      open={isModalOpen}
      onOk={saveCustomShoutoutClicked}
      onCancel={handleCancel}
    >
      {currentCustomShoutout ? (
        <>
          <Title level={5}>Enter Name:</Title>
          <Input
            style={{ width: 200 }}
            status={nameInputStatusText}
            value={currentCustomShoutout.shoutout_name}
            onChange={customShoutoutNameChanged}
            placeholder="Shoutout name"
            maxLength={75} // to satisfy slack limit on dropdown length, error from slack: "must be less than 76 characters"
          />
          <div style={{ marginTop: "20px" }}></div>
          <Title level={5}>Enter Description:</Title>
          <Input
            style={{ width: '100%' }}
            value={currentCustomShoutout.shoutout_description}
            onChange={customShoutoutDescriptionChanged}
            placeholder="Describe when to use this shoutout type"
          />
          <div style={{ marginTop: "20px" }}></div>
          <Title level={5}>Choose Icon:</Title>
          <Row gutter={16}>
            <Col className="centered-col">
              <Select
                disabled={currentShoutoutHasALocalOrWebCustomIcon()}
                style={{ width: 175 }}
                value={currentCustomShoutout.shoutout_icon}
                onChange={customShoutoutIconChanged}
              >
                {getCustomShoutoutsQuery.data.shoutoutCustomIconNames.map(
                  (custom_shoutout_icon_name) => (
                    <Option
                      value={custom_shoutout_icon_name}
                      key={custom_shoutout_icon_name}
                    >
                      {getNiceName(custom_shoutout_icon_name)}
                    </Option>
                  )
                )}
              </Select>
            </Col>
            <div style={{ marginTop: "20px" }}></div>
            <Col className="centered-col">
              {getPreviewIconImageForShoutout(currentCustomShoutout)}
            </Col>
          </Row>
          <div style={{ marginTop: "20px" }}>
            <input
              onChange={onCustomIconAdded}
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onClick={function (evt) {
                // Clear value so onchange gets triggered each time even if same file is selected back to back
                evt.target.value = null;
              }}
            />
            {addCustomIconErrorText && (
              <Text type="danger">{addCustomIconErrorText}</Text>
            )}
            {currentShoutoutHasALocalOrWebCustomIcon() ? (
              <div>
                {getPreviewIconImageForShoutout(currentCustomShoutout)}

                <Button
                  onClick={() => removeCustomAddedIcon()}
                  type="text"
                  danger
                >
                  Remove
                </Button>
              </div>
            ) : (
              <Button onClick={onButtonClick}>Upload icon instead</Button>
            )}
            <div style={{ marginTop: "5px" }}>
              {currentShoutoutHasALocalOrWebCustomIcon() ? (
                imageUploadStatusText
              ) : (
                <Text type="secondary">
                  Recommended upload dimensions of 1:1
                </Text>
              )}
            </div>
          </div>
          <div style={{ marginTop: "20px" }}></div>
          <Title level={5}>Select Color:</Title>
          <ColorSelector
            colorUpdated={customShoutoutColorChanged}
            currentColor={currentCustomShoutout.shoutout_color}
          />
          <div style={{ marginTop: "40px" }}></div>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );

  // explain results modal
  const explainValuesModal = (
    <Modal
      title="Choosing Your Company's Values"
      open={explainValuesModalOpen}
      onCancel={handleExplainerModalCancel}
      footer={[
        <Button key="back" onClick={handleExplainerModalCancel}>
          Close
        </Button>,
      ]}
    >
      Using custom shoutouts that are the same as your company's values can be a huge driver of reinforcing these values over time. Read the advice below to learn how to best choose these important values so that they're more than just over-used buzzwords. 
      <br /><br />

      <img width={"100%"} src={companyOffice} />
      <br /><br />

      This advice comes from an August 2024 article published by  <strong>The Harvard Business Review – <a href="https://hbr.org/2024/07/build-a-corporate-culture-that-works">"Build a Corporate Culture That Works"</a></strong>.
      <br /><br />

      <h3>Step 1: Build Your Culture Based on Real-World Dilemmas</h3>
      The trick to making a desired culture come alive is to debate and articulate it using dilemmas. If you identify the tough dilemmas your employees routinely face and clearly state how they should be resolved — "In this company, when we come across this dilemma, we turn left" — then your desired culture will take root and influence the behavior of the team.
      <br /><br />

      <h3>Step 2: Move Your Culture from Abstraction to Action</h3>
      If you already have a stated culture consisting of abstract principles in place, “dilemma-test” them to determine whether they are actionable enough to be useful in real decision-making situations. For example, let's pressure-test Pixar's value of "Regularly share unfinished work":
      <br /><br />

      <img width={"100%"} src={pixarValues} />
      <br /><br />
  
      <h3>Step 3: Paint Your Culture in Full Color</h3>
      
      Once you have identified a clear set of values and dilemma-tested them, articulate your desired culture using concrete, colorful images to get the values to stick. Research on the picture superiority effect (PSE) shows that images lodge themselves in our memories in a way abstract words don’t. If I ask you to remember the words “justice” and “pineapple,” you are more likely to remember “pineapple.” If I tell you that the pineapple is the size and texture of a groundhog covered in icicles, it lodges indelibly in your memory.
      <br /><br />

      <img width={"100%"} src={paintedBuilding} />
      <br /><br />

      Another way to put color into your organizational culture is to articulate it in an edgy, counterintuitive way. Be provocative, and your employees will remember. The counterintuitive “Don’t seek to please your boss” stops employees in their tracks and forces them to make each decision with the good of the company foremost in mind.
      <br /><br />

      <h3>Step 4: Hire the Right People, and They Will Build the Right Culture</h3>
      If you hire people whose personalities don’t align with your culture, no matter what else you get right, you are unlikely to get the desired behavior. When defining company culture, first tackle whom you will hire. One company that has done this well is Patagonia. This example paragraph, clipped from their culture page demonstrates how to steer managers toward right-fit hiring decisions:
      <br /><br />

      <img width={"100%"} src={patagoniaValues} />
      <br /><br />

      This description makes it super clear that you should take a chance on a mountain biker who may not have the right technical experience, and that you should go through employee referrals to find candidates and not pay a headhunter to find job candidates.
      <br /><br />

      <h3>Step 5: Make Sure that Culture Drives Strategy</h3>
      Many companies define their culture by focusing on employee attitudes, but it's much more important to identify your strategic objective—whether it is to reduce costs, minimize business complexity, or scale up through mergers—and use dilemmas to ensure that your employees understand what decisions they should be making to move the business in the right direction. Take HubSpot for example, tt’s culture brief states: “We are adaptable, constantly changing, lifelong learners.” It then presents a hiring scenario that brings the value to life.
      <br /><br />

      <img width={"100%"} src={hubspotValues} />
      <br /><br />

      Any employee faced with the decision “Should I hire a highly talented employee who’s seeking stability and predictability?” knows which way to lean: Don’t make the hire.

    </Modal>
  );

  // Main UI
  const columns = [
    {
      title: "Shoutout name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return <div>{record.shoutout_name}</div>;
      },
    },
    {
      title: "Shoutout Description",
      render: (_, record) => {
        return <div>{record.shoutout_description}</div>;
      },
    },
    {
      title: "Icon",
      render: (_, record) => {
        return (
          <div style={{ width: "120px" }}>
            {getPreviewIconImageForShoutout(record)}
            <span style={{ marginLeft: "10px"}}>
              {getNiceName(record.shoutout_icon)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Color",
      render: (_, record) => {
        return (
          <div
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: record.shoutout_color,
            }}
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => openEditModalWithShoutout(record)}>Edit Shoutout</a>
          <a onClick={() => deleteShoutoutClicked(record)}>Delete Shoutout</a>
        </Space>
      ),
    },
  ];

  // Table Data
  var tableArea;
  if (getCustomShoutoutsQuery.isLoading) {
    tableArea = <div>Loading custom shoutouts...</div>;
  } else if (getCustomShoutoutsQuery.isError || getCustomShoutoutsQuery.data.error) {
    tableArea =
    "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getCustomShoutoutsQuery.data.customShoutouts.map(
      (currentShoutout) => {
        return {
          id: currentShoutout.id,
          key: currentShoutout.id,
          shoutout_name: currentShoutout.shoutout_name,
          shoutout_description: currentShoutout.shoutout_description,
          shoutout_color: currentShoutout.shoutout_color,
          shoutout_icon: currentShoutout.shoutout_icon,
          shoutout_custom_icon_path: currentShoutout.shoutout_custom_icon_path,
        };
      }
    );
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
        scroll={{ x: 'max-content' }} // Allows horizontal scrolling if needed
      />
    );
  }

  // Page Frame
  let mainArea;
  mainArea = (
    <>
      <Title>
        <NotificationOutlined style={{ fontSize: "32px" }} /> Custom Shoutouts
      </Title>

      {/* top left side */}
      <a
          onClick={() => {
            setExplainValuesModalOpen(true);
          }}
          className="explainResults"
          href="#"
        >
        <ExclamationCircleOutlined /> Advice on choosing & instilling company values
        <br />
        <br />
      </a>

      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={() => openEditModalWithShoutout()}
      >
        Add custom shoutout
      </Button>

      <div style={{ marginTop: "30px" }}></div>
      {tableArea}
    </>
  );

  return (
    <>
      <MainContainer>
        <div className="main-container">
          {createShoutoutModal}
          {explainValuesModal}
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}

export default App;
