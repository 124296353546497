import React, { useState, useEffect } from "react";
import MainContainer from "../../Container/MainContainer";
import { Card, Tabs } from "antd";
import { EditOutlined, KeyOutlined } from "@ant-design/icons";
import DoodleDashTab from './DoodleDashTab';
import FishbowlTab from './FishbowlTab';
import { useTenantChannels, renderChannelOptions, mapChannels, mapChannelIdsToDisplayNames, mapChannelNamesToIds } from "../Shared/useTenantChannels";

const { TabPane } = Tabs;

const Games = () => {

    const [tokenErrorType, setTokenErrorType] = useState(false);

    const getTenantChannelsQuery = useTenantChannels(setTokenErrorType);
    const channelOptions = renderChannelOptions(getTenantChannelsQuery?.data);

    return (
        <MainContainer>
            <div className="main-container">
                <Card className="main-container-card">
                <Tabs defaultActiveKey="1">
                    <TabPane tab={<span><EditOutlined /> DoodleDash</span>} key="1">
                        <DoodleDashTab channelOptions={channelOptions} />
                    </TabPane>
                    <TabPane tab={<span><KeyOutlined /> Fishbowl</span>} key="2">
                        <FishbowlTab />
                    </TabPane>
                </Tabs>
                </Card>
            </div>
        </MainContainer>
    );
}

export default Games;