import React, { useState, useEffect, useCallback } from 'react';
import { Upload, message, Card, Typography, List, Button, Popconfirm, Badge, Image, Switch, Tooltip, Input, Tag, DatePicker, Table, Statistic, Modal, Spin } from 'antd';
import { InboxOutlined, QuestionCircleOutlined, FileOutlined, DownloadOutlined, DeleteOutlined, RobotOutlined, InfoCircleOutlined, EyeOutlined, EyeInvisibleOutlined, CommentOutlined } from '@ant-design/icons';
import MainContainer from "../Container/MainContainer";
import { doPostRequest, doGetRequest, getBaseUrl, getTokenErrorAreaIfNeeded } from "../../helpers/requestHelpers";
import { saveAs } from 'file-saver';
import moment from 'moment'; // Import moment for date handling
import { CSVLink } from 'react-csv'; // Import CSVLink for CSV download
import ReactMarkdown from 'react-markdown'; // Import the Markdown renderer
import {
  convertMrkdwnToHtml
} from "../../helpers/utilityHelpers";

const { Dragger } = Upload;
const { Title, Paragraph } = Typography;

export default function AssistantPage() {
  const [existingDocs, setExistingDocs] = useState([]);
  const [hotKeywords, setHotKeywords] = useState([]); // New state for hot keywords
  const [newKeyword, setNewKeyword] = useState(''); // State for new keyword input
  const [answers, setAnswers] = useState([]); // State for employee answers
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalSuccessfullyAnswered, setTotalSuccessfullyAnswered] = useState(0);
  const [successRate, setSuccessRate] = useState(0);
  const [topThemes, setTopThemes] = useState(''); // State to hold top themes
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const [loadingThemes, setLoadingThemes] = useState(false); // New loading state

  // New state for customizable messages
  const [noAnswerMessage, setNoAnswerMessage] = useState(''); // Message when bot cannot answer
  const [keywordResponseMessage, setKeywordResponseMessage] = useState(''); // Message for hot keywords

  // Calculate the last week's date range
  const lastWeekStart = moment().subtract(7, 'days').startOf('day');
  const lastWeekEnd = moment().endOf('day');

  // Initialize state with last week's date range
  const [dateRange, setDateRange] = useState([lastWeekStart, lastWeekEnd]); // Default to last week

  const [showPreview, setShowPreview] = useState(false);

  const fetchExistingDocs = useCallback(async () => {
    try {
      console.log('Fetching existing documents...');
      const response = await doGetRequest(
        `${getBaseUrl()}/api/getEmployeeAssistantDocs`,
        getTokenErrorAreaIfNeeded
      );

      console.log('Response:', response);

      if (!response.error && Array.isArray(response.documents)) {
        console.log('Docs received:', response.documents);
        setExistingDocs(response.documents);
      } else {
        console.error('Error fetching docs:', response.error || 'Invalid response format');
        setExistingDocs([]);
        message.error(`Failed to fetch existing documents: ${response.error || 'Invalid response format'}`);
      }
    } catch (error) {
      console.error('Error fetching existing documents:', error);
      setExistingDocs([]);
      message.error(`Error fetching existing documents: ${error.message}`);
    }
  }, []);

  const fetchHotKeywordsAndMessageCustomizations = useCallback(async () => {
    try {
      const response = await doGetRequest(
        `${getBaseUrl()}/api/getHotKeywordsAndMessageCustomizations`,
        getTokenErrorAreaIfNeeded
      );

      if (!response.error && Array.isArray(response.hot_keywords)) {
        setHotKeywords(response.hot_keywords); // Update state with fetched keywords
        setNoAnswerMessage(response.assistant_no_answer_message || 
          "I'm not sure I can help you with that specific question – please reach out to your HR/People team for more information or contact one of your CultureBot admins"
        ); // Default message if not present
        setKeywordResponseMessage(response.keyword_response_message || 
          "Your question should be directed to your HR/People team for more information or contact one of your CultureBot admins"
        ); // Default message if not present
      } else {
        console.error('Error fetching hot keywords:', response || 'Invalid response format');
        message.error(`Failed to fetch hot keywords: ${response.error || 'Invalid response format'}`);
      }
    } catch (error) {
      console.error('Error fetching hot keywords:', error);
      message.error(`Error fetching hot keywords: ${error.message}`);
    }
  }, []);

  // Fetch answers based on date range
  const fetchAnswers = useCallback(async (startDate, endDate) => {
    try {
      console.log('Fetching answers... ', startDate, endDate);

      const firstDateUnix = moment(startDate.startOf("day")).unix();
      const secondDateUnix = moment(endDate.endOf("day")).unix();
      
      const response = await doGetRequest(
        `${getBaseUrl()}/api/getEmployeeAssistantAnswers?start_date=${firstDateUnix}&end_date=${secondDateUnix}`,
        getTokenErrorAreaIfNeeded
      );

      if (!response.error && Array.isArray(response.answers)) {
        setAnswers(response.answers); // Update state with fetched answers
        // Update metrics from the response
        const { total_questions_asked, total_successfully_answered, success_rate } = response.metrics;
        setTotalQuestions(total_questions_asked);
        setTotalSuccessfullyAnswered(total_successfully_answered);
        setSuccessRate(success_rate);
      } else {
        console.error('Error fetching answers:', response.error || 'Invalid response format');
        setAnswers([]);
      }
    } catch (error) {
      console.error('Error fetching answers:', error);
      setAnswers([]);
    }
  }, []);

  // Handle date range change
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const startDate = moment(dates[0]).utc().startOf("day");
      const endDate = moment(dates[1]).utc().endOf("day");
      fetchAnswers(startDate, endDate); 
    }
  };

  // Function to fetch top themes with loading state
  const fetchTopThemes = async () => {
    setLoadingThemes(true); // Set loading to true
    try {
      const questions = answers.map(answer => answer.question); // Extract questions from answers
      const response = await doPostRequest(
        `${getBaseUrl()}/api/topEmployeeAssistantThemes`,
        { questions: questions } // Send only questions
      );

      if (!response.error) {
        setTopThemes(response.summary); // Update state with fetched themes
      } else {
        message.error(`Failed to fetch top themes: ${response.error || 'Invalid response format'}`);
      }
    } catch (error) {
      message.error(`Error fetching top themes: ${error.message}`);
    } finally {
      setLoadingThemes(false); // Set loading to false after fetching
    }
  };

  // Function to handle button click
  const handleTopThemesClick = () => {
    if (answers.length === 0) {
      message.warning('Please select a date range that provides data before fetching top themes.'); // Show warning message
      return; // Exit the function if no answers are available
    }
    fetchTopThemes(); // Fetch themes when button is clicked
    setIsModalVisible(true); // Show the modal
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsModalVisible(false); // Hide the modal
  };

  useEffect(() => {
    fetchExistingDocs();
    fetchHotKeywordsAndMessageCustomizations();
    fetchAnswers(lastWeekStart, lastWeekEnd);
  }, [fetchExistingDocs, fetchHotKeywordsAndMessageCustomizations, fetchAnswers]);

  const handlePDFUpload = async (file) => {
    const formData = new FormData();
    formData.append('pdf', file, file.file_path);

    // Log FormData contents
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      console.log('Attempting to upload file:', file.file_path);
      console.log('Upload URL:', `${getBaseUrl()}/api/handlePDFUpload`);
      
      const response = await doPostRequest(
        `${getBaseUrl()}/api/handlePDFUpload`,
        formData,
        getTokenErrorAreaIfNeeded
      );

      console.log('Server response:', response);

      if (!response.error) {
        message.success({
          content: `Document uploaded successfully`,
          duration: 5,
          style: {
            marginTop: '20vh',
          },
        });
        fetchExistingDocs(); // Refresh the list after successful upload
      } else {
        message.error({
          content: `Upload failed: ${response.error}`,
          duration: 5,
          style: {
            marginTop: '20vh',
          },
        });
      }
    } catch (error) {
      console.error('Detailed error:', error);
      message.error({
        content: `Error uploading PDF: ${error.message}`,
        duration: 5,
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  const handleDownload = async (document) => {
    try {
      const response = await doGetRequest(
        `${getBaseUrl()}/api/downloadEmployeeAssistantDoc?file_path=${encodeURIComponent(document.file_path)}`,
        getTokenErrorAreaIfNeeded,
        [],
        { responseType: 'blob' }
      );

      if (response instanceof Blob) {
        saveAs(response, document.file_path || 'document');
      } else {
        throw new Error('Response is not a Blob');
      }
    } catch (error) {
      console.error('Error downloading document:', error);
      message.error(`Error downloading document: ${error.message}`);
    }
  };

  const handleDelete = async (document) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/deleteEmployeeAssistantDoc`,
        { file_path: document.file_path },
        getTokenErrorAreaIfNeeded
      );

      if (response.success) {
        message.success(`Document deleted successfully`);
        fetchExistingDocs(); // Refresh the list after successful deletion
      } else {
        throw new Error(response.error || 'Failed to delete document');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      message.error(`Error deleting document: ${error.message}`);
    }
  };

  const handleVisibilityToggle = async (document, isShown) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/toggleEmployeeAssistantDocVisibility`,
        { file_path: document.file_path, isShown },
        getTokenErrorAreaIfNeeded
      );

      if (response.success) {
        // Extract the file name from the full path
        const fileName = document.file_path.split('/').pop();
        message.success(`Document visibility updated successfully`);
        fetchExistingDocs(); // Refresh the list after successful update
      } else {
        throw new Error(response.error || 'Failed to update document visibility');
      }
    } catch (error) {
      console.error('Error updating document visibility:', error);
      message.error(`Error updating document visibility: ${error.message}`);
    }
  };

  const saveHotKeywords = async (keywords) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/saveHotKeywords`,
        { keywords }, // Send the keywords as an object
        getTokenErrorAreaIfNeeded
      );

      if (response.success) {
        message.success('Hot keywords saved successfully!');
        fetchHotKeywordsAndMessageCustomizations(); // Refresh the list after saving
      } else {
        throw new Error(response.error || 'Failed to save hot keywords');
      }
    } catch (error) {
      console.error('Error saving hot keywords:', error);
      message.error(`Error saving hot keywords: ${error.message}`);
    }
  };

  const handleKeywordSubmit = () => {
    if (newKeyword && !hotKeywords.includes(newKeyword)) {
      const updatedKeywords = [...hotKeywords, newKeyword];
      setHotKeywords(updatedKeywords); // Update state with new keyword
      setNewKeyword(''); // Clear input after submission
      message.success(`Keyword "${newKeyword}" added.`);
      saveHotKeywords(updatedKeywords); // Save updated keywords to the server
    } else {
      message.error('Please enter a valid keyword or it already exists.');
    }
  };

  const checkForHotKeywords = (message) => {
    const foundKeywords = hotKeywords.filter(keyword => message.includes(keyword));
    if (foundKeywords.length > 0) {
      message.error('Please reach out to HR directly regarding sensitive topics.');
    }
  };

  const handleKeywordRemove = async (keyword) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/removeHotKeyword`,
        { keyword }, // Send the keyword to be removed
        getTokenErrorAreaIfNeeded
      );

      if (response.success) {
        setHotKeywords(hotKeywords.filter(k => k !== keyword)); // Update state after successful removal
        message.success(`Keyword "${keyword}" removed successfully.`);
      } else {
        throw new Error(response.error || 'Failed to remove keyword');
      }
    } catch (error) {
      console.error('Error removing keyword:', error);
      message.error(`Error removing keyword: ${error.message}`);
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    accept: '.pdf',
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(`${file.name} is not a PDF file. We only accept PDFs.`);
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    customRequest: ({ file, onSuccess, onError }) => {
      handlePDFUpload(file)
        .then(() => {
          onSuccess('ok');
          fetchExistingDocs(); // Refresh the list after successful upload
        })
        .catch((error) => {
          onError(error);
          console.error('Upload error:', error);
        });
    },
  };

  // Columns for the answers table
  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      fixed: 'left', // Fix this column to the left
      width: 120, // Set a fixed width for the date column
      render: (text) => moment.unix(text).format('MM/DD/YYYY'), // Convert Unix timestamp to date
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      fixed: 'left', // Fix this column to the left
      width: 300, // Set a fixed width
    },
    {
      title: 'Employee',
      dataIndex: 'user_id',
      key: 'user_id',
      fixed: 'left', // Fix this column to the left
      width: 150, // Set a fixed width
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'Answered Successfully?',
      dataIndex: 'is_relevant', // Assuming the response has an 'answer' field
      key: 'is_relevant',
    },
    {
      title: 'Answer',
      dataIndex: 'answer', // Assuming the response has an 'answer' field
      key: 'answer',
    },
  ];

  // Function to format data for CSV export
  const formatDataForCSV = (data) => {
    return data.map(item => ({
      question: item.question,
      user_id: item.user_id,
      user_email: item.user_email,
      answer: item.answer,
      is_relevant: item.is_relevant,
      date: moment(item.date).format('YYYY-MM-DD'), // Format date to match the table
    }));
  };

  // Define CSV headers to match table headers
  const csvHeaders = [
    { label: 'Question', key: 'question' },
    { label: 'Employee', key: 'user_id' },
    { label: 'Email', key: 'user_email' },
    { label: 'Answer', key: 'answer' },
    { label: 'Answered Successfully?', key: 'is_relevant' },
    { label: 'Date', key: 'date' },
  ];

  // Function to save bot message response settings
  const saveBotMessageResponses = async (type) => {
    const messageData = {
      noAnswerMessage,
      keywordResponseMessage,
    };    
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/updateEmployeeAssistantSettings`,
        { type, message: messageData }, // Send the type and settings
        getTokenErrorAreaIfNeeded
      );

      if (response.success) {
        message.success(response.message); // This should work if message is imported correctly
      } else {
        message.error(`Failed to update employee assistant message settings: ${response.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      message.error(`Error updating settings: ${error.message}`);
    }
  };

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Title level={2} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
              <RobotOutlined style={{ fontSize: "32px", marginRight: '10px' }} />
              Train Your Employee Assistant
            </Title>
            <Badge 
              count="Beta" 
              style={{ 
                backgroundColor: '#52c41a',
                marginTop: '5px' // Adjust this value as needed
              }} 
            />
          </div>
          <br />
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single PDF upload
            </p>
          </Dragger>
        </Card>

        <Card style={{ padding: 10, margin: 20 }}>
          <Title level={3}>
            <FileOutlined style={{ fontSize: "24px" }} /> Existing Documents
          </Title>
          <List
            dataSource={existingDocs}
            renderItem={(item) => {
              // Extract filename from the full path
              const fileName = item.file_path.split('/').pop();
              
              return (
                <List.Item
                  key={item.file_path} // Ensure each item has a unique key
                  actions={[
                    <Tooltip title={item.file_shown ? "Hide from employees" : "Show to employees"}>
                      <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                        checked={item.file_shown}
                        onChange={(checked) => handleVisibilityToggle(item, checked)}
                      />
                    </Tooltip>,
                    <Button 
                      icon={<DownloadOutlined />} 
                      onClick={() => handleDownload(item)}
                    >
                      Download
                    </Button>,
                    <Tooltip title="Delete document">
                      <Popconfirm
                        title="Are you sure you want to delete this document?"
                        onConfirm={() => handleDelete(item)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button 
                          icon={<DeleteOutlined />} 
                          danger
                        />
                      </Popconfirm>
                    </Tooltip>
                  ]}
                >
                  <FileOutlined style={{ marginRight: 8 }} /> 
                  {fileName || 'Unnamed document'}
                </List.Item>
              );
            }}
            locale={{ emptyText: "No documents uploaded yet" }}
          />
        </Card>

                {/* New section for hot keywords */}
                <Card style={{ padding: 10, margin: 20 }}>
          <Title level={3}>
            <EyeOutlined style={{ fontSize: "24px", marginRight: '10px' }} />
            Hot Keywords
          </Title>
          <Paragraph>
            Enter keywords that are sensitive or require special attention. 
            When an employee types these keywords, the system will prompt them 
            to reach out to HR directly instead of providing a response. 
            This helps ensure that sensitive topics are handled appropriately.
          </Paragraph>
          <Input
            value={newKeyword}
            onChange={(e) => setNewKeyword(e.target.value)}
            onPressEnter={handleKeywordSubmit}
            placeholder="Enter a hot keyword and press Enter"
          />
          <Paragraph style={{ marginTop: '10px' }}>
            Current hot keywords: 
            {hotKeywords.length > 0 ? (
              hotKeywords.map((keyword) => (
                <Tag 
                  key={keyword} 
                  closable 
                  onClose={() => handleKeywordRemove(keyword)}
                  style={{ margin: '4px' }}
                >
                  {keyword}
                </Tag>
              ))
            ) : (
              ' None'
            )}
          </Paragraph>
        </Card>

        <Card style={{ padding: 10, margin: 20 }}>
          <Title level={3}>
            <CommentOutlined style={{ fontSize: "24px", marginRight: '10px' }} />
            Customize Bot Responses
          </Title>
          <Paragraph>
            Customize the messages the bot will use in specific situations. You can also use the following syntax/formatting in your messages:
          </Paragraph>
          
          <Paragraph style={{ marginTop: '20px' }}>
            <ul>
              <li><strong>Bold Text:</strong> <code>*This is bold*</code></li>
              <li><strong>Italic Text:</strong> <code>_This is italic_</code></li>
              <li><strong>Links:</strong> <code>&lt;http://example.com|Link Text&gt;</code></li>
            </ul>
          </Paragraph>

          <h3>1. Message for when the bot cannot answer successfully</h3>
          <Input.TextArea
            value={noAnswerMessage}
            onChange={(e) => setNoAnswerMessage(e.target.value)}
            rows={6} 
          />
          <Button 
            type="default" // Secondary color
            icon={<EyeOutlined />} // Eye icon
            onClick={() => setShowPreview('noAnswer')} // Set preview for noAnswerMessage
            style={{ marginTop: '10px', marginRight: '10px' }}
          >
            Preview Message
          </Button>
          <Button 
            type="primary" // Primary color for save
            onClick={() => saveBotMessageResponses('noAnswer')} // Save function for noAnswerMessage
            style={{ marginTop: '10px' }}
          >
            Save Message
          </Button>

          <h3 style={{ marginTop: '20px' }}>2. Message for when a hot keyword is detected</h3>
          <Input.TextArea
            value={keywordResponseMessage}
            onChange={(e) => setKeywordResponseMessage(e.target.value)}
            rows={6}
          />
          <Button 
            type="default" // Secondary color
            icon={<EyeOutlined />} // Eye icon
            onClick={() => setShowPreview('keywordResponse')} // Set preview for keywordResponseMessage
            style={{ marginTop: '10px', marginRight: '10px' }}
          >
            Preview Message
          </Button>
          <Button 
            type="primary" // Primary color for save
            onClick={() => saveBotMessageResponses('keywordResponse')} // Save function for keywordResponseMessage
            style={{ marginTop: '10px' }}
          >
            Save Message
          </Button>

          {/* Preview Section */}
          {showPreview && (
            <>
              <h3 style={{ marginTop: '20px', marginBottom: '10px', display: 'block' }}>Message Preview:</h3>
              <div style={{ border: '1px solid #d9d9d9', padding: '10px 15px', borderRadius: '5px' }}>
                {showPreview === 'noAnswer' && (
                  <div dangerouslySetInnerHTML={{ __html: convertMrkdwnToHtml(noAnswerMessage || 'Enter your message above to see the preview.') }} />
                )}
                {showPreview === 'keywordResponse' && (
                  <div dangerouslySetInnerHTML={{ __html: convertMrkdwnToHtml(keywordResponseMessage || 'Enter your message above to see the preview.') }} />
                )}
              </div>
            </>
          )}
        </Card>

        <Card style={{ padding: 10, margin: 20 }}>
          <Title level={3}>
            <InfoCircleOutlined style={{ fontSize: "24px", marginRight: '10px' }} /> Example Employee Usage
          </Title>
          <Paragraph>
            Employees can use the AI Assistant to quickly find information from uploaded documents. 
            Simply ask a question related to the content of the documents, and the AI will provide 
            relevant answers and citations.
          </Paragraph>
          <Image
            src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/other/employee-assistant-example-usage.png"
            alt="Example of AI Assistant usage"
            style={{
              maxWidth: '100%',
              height: 'auto',
              marginTop: '20px',
              border: '1px solid #d9d9d9',  // Light gray border
              borderRadius: '8px',          // Rounded corners
              padding: '8px',               // Some padding inside the border
            }}
            preview={false}
          />
          <Paragraph style={{ marginTop: '15px', fontStyle: 'italic', color: '#666' }}>
            This image demonstrates a sample interaction between an employee and the AI Assistant. 
            It shows how employees can ask questions and receive answers based on information from the uploaded documents.
          </Paragraph>
        </Card>

        {/* New Reports Section */}
        <Card style={{ padding: 10, margin: 20 }}>
          <Title level={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>
              <QuestionCircleOutlined style={{ marginRight: '10px' }} />
              Questions & Answers Report
            </span>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              {/* New Top Themes Button */}
              <Button 
                type="primary" 
                icon={<RobotOutlined />} // Add the icon for consistency
                style={{ marginRight: '10px' }} // Add some space between buttons
                className="ai-analysis-btn"
                onClick={handleTopThemesClick}
              >
                View Top Themes
              </Button>

              {/* Download Button */}
              <CSVLink 
                data={formatDataForCSV(answers)} // Use formatted data for CSV
                headers={csvHeaders} // Pass the headers for the CSV
                filename={"employee_qa_report.csv"}
              >
                <Tooltip title="Download Q&A (CSV)">
                  <DownloadOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                </Tooltip>
              </CSVLink>

            </div>
          </Title>
          <DatePicker.RangePicker 
            value={dateRange}
            onChange={handleDateRangeChange}
            style={{ marginBottom: '20px' }}
          />
          
          {/* Aggregate Metrics Section */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Card style={{ width: '30%' }}>
              <Statistic title="Total Questions Asked" value={totalQuestions} />
            </Card>
            <Card style={{ width: '30%' }}>
              <Statistic title="Total Successfully Answered" value={totalSuccessfullyAnswered} />
            </Card>
            <Card style={{ width: '30%' }}>
              <Statistic title="Assistant Success Rate (%)" value={successRate.toFixed(2)} suffix="%" />
            </Card>
          </div>

          <Table 
            dataSource={answers} 
            columns={columns} 
            rowKey="id" // Assuming each answer has a unique 'id'
            locale={{ emptyText: "No answers found for the selected date range." }}
            scroll={{ x: 'max-content' }} // Enable horizontal scrolling
          />
        </Card>

        {/* Modal for Top Themes */}
        <Modal
          title="Top Themes"
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null} // No footer buttons
        >
          {loadingThemes ? (
             <div style={{ textAlign: 'center', padding: '20px' }}>
              <Spin size="large" />
              <p style={{ marginTop: '10px' }}>Analyzing results for top themes (please be patient)...</p>
            </div>
          ) : (
            <Paragraph>
              {topThemes.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  {index < topThemes.split('\n').length - 1 && (
                    <>
                      <br />
                      <br /> {/* Two line breaks for each line except the last */}
                    </>
                  )}
                </span>
              ))}
            </Paragraph>
          )}
        </Modal>

      </div>
    </MainContainer>
  );
}