import React from "react";
import { Area } from "@ant-design/plots";
import { Alert } from "antd";
import moment from "moment";

// response rate graphs
export default function ResponseRateOverTime({ data }) {

  // data must be present to show charts
  if (data) {

    // not enough data to show graph (this shouldn't happen, as the overview graphs are hidden if fewer than 2 campaign runs exist)
    if (data["campaign_run_overview"].length < 2) {
      return (
        <div>
          <Alert type="warning" showIcon description="Not enough campaign runs to show overview graphs at this time, please check back once you have at least two campaign runs!" />
          <br />
        </div>
      );
    }

    // --------- response rates ---------
    const full_chart_data_response_rates = [];
    const full_chart_data_total_responses = [];
    for (const [key, campaign_run] of Object.entries(
      data["campaign_run_overview"]
    )) {
        full_chart_data_response_rates.push({
            timePeriod: moment.unix(campaign_run["run_time"]).format("MM/DD/YYYY"),
            value: Math.round(
            (campaign_run["total_responses"] / campaign_run["audience_size"]) *
                100,
            2
            ),
        });
        full_chart_data_total_responses.push({
            timePeriod: moment.unix(campaign_run["run_time"]).format("MM/DD/YYYY"),
            value: campaign_run["total_responses"]
        });
    }

    // example: https://ant-design-charts.antgroup.com/en/examples/line/multiple/#line-label
    const config_rates = {
      data: full_chart_data_response_rates,
      xField: "timePeriod",
      yField: "value",
      xAxis: {
        range: [0, 1],
      },
      yAxis: {
        label: {
          formatter: (v) => `${v}%`,
        },
      },
      smooth: true,
      point: {
        size: 5,
        shape: "dot",
      },
    };

    const config_total_responses = {
        data: full_chart_data_total_responses,
        xField: "timePeriod",
        yField: "value",
        xAxis: {
          range: [0, 1],
        },
        yAxis: {
          label: {
            formatter: (v) => `${v}`,
          },
        },
        smooth: true,
        point: {
          size: 5,
          shape: "dot",
        },
      };

    return (
      <>
        <h3>Response Rate (Over Time):</h3>
        <Area {...config_rates} style={{ maxHeight: 300 }} />
        <br />
        <h3>Total Responses (Over Time):</h3>
        <Area {...config_total_responses} style={{ maxHeight: 300 }} />
        <br />
      </>
    );
  } else {
    return <label>Loading response rates over time...</label>;
  }
}

// number type graphs
export function NumberTypeAggregations({ data }) {

  // data must be present to show charts
  if (data) {

    // data shape: {"number_questions": [
    //     {
    //       "question one..." [
    //          {
    //              "created_at": 176039234
    //              "gross_total_for_question": 150
    //              "total_submissions": 10 
    //          },
    //          ...
    //       ]
    //     },
    //     ... 
    // ]}

    // no numbers graphs to even show
    if (data["number_questions"].length < 1) {
        return "";
    }

    // loop through all questions that have data available (as number type)
    const all_charts = []
    for (const [key, stats] of Object.entries(
      data["number_questions"]
    )) {

        // build chart data for each question's stats
        const single_chart_data = []    
        for (const [key_two, question_data_for_run] of Object.entries(
            stats
        )) {
            single_chart_data.push({
                timePeriod: moment.unix(question_data_for_run["run_time"]).format("MM/DD/YYYY"),
                  value: parseFloat((question_data_for_run["gross_total_for_question"] / question_data_for_run["total_submissions"]).toFixed(2)),
            });
        }

        const config = {
            data: single_chart_data,
            xField: "timePeriod",
            yField: "value",
            xAxis: {
              range: [0, 1],
            },
            smooth: true,
            point: {
              size: 5,
              shape: "dot",
            },
        };

        // add this chart to charts array
        all_charts.push(
            <>
                <h3>{key} (Average Over Time):</h3>
                <Area {...config} style={{ maxHeight: 300 }} />
                <br />
            </>
        ); 
    }
    return all_charts;
  } else {
    return <label>Loading averages for number-type form questions over time...</label>;
  }
}
