import React from "react";
import {
    MailOutlined, 
    SendOutlined,
    UserOutlined
} from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import FeedbackTable from "./FeedbackTable";
import { CSVLink } from "react-csv";
import {
  Card,
  Typography,
  Progress,
  DatePicker,
  Col,
  Row,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./FeedbackStats.css";
import moment from "moment";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function FeedbackPage() {

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const firstDateFromStorage = sessionStorage.getItem("feedback_stats_first_date");
  const secondDateFromStorage = sessionStorage.getItem(
    "feedback_stats_second_date"
  );

  const defaultFirstDate = moment().subtract(7, "d");
  const defaultSecondDate = moment();
  const [firstDate, setFirstDate] = useState(
    firstDateFromStorage ? moment(firstDateFromStorage) : defaultFirstDate
  );
  const [secondDate, setSecondDate] = useState(
    secondDateFromStorage ? moment(secondDateFromStorage) : defaultSecondDate
  );

  // fetch feedback
  const getFeedbackQuery = useQuery(
    ["GET_FEEDBACK_SENT", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment.utc(firstDate.startOf("day")).unix();
      const secondDateUnix = moment.utc(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getFeedback`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );
  
  // fetch stats
  const getFeedbackStatsQuery = useQuery(
    ["GET_FEEDBACK_STATS", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment.utc(firstDate.startOf("day")).unix();
      const secondDateUnix = moment.utc(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getFeedbackStats`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // ---- UI handlers ---- //
  async function dateRangeSelectedChange(input) {
    // input expected to be null in case when x is selected
    if (input == null) {
      setFirstDate(defaultFirstDate);
      setSecondDate(defaultSecondDate);
      sessionStorage.removeItem("feedback_stats_first_date");
      sessionStorage.removeItem("feedback_stats_second_date");
      return;
    }

    setFirstDate(input[0]);
    setSecondDate(input[1]);
    // cache values in browser so user doesn't lose place when refreshing window
    sessionStorage.setItem("feedback_stats_first_date", input[0]);
    sessionStorage.setItem("feedback_stats_second_date", input[1]);
  }

  async function setAllTimeDateRange() {
    const allTime = moment().set({ year: 2020, month: 0, date: 1 });
    setFirstDate(allTime);
    setSecondDate(moment()); // now
    sessionStorage.setItem("feedback_stats_first_date", allTime);
    sessionStorage.setItem("feedback_stats_second_date", moment()); // now
  }

  // ---- stats section ---- //
  const StatsSection = () => (
    <Row gutter={16}>
      <Col span={6}>
        <Card
          title={
            <div>
              <SendOutlined /> Feedback Submissions 
            </div>
          }
        >
          <div className="progressBox">
            <div className="progressBox-left">
              <Progress
                type="circle"
                percent={getFeedbackStatsQuery.data ? Math.round(getFeedbackStatsQuery.data.stats.anonymous/getFeedbackStatsQuery.data.stats.sent*100,2) : 0}
              />
            </div>
            <div className="progressBox-right">
              <strong>Submissions: </strong>
              {getFeedbackStatsQuery.data ? getFeedbackStatsQuery.data.stats.sent : null}
              <br />
              <strong>Anonymous: </strong>
              {getFeedbackStatsQuery.data ? getFeedbackStatsQuery.data.stats.anonymous : null}
            </div>
          </div>
        </Card>
      </Col>
      <Col span={6}>
        <Card
          title={
            <div>
              <UserOutlined /> Top Senders
            </div>
          }
        >
          {getFeedbackStatsQuery.data ? getFeedbackStatsQuery.data.stats.top_senders.map((sender, index) => {
            if (index > 9) {
              return undefined;
            } // show max 10
            if (index == getFeedbackStatsQuery.data.stats.top_senders.length - 1) {
              return sender.name + ": " + sender.sent;
            } else {
              return sender.name + ": " + sender.sent + ", ";
            }
          }) : null}
        </Card>
      </Col>
    </Row>
  );

  // Page Frame
  let mainArea;
  if (getFeedbackQuery.isLoading) {
    mainArea = <div>Loading...</div>;
  } else if (getFeedbackQuery.isError || getFeedbackQuery.data.error) {
    mainArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    mainArea = (
      <div>
        <div>
          <Title>
            <MailOutlined style={{ fontSize: "32px" }} /> Employee Feedback
          </Title>
          <p>
            The table below shows all the employee feedback shared to date. Click the button on each row to start a conversation in Slack with the provider of the feedback (if anonymous, anonymity will be kept).
          </p>
          <div>
            <h3>Date Range:</h3>
            <RangePicker
              onChange={dateRangeSelectedChange}
              value={[firstDate, secondDate]}
            />
            &nbsp;&nbsp;
            <a onClick={() => setAllTimeDateRange()}>All Time</a>
          </div>
          <br />
          <StatsSection />
          <br />
          <FeedbackTable getFeedbackQuery={getFeedbackQuery} />
        </div>
      </div>
    );
  }

  return (
    <>
      <MainContainer>
        <div className="main-container">
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}
