import { useQuery } from "@tanstack/react-query";
import { getBaseUrl, doGetRequest } from "../../../helpers/requestHelpers";
import { Select } from "antd";

const { Option } = Select;

export const useTenantChannels = (setTokenErrorType) => {
  return useQuery(
    ["GET_TENANT_CHANNELS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getChannelsForTenant`,
        setTokenErrorType,
        []
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" }  // Limit teams api calls by requiring refresh for channels to pop up
  );
};

export const renderChannelOptions = (getTenantChannelsQuery) => {
    if (!getTenantChannelsQuery) return null;
    return Object.entries(getTenantChannelsQuery).map(([teamId, teamData]) => (
      <Select.OptGroup key={teamData.team_id} data-optgroup-key={teamData.team_id} label={`Team: ${teamData.team_name}`}>
        {teamData.channels.map(channel => (
          <Option key={channel.id} value={channel.id}>
            {channel.displayName}
          </Option>
        ))}
      </Select.OptGroup>
    ));
}; 

export const mapChannels = (tenantChannelsData) => {
  return Object.values(tenantChannelsData).flatMap(teamData =>
    teamData.channels.map(channel => ({
      id: channel.id,
      displayName: channel.displayName,
      teamName: teamData.team_name,
      teamId: teamData.team_id
    }))
  );
}; 

export const mapChannelIdsToDisplayNames = (channelIds, channelMap) => {
  return channelIds.map(id => {
    const channel = channelMap.find(channel => channel.id === id);
    return channel ? `${channel.teamName} - ${channel.displayName}` : id; // Use team and channel name
  });
}; 

export const mapChannelNamesToIds = (channels, newChannelMap) => {
  const channelIds = channels.map(name => {
    const [teamName, displayName] = name.split(' - ');
    const channel = newChannelMap.find(channel => channel.displayName === displayName && channel.teamName === teamName);
    return channel ? channel.id : name; // Fallback to name if ID not found
  });

  const channelsTeamIds = newChannelMap.reduce((acc, channel) => {
    acc[channel.id] = channel.teamId;
    return acc;
  }, {});

  return { channelIds, channelsTeamIds };
}; 
