import React, { useState, useEffect } from "react";
import MainContainer from "../Container/MainContainer";
import { Card, Button, Modal } from "antd";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doPostRequest,
  doGetRequest,
} from "../../helpers/requestHelpers";
import { InfoCircleOutlined } from "@ant-design/icons";

const Billing = () => {
  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false);
  const [currentSubscriptionDetails, setCurrentSubscriptionDetails] = useState({});

  const openBillingModal = async () => {
    setIsBillingModalVisible(true);
  };

  const closeBillingModal = () => {
    setIsBillingModalVisible(false);
  };

  // get all custom celebrations
  const getHomePageInfoForAdminQuery = useQuery(
    ["GET_HOME_PAGE_INFO_FOR_ADMIN"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getHomePageInfoForAdmin`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if(getHomePageInfoForAdminQuery.data?.main_admin_info) {
        // payment links & amounts
        let subscriptionAmountDollars = Math.round(getHomePageInfoForAdminQuery.data?.main_admin_info.stripe_subscription_amount / 100)
        let paymentFrequency = ''
        let planImage = ''
        if(subscriptionAmountDollars < 1188) {
            paymentFrequency = "per month"
            planImage = "https://culturebot.nyc3.cdn.digitaloceanspaces.com/other/monthly-paid-plan.png"
        } else {
            paymentFrequency = "per year"
            planImage = "https://culturebot.nyc3.cdn.digitaloceanspaces.com/other/annual-paid-plan.png"
        }
        setCurrentSubscriptionDetails({
            amount: subscriptionAmountDollars,
            frequency: paymentFrequency,
            planImage: planImage
        })
    }
  }, [getHomePageInfoForAdminQuery.data?.main_admin_info])

  const upgradeInfo = getHomePageInfoForAdminQuery.data?.upgrade_info || {};
  console.log("upgradeInfo: ", upgradeInfo);

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">
            <img
            src="https://graphics.getculturebot.com/home_header_graphics/upgrade-billing.png"
            alt="upgrade & billing banner"
            style={{ width: "100%", maxWidth: "600px", height: "auto", borderRadius: "10px" }}
          />
          <div style={{ marginTop: "10px", fontSize: "18px", fontWeight: "bold" }}>
            Upgrade & Billing
          </div>
          {currentSubscriptionDetails.amount > 0 && (
            <Button style={{ marginTop: "10px" }} shape="round" onClick={openBillingModal}>
                <InfoCircleOutlined /> View Subscription Details
            </Button>
          )}
          {getHomePageInfoForAdminQuery.data?.main_admin_info.num_slack_users < 25 ? (
            <div style={{ backgroundColor: '#e6f7ff', borderRadius: '10px', padding: '10px', textAlign: 'center', fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>
              CultureBot is free for teams with fewer than 25 users in a workspace
            </div>
          ) : getHomePageInfoForAdminQuery.data?.main_admin_info.num_slack_users >= 200 ? (
            <div style={{ backgroundColor: '#e6f7ff', borderRadius: '10px', padding: '10px', textAlign: 'center', fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>
              Your Teams workspace has {getHomePageInfoForAdminQuery.data.main_admin_info.num_slack_users} users - please <a href="https://calendly.com/culture-bot/15min?month=2024-05" style={{ textDecoration: 'underline' }}>contact us</a> for enterprise pricing.
            </div>
          ) : currentSubscriptionDetails.amount > 0 ? null : (
            <>
              <div style={{ fontSize: "24px", fontWeight: "bold", textAlign: 'center', marginBottom: '20px' }}>Choose Your Plan</div>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {/* BASIC Plan Card */}
                <div style={{ textAlign: 'center', width: '45%', border: '1px solid #e0e0e0', borderRadius: '10px', padding: '20px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
                  <h3 style={{ margin: 0 }}>BASIC</h3>
                  <p style={{ fontStyle: 'italic', color: '#666' }}>Team celebrations only</p>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>✔️ Birthdays</li>
                    <li>✔️ Work Anniversaries</li>
                    <li>✔️ Custom Celebrations</li>
                  </ul>
                  <a href={upgradeInfo.celebrationsLinkMonthly} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '10px', textDecoration: 'none', backgroundColor: '#eee', color: '#007bff', padding: '15px', borderRadius: '5px', textAlign: 'center', fontWeight: 'bold' }}>
                    Upgrade Monthly - {upgradeInfo.celebrationsPriceMonthly}
                  </a>
                  <a href={upgradeInfo.celebrationsLinkAnnual} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '10px', textDecoration: 'none', backgroundColor: '#007bff', color: '#fff', padding: '15px', borderRadius: '5px', textAlign: 'center', fontWeight: 'bold' }}>
                    Upgrade Annually - {upgradeInfo.celebrationsPriceAnnual}
                  </a>
                </div>

                {/* STANDARD Plan Card */}
                <div style={{ textAlign: 'center', width: '45%', border: '2px solid #007bff', borderRadius: '10px', padding: '20px', backgroundColor: '#f0f8ff', boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
                  <h3 style={{ margin: 0 }}>STANDARD</h3>
                  <p style={{ fontStyle: 'italic', color: '#666' }}>Connect & engage your team</p>
                  <div style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff', marginBottom: '10px' }}>Best Value!</div>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>✔️ Everything in Basic</li>
                    <li>✔️ Shoutouts</li>
                    <li>✔️ Peer Rewards & eGift Cards</li>
                    <li>✔️ Conversation Starters</li>
                    <li>✔️ In-Slack Games</li>
                    <li>✔️ Usage stats & reporting</li>
                    <li>✔️ HRIS-Sync</li>
                  </ul>
                  <a href={upgradeInfo.standardLinkMonthly} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '10px', textDecoration: 'none', backgroundColor: '#eee', color: '#007bff', padding: '15px', borderRadius: '5px', textAlign: 'center', fontWeight: 'bold' }}>
                    Upgrade Monthly - {upgradeInfo.standardPriceMonthly}
                  </a>
                  <a href={upgradeInfo.standardLinkAnnual} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '10px', textDecoration: 'none', backgroundColor: '#007bff', color: '#fff', padding: '15px', borderRadius: '5px', textAlign: 'center', fontWeight: 'bold' }}>
                    Upgrade Annually - {upgradeInfo.standardPriceAnnual}
                  </a>
                </div>
              </div>
            </>
          )}
        </Card>
      </div>

      {/* billing management modal */}
      {currentSubscriptionDetails.amount ? (
        <Modal
          title="Manage Account Billing"
          visible={isBillingModalVisible}
          onOk={closeBillingModal}
          onCancel={closeBillingModal}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p><strong>Your current team subscription:</strong> ${currentSubscriptionDetails.amount} {currentSubscriptionDetails.frequency} 
              <br /><br />
              💡 In the future you'll be able to manage your billing from here directly. Until then, please email <a href="mailto:support@getculturebot.com">support@getculturebot.com</a> with any billing related questions or inquiries.
            </p>
            <img src={currentSubscriptionDetails.planImage} alt="Plan" style={{ width: '100px', height: '100px' }} />
          </div>
        </Modal>
      ) : null }

    </MainContainer>
  );
};

export default Billing;
