import { useQuery } from "@tanstack/react-query";
import { getBaseUrl, doGetRequest } from "../../../helpers/requestHelpers";
import { Select } from "antd";

const { Option } = Select;

export const useTenantUsers = (setTokenErrorType) => {
  return useQuery(
    ["GET_TENANT_USERS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getUsersForTenant`,
        setTokenErrorType,
        []
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" }  // Limit teams api calls by requiring refresh for channels to pop up
  );
};

export const renderUserOptions = (getTenantUsersQuery) => {
  if (!getTenantUsersQuery.data) return null;
  console.log("getTenantUsersQuery: ", getTenantUsersQuery.data);
  return getTenantUsersQuery.data.map(user => (
    <Option key={user.id} value={user.id}>{user.displayName}</Option>
  ));
}; 