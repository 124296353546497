import React from "react";
import { useState, useEffect } from "react";
import { Table, Space, Popconfirm, message, Alert } from "antd";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";

const FullAdmins = ({ getAdminsQuery }) => {
    
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const removeAdminClicked = async (admin, refetchAdmins) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/removeAdmin`,
        { user_id: admin.user_id },
        setTokenErrorType
      );

      if (response.success) {
        message.success(`Successfully removed admin: ${admin.first_name} ${admin.last_name}`);
        refetchAdmins();
      } else if (response.error) {
        throw new Error(response.error);
      } else {
        throw new Error("Failed to remove admin");
      }
    } catch (error) {
      const errorMessage = JSON.parse(error.message);
      message.error(errorMessage.error);
    }
  };

  if (getAdminsQuery.isLoading) return <p>Loading...</p>;
  if (getAdminsQuery.error) return <p>Error loading admins</p>;

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (_, admin) => {
        return (
          <div>
            {admin.first_name} {admin.last_name}
          </div>
        );
      },
    },
    {
      title: "Employee Email",
      dataIndex: "email",
      key: "email",
      render: (_, admin) => {
        return <div>{admin.email}</div>;
      },
    },
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, admin) => moment.unix(admin.created_at).format("MM/DD/YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, admin) => (
        <Space size="middle">
          <Popconfirm
            title={`Are you sure to remove ${admin.first_name} ${admin.last_name} as admin?`}
            onConfirm={() => removeAdminClicked(admin, getAdminsQuery.refetch)}
            okText="Yes"
            cancelText="No"
          >
            <a style={{ color: "red" }}>Remove as admin</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Alert 
        message="Note: full admins have permissions to manage all aspects of the platform." 
        type="warning" 
        style={{ marginBottom: 16 }} 
      />
      <Table
        columns={columns}
        dataSource={getAdminsQuery.data.admins}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    </>
  );
};

export default FullAdmins;
