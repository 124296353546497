import { useEffect } from 'react';
import { useTeams } from 'msteams-react-base-component';
import * as microsoftTeams from "@microsoft/teams-js";

export const useMSTeams = () => {
  const [{ inTeams }] = useTeams();

  useEffect(() => {
    if (inTeams) {
      microsoftTeams.app.initialize();
      microsoftTeams.appInitialization.notifyAppLoaded();
    }
  }, [inTeams]);
}; 