import React from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import { Card, Typography, Alert, Badge } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import AlertsDetails from './AlertsDetails';

const { Title } = Typography;

export default function CelebrationAlertsPage() {

  // determine if admin or not
  const getTokenPermissionsQuery = useQuery(
    ["GET_TOKEN_PERMISSIONS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getTokenPermissions`);
    },
    { refetchOnWindowFocus: false }
  );

  // Page Frame
  let mainArea = (
    <div>
      <div>
        <Title>
          <ClockCircleOutlined style={{ fontSize: "32px" }} /> Celebration Alerts
          <Badge 
              count="Beta" 
              style={{ 
                backgroundColor: '#52c41a',
                marginLeft: '10px'
              }} 
            />
        </Title>
      </div>
    </div>
  );

  return (
    <>
      <MainContainer>
        <div style={{ marginLeft: "200px", padding: "24px" }}>
          <Card style={{ padding: 10, margin: 20 }}>
            {mainArea}
            {/* show plan warning if not on at least standard plan */}
            {getTokenPermissionsQuery.data?.plan_name == 'basic' ? (
              <>
                <Alert
                  style={{ marginBottom: '25px', paddingBottom: 0 }}
                  showIcon
                  description={
                    <p>
                      Celebrations Alerts is a standard plan feature - please{' '}
                      <a target="_blank" href="https://getculturebot.com/pricing.html">
                        {' '}
                        upgrade your plan{' '}
                      </a>{' '}
                      or{' '}
                      <a target="_blank" href="https://calendly.com/culture-bot/15min">
                        {' '}
                        contact us{' '}
                      </a>{' '}
                      to learn more.
                    </p>
                  }
                  type="warning"
                ></Alert>
              </>
            ) : <AlertsDetails/>}
          </Card>
        </div>
      </MainContainer>
    </>
  );
}
