import React from "react";
import {
  CommentOutlined,
  SendOutlined,
  TrophyOutlined,
  OrderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import MeetingsTable from "./MeetingsTable";
import PairingsTable from "./PairingsTable";
import { CSVLink } from "react-csv";
import {
  Card,
  Button,
  Space,
  Modal,
  Typography,
  Form,
  Input,
  Checkbox,
  Alert,
  DatePicker,
  Tabs,
  Col,
  Row,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./IntroStats.css";
import moment from "moment";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function IntrosPage() {
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const firstDateFromStorage = sessionStorage.getItem("intro_stats_first_date");
  const secondDateFromStorage = sessionStorage.getItem(
    "intro_stats_second_date"
  );

  const defaultFirstDate = moment().subtract(7, "d");
  const defaultSecondDate = moment();
  const [firstDate, setFirstDate] = useState(
    firstDateFromStorage ? moment(firstDateFromStorage) : defaultFirstDate
  );
  const [secondDate, setSecondDate] = useState(
    secondDateFromStorage ? moment(secondDateFromStorage) : defaultSecondDate
  );

  // fetch verified meetings 
  const getIntroMeetingsQuery = useQuery(
    ["GET_INTRO_MEETINGS", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment.utc(firstDate.startOf("day")).unix();
      const secondDateUnix = moment.utc(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getIntroMeetings`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // fetch intros pairings sent
  const getIntroPairingsQuery = useQuery(
    ["GET_INTRO_PAIRINGS", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment.utc(firstDate.startOf("day")).unix();
      const secondDateUnix = moment.utc(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getIntroPairings`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // ---- UI handlers ---- //
  async function dateRangeSelectedChange(input) {
    // input expected to be null in case when x is selected
    if (input == null) {
      setFirstDate(defaultFirstDate);
      setSecondDate(defaultSecondDate);
      sessionStorage.removeItem("intro_stats_first_date");
      sessionStorage.removeItem("intro_stats_second_date");
      return;
    }

    setFirstDate(input[0]);
    setSecondDate(input[1]);
    // cache values in browser so user doesn't lose place when refreshing window
    sessionStorage.setItem("intro_stats_first_date", input[0]);
    sessionStorage.setItem("intro_stats_second_date", input[1]);
  }

  async function setAllTimeDateRange() {
    const allTime = moment().set({ year: 2020, month: 0, date: 1 });
    setFirstDate(allTime);
    setSecondDate(moment()); // now
    sessionStorage.setItem("intro_stats_first_date", allTime);
    sessionStorage.setItem("intro_stats_second_date", moment()); // now
  }

  // Page Frame
  let mainArea;
  if (getIntroMeetingsQuery.isLoading) {
    mainArea = <div>Loading...</div>;
  } else if (getIntroMeetingsQuery.isError || getIntroMeetingsQuery.data.error) {
    mainArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    mainArea = (
      <div>
        <div>
          <Title>
            <CommentOutlined style={{ fontSize: "32px" }} /> Intro Meetings
          </Title>
          <p>
            The <strong>'verified meetings'</strong> tab below shows all the intro meetings that have been self-reported by employees to have occurred. Filtering by date range filters the table by the date the meeting was confirmed to have happened. It is possible that the actual meeting date varies slightly from the date employees were reported to have met. 
            <br /><br />
            The <strong>'intros sent'</strong> tab shows all intro pairings that have been sent.
          </p>
          <div>
            <h3>Date Range:</h3>
            <RangePicker
              onChange={dateRangeSelectedChange}
              value={[firstDate, secondDate]}
            />
            &nbsp;&nbsp;
            <a onClick={() => setAllTimeDateRange()}>All Time</a>
          </div>
          <br />

          <Tabs
            defaultActiveKey="1"
            type="card"
            size="middle"
            items={[
              {
                label: `Verified Meetings (${getIntroMeetingsQuery.data?.intros?.length || 0})`,
                key: 1,
                children: <MeetingsTable getIntroMeetingsQuery={getIntroMeetingsQuery} />,
              },
              {
                label: `Intros Sent (${getIntroPairingsQuery.data?.intros?.length || 0})`,
                key: 2,
                children: <PairingsTable getIntroPairingsQuery={getIntroPairingsQuery} />,
              },
            ]}
          />

        </div>
      </div>
    );
  }

  return (
    <>
      <MainContainer>
        <div className="main-container">
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}
