import { useState, useRef } from "react"
import { Button, Modal, Typography, Space, message, Spin, Tooltip } from "antd"
import { RobotOutlined, BarChartOutlined, DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { Column } from "@ant-design/plots"
import ReactMarkdown from 'react-markdown'
import { doPostRequest, getBaseUrl } from "../../helpers/requestHelpers"
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import "./CampaignResults.scss"

const { Title } = Typography

export function AIAnalysis({ surveyResults, loadingSurveyResults }) {

  const [aiAnalysis, setAIAnalysis] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [chartData, setChartData] = useState([])
  const contentRef = useRef(null)

  const handleGetAIAnalysis = async () => {

    if (!surveyResults.all_responses || surveyResults.all_responses.length === 0) {
      message.warning("No survey results available. Please try again when there are results.");
      return; // Early return if no results
    }
    setIsModalVisible(true)
    setIsLoading(true)
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/analyzeSurveyResponses`,
        { survey_results: surveyResults }
      )
      if (!response.error) {
        setAIAnalysis(response.summary)
        setChartData(response.chartData || [])
      } else {
        throw new Error(response.message || "Failed to fetch AI analysis")
      }
    } catch (error) {
      setAIAnalysis("Error fetching AI analysis, please try again later.")
      console.error("Error fetching AI analysis:", error)
      message.error("Failed to fetch AI analysis. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownload = async () => {
    const content = contentRef.current
    if (!content) return

    try {
      const canvas = await html2canvas(content)
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.save("ai_analysis_summary.pdf")
    } catch (error) {
      console.error("Error generating PDF:", error)
      message.error("Failed to generate PDF. Please try again.")
    }
  }

  const config = {
    data: chartData,
    xField: 'field',
    yField: 'average',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  }

  const aiInfoText = `
    This AI analysis uses advanced natural language processing to interpret your survey results. 
    It identifies key trends, correlations, and insights from the data. 
    The analysis is generated in real-time and is not stored or used for any other purpose.
  `

  return (
    <Space>
      <Tooltip title={aiInfoText}>
        <InfoCircleOutlined style={{ color: '#8c8c8c', cursor: 'pointer' }} />      
      </Tooltip>
      <Button
        onClick={handleGetAIAnalysis}
        className="ai-analysis-btn"
        icon={<RobotOutlined />}
        disabled={loadingSurveyResults}
      >
        View AI Analysis
      </Button>
      <Modal
        title={
          <Space>
            <RobotOutlined />
            <span>AI Analysis</span>
          </Space>
        }
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width={700}
        footer={[
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
          <Button 
            key="download" 
            type="primary"
            onClick={handleDownload} 
            icon={<DownloadOutlined />}
            disabled={isLoading}
          >
            Download Summary
          </Button>
        ]}
      >
        {isLoading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="large" />
            <p style={{ marginTop: '10px' }}>Analyzing survey results (please be patient)...</p>
          </div>
        ) : (
          <div className="ai-analysis-content" ref={contentRef}>
            <Title level={4}>Results Overview</Title>
            <ReactMarkdown>{aiAnalysis}</ReactMarkdown>
            {chartData.length > 0 && (
              <>
                <Title level={4}><BarChartOutlined /> Key Metrics</Title>
                <Column {...config} />
              </>
            )}
          </div>
        )}
      </Modal>
    </Space>
  )
}