import React from "react";
import { Alert } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Navbar from "../Navbar/Navbar";
import { getBaseUrl, doGetRequest } from "../../helpers/requestHelpers";
import useAuth from "../../hooks/basicAuthCheck";
import { useState, useEffect } from "react";

export default function MainContainer({ children, noNav = false }) {
  
  const isAuthenticated = useAuth();
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const queryClient = useQueryClient();

  // useQuery to permissions type from token and then in other spots in use the same exact usequery and should not do those requests because it only hits this one
  // You can use this with the depedency to figure out user permissions on other pages and not have it hit twice
  const getTokenPermissionsQuery = useQuery(
    ["GET_TOKEN_PERMISSIONS"],
    async () => {
      try {
        const response = await doGetRequest(`${getBaseUrl()}/api/getTokenPermissions`, setTokenErrorType);
        if ('error' in response && response.error === "no_token") {
          localStorage.setItem("token", ""); // clear token b/c it may be invalid and we don't want to use it any longer in storage
          window.location.href = "/app/login"
        }
        return response;
      } catch (error) {
        console.log("Error fetching token permissions... ", error);
        throw new Error("Failed to fetch token permissions");
      }
    },
    { refetchOnWindowFocus: false, retry: 1, enabled: !!localStorage.getItem("token") }
  );
  
  useEffect(() => {
    queryClient.invalidateQueries("GET_TOKEN_PERMISSIONS");
  }, [localStorage.getItem("token")]);

  // logout
  const logout = () => {
    localStorage.setItem('token', '');
    localStorage.setItem('usingTeams', '');
    localStorage.setItem('is_admin', '');
    window.location.href = "/app/login"
  };

  // check if user is authenticated first (if not, MS Teams shows a big warning banner)
  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }
  
  // standard error area 
  const unknownErrorDiv = (
    <div>
      Error loading data, please try refreshing again. If the problem persists please contact support@getculturebot.com or <strong><a onClick={() => {logout()}}>logout</a></strong> and login again.
    </div>
  );

  // Based on status of token permissions request, show page info
  if (getTokenPermissionsQuery.isLoading) {
    return <div>Loading...</div>;
  } 
  
  // Token either malformed, missing, or some unknown error (e.g. bad internet)
  else if (getTokenPermissionsQuery.isError) {
    let token = localStorage.getItem("token");
    // Token is not correct shape -> must be invalid, log user out
    // This can be from old tokens
    if (!token || (token.match(/\./g) || []).length != 2) {
      localStorage.setItem("token", "");
      localStorage.setItem("usingTeams", "");
      localStorage.setItem("is_admin", "");
      window.location.href = "/app/login";
    }
    // Errored for some other reason, show user vanilla error message
    // We are going to avoid resetting their token as default (just do in cases we are more sure we want to reset)
    // To protect against resetting too much or if request fails because of bad internet
    else {
      // Note: This is an expected case for when the token does not match up with anything in our db (as well as unexpected cases)
      // Would potentially be nice to differeniate that case from others but not overly important for now
      return unknownErrorDiv;
    }
  } 
  
  // Token exists and shape is OK, but something wrong with it 
  else if (getTokenPermissionsQuery.data.error) {
    if (getTokenPermissionsQuery.data.error === "token_expired") {
      localStorage.setItem("token", "");
      localStorage.setItem("usingTeams", "");
      localStorage.setItem("is_admin", "");
      // might be logged out and trying to visit a certain page (such as managing custom shoutouts or viewing peer rewards) -- save current page in localStorage so user can get back to
      const page = window.location.pathname.split("/app/");
      localStorage.setItem("redirect", page[1]);
      window.location.href = "/app/login";
    } else {
      return unknownErrorDiv;
    }
  } 
  
  // Token totally OK > check permissions next
  else {
    let mainArea = children;
    let permissionsError = (
      <div>
        <br /><br /><br />
        <Alert
          description="You do not have permission to view this page... you may need to logout
          above and login again with the same user account you're logged into
          Slack or Microsoft Teams with!"
          type="warning"
          showIcon
          style={{'margin': '20px'}} />
      </div>
    )

    // Admins ONLY
    const adminOnlyPages = ["/app/custom_shoutouts", "/app/shoutout_stats", "/app/intro_stats", "/app/hris_setup", "/app/graphics_library", "/app/employee_assistant"]
    if (
      !getTokenPermissionsQuery.data.is_admin && adminOnlyPages.includes(window.location.pathname)
    ) {
      mainArea = permissionsError;
    }

    // Feedback page: permissions only for those explicity stated in CultureBot UI 
    if (!getTokenPermissionsQuery.data.feedback_rights && (window.location.pathname == "/app/employee_feedback")) {
      mainArea = permissionsError;
    }

    // Survey page: permissions for any admins AND anyone with creator rights (per CultureBot UI) 
    if (!getTokenPermissionsQuery.data.is_admin && !getTokenPermissionsQuery.data.survey_creator_rights && (window.location.pathname == "/app/create_form")) {
      mainArea = permissionsError;
    }

    // Redirect non-admin users from /app/home to /app/members
    if (!getTokenPermissionsQuery.data.is_admin && window.location.pathname === "/app/home") {
      window.location.href = "/app/members";
      return null; // Prevent rendering while redirecting
    }

    return (
      <>
        {!noNav && <Navbar perms={getTokenPermissionsQuery.data} logout={logout} />}
        {mainArea}
      </>
    );
  }
}
