import React from "react";
import { Bar } from "@ant-design/plots";

export default function SelectGraph({ answers }) {

  const data = [];
  for (const [key, value] of Object.entries(answers)) {
    data.push({ answer: key, sum: value });
  }
  
  const config = {
    data,
    xField: "sum",
    yField: "answer",
    seriesField: "answer",
    legend: {
      position: "bottom-left",
    },
    barBackground: {
      style: {
        fill: "rgba(0,0,0,0.1)",
      },
    },
    minBarWidth: 20,
    maxBarWidth: 20,
    maxBarHeight: 10,
  };

  return (
    <>
      <Bar {...config} style={{ maxHeight: 200 }} />
    </>
  );
}
