import { Table } from "antd";
import { useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import moment from "moment";
import {
    LeftOutlined
} from "@ant-design/icons";
import { useState } from "react";

export default function MessagesSentTable({ expId, setExpId }) {
  
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const getExperienceMessagesQuery = useQuery(
    ["GET_CB_EXPERIENCE_MESSAGES", expId],
    async () => {
        return doGetRequest(
            `${getBaseUrl()}/api/getCBExperienceMessages`,
            setTokenErrorType,
            [{ name: "experience_id", value: expId }]
        );
    },
    { refetchOnWindowFocus: false }
  );

  const columns = [
    {
      title: "Time Sent At",
      dataIndex: "created_at",
      key: "created_at",
      width: "20%",
      render: (_, record) => {
        return <div>{moment.unix(record.created_at).format("MM/DD/YYYY - HH:mm (z)")}</div>;
      },
      sorter: (a, b) => a.created_at < b.created_at,
      sortDirections: ["descend", "ascend"],
    },
    {
        title: "Users Sent To",
        dataIndex: "users_sent_to",
        key: "users_sent_to",
        width: "15%",
        render: (_, record) => {
          return <div>{record.sent_to_users_real_names ? record.sent_to_users_real_names.join(", ") : record.sent_to_user_ids}</div>;
        },
    },
    {
        title: "Channel Sent To",
        dataIndex: "channel_sent_to",
        key: "channel_sent_to",
        width: "15%",
        render: (_, record) => {
            return <div>{record.sent_to_channel_real_name ? record.sent_to_channel_real_name : record.sent_to_channel_id}</div>;
        },
    },
    {
      title: "Message Type",
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (_, record) => {
          return <div>{record.step_settings['type']}</div>;
      },
    },
    {
        title: "Message",
        dataIndex: "message",
        key: "message",
        width: "40%",
        render: (_, record) => {
          return <div>{record.step_settings['message']}</div>;
        },
    },
  ];

  var tableArea;
  if (getExperienceMessagesQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (
    getExperienceMessagesQuery.isError ||
    getExperienceMessagesQuery.data.error
  ) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    console.log("getExperienceMessagesQuery: ", getExperienceMessagesQuery.data, expId)
    const tableData = getExperienceMessagesQuery.data.messages.map((message) => {
      return {
        id: message.id,
        step_settings: message.step_settings_when_sent,
        sent_to_channel_id: message.sent_to_channel, 
        sent_to_user_ids: message.sent_to_users,
        sent_to_channel_real_name: message.sent_to_channel_real_name, 
        sent_to_users_real_names: message.sent_to_users_real_names,
        created_at: message.created_at,
      };
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    );
  }
  return (
    <>
    <a
        onClick={() => {
            setExpId(null);
        }}
        className="backButton"
    >
        <LeftOutlined /> Back to Experiences
    </a>
    {tableArea}
    </>
  )
}
