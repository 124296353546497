import React from "react";
import {
  Typography,
  DatePicker,
  Select,
  Popconfirm,
  Table,
  Tooltip,
  Button,
} from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./Rewards.css";
import moment from "moment";
import { CSVLink } from "react-csv";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function Redemptions({rewardSettings}) {

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("approved");
  const [csvData, setCsvData] = useState([]);
  const [updatingApprovedRewards, setUpdatingApprovedRewards] = useState([]);
  const [updatingDeniedRewards, setUpdatingDeniedRewards] = useState([]);
  const queryClient = useQueryClient();
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // fetch redemptions
  const getRedemptionsQuery = useQuery(
    ["GET_REDEMPTIONS", selectedStatus],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getRedemptions`,
        setTokenErrorType,
        [{ name: "status", value: selectedStatus }]
      );
    },
    { refetchOnWindowFocus: false }
  );
 
  // ---- export to csv ---- //
  // https://www.npmjs.com/package/react-csv
  async function formatCsvData() {
    const tmpData = [];
    tmpData.push(["Redeemed At", "Redeemer", "Reward", "Points", "Card Amount", "Status"]);
    getRedemptionsQuery.data.redemptions.forEach((row) => {
      tmpData.push([
        moment.unix(row.redeemed_at).format("MM/DD/YYYY"),
        row.recipient_real_name,
        row.reward_description,
        row.reward_points,
        row.tango_card_amount,
        row.fulfilled,
      ]);
    });
    setCsvData(tmpData);
  }

  // handlers
  async function updateRedemptionStatus(redemption_id, new_status) {
   
    // button loader
    if(new_status == false) {
      setUpdatingDeniedRewards([redemption_id]);
    } else {
      setUpdatingApprovedRewards([redemption_id]);
    }

    // request
    const response = await doPostRequest(
      `${getBaseUrl()}/api/updateRedemptionStatus`,
      { redemption_id: redemption_id, fulfilled: new_status },
      setTokenErrorType
    );

    // update button loader
    if(new_status == false) {
      setUpdatingDeniedRewards([]);
    } else {
      setUpdatingApprovedRewards([]);
    }

    // response
    if (response["success"]) {
      queryClient.invalidateQueries("GET_REDEMPTIONS");
    } else if (response["error"]) {
      window.alert(response["error"]);
    }
  }

  // ------- Input Handlers ------ //
  const updatedFilter = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  useEffect(() => {
    if (getRedemptionsQuery.data) {
      formatCsvData();
    }
  }, [selectedStatus, getRedemptionsQuery.data]);

  // filter select (approved/pending/denied)
  const filterSelect = (
    <>
      <Select
        style={{ width: 175 }}
        value={selectedStatus}
        onChange={updatedFilter}
        size="large"
      >
        <Option value="approved" key="approved">
          Approved
        </Option>
        <Option value="pending" key="pending">
          Pending
        </Option>
        <Option value="denied" key="denied">
          Denied
        </Option>
      </Select>
      <br />
      <br />
    </>
  );

  // table fields
  const columns = [
    {
      title: "Date Redeemed",
      dataIndex: "redeemed_at",
      key: "date",
      width: 150,
      fixed: "left", // Fix this column to the left
      render: (redeemed_at) => {
        return (
          <div>{moment.unix(redeemed_at).format("MM/DD/YYYY")}</div>
        );
      },
    },
    {
      title: "Redeemer",
      dataIndex: "redeemer",
      key: "redeemer",
      width: 150,
      fixed: "left", // Fix this column to the left
      render: (redeemer) => {
        return <div>{redeemer}</div>;
      },
    },
    {
      title: "Reward Description",
      dataIndex: "reward",
      key: "reward",
      width: 225,
      render: (_, record) => {
        return <div>{record.reward}</div>;
      },
    },
    {
      title: "Reward Type",
      dataIndex: "reward_type",
      key: "reward_type",
      width: 150,
      render: (_, record) => {
        return <div>{record.reward_type}</div>;
      },
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      width: 100,
      render: (_, record) => {
        return <div>{record.points}</div>;
      },
    },
    {
      title: "Card Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (_, record) => {
        return <div>{record.card_amount}</div>;
      },
    },
    {
      title: () => {
        return (
          <div>
            Status{" "}
            {
              <CSVLink
                data={csvData}
                filename={"all-redemptions.csv"}
                target="_blank"
              >
                <Tooltip placement="left" title="Download redemptions (CSV)">
                  <DownloadOutlined className="exportCSV" />
                </Tooltip>
              </CSVLink>
            }
          </div>
        );
      },
      dataIndex: "status",
      key: "status",
      width: 300,
      render: (_, record) => {
        return (
          <div>
            {!record.fulfilled ? (
              record.fulfilled == false ? (
                "Denied"
              ) : (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    disabled={updatingApprovedRewards.includes(record.id)}
                    loading={updatingApprovedRewards.includes(record.id)}
                    style={{ "margin-right": "10px" }}
                    onClick={() => {
                      updateRedemptionStatus(record.id, true);
                    }}
                  >
                    <CheckOutlined /> Approve
                  </Button>
                  <Popconfirm
                    title="Deny this redemption?"
                    onConfirm={() => {updateRedemptionStatus(record.id, false)}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="primary"
                      danger={true}
                      shape="round"
                      disabled={updatingDeniedRewards.includes(record.id)}
                      loading={updatingDeniedRewards.includes(record.id)}
                    >
                      <CloseOutlined /> Deny
                    </Button>
                  </Popconfirm>
                </>
              )
            ) : (
              record.reward_type == 'custom' ? (
                <>
                  <Popconfirm
                      title="Deny this redemption? This cannot be undone."
                      onConfirm={() => {updateRedemptionStatus(record.id, false)}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="secondary"
                        danger={true}
                        shape="round"
                        disabled={updatingDeniedRewards.includes(record.id)}
                        loading={updatingDeniedRewards.includes(record.id)}
                      >
                        <CloseOutlined /> Deny
                      </Button>
                    </Popconfirm>
                  </>
              ) : "Approved"
            )}
          </div>
        );
      },
    },
  ];

  // build table area
  var tableArea;
  if (getRedemptionsQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (getRedemptionsQuery.isError || getRedemptionsQuery.data.error) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getRedemptionsQuery.data.redemptions.map((redemption) => {
      return {
        id: redemption.id,
        redeemer: redemption.recipient_real_name,
        redeemed_at: redemption.redeemed_at,
        reward: redemption.reward_description,
        reward_type: redemption.tango_card_utid ? 'e-gift card' : 'custom',
        points: redemption.reward_points,
        card_amount: redemption.tango_card_amount ? `$${redemption.tango_card_amount.toFixed(2)}` : null,
        fulfilled: redemption.fulfilled,
        tango_utid: redemption.tango_card_utid,
      };
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
        scroll={{ x: 'max-content' }} // Enable horizontal scrolling
      />
    );
  }

  return (
    <>
      {filterSelect}
      {tableArea}
    </>
  );
}
