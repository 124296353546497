import { Card, Col, Row, Button, Modal, InputNumber, Alert, Empty, ConfigProvider } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getBaseUrl, doGetRequest, doPostRequest, getTokenErrorAreaIfNeeded } from '../../helpers/requestHelpers';
import { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { LinkOutlined } from '@ant-design/icons';
import { customTheme } from './theme';

export default function CatalogGrid({ rewards, points, rewardSettings, exchangeRateInfo, currency, perms, selectedCountry, inTeams, selectedCategory }) {

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const queryClient = useQueryClient();

  const [selectedReward, setSelectedReward] = useState({});
  const [selectedRewardMin, setSelectedRewardMin] = useState(1);
  const [selectedRewardMax, setSelectedRewardMax] = useState();
  const [selectedRewardAmount, setSelectedRewardAmount] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);

  const [issueRedeemingCard, setIssueRedeemingCard] = useState('');
  const [redemptionRequestReceived, setRedemptionRequestReceived] = useState(false);

  const [fixedValueItemShown, setFixedValueItemShown] = useState(false);
  const [hasFixedValueItems, setHasFixedValueItems] = useState(false);

  // ----- Conversion Rate (if selected item is non-US) ------ //
  let conversation_rate = 1.0;
  if (exchangeRateInfo && exchangeRateInfo.exchangeRates && exchangeRateInfo.exchangeRates.length > 0) {
    conversation_rate = exchangeRateInfo.exchangeRates[0]['baseFx'];
    if (selectedReward && 'items' in selectedReward) {
      // note: if prepaid card was selected, it always needs shown in USD!
      let selectedRewardFirstItemCurrency = selectedReward['items'][0]['currencyCode'];
      if (selectedRewardFirstItemCurrency == 'USD') {
        conversation_rate = 1.0;
        currency = 'USD';
      }
    }
  }

  // ----- Calculated Values ------ //
  const giftCardMaxAmount =
    points && points.redeemable_points && rewardSettings && rewardSettings.points_dollar_values
      ? (points.redeemable_points * (rewardSettings.points_dollar_values[selectedCountry] || 0.1) * conversation_rate).toFixed(2)
      : 0;

  // ------- Input Handlers ------ //
  const handleCancel = () => {
    setIsModalOpen(false); 
    setErrorMessage('');
    setSelectedRewardAmount();
  };

  // Submit Order
  async function submitRedemption() {
    setSaveButtonIsLoading(true);
    const usdAmount = selectedRewardAmount / conversation_rate;
    const response = await doPostRequest(
      `${getBaseUrl()}/api/redeemReward`,
      { selected_reward: selectedReward, amount: usdAmount, international_amount: selectedRewardAmount, country: selectedCountry },
      setTokenErrorType
    );
    setSaveButtonIsLoading(false);
    if (response['error']) {
      setIssueRedeemingCard(response['error']); // error redeeming gift card
    } else {
      queryClient.invalidateQueries('GET_POINTS_INFO_FOR_USER');
      setIssueRedeemingCard('');
      setRedemptionRequestReceived(true);
      setTimeout(function () {
        setRedemptionRequestReceived(false);
      }, 3000);
    }
  }

  useEffect(() => {
    // find min value for selected reward
    if (selectedReward.items) {
      let minValue = 1;
      let maxValue = 1;
      selectedReward.items.forEach(item => {
        if (item.minValue > minValue) {
          minValue = item.minValue;
        }
        if (item.maxValue > maxValue) {
          maxValue = item.maxValue;
        }
      });
      setSelectedRewardMin(minValue);
      setSelectedRewardMax(maxValue);

      // determine if any face value items can be shown (if not, we'll want to show an alert on the page)
      selectedReward.items.forEach(item => {
        if (item.faceValue) {
          setHasFixedValueItems(true); // tells us we at least have possible fixed value items displayed
        }
        if (item.faceValue && item.faceValue <= parseFloat(giftCardMaxAmount)) {
          setFixedValueItemShown(true); // tells us a fixed value item has been actually shown
        }
      });
    }
  }, [rewards, selectedReward, selectedRewardAmount]);

  // Redeem Reward Modal
  const redeemRewardModal = (
    <Modal
      forceRender // pre-render children
      title={selectedReward.reward_id ? "Redeem Custom Gift" : "Choose Amount"} // former is a custom gift, latter is an e-gift card
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          form="customForm"
          key="submit"
          htmlType="submit"
          type="primary"
          disabled={(!selectedReward.items || selectedRewardAmount > 0) && !redemptionRequestReceived ? false : true} // disable 'continue' button if (selected e-gift card OR custom reward) AND currently redeeming gift
          loading={saveButtonIsLoading}
          onClick={submitRedemption}
        >
          Redeem
        </Button>,
      ]}
    >
      <img
        className="rewardModalCover"
        alt="reward icon"
        src={
          selectedReward.imageUrl
            ? selectedReward.imageUrl
            : selectedReward.image
            ? selectedReward.image
            : 'https://culturebot.nyc3.cdn.digitaloceanspaces.com/other/custom_reward.png'
        }
      />
      <h3>{selectedReward.brandName}</h3>
      {selectedReward &&
        selectedReward.items &&
        selectedReward.items.map((item, index) => {
          return (
            <p>
              {item.faceValue ? (
                item.faceValue <= parseFloat(giftCardMaxAmount) ? (
                  <Button
                    onClick={() => {
                      setSelectedRewardAmount(item.faceValue);
                    }}
                    type="dashed"
                    size="small"
                  >
                    {item.faceValue} {currency} - Select
                  </Button>
                ) : null
              ) : (
                `${item.minValue < 1 ? 1 : item.minValue} ${currency} - ${item.maxValue} ${currency}`
              )}
            </p>
          );
        })}

      <p>
        <strong>Your redeemable points:</strong> {points ? points.redeemable_points.toFixed(2) : 0}{' '}
        {giftCardMaxAmount && !selectedReward.reward_id ? `(${giftCardMaxAmount} ${currency})` : null}
      </p>

      {selectedRewardAmount ? (
        <p>
          <strong>Points to redeem this reward: </strong>
          {(selectedRewardAmount / conversation_rate / (rewardSettings.points_dollar_values[selectedCountry] || 0.1)).toFixed(2)}
          {selectedRewardAmount ? `(${selectedRewardAmount.toFixed(2)} ${currency})` : null}
        </p>
      ) : null}

      {/* custom reward - no inputting amount */}
      {selectedReward.reward_id ? (
        <p>
          <strong>Points to reedem this reward: </strong>
          {selectedReward.points}
        </p>
      ) : null}

      {selectedReward.reward_id && selectedReward.link ? (
        <a target="_blank" href={selectedReward.link}>
          Open Gift Link <LinkOutlined />
        </a>
      ) : null}

      {/* gift cards that are fixed value, but user doesn't have enough points to show any options  */}
      {hasFixedValueItems && !fixedValueItemShown ? (
        <>
          <Alert description="Not enough points to redeem this reward" type="error"></Alert>
          <br />
        </>
      ) : null}

      {/* gift card that allow user to input exact amount  */}
      {!selectedReward.faceValue && !selectedReward.reward_id ? (
        points &&
        rewardSettings &&
        !redemptionRequestReceived &&
        points.redeemable_points * rewardSettings.points_dollar_value * conversation_rate < selectedRewardMin ? (
          <>
            <Alert description="Not enough points to redeem this reward" type="error"></Alert>
            <br />
          </>
        ) : (
          <>
            <p>Enter amount to redeem:</p>
            <InputNumber
              addonBefore={currency}
              style={{
                width: 200,
              }}
              min={selectedRewardMin}
              max={parseFloat(giftCardMaxAmount) < selectedRewardMax ? giftCardMaxAmount : selectedRewardMax}
              value={selectedRewardAmount}
              onChange={e => {
                setSelectedRewardAmount(e);
              }}
              placeholder="Enter amount..."
            />
            <br />
            <br />
          </>
        )
      ) : null}

      {ReactHtmlParser(selectedReward.shortDescription)}

      <p className="singleRewardDisclaimer">{ReactHtmlParser(selectedReward.disclaimer)}</p>

      {/* error state */}
      {issueRedeemingCard ? <Alert description={<strong>{issueRedeemingCard}</strong>} type="error" /> : null}

      {/* success state */}
      {redemptionRequestReceived ? (
        <Alert
          description={
            <strong>
              Your request has been submitted to your CultureBot admins for approval. Once your reward is approved, you
              will be notified via Slack and email.
            </strong>
          }
          type="success"
        />
      ) : null}
    </Modal>
  );

  // No Rewards Disclaimer (for catalog grid)
  const noRewardsToDisplay = (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{
        height: 100,
      }}
      className="noRewardsInCatalog"
      description={<span>No rewards to display</span>}
    ></Empty>
  );

  return (
    <ConfigProvider theme={customTheme}>
      <Row gutter={16}>
        {redeemRewardModal}

        {/* culturebot custom rewards */}
        {rewards && rewards.custom_rewards
          ? rewards.custom_rewards.map(reward => {
              return (
                <Col key={reward.id} teams={5} xl={4}> {/* on 1060px (teams window size) and down have each column take up 6 units (more) space than on larger window sizes */}
                  <Card
                    bordered={false}
                    className="rewardCard"
                    cover={
                      <div
                        alt="reward cover"
                        id="cover"
                        style={{
                          backgroundImage: `url(${reward.image || 'https://culturebot.nyc3.cdn.digitaloceanspaces.com/other/custom_reward.png'})`,
                        }}
                      />
                    }
                  >
                    {reward.description}
                    <br />
                    <Button
                      block
                      type="dashed"
                      style={{ marginTop: 5 }}
                      onClick={() => {
                        setIsModalOpen(true);
                        setSelectedReward(reward);
                        setFixedValueItemShown(false);
                        setHasFixedValueItems(false);
                      }}
                    >
                      Select
                    </Button>
                  </Card>
                  <br />
                </Col>
              );
            })
          : null}

        {/* tango gift cards -- show only to PRO plan users, hide on Teams mobile due to MS requirements */}
        {rewards && rewards.brands && perms.data && perms.data.plan_name == 'pro' && !(inTeams && (window.innerWidth <= 768))
          ? rewards.brands.map(reward => {
              return (
                <Col teams={5} xl={4}>
                  <Card bordered={false} className="rewardCard" cover={<img alt="reward cover" src={reward.imageUrl} />}>
                    <p>{reward.brandName}</p>
                    <Button
                      block
                      type="dashed"
                      style={{ marginTop: 5 }}
                      onClick={() => {
                        setIsModalOpen(true);
                        setSelectedReward(reward);
                        setFixedValueItemShown(null);
                        setHasFixedValueItems(false);
                      }}
                    >
                      Select
                    </Button>
                  </Card>
                  <br />
                </Col>
              );
            })
          : null}

        {/* must have PRO plan to see catalog warning */}
        {rewards && rewards.brands && perms.data && perms.data.plan_name != 'pro' ? (
          <>
            <Alert
              style={{ marginBottom: '25px', paddingBottom: 0 }}
              showIcon
              description={
                <p>
                  The gift card catalog is a pro plan feature - please{' '}
                  <a target="_blank" href="https://calendly.com/culture-bot/15min">
                    {' '}
                    contact us{' '}
                  </a>{' '}
                  to learn more.
                </p>
              }
              type="warning"
            ></Alert>
          </>
        ) : null}
        
        {/* if user is on Teams mobile, we can't permit them to purchase gift cards, so tell them to go to web */}
        {inTeams && (window.innerWidth <= 768) && selectedCategory != "custom reward" ? (
          <Alert
            style={{ marginBottom: '25px', paddingBottom: 0 }}
            showIcon
            description={
              <p>
                Please visit the web app to purchase gift cards.
              </p>
            }
            type="warning"
          />
        ) : null}

        {/* empty catalog warning  */}
        {rewards && rewards.custom_rewards && rewards.custom_rewards.length < 1 ? noRewardsToDisplay : null}
        {rewards && rewards.brands && rewards.brands.length < 1 ? noRewardsToDisplay : null}

        {/* tango general disclaimer */}
        {rewards && rewards.brands && rewards.brands.length > 0 ? (
          <p className="generalDisclaimer">
            The merchants represented are not sponsors of the rewards or otherwise affiliated with CultureBot. The logos
            and other identifying marks attached are trademarks of and owned by each represented company and/or its
            affiliates. Please visit each company's website for additional terms and conditions.
          </p>
        ) : null}
      </Row>
    </ConfigProvider>
  );
}
