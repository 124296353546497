import React, { useState, useEffect } from "react";
import {
  BarChartOutlined,
  CheckSquareOutlined, 
  BellOutlined,
  SendOutlined,
  TrophyOutlined,
  OrderedListOutlined,
  UserOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import ShoutoutsTable from "./ShoutoutsTable";
import {
  filterOptionFunction,
} from "../CreateCampaign/CreateCampaignPageHelpers";
import { CSVLink } from "react-csv";
import {
  Card,
  Typography,
  DatePicker,
  Row,
  Col,
  Button,
  Popover,
  Modal,
  Input,
  Select,
  TimePicker, 
  Checkbox
} from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
  doPostRequest,
} from "../../helpers/requestHelpers";
import "./ShoutoutStats.css";
import moment from "moment";
import MainContainer from "../Container/MainContainer";
import {
  roundToLastHalfHour
} from "../../helpers/utilityHelpers";
import {
  useTenantChannels,
  renderChannelOptions,
  mapChannels
} from "../MicrosoftTeams/Shared/useTenantChannels";

// non-state constants
const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

// set default tz for user
const userTz = moment.tz.guess()
moment.tz.setDefault(userTz);

export default function ShoutoutStatsPage() {
  const [tokenErrorType, setTokenErrorType] = useState(false);

  const firstDateFromStorage = sessionStorage.getItem(
    "shoutout_stats_first_date"
  );
  const secondDateFromStorage = sessionStorage.getItem(
    "shoutout_stats_second_date"
  );
  const defaultFirstDate = moment().subtract(7, "d");
  const defaultSecondDate = moment();
  const [firstDate, setFirstDate] = useState(
    firstDateFromStorage ? moment(firstDateFromStorage) : defaultFirstDate
  );
  const [secondDate, setSecondDate] = useState(
    secondDateFromStorage ? moment(secondDateFromStorage) : defaultSecondDate
  );

  const [shoutoutsSent, setShoutoutsSent] = useState("--");
  const [shoutoutsReceived, setShoutoutsReceived] = useState("--");
  const [shoutoutsPointsGiven, setShoutoutsPointsGiven] = useState("--");
  const [shoutoutsTopSenders, setShoutoutsTopSenders] = useState([{}]);
  const [shoutoutsTopReceivers, setShoutoutsTopReceivers] = useState([{}]);
  const [shoutoutsTopCategories, setShoutoutsTopCategories] = useState([{}]);
  const [shoutoutsTopPointsReceivers, setShoutoutsTopPointsReceivers] =
    useState([{}]);
  const [shoutoutsTopRedeemables, setShoutoutsTopRedeemables] =
    useState([{}]);
  const [sendersCSVData, setSendersCSVData] = useState([]);
  const [receiversCSVData, setReceiversCSVData] = useState([]);
  const [pointsLeadersCSVData, setPointsLeadersCSVData] = useState([]);
  const [redeemableCSVData, setRedeemableCSVData] = useState([]);
  const [reminderSent, setReminderSent] = useState(false);

  // Scheduled report modal
  const [isReportModalVisible, setReportModalVisible] = useState(false);
  const [tempReportFrequency, setTempReportFrequency] = useState('mondays');
  const [tempSelectedChannels, setTempSelectedChannels] = useState([]);
  const [tempTimeOfDay, setTempTimeOfDay] = useState(moment('12:00', 'HH:mm'));
  const queryClient = useQueryClient();

  // Add state variables for the new settings
  const [includeTopSendersReceivers, setIncludeTopSendersReceivers] = useState(false);
  const [includeTopPointsSentReceived, setIncludeTopPointsSentReceived] = useState(false);
  const [includeTopShoutoutTypes, setIncludeTopShoutoutTypes] = useState(false);
  const [maxItems, setMaxItems] = useState(3); // Default max items
  const [isInvalidTimeModalOpen, setIsInvalidTimeModalOpen] = useState(false);

  // fetch stats
  const getShoutoutsStatsQuery = useQuery(
    ["GET_SHOUTOUTS_STATS", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment(firstDate.startOf("day")).unix();
      const secondDateUnix = moment(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getShoutoutsStats`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // fetch public report settings
  const getPublicReportSettingsQuery = useQuery(
    ["GET_PUBLIC_REPORT_SETTINGS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getShoutoutPublicReportSettings`, setTokenErrorType);
    },
    { refetchOnWindowFocus: false }
  );

  // gets all channels (for slack OR teams)
  const getChannelsQuery = useQuery(
    ["getChannelsQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "channels" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  )

  // ---- UI handlers ---- //
  async function sendRedeemablePointsNotifications() {
    setReminderSent(true);
    // Logic to send notifications to users with redeemable points left 
    try {
      const response = await doGetRequest(
        `${getBaseUrl()}/api/sendRedeemablePointsNotifications`,
        setTokenErrorType,
        []
      );
    } catch (error) {
      console.error("Error sending sendRedeemablePointsNotifications notification:", error);
      alert("An error occurred while sending the notification.");
    }
  }

  async function dateRangeSelectedChange(input) {
    if (input == null) {
      setFirstDate(defaultFirstDate);
      setSecondDate(defaultSecondDate);
      sessionStorage.removeItem("shoutout_stats_first_date");
      sessionStorage.removeItem("shoutout_stats_second_date");
      return;
    }

    setFirstDate(input[0]);
    setSecondDate(input[1]);
    // cache values in browser so user doesn't lose place when refreshing window
    sessionStorage.setItem("shoutout_stats_first_date", input[0]);
    sessionStorage.setItem("shoutout_stats_second_date", input[1]);
  }

  async function setAllTimeDateRange() {
    const allTime = moment().set({ year: 2020, month: 0, date: 1 });
    setFirstDate(allTime);
    setSecondDate(moment()); // now
    sessionStorage.setItem("shoutout_stats_first_date", allTime);
    sessionStorage.setItem("shoutout_stats_second_date", moment()); // now
  }

  // CSV formatters
  async function formatCSV(csvType, columns) {
    const columnsArr = [];
    columns.forEach((column) => {
      columnsArr.push(column);
    });
    const tmpData = [columns];
    if (csvType == "senders") {
      getShoutoutsStatsQuery.data.stats.top_senders.forEach((row) => {
        tmpData.push([row.name, row.email, row.sent]);
      });
      setSendersCSVData(tmpData);
    } else if (csvType == "receivers") {
      getShoutoutsStatsQuery.data.stats.top_receivers.forEach((row) => {
        tmpData.push([row.name, row.email, row.received]);
      });
      setReceiversCSVData(tmpData);
    } else if (csvType == "points_leaders") {
      getShoutoutsStatsQuery.data.stats.top_points_receivers.forEach((row) => {
        tmpData.push([row.name, row.email, row.received]);
      });
      setPointsLeadersCSVData(tmpData);
    } else if (csvType == "currently_redeemable") {
      getShoutoutsStatsQuery.data.stats.top_points_redeemable.forEach((row) => {
        tmpData.push([row.name, row.email, row.received]);
      });
      setRedeemableCSVData(tmpData);
    }
  }

  // re-render form after date selection changes
  useEffect(() => {
    if (getShoutoutsStatsQuery.data && !('error' in getShoutoutsStatsQuery.data)) {
      setShoutoutsSent(getShoutoutsStatsQuery.data.stats.sent);
      setShoutoutsReceived(getShoutoutsStatsQuery.data.stats.received);
      setShoutoutsTopSenders(getShoutoutsStatsQuery.data.stats.top_senders);
      setShoutoutsTopReceivers(getShoutoutsStatsQuery.data.stats.top_receivers);
      setShoutoutsPointsGiven(getShoutoutsStatsQuery.data.stats.total_points_given);
      setShoutoutsTopCategories(
        getShoutoutsStatsQuery.data.stats.top_categories
      );
      setShoutoutsTopPointsReceivers(
        getShoutoutsStatsQuery.data.stats.top_points_receivers
      );
      setShoutoutsTopRedeemables(
        getShoutoutsStatsQuery.data.stats.top_points_redeemable
      )
      // format all CSVs for download
      formatCSV("senders", ["Sender", "Sender Email", "Shoutouts Sent"]);
      formatCSV("receivers", [
        "Receiver",
        "Receiver Email",
        "Shoutouts Received",
      ]);
      formatCSV("points_leaders", [
        "Leader",
        "Leader Email",
        "Points Received",
      ]);
      formatCSV("currently_redeemable", [
        "Employee",
        "Employee Email",
        "Redeemable Points",
      ]);
    }
  }, [firstDate, secondDate, getShoutoutsStatsQuery.data, reminderSent]);

  // updates public report settings
  useEffect(() => {
    if (getPublicReportSettingsQuery.data && !('error' in getPublicReportSettingsQuery.data)) {
     
      const settings = getPublicReportSettingsQuery.data;
      setTempReportFrequency(settings.shoutout_report_frequency);
      setTempSelectedChannels(settings.shoutout_report_channels || []); // Backend may passing None that would result in weird empty object in frontend - so "|| []" to avoid
      
      const timeOfDay =
        settings.shoutout_report_hour_of_day === '' ? 
        null: // Avoid processing date and seeing "invalid time" on frontend for the '' case
        moment.utc(settings.shoutout_report_hour_of_day, "HH:mm").tz(moment.tz.guess());
     
      setTempTimeOfDay(timeOfDay);
     
      if (settings.shoutout_report_included_metrics) {
        setIncludeTopSendersReceivers(settings.shoutout_report_included_metrics.includes('top_senders_receivers'));
        setIncludeTopPointsSentReceived(settings.shoutout_report_included_metrics.includes('top_points_sent_received'));
        setIncludeTopShoutoutTypes(settings.shoutout_report_included_metrics.includes('top_shoutout_types'));
      }
      setMaxItems(settings.shoutout_report_max_items_per_metric);
    }
  }, [getPublicReportSettingsQuery.data]);

  // ---- stats section ---- //
  const StatsSection = () => (
    <Row gutter={[16, 16]} justify="start">
      <Col xs={24} sm={12} style={{ minWidth: '250px' }}>
        <Card
          title={
            <div>
              <SendOutlined /> Summary Stats
            </div>
          }
        >
          <strong>Sent: </strong>
          {shoutoutsSent}
          <br />
          <br />
          <strong>Received: </strong>
          {shoutoutsReceived}
          <br />
          {shoutoutsPointsGiven > 0 && (
            <>
              <br />
              <strong>Points Given: </strong>
              {shoutoutsPointsGiven}
              <br />
              <br />
              <strong>Avg. Points/Shoutout: </strong>
              {shoutoutsPointsGiven > 0 ? ( // Ensure no division by zero
                (shoutoutsPointsGiven / shoutoutsSent).toFixed(1)
              ) : "N/A"}
            </>
          )}
          <br />
          <div onClick={showReportModal} style={{marginTop: '15px', maxWidth: '240px', color: '#1890ff', cursor: 'pointer'}}>
            <ScheduleOutlined /> Configure Public Report
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={12} style={{ minWidth: '250px' }}>
        <Card
          title={
            <div>
              <TrophyOutlined /> Top Senders & Receivers
            </div>
          }
          style={{ minHeight: '290px' }}
        >
          <strong>Senders: </strong>
          {shoutoutsTopSenders.map((sender, index) => {
            if (index > 9) {
              return undefined;
            } // show max 10
            if (index == shoutoutsTopSenders.length - 1) {
              return sender.name + ": " + sender.sent;
            } else {
              return sender.name + ": " + sender.sent + ", ";
            }
          })}

          <br />
          {shoutoutsTopSenders.length > 10 ? (
            <>
              <CSVLink
                data={sendersCSVData}
                filename={"all-senders.csv"}
                target="_blank"
                className="downloadAllLink"
              >
                Download All
              </CSVLink>
              <br />
            </>
          ) : null}
          <br />
          <strong>Receivers: </strong>
          {shoutoutsTopReceivers.map((receiver, index) => {
            if (index > 9) {
              return undefined;
            } // show max 10
            if (index == shoutoutsTopReceivers.length - 1) {
              return receiver.name + ": " + receiver.received;
            } else {
              return receiver.name + ": " + receiver.received + ", ";
            }
          })}
          <br />
          {shoutoutsTopReceivers.length > 10 ? (
            <CSVLink
              data={receiversCSVData}
              filename={"all-receivers.csv"}
              target="_blank"
              className="downloadAllLink"
            >
              Download All
            </CSVLink>
          ) : null}
        </Card>
      </Col>
      <Col xs={24} sm={12} style={{ minWidth: '250px' }}>
        <Card
          title={
            <div>
              <OrderedListOutlined /> Top Shoutout Types
            </div>
          }
        >
          <strong>Sent: </strong>
          {shoutoutsTopCategories.map((category, index) => {
            if (index > 9) {
              return undefined;
            } // show max 10
            if (index == shoutoutsTopCategories.length - 1) {
              return category[0] + ": " + category[1];
            } else {
              return category[0] + ": " + category[1] + ", ";
            }
          })}
        </Card>
      </Col>
      <Col xs={24} sm={12} style={{ minWidth: '250px' }}>
        <Card
          title={
            <div>
              <UserOutlined /> Points Leaders
            </div>
          }
        >
          <strong>Top Receivers: </strong>
          {shoutoutsTopPointsReceivers.map((leader, index) => {
            if (index > 9) {
              return undefined;
            } // show max 10
            if (index == shoutoutsTopPointsReceivers.length - 1) {
              return leader.name + ": " + leader.received + " points";
            } else {
              return leader.name + ": " + leader.received + " points, ";
            }
          })}
          <br />
          {shoutoutsTopPointsReceivers.length > 10 ? (
            <CSVLink
              data={pointsLeadersCSVData}
              filename={"all-points-receivers.csv"}
              target="_blank"
              className="downloadAllLink"
            >
              Download All
            </CSVLink>
          ) : null}

          <br />
          <strong>Currently Redeemable: </strong>
          {shoutoutsTopRedeemables.map((employee, index) => {
            if (index > 9) {
              return undefined;
            } // show max 10
            if (index == shoutoutsTopRedeemables.length - 1) {
              return employee.name + ": " + employee.received + " points";
            } else {
              return employee.name + ": " + employee.received + " points, ";
            }
          })}
          <br />
          {shoutoutsTopRedeemables.length > 10 ? (
            <CSVLink
              data={redeemableCSVData}
              filename={"top-redeemable-points.csv"}
              target="_blank"
              className="downloadAllLink"
            >
              Download All
            </CSVLink>
          ) : null}
          <br />
          {shoutoutsTopRedeemables.length > 0 && (
            <>
              <br /> 
              <Popover 
                content="This will notify users about redeemable points they can use with the gift catalog." 
                trigger="hover" 
                style={{ maxWidth: '250px' }}
              >
                <Button
                  type="primary"
                  shape="round"
                  disabled={reminderSent}
                  className="notifyButton"
                  icon={
                    reminderSent ? <CheckSquareOutlined /> : <BellOutlined />
                  }
                  onClick={() => {
                    sendRedeemablePointsNotifications();
                  }}
                >
                  {reminderSent ? "Reminder Sent!" : "Notify Users About Gifts"}
                </Button>
              </Popover>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );

  // Page Frame
  let mainArea;
  if (getShoutoutsStatsQuery.isLoading) {
    mainArea = <div>Loading...</div>;
  } else if (
    // We don't really expect these in any known cases at this point
    // Maybe a rare case? where token expires while on page somehow?
    // TODO it would be nice to bugsnag if this happens maybe?
    getShoutoutsStatsQuery.isError ||
    getShoutoutsStatsQuery.data.error
  ) {
    mainArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    mainArea = (
      <div>
        <div>
          <Title level={2}>
            <BarChartOutlined style={{ fontSize: "32px" }} /> Shoutout Stats
          </Title>
          <div>
            <h3>Date Range:</h3>
            <p>Dates selected below are shown in your timezone – <strong>{userTz}</strong></p>
            <RangePicker
              onChange={dateRangeSelectedChange}
              value={[firstDate, secondDate]}
            />
            &nbsp;&nbsp;
            <a onClick={() => setAllTimeDateRange()}>All Time</a>
          </div>
          <br />
          <StatsSection />
          <br />
          <ShoutoutsTable firstDate={firstDate} secondDate={secondDate} />
        </div>
      </div>
    );
  }

  const showReportModal = () => {
    setReportModalVisible(true);
  };

  const handleReportModalOk = async () => {
    
    // Prepare the data to be sent in the POST request
    const requestData = {
      shoutout_report_frequency: tempReportFrequency,
      shoutout_report_channels: tempSelectedChannels,
      shoutout_report_hour_of_day: tempTimeOfDay === null ? "" : tempTimeOfDay.utc().format("HH:mm"),
      include_top_senders_receivers: includeTopSendersReceivers,
      include_top_points_sent_received: includeTopPointsSentReceived,
      include_top_shoutout_types: includeTopShoutoutTypes,
      shoutout_report_max_items_per_metric: maxItems,
    };

    // Send the updated settings to the server using POST
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/updateShoutoutPublicReportSettings`,
        requestData,
        setTokenErrorType
      );
      queryClient.invalidateQueries("GET_PUBLIC_REPORT_SETTINGS");
    } catch (error) {
      console.error("Error updating public report settings:", error);
      alert("An error occurred while updating the report settings, please try again.");
    }

    setReportModalVisible(false);
  };

  const handleReportModalCancel = () => {
    setReportModalVisible(false);
  };

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">
          {mainArea}
          <Modal
            title="Configure Public Report"
            visible={isReportModalVisible}
            onOk={handleReportModalOk}
            onCancel={handleReportModalCancel}
          >
            <h4>Select Frequency:</h4>
            <Select defaultValue={tempReportFrequency} onChange={setTempReportFrequency} style={{ width: '300px' }}>
              <Select.Option value="mondays">Weekly (every Monday)</Select.Option>
              <Select.Option value="fridays">Weekly (every Friday)</Select.Option>
              <Select.Option value="monthly">Monthly (every first Monday of month)</Select.Option>
            </Select>
            <br/><br/>
            
            <h4>Select Channels (to send to):</h4>
            <Select
              showSearch
              mode="multiple"
              style={{ width: "100%" }}
              value={tempSelectedChannels}
              onChange={setTempSelectedChannels}
              filterOption={filterOptionFunction}
            >
              {getChannelsQuery.data ? (
                getChannelsQuery.data.platform == 'slack' ? (
                  getChannelsQuery.data.conversations.map((conversation) => {
                    return (
                      <Option
                        key={conversation.id}
                        value={conversation.id}
                        label={conversation.name}
                      >
                        {conversation.name}
                      </Option>
                    );
                  })
                ) : (
                  renderChannelOptions(getChannelsQuery.data.conversations) // for Teams, we need to render the options differently
                )
              ) : []}
            </Select>
            <br/><br/>

            <h4>Time of Day to Send:</h4>
            <TimePicker
              value={tempTimeOfDay}
              format="h:mm a"
              minuteStep={30}
              allowClear={false}
              use12Hours={true}
              onChange={(date) => {
                const result = roundToLastHalfHour(date);
                if (result.adjusted) {
                  setIsInvalidTimeModalOpen(true);
                }
                setTempTimeOfDay(moment.utc(result.date, "HH:mm").tz(moment.tz.guess()));
              }}
            />
            <Modal
                title="Invalid Time"
                open={isInvalidTimeModalOpen}
                cancelButtonProps={{ hidden: true }}
                closable={false} // This will remove the close "x" button
                onOk={() => {
                  setIsInvalidTimeModalOpen(false);
                }}
              >
                <p>
                  CultureBot only supports sending times on the hour or on a
                  half hour. The selected time has been adjusted back to the
                  nearest hour or half hour mark.
                </p>
            </Modal>
            <br/><br/>

            {/* New settings section */}
            <h4>Additional Settings:</h4>
            <Checkbox
              checked={includeTopSendersReceivers}
              onChange={(e) => setIncludeTopSendersReceivers(e.target.checked)}
            >
              Include top senders/receivers of shoutouts
            </Checkbox>
            <br/>
            <Checkbox
              checked={includeTopPointsSentReceived}
              onChange={(e) => setIncludeTopPointsSentReceived(e.target.checked)}
            >
              Include top points senders/receivers
            </Checkbox>

            <br/>
            <Checkbox
              checked={includeTopShoutoutTypes}
              onChange={(e) => setIncludeTopShoutoutTypes(e.target.checked)}
            >
              Include top shoutout types overall
            </Checkbox>
            <br/><br/>

            <h4>Max Items (Per Statistic):</h4>
            <Select value={maxItems} onChange={setMaxItems} style={{ width: '100px' }}>
              <Select.Option value={3}>3</Select.Option>
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
            </Select>
            <br/><br/>

            <h4>Report Preview:</h4>
            <img 
              src="https://graphics.getculturebot.com/other/shoutout-leaderboard-message.png" 
              alt="Report Preview" 
              style={{ width: '100%', borderRadius: '8px', padding: '5px' }} 
            />
            
          </Modal>
        </Card>
      </div>
    </MainContainer>
  );
}
