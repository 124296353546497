import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import {
  GiftOutlined,
  CommentOutlined,
  FormOutlined,
  NotificationOutlined,
  BarChartOutlined,
  MailOutlined,
  SyncOutlined,
  CompassOutlined,
  FireOutlined,
  RobotOutlined,
  LogoutOutlined,
  PictureOutlined,
  StarOutlined,
  LockOutlined,
  UserOutlined,
  PlaySquareOutlined,
  HomeOutlined, 
  SettingOutlined,
  RocketOutlined,
  ClockCircleOutlined,
  MenuOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { Link, useLocation } from 'react-router-dom';
import cbLogoBlue from "../../images/cb-logo-ios.png";
import cbLogoWordmark from "../../images/logo-blue.png";
import './Navbar.scss'; // Make sure to create this CSS file

const { Sider } = Layout;

export default function CBNavbar({ perms, publicPage, logout }) {

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [inTeams, setInTeams] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Trigger the spin animation after a short delay here
    if (perms) {
      setInTeams(!!perms.logged_in_with_teams);
    }
    const timer = setTimeout(() => setIsLoaded(true), 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getMenuItems = () => {

    const items = [];

    // Slack navbar pages 
    if (!publicPage && !inTeams) { // public campaign results page should not show side nav
      
      if (perms && perms.is_admin) {
        items.push(
          {
            key: 'custom_shoutouts',
            icon: <NotificationOutlined />,
            label: <Link to="/app/custom_shoutouts">Custom Shoutouts</Link>,
          },
          {
            key: 'shoutout_stats',
            icon: <BarChartOutlined />,
            label: <Link to="/app/shoutout_stats">Shoutout Reports</Link>,
          }
        );
      }

      items.push({
        key: 'celebration_alerts',
        icon: <ClockCircleOutlined />,
        label: <Link to="/app/celebration_alerts">Celebration Alerts</Link>,
      });

      items.push({
        key: 'rewards',
        icon: <GiftOutlined />,
        label: <Link to="/app/rewards">Peer Rewards</Link>,
      });

      if (perms && perms.is_admin) {
        items.push(
          {
            key: 'intro_stats',
            icon: <CommentOutlined />,
            label: <Link to="/app/intro_stats">Intros Made</Link>,
          },
          {
            key: 'reactions',
            icon: <FireOutlined />,
            label: <Link to="/app/reactions">Reactions</Link>,
          }
        );
      }

      if (perms && perms.feedback_rights && perms.plan_name == "pro") {
        items.push({
          key: 'employee_feedback',
          icon: <MailOutlined />,
          label: <Link to="/app/employee_feedback">Feedback</Link>,
        });
      }

      if (perms && (perms.is_admin || perms.survey_creator_rights) && perms.plan_name == "pro") {
        items.push({
          key: 'create_form',
          icon: <FormOutlined />,
          label: <Link to="/app/create_form">Surveys</Link>,
        });
      }

      if (perms && perms.is_admin && perms.plan_name == "pro") {
        items.push({
          key: 'experiences',
          icon: <CompassOutlined />,
          label: <Link to="/app/experiences">Experiences</Link>,
        });
      }

      if (perms && perms.is_admin && perms.plan_name == "pro") {
        items.push({
          key: 'hris_setup',
          icon: <SyncOutlined />,
          label: <Link to="/app/hris_setup">HRIS Sync</Link>,
        });
      }

      if (perms && perms.is_admin) {
        items.push({
          key: 'employee_assistant',
          icon: <RobotOutlined />,
          label: <Link to="/app/employee_assistant">Employee Assistant</Link>,
        });
        items.push({
          key: 'graphics_library',
          icon: <PictureOutlined />,
          label: <Link to="/app/graphics_library">Graphics Library</Link>,
        });
        items.push({
          key: 'permissions',
          icon: <LockOutlined />,
          label: <Link to="/app/permissions">User Permissions</Link>,
        });
      }

    }

    // Teams navbar pages (add menu items specific to Teams)
    if (inTeams) {

      // admin only pages
      if (perms && perms.is_admin) {
        
        items.push({
          key: 'home',
          icon: <HomeOutlined />,
          label: <Link to="/app/home">Home</Link>,
        });

        items.push({
          key: 'celebrations',
          icon: <StarOutlined />,
          label: <Link to="/app/celebrations">Celebrations</Link>,
        });

        items.push({
          key: 'celebration_alerts',
          icon: <ClockCircleOutlined />,
          label: <Link to="/app/celebration_alerts">Celebration Alerts</Link>,
        });

        if (perms.plan_name == "standard" || perms.plan_name == "pro") {
          items.push({
            key: 'water_cooler',
            icon: <CommentOutlined />,
            label: <Link to="/app/water_cooler">Water Cooler</Link>,
          });

          items.push({
            key: 'shoutouts',
            icon: <NotificationOutlined />,
            label: <Link to="/app/shoutouts">Shoutouts</Link>,
          });
  
          items.push({
            key: 'custom_shoutouts',
            icon: <SettingOutlined />,
            label: <Link to="/app/custom_shoutouts">Custom Shoutouts</Link>,
          });
  
          items.push({
            key: 'shoutout_stats',
            icon: <BarChartOutlined />,
            label: <Link to="/app/shoutout_stats">Shoutout Reports</Link>,
          });
                  
          items.push({
            key: 'rewards',
            icon: <GiftOutlined />,
            label: <Link to="/app/rewards">Peer Rewards</Link>,
          });
  
          items.push({
            key: 'games',
            icon: <PlaySquareOutlined />,
            label: <Link to="/app/games">Team Games</Link>,
          });
        }

        items.push({
          key: 'graphics_library',
          icon: <PictureOutlined />,
          label: <Link to="/app/graphics_library">Graphics Library</Link>,
        });
        
        items.push({
          key: 'permissions',
          icon: <LockOutlined />,
          label: <Link to="/app/permissions">User Permissions</Link>,
        });

        // if pro plan, add HRIS sync
        if (perms.plan_name == "pro") {
          items.push({
            key: 'hris_setup',
            icon: <SyncOutlined />,
            label: <Link to="/app/hris_setup">HRIS Sync</Link>,
          });
        }

        items.push({
          key: 'billing',
          icon: <RocketOutlined />,
          label: <Link to="/app/billing">Upgrade</Link>,
        });

      }

      // member overview page 
      else {

        items.push({
          key: 'members',
          icon: <UserOutlined />,
          label: <Link to="/app/members">Home</Link>,
        });

        // has partial admin rights for celebrations
        if (perms.bday_admin_rights || perms.anniversary_admin_rights || perms.custom_celebration_config) {
          items.push({
            key: 'celebrations',
            icon: <StarOutlined />,
            label: <Link to="/app/celebrations">Celebrations</Link>,
          });
        }

        // has partial admin rights for water cooler
        if (perms.water_cooler_admin_rights) {
          items.push({
            key: 'water_cooler',
            icon: <CommentOutlined />,
            label: <Link to="/app/water_cooler">Water Cooler</Link>,
          });
        }

        // standard and pro plan can see gift catalog and games
        if (perms.plan_name == "standard" || perms.plan_name == "pro") {
          items.push({
            key: 'rewards',
            icon: <GiftOutlined />,
            label: <Link to="/app/rewards">Peer Rewards</Link>,
          });
          items.push({
            key: 'games',
            icon: <PlaySquareOutlined />,
            label: <Link to="/app/games">Team Games</Link>,
          });
        }

      }
      
    }

    return items;
  };

  return (
    <Sider
      className="navbar"
      style={{
        height: isMobile ? 'auto' : '100vh',
        position: isMobile ? 'relative' : 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1000,
      }}
      width={isMobile ? '100%' : 200}
      theme="light"
    >
      <div className="navbar-content">

        {/* desktop specific menu components */}
        <div className="navbar-logo-container">
          <img 
            src={cbLogoBlue} 
            alt="Logo" 
            className={`navbar-logo ${isLoaded ? 'spin-horizontal' : ''}`} 
          />
        </div>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname.split('/')[2]]}
          items={getMenuItems()}
          className="navbar-menu"
        />

        {/* mobile specific menu components*/}
        {isMobile && (
          <div className="navbar-hamburger">
            <img 
              src={cbLogoWordmark} 
              alt="Logo" 
              style={{ width: '200px', height: 'auto' }}
            />
            <Button
              icon={mobileMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            />
          </div>
        )}
        {isMobile && mobileMenuOpen && (
          <Menu
            mode="inline"
            selectedKeys={[location.pathname.split('/')[2]]}
            items={getMenuItems()} 
            className="navbar-menu-mobile"
          />
        )}

      </div>
      {!inTeams && (
        <div className="navbar-logout-container">
          <Button
            type="link"
            icon={<LogoutOutlined />}
            onClick={logout}
            className="navbar-logout-button"
          >
            Logout
          </Button>
        </div>
      )}
    </Sider>
  );
}
