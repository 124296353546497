import { Table, Tooltip } from "antd";
import { useQuery } from "@tanstack/react-query";
import { getBaseUrl } from "../../helpers/requestHelpers";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { useState, useEffect } from "react";
import moment from "moment";
import useColumnSearch from "../../hooks/columnSearch";

export default function ReactionsTable({ getReactionMessagesQuery }) {
  const [csvData, setCsvData] = useState([]);
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // ---- export to csv ---- //
  // https://www.npmjs.com/package/react-csv
  async function formatCsvData() {
    const tmpData = [];
    tmpData.push([
      "Message Time",
      "Category",
      "Channel",
      "Message",
      "Reactions",
    ]);
    getReactionMessagesQuery.data.messages.forEach((row) => {
      tmpData.push([
        moment.unix(row.message_time).format("MM/DD/YYYY – HH:mm A"),
        row.category,
        row.channel_real_name,
        row.message,
        row.reactions_formatted,
      ]);
    });
    setCsvData(tmpData);
  }

  useEffect(() => {
    if (getReactionMessagesQuery.data) {
      formatCsvData();
    }
  }, [getReactionMessagesQuery.data]);

  // Table fields
  const columns = [
    {
      title: "Message Time",
      dataIndex: "message_time",
      key: "message_time",
      width: "12%",
      render: (_, record) => {
        return (
          <div>
            {moment.unix(record.message_time).format("MM/DD/YYYY – HH:mm A")}
          </div>
        );
      },
      sorter: (a, b) => a.message_time < b.message_time,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "10%",
      render: (_, record) => {
        return <div>{record.category}</div>;
      },
      ...useColumnSearch("category"),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      width: "10%",
      render: (_, record) => {
        return <div>{record.channel}</div>;
      },
      ...useColumnSearch("channel"),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "28%",
      render: (_, record) => {
        return <div>{record.message}</div>;
      },
      ...useColumnSearch("message"),
    },
    {
      title: () => {
        return (
          <div>
            Reactions{" "}
            <CSVLink
              data={csvData}
              filename={"all-message-reactions.csv"}
              target="_blank"
            >
              <Tooltip
                placement="left"
                title="Download Message Reactions (CSV)"
              >
                <DownloadOutlined className="exportCSV" />
              </Tooltip>
            </CSVLink>
          </div>
        );
      },
      dataIndex: "reactions",
      key: "reactions",
      width: "15%",
      render: (_, record) => {
        return <div>{record.reactions}</div>;
      },
    },
  ];

  // Build table area
  var tableArea;
  if (getReactionMessagesQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (
    getReactionMessagesQuery.isError ||
    getReactionMessagesQuery.data.error
  ) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getReactionMessagesQuery.data.messages.map((message) => {
      return {
        key: message.id,
        id: message.id,
        category: message.category,
        message_time: message.message_ts,
        channel: message.channel_real_name,
        message: message.message,
        reactions: message.reactions_formatted,
      };
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    );
  }

  return <>{tableArea}</>;
}
