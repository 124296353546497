import { Table, Tooltip, Tag, Button, Input, Space } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../helpers/requestHelpers";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";

import { useState, useEffect, useRef } from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import useColumnSearch from "../../hooks/columnSearch";

// Get the timestamp for the "Met On" column
// either the record created time in case user reported met OR
function getUnixTimeMetOnFromIntroActivityRecord(record) {
  if (record.metadata.marked_as_happened_from_cal_event_creation) {
    return record.metadata.cal_scheduled_meeting_time;
  }
  return record.created_at;
}
export default function MeetingsTable({ getIntroMeetingsQuery }) {

  const [csvData, setCsvData] = useState([]);
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [updatingShoutoutActivities, setUpdatingShoutoutActivities] = useState(
    []
  );
  const queryClient = useQueryClient();
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // ---- export to csv ---- //
  // https://www.npmjs.com/package/react-csv
  async function formatCsvData() {
    const tmpData = [];
    tmpData.push([
      "Logged By",
      "Users Met Names",
      "Users Met Emails",
      "Met On",
      "Introduced On",
      "",
    ]);
    getIntroMeetingsQuery.data.intros.forEach((row) => {
      tmpData.push([
        row.we_met_logged_by,
        row.users_met_names,
        row.users_met_emails,
        moment
          .unix(getUnixTimeMetOnFromIntroActivityRecord(row))
          .format("MM/DD/YYYY"),
        moment
          .unix(row.metadata["intro_group_created_at"])
          .format("MM/DD/YYYY"),
      ]);
    });
    setCsvData(tmpData);
  }

  useEffect(() => {
    if (getIntroMeetingsQuery.data) {
      formatCsvData();
    }
  }, [getIntroMeetingsQuery.data]);

  // table fields
  const columns = [
    {
      title: "Logged By",
      dataIndex: "we_met_logged_by",
      key: "we_met_logged_by",
      width: "15%",
      render: (_, record) => {
        return <div>{record.we_met_logged_by}</div>;
      },
      ...useColumnSearch("we_met_logged_by"),
    },
    {
      title: "Users Met Names",
      dataIndex: "users_met_names",
      key: "users_met_names",
      width: "25%",
      render: (_, record) => {
        return (
          <div>
            {record.users_met_names.map((name) => {
              return <Tag>{name}</Tag>;
            })}
          </div>
        );
      },
      ...useColumnSearch("users_met_names"),
    },
    {
      title: "Users Met Emails",
      dataIndex: "users_met_emails",
      key: "users_met_emails",
      width: "15%",
      render: (_, record) => {
        return (
          <div>
            {record.users_met_emails.map((name) => {
              return <Tag>{name}</Tag>;
            })}
          </div>
        );
      },
      ...useColumnSearch("users_met_emails"),
    },
    {
      title: "Met On",
      dataIndex: "met_at",
      key: "met_at",
      width: "10%",
      render: (_, record) => {
        return (
          <div>
            {moment
              .unix(getUnixTimeMetOnFromIntroActivityRecord(record.full_intro))
              .format("MM/DD/YYYY")}
          </div>
        );
      },
      sorter: (a, b) =>
        getUnixTimeMetOnFromIntroActivityRecord(a.full_intro) <
        getUnixTimeMetOnFromIntroActivityRecord(b.full_intro),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: () => {
        return <div>Introduced On </div>;
      },
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (_, record) => {
        return (
          <div>
            {moment.unix(record.intro_group_created_at).format("MM/DD/YYYY")}
          </div>
        );
      },
      sorter: (a, b) => a.intro_group_created_at < b.intro_group_created_at,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: () => {
        return (
          <div>
            <CSVLink
              data={csvData}
              filename={"all-intro-meetings.csv"}
              target="_blank"
            >
              <Tooltip placement="left" title="Download Intro Meetings (CSV)">
                <DownloadOutlined className="exportCSV" />
              </Tooltip>
            </CSVLink>
          </div>
        );
      },
      dataIndex: "",
      key: "",
      width: "5%",
    },
  ];

  // build table area
  const tableData = getIntroMeetingsQuery.data.intros.map((intro) => {
    return {
      id: intro.id,
      we_met_logged_by: intro.we_met_logged_by,
      users_met_names: intro.users_met_names,
      users_met_emails: intro.users_met_emails,
      met_at: intro.created_at,
      intro_group_created_at: intro.metadata["intro_group_created_at"],
      full_intro: intro, // Sending so we can call getUnixTimeMetOnFromIntroActivityRecord in a uniform way
    };
  });

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={{
        ...pagination,
        onChange: (page, pageSize) => {
          setPagination((prev) => ({ ...prev, pageSize }));
        },
      }}
    />
  );
}
