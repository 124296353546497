import React, { useCallback } from "react";
import MainContainer from "../Container/MainContainer";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../helpers/requestHelpers";
import { SyncOutlined } from "@ant-design/icons";
import { Card, Typography, Button } from "antd";
import "./IntegrationSetup.css";
import { useMergeLink } from "@mergeapi/react-merge-link";
import SyncRunsTable from "./SyncRunsTable";
import moment from "moment";
import banner from "../../images/hris-integrations-banner.png";

const { Title } = Typography;

export default function IntegrationSetup() {
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [finishedSetup, setFinishedSetup] = useState(false);

  // fetch link token
  const getLinkToken = useQuery(
    ["GET_LINK_TOKEN", finishedSetup],
    async () => {
      setFinishedSetup(false);
      return doGetRequest(
        `${getBaseUrl()}/api/createLinkToken`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  // fetch link token
  const syncRunsQuery = useQuery(
    ["GET_SYNC_RUNS"],
    async () => {
      setFinishedSetup(false);
      return doGetRequest(`${getBaseUrl()}/api/getSyncRuns`, setTokenErrorType);
    },
    { refetchOnWindowFocus: false }
  );

  // authentication with HRIS completed => ask backend to swap public_token for account_token (used to request data)
  async function onSuccess(public_token) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/requestAccountToken`,
      { public_token: public_token },
      setTokenErrorType
    );
    if (response["success"]) {
      window.alert(
        'You have successfully integrated with your HRIS system. Your sync will run inside the next 24 hrs, and will update daily. After the first run, you will see your sync history listed in a table below. Please feel free to leave this page. To update your connection settings, just click on the "Connect to HRIS" button below one more time.'
      );
    }
  }

  // if token exists, load syncing modal experience
  const { open, isReady } = useMergeLink({
    linkToken: getLinkToken.data ? getLinkToken.data.token : null,
    onSuccess,
    onExit: () => {
      setFinishedSetup(true);
    },
  });

  // Page Frame
  let mainArea;
  if (getLinkToken.isLoading) {
    mainArea = <div>Loading...</div>;
  } else if (getLinkToken.isError || getLinkToken.data.error) {
    mainArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    mainArea = (
      <div>
        <div>
          <img width={"100%"} src={banner} />
          <br />
          <br />
          {getLinkToken.data && getLinkToken.data.token !== "disabled" ? (
            <>
             <h2>How It Works</h2>
              <p>
                Click the button below to choose an HRIS system to connect with
                (you may need IT support to locate specific tokens or keys to
                complete the integration process).
                <br />
                <br />
                After integrating, CultureBot will automatically update
                birthdays and anniversaries (every 24 hrs) for all employees
                that also have a Slack account (matching on their email). If
                you're using surveys, you will also be able to send campaigns to
                employees based on their department, location, and more.
                <br />
                <br />
                After the initial sync, you will see a table below listing out
                any employees whose information was updated/created on a daily
                basis.
                <br />
                {syncRunsQuery.data && syncRunsQuery.data.last_sync_started ? (
                  <>
                    <br />
                    <strong>
                      Last Sync:{" "}
                      {moment
                        .unix(syncRunsQuery.data.last_sync_started)
                        .format("MM/DD/YYYY – HH:mm")}
                    </strong>
                  </>
                ) : null}
              </p>
              <div>
                <Button
                  disabled={!isReady}
                  onClick={open}
                  type="primary"
                  shape="round"
                  icon={<SyncOutlined />}
                  style={{ marginTop: "10px" }}
                >
                  Connect to HRIS
                </Button>
              </div>
              <br />
              <SyncRunsTable syncRunsQuery={syncRunsQuery} />
            </>
          ) : (
            <>
              <p>
                The HRIS Integration service is a premium feature that is not
                enabled for your account yet. Use it to sync employee birthdays,
                start dates, locations, and more.
                <br />
                <br />
                Please get in touch with your account manager or email{" "}
                <strong>support@getculturebot.com</strong> to get setup today.
              </p>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <MainContainer>
        <div className="main-container">
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}
