import React from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  TeamOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
  OrderedListOutlined,
  FormOutlined,
  ReloadOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
  DashboardOutlined,
  EditOutlined,
  NumberOutlined,
  SelectOutlined,
  CheckCircleOutlined,
  TextAreaOutlined,
} from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import CBFormTable from "./CBFormTable";
import {
  Card,
  Button,
  Space,
  Modal,
  Typography,
  Form,
  Input,
  InputNumber,
  Checkbox,
  Alert,
  Tabs,
  Tag,
  Select,
  Divider,
} from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./CreateForm.css";
import templateFields from "./templateFields";
import CreateCampaignPage from "../CreateCampaign/CreateCampaignPage";

const { Title } = Typography;

export default function CreateFormPage() {
  const [form] = Form.useForm();
  let initialForm = JSON.parse(JSON.stringify(templateFields["blank"]));
  const [currentForm, setCurrentForm] = useState(initialForm);
  const [uid, setUid] = useState(crypto.randomUUID());
  const [inputFields, setInputFields] = useState(
    templateFields["blank"]["inputs"]
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingForm, setIsEditingForm] = useState(false);
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);
  const [activeCampaignForForm, setActiveCampaignForForm] = useState("");
  const queryClient = useQueryClient();
  const [lastUsedTemplate, setLastUsedTemplate] = useState(null);

  // ------- Forms CRUD ------- //

  // saves a new custom form
  async function saveForm(jsonToSend) {
    setSaveButtonIsLoading(true);
    
    jsonToSend['last_used_form'] = lastUsedTemplate;

    const response = await doPostRequest(
      `${getBaseUrl()}/api/addOrEditForm`,
      jsonToSend,
      setTokenErrorType
    );

    // form was saved 
    if (!response["error"]) {
      // Invalidate parts of the app using GET_CB_FORMS (like the table) so they refresh
      queryClient.invalidateQueries("GET_CB_FORMS");
      setIsModalOpen(false);
      setSaveButtonIsLoading(false);
    }

    // form failed to save
    else {
      setErrorMessage(response["error"]);
      setSaveButtonIsLoading(false);
    }

  }

  // when editing/cloning a form, builds up initial values and inputs
  function setInputsAndInitialValuesForFormObject(newObject, cbForm) {
    // Assume we can always push the form name first
    newObject.inputs.push({
      name: "Form Name",
    });
    newObject.initialValues["Form Name"] = cbForm.form_name;

    cbForm.form_items.forEach((current_item) => {
      newObject.inputs.push({
        name: `Question ${current_item.id}`,
        checkbox_name: `Checkbox ${current_item.id}`,
        select_name: `Select ${current_item.id}`,
        number_input_name: `Number ${current_item.id}`,
        typeOfQuestion: current_item.item_type,
        link_name: `Link Name ${current_item.id}`,
        link_url: `Link URL ${current_item.id}`,
        use_link: `Include Link ${current_item.id}`,
      });

      newObject.initialValues[`Question ${current_item.id}`] =
        current_item.question_text;
      newObject.initialValues[`Checkbox ${current_item.id}`] =
        current_item.question_required;
      newObject.initialValues[`Link Name ${current_item.id}`] =
        current_item.question_link_name;
      newObject.initialValues[`Link URL ${current_item.id}`] =
        current_item.question_link;
      newObject.initialValues[`Include Link ${current_item.id}`] = 
        current_item.question_link_name ? true : false;
      newObject.initialValues[`Select ${current_item.id}`] =
        current_item.question_options;

      // number types use question_options array to store (min, max) interval, doesn't always exist though!
      if (
        current_item.question_options &&
        current_item.question_options.length > 1
      ) {
        newObject.initialValues[`Number ${current_item.id} min`] = parseInt(
          current_item.question_options[0]
        );
        newObject.initialValues[`Number ${current_item.id} max`] = parseInt(
          current_item.question_options[1]
        );
      }
    });

    return newObject;
  }

  // clone form (pre-fills create new form modal with existing questions/values)
  async function cloneCBFormClicked(cbForm) {
    setIsEditingForm(false);

    // build object that looks the same as the ones we use in templateField.js
    const newObject = {};
    newObject.inputs = [];
    newObject.initialValues = {};
    const finalObject = setInputsAndInitialValuesForFormObject(
      newObject,
      cbForm
    );

    setCurrentForm(finalObject);
    setIsModalOpen(true);
  }

  // edit individual form
  async function editCBFormClicked(cbForm, activeCampaign) {
    setIsEditingForm(true);
    setActiveCampaignForForm(activeCampaign);

    // build object that looks the same as the ones we use in templateField.js
    const newObject = {};
    newObject.id = cbForm.id;
    newObject.inputs = [];
    newObject.initialValues = {};
    const finalObject = setInputsAndInitialValuesForFormObject(
      newObject,
      cbForm
    );

    setCurrentForm(finalObject);
    setIsModalOpen(true);
  }

  // delete individual form
  async function deleteCBFormClicked(cbFormId) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteCBForm`,
      { cb_form_id: cbFormId },
      setTokenErrorType
    );
    // Invalidate query to trigger table refresh
    queryClient.invalidateQueries("GET_CB_FORMS");

    // todo some additional error handling outside of setTokenErrorType would probably be nice (for thrown errors too)
  }

  const getApiJsonFromFormValues = (formValues) => {
    const returnJson = { form_name: formValues["Form Name"], form_items: [] };

    // We only expect forms being edited from db to have an id
    if (currentForm.id) {
      returnJson.id = currentForm.id;
    }

    for (const [key, value] of Object.entries(formValues)) {
      // Assume all question have one of these
      if (key.startsWith("Question ")) {
        const questionId = key.replace("Question ", "");
        // Find question data based on key
        const currentQuestionData = currentForm.inputs.find((currentInput) => {
          return currentInput.name === key;
        });
        const jsonForQuestion = {
          item_type: currentQuestionData.typeOfQuestion,
          question_text: formValues[key],
          question_required: !!formValues[`Checkbox ${questionId}`], // Assuming if question key exists then this key does too
          link_name: formValues[`Link Name ${questionId}`],
          link_url: formValues[`Link URL ${questionId}`],
        };
        // add question options if single or multi select
        if (
          ["SINGLE_SELECT_QUESTION", "MULTI_SELECT_QUESTION"].includes(
            currentQuestionData.typeOfQuestion
          )
        ) {
          jsonForQuestion.question_options = formValues[`Select ${questionId}`];
        }
        // add min, max if question is number type
        if (["NUMBER_QUESTION"].includes(currentQuestionData.typeOfQuestion)) {
          jsonForQuestion.question_options = [
            formValues[`Number ${questionId} min`],
            formValues[`Number ${questionId} max`],
          ];
        }
        returnJson.form_items.push(jsonForQuestion);
      }
    }

    return returnJson;
  };

  // ------- Input Handlers ------ //
  const onFinish = (formValues) => {
    // check to make sure form name is not empty, and question one is not empty
    var foundNameError = false;
    var inputFoundWithValue = false;
    var inputFoundWithoutValue = false;
    var numberInputNotInteger = false;
    var numberInputFoundWithoutMin = false;
    var numberInputFoundWithoutMax = false;
    var selectInputFoundWithout2Values = false;
    Object.keys(formValues).forEach(function (key) {
      if (
        key == "Form Name" &&
        typeof formValues[key] === "string" &&
        formValues[key].trim() == ""
      ) {
        setErrorMessage("Form name must be set before saving");
        foundNameError = true;
      } else if (
        key.includes("Question") &&
        typeof formValues[key] === "string"
      ) {
        if (formValues[key].trim() !== "") {
          inputFoundWithValue = true;
        } else {
          inputFoundWithoutValue = true;
        }
      } else if (key.includes("Select ")) {
        // Expected to be undefined if it was just added and not touched
        console.log("key! ", key, formValues[key]);
        if (formValues[key] === undefined || formValues[key].length < 2) {
          selectInputFoundWithout2Values = true;
        }
      } else if (key.includes("Number ")) {
        if (!Number.isInteger(formValues[key])) {
          numberInputNotInteger = true;
        }
        if (
          key.includes("max") &&
          (!formValues[key] || formValues[key] === null)
        ) {
          numberInputFoundWithoutMax = true;
        }
        if (
          key.includes("min") &&
          (!formValues[key] || formValues[key] === null)
        ) {
          numberInputFoundWithoutMin = true;
        }
      }
    });

    // no values for questions added
    if (!foundNameError && !inputFoundWithValue) {
      setErrorMessage("At least one question must be entered before saving");
    } else if (inputFoundWithoutValue) {
      setErrorMessage("Questions cannot be left blank before saving");
    } else if (activeCampaignForForm) {
      setErrorMessage("You tried editing a form with an active campaign");
    } else if (numberInputNotInteger) {
      setErrorMessage(
        "Please make sure the min and max are integers for all number fields before saving"
      );
    } else if (numberInputFoundWithoutMax) {
      setErrorMessage(
        "Please enter a max value for all number fields before saving"
      );
    } else if (numberInputFoundWithoutMin) {
      setErrorMessage(
        "Please enter a min value for all number fields before saving"
      );
    } else if (selectInputFoundWithout2Values) {
      setErrorMessage(
        "At least 2 possible answers must be provided for multiple choice questions"
      );
    }

    // no errors (1. form has name 2. at least one question 3. no questions left blank 4. No multiple choice questions with less than two answers) > save form
    if (
      !foundNameError &&
      inputFoundWithValue &&
      !inputFoundWithoutValue &&
      !activeCampaignForForm &&
      !selectInputFoundWithout2Values &&
      !numberInputNotInteger &&
      !numberInputFoundWithoutMax &&
      !numberInputFoundWithoutMin
    ) {
      const apiJson = getApiJsonFromFormValues(formValues);
      saveForm(apiJson);
      setErrorMessage("");
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {

    if(!isEditingForm && inputFields.length > 2) { // we choose > 2 here b/c 2 input fields are added automatically
      let confirm = window.confirm("You added a question to your form but have not saved it yet. Click 'cancel' below to close this message and save your work before closing.");
      if (!confirm) {
       return 
      }
    }
    
    setIsModalOpen(false);
    setActiveCampaignForForm(false);
    setErrorMessage("");
  };
  
  const addAQuestion = (typeOfQuestion) => {
    setUid(crypto.randomUUID());
    const newCurrentForm = JSON.parse(JSON.stringify(currentForm));

    // add new input to json
    newCurrentForm["inputs"].push({
      name: `Question ${uid}`,
      checkbox_name: `Checkbox ${uid}`,
      typeOfQuestion: typeOfQuestion,
      select_name: `Select ${uid}`, // This will be used for SINGLE_SELECT_QUESTION & MULTI_SELECT_QUESTION options
      number_input_name: `Number ${uid}`, // This is used to distinguish number inputs from free text inputs
      link_name: `Link Name ${uid}`,
      link_url: `Link URL ${uid}`,
      use_link: `Include Link ${uid}`,
    });
    newCurrentForm["initialValues"][`Question ${uid}`] = "";

    setCurrentForm(newCurrentForm);
  };
  const removeQuestions = (inputName) => {
    // first, make sure user has at least one input left (at least one is mandatory)
    if (currentForm["inputs"].length <= 2) {
      setErrorMessage(
        "You must have at least one question in your form before saving"
      );
    }

    // has 2 or more inputs left > can safely remove some
    else {
      setErrorMessage("");

      const newCurrentForm = JSON.parse(JSON.stringify(currentForm));
      // remove from inputs
      newCurrentForm["inputs"] = newCurrentForm["inputs"].filter(function (
        item
      ) {
        return item.name != inputName;
      });

      // remove from initial values
      delete newCurrentForm["initialValues"][inputName];

      setCurrentForm(newCurrentForm);
    }
  };
  const getFormItemsForInput = (input) => {
    const arrayToReturn = [
      <Form.Item name={input.name} noStyle>
        <Input
          style={{ width: "90%" }}
          placeholder="Enter a question to ask your team"
        />
      </Form.Item>,
      <MinusCircleOutlined
        className="dynamic-delete-button"
        onClick={() => removeQuestions(input.name)}
      />,
    ];
    if (input.typeOfQuestion === "FREE_TYPE_QUESTION" || input.typeOfQuestion === "TEXT_AREA_QUESTION") {
      return arrayToReturn;
    } else if (input.typeOfQuestion === "NUMBER_QUESTION") {
      arrayToReturn.push(
        <>
          Min:{" "}
          <Form.Item name={`${input.number_input_name} min`} noStyle>
            <InputNumber
              style={{ width: "100px", marginRight: "10px", marginTop: "5px" }}
              placeholder="0"
            />
          </Form.Item>
          Max:
          <Form.Item name={`${input.number_input_name} max`} noStyle>
            <InputNumber
              style={{ width: "100px", marginLeft: "10px", marginTop: "5px" }}
              placeholder="10"
            />
          </Form.Item>
        </>
      );
      return arrayToReturn;
    } else if (
      input.typeOfQuestion === "SINGLE_SELECT_QUESTION" ||
      input.typeOfQuestion === "MULTI_SELECT_QUESTION"
    ) {
      const handleInputKeyDown = (event) => {
        // Slack dropdown options have 76 char limit as of 5/27/2024
        if (event.target.value.length >= 75 && event.key !== "Backspace") {
          event.preventDefault();
        }
      };

      arrayToReturn.push(
        <>
          <Form.Item name={input.select_name} noStyle>
            <Select
              style={{ width: "90%" }}
              mode="tags"
              onInputKeyDown={handleInputKeyDown}
              placeholder="Add possible responses (hit enter after each item)"
            />
          </Form.Item>
          <Tag color="blue" style={{ marginTop: 5 }}>
            {input.typeOfQuestion == "SINGLE_SELECT_QUESTION"
              ? "Single Select"
              : "Multiple Select"}
          </Tag>
        </>
      );
      return arrayToReturn;
    }
  };
  const resetWithTemplate = (templateName) => {
    let newCurrentForm = JSON.parse(
      JSON.stringify(templateFields[templateName])
    );
    setCurrentForm(newCurrentForm);
    setErrorMessage("");
    setLastUsedTemplate(templateName);
  };
  const inputValuesChanged = (changedValues) => {
    let newCurrentForm = JSON.parse(JSON.stringify(currentForm));
    // go through new changes => update templateFields obj
    Object.entries(changedValues).forEach(([question, value]) => {
      newCurrentForm["initialValues"][question] = value;
    });
    setCurrentForm(newCurrentForm);
  };

  // re-render form after dynamic changes
  // TODO: check if we can remove?
  useEffect(() => {
    setInputFields([...currentForm["inputs"]]);
    form.setFieldsValue(currentForm["initialValues"]);
  }, [currentForm, uid]); // Only re-run the effect if count changes

  // -------- create new form -------- //

  // form layout
  const formItemLayout = {
    wrapperCol: {
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  // form itself
  const createNewForm = (
    <Modal
      forceRender // pre-render children
      title={isEditingForm ? "Edit Form" : "Create New Form"}
      open={isModalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          form="customForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={saveButtonIsLoading}
        >
          Save
        </Button>,
      ]}
    >
      {/* form templates  */}
      {isEditingForm ? null : (
        <>
          <div className="template-button-container">
            <h4>Choose Template:</h4>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("blank")}
            >
              Blank
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("onboarding")}
            >
              <OrderedListOutlined /> Onboarding Survey
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("team_health")}
            >
              <TeamOutlined /> Team Health
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("employee_intro")}
            >
              <QuestionCircleOutlined /> Employee Intro (2 Truths and a Lie)
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("nps")}
            >
              <SmileOutlined /> eNPS
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("three_sixty_review")}
            >
              <ReloadOutlined /> Teammate 360 Review
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("nominations")}
            >
              <TrophyOutlined /> Employee Nominations
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("standup")}
            >
              <ClockCircleOutlined /> Standup
            </Button>
            <Button
              className="template-button"
              type="dashed"
              onClick={() => resetWithTemplate("check_in")}
            >
              <DashboardOutlined /> Pulse Check-In
            </Button>
          </div>
          <br />
        </>
      )}

      {/* show error message if exists */}
      {errorMessage != "" ? (
        <>
          <Alert message={errorMessage} type="error" />
          <br />
        </>
      ) : null}

      {/* -------- dynamic form itself -------- */}
      <>
        <Form
          form={form}
          initialValues={currentForm["initialValues"]}
          onValuesChange={inputValuesChanged}
          id="customForm"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          {...formItemLayout}
        >
          {/* alert in case user tried to edit this form */}
          {isEditingForm && activeCampaignForForm && !errorMessage ? (
            <>
              <Alert
                message={"Cannot edit this form - active campaign running!"}
                type="error"
              />
              <br />
            </>
          ) : null}

          {/* -------- template inputs -------- */}
          {inputFields.map((input, index) => {
            return (
              <div key={index}>
                {/* show labels above first and second elements to organize form */}
                {index == 0 ? (
                  <h4>
                    <FormOutlined style={{ marginRight: 5 }} /> Form Name
                  </h4>
                ) : (
                  <>
                    <h4 style={{ marginTop: "-10px" }}>
                    Question #{index}
                    </h4>
                  </>
                )}
                <Form.Item {...formItemLayout}>
                  {/* form name (first element) is required */}
                  {index == 0 ? (
                    <Form.Item
                      name={input.name}
                      noStyle
                      rules={[
                        {
                          required: true,
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input
                        style={
                          index !== 0 ? { width: "90%" } : { width: "100%" }
                        }
                        placeholder="Name your form"
                      />
                    </Form.Item>
                  ) : (
                    getFormItemsForInput(input)
                  )}
                </Form.Item>
                {index !== 0 ? (
                  <>
                    <Form.Item
                      name={input.checkbox_name}
                      className="create-form-checkbox"
                      valuePropName="checked"
                      style={{ display: 'inline-block', marginRight: '10px' }}
                    >
                      <Checkbox>Make this question required</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name={input.use_link}
                      className="create-form-checkbox"
                      valuePropName="checked"
                      style={{ display: 'inline-block', marginRight: '10px' }}
                    >
                      <Checkbox>Include a link or image</Checkbox>
                    </Form.Item>
                    {form.getFieldValue(input.use_link) ? (
                      <>
                        <Form.Item
                          name={input.link_name}
                          rules={[{ required: true, message: 'Enter a link name' }]}
                          style={{ display: 'inline-block', width: 'auto', marginRight: '10px', marginTop: '-10px' }}
                        >
                          <Input placeholder="Link name" style={{ width: '200px' }} />
                        </Form.Item>
                        <Form.Item
                          name={input.link_url}
                          rules={[
                            { required: true, message: 'Enter a link' },
                            { 
                              type: 'url', 
                              message: 'Please enter a valid URL' 
                            }
                          ]}
                          style={{ display: 'inline-block', width: 'auto', marginTop: '-10px' }}
                        >
                          <Input placeholder="Link or image URL" style={{ width: '215px' }} />
                        </Form.Item>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            );
          })}
        </Form>

        {/* only show add question if form has no active campaign running */}
        {isEditingForm && activeCampaignForForm ? null : (
          <div className="template-button-container">
            <h4 style={{ marginBottom: "5px" }}>Add New Question:</h4>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap", // Allow buttons to wrap into multiple lines
                justifyContent: "flex-start", // Align buttons to the start
                gap: "8px", // Adjust the gap between buttons
              }}
            >
              <Button
                className="create-form-add-button"
                onClick={() => {
                  addAQuestion("FREE_TYPE_QUESTION");
                }}
                icon={<EditOutlined />}
              >
                Text (Single Line)
              </Button>
              <Button
                className="create-form-add-button"
                onClick={() => {
                  addAQuestion("NUMBER_QUESTION");
                }}
                icon={<NumberOutlined />}
              >
                Number
              </Button>
              <Button
                className="create-form-add-button"
                onClick={() => {
                  addAQuestion("SINGLE_SELECT_QUESTION");
                }}
                icon={<SelectOutlined />}
              >
                Single Select
              </Button>
              <Button
                className="create-form-add-button"
                onClick={() => {
                  addAQuestion("MULTI_SELECT_QUESTION");
                }}
                icon={<CheckCircleOutlined />}
              >
                Multiple Select
              </Button>
              <Button
                className="create-form-add-button"
                onClick={() => {
                  addAQuestion("TEXT_AREA_QUESTION");
                }}
                icon={<FormOutlined />}
              >
                Text (Multi Line)
              </Button>
            </div>
          </div>
        )}
      </>
    </Modal>
  );

  // tab 1 content (forms & surveys)
  const formAndSurveyTabContent = (
    <>
      <Space wrap>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          onClick={() => {
            setCurrentForm(JSON.parse(JSON.stringify(templateFields["blank"])));
            setIsEditingForm(false);
            setIsModalOpen(true);
          }}
        >
          Create New Form
        </Button>
      </Space>
      <div style={{ marginTop: "30px" }}></div>
      <CBFormTable
        editClickedOnForm={(clickedId, activeCampaign) =>
          editCBFormClicked(clickedId, activeCampaign)
        }
        cloneClickedOnForm={(clickedId) => cloneCBFormClicked(clickedId)}
        deleteClickedOnId={(clickedId) => {
          deleteCBFormClicked(clickedId);
        }}
      />
    </>
  );

  // Page Frame
  let mainArea = (
    <div>
      <div>
        <Title>
          <FormOutlined style={{ fontSize: "32px" }} /> Survey Forms & Campaigns
        </Title>
        <p>
          First, create a survey form below. Next, visit the 'survey campaigns' tab to create a campaign that will send this survey to your team once or on a recurring basis.
        </p>

        {/* top left side */}
        <a
          target="_blank"
          href="https://getculturebot.com/blog/forms-and-surveys-in-slack/"
          className="explainResults"
        >
          <QuestionCircleOutlined /> Read the full overview guide
          <br />
          <br />
        </a>

        <Tabs
          defaultActiveKey="1"
          type="card"
          size="middle"
          items={[
            {
              label: "Survey Forms",
              key: 1,
              children: formAndSurveyTabContent,
            },
            {
              label: "Survey Campaigns",
              key: 2,
              children: <CreateCampaignPage />,
            },
          ]}
        />
      </div>
    </div>
  );

  return (
    <>
      <MainContainer>
        <div className="main-container">
          {createNewForm}
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}
