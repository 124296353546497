import { Table, Tooltip, Modal, Button, Input, Space } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../helpers/requestHelpers";
import {
  DownloadOutlined,
  SearchOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import useColumnSearch from "../../hooks/columnSearch";

export default function FeedbackTable({ getFeedbackQuery }) {
  const [csvData, setCsvData] = useState([]);
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [updatingShoutoutActivities, setUpdatingShoutoutActivities] = useState(
    []
  );
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackSelected, setFeedbackSelected] = useState("");
  const [feedbackRepliesThread, setFeedbackRepliesThread] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // get replies for specific feedback
  const getFeedbackRepliesQuery = useQuery(
    ["GET_FEEDBACK_REPLIES", feedbackSelected],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getFeedbackReplies`,
        setTokenErrorType,
        [{ name: "feedback_activity_id", value: feedbackSelected }]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // ---- export to csv ---- //
  // https://www.npmjs.com/package/react-csv
  async function formatCsvData() {
    const tmpData = [];
    tmpData.push(["Sender Name", "Sender Email", "Message", "Sent On"]);
    getFeedbackQuery.data.feedback.forEach((row) => {
      tmpData.push([
        row.sender_real_name,
        row.sender_email,
        row.message,
        moment.unix(row.created_at).format("MM/DD/YYYY"),
      ]);
    });
    setCsvData(tmpData);
  }

  useEffect(() => {
    if (getFeedbackQuery.data) {
      formatCsvData();
    }
    if (getFeedbackRepliesQuery.data && getFeedbackRepliesQuery.data.replies.length) {
      setFeedbackRepliesThread(getFeedbackRepliesQuery.data.replies[0].thread_id)
    }
  }, [getFeedbackQuery.data, getFeedbackRepliesQuery.data]);

  // Table Row & Modal Handlers
  const openFeedbackModal = (feedback_id) => {
    setFeedbackSelected(feedback_id);
    setFeedbackModalOpen(true);
  };

  const closeFeedbackModal = () => {
    setFeedbackModalOpen(false);
    setReplyMessage("");
    setFeedbackRepliesThread("");
    setLoading(false);
  };

  const updateReplyMessage = (e) => {
    setReplyMessage(e.target.value);
  };

  async function handleMessageSubmit() {
    if(replyMessage) {
      setLoading(true);
      const response = await doPostRequest(
        `${getBaseUrl()}/api/submitFeedbackReply`,
        { message: replyMessage, feedback_activity_id: feedbackSelected, feedback_reply_thread_id: feedbackRepliesThread },
        setTokenErrorType
      );
      setLoading(false);
      setReplyMessage("");
      if (response["success"]) {
        queryClient.invalidateQueries("GET_FEEDBACK_REPLIES"); // refresh replies since a new one was just saved
      } else {
        window.alert(response["error"]);
      }
    } else {
      // no message yet > let user know they need to type message before sending 
      window.alert("Please type a reply before clicking submit.");
    }
  }

  // Modal to create shoutout
  const feedbackConversationModal = (
    <Modal
      title="Feedback Conversation"
      open={feedbackModalOpen}
      onCancel={() => closeFeedbackModal()}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}
      footer={[
        <div style={{ display: "flex", flexGrow: 1 }}>
          <Button
            key="submit"
            style={{ order: 1 }}
            type="primary"
            loading={loading}
            onClick={handleMessageSubmit}
          >
            Submit
          </Button>
          ,
          <Input
            style={{ order: 0, marginRight: "5px" }}
            placeholder="Type reply here..."
            value={replyMessage}
            onChange={updateReplyMessage}
          />
        </div>,
      ]}
    >
      {getFeedbackRepliesQuery.data && getFeedbackRepliesQuery.data.replies.length ? getFeedbackRepliesQuery.data.replies.map((reply) => {
        return (
          <>
            <label>
              <strong>{reply.sender_real_name}</strong> ({moment.unix(reply.created_at).format("HH:mm on MM/DD/YYYY")})
            </label>
            <p>
              {reply.message}
            </p>
         </>
        );
      }) : 'No replies yet, send the first below...'}
    </Modal>
  );

  // Table fields
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "sender_name",
      key: "sender_name",
      width: "15%",
      onClick: () => {
        openFeedbackModal();
      },
      render: (_, record) => {
        return <div>{record.sender_name}</div>;
      },
      ...useColumnSearch("sender_name"),
    },
    {
      title: "Employee Email",
      dataIndex: "sender_email",
      key: "sender_email",
      width: "12%",
      render: (_, record) => {
        return <div>{record.sender_email}</div>;
      },
      ...useColumnSearch("sender_email"),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "28%",
      render: (_, record) => {
        return <div>{record.message}</div>;
      },
      ...useColumnSearch("message"),
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (_, record) => {
        return <div>{moment.unix(record.created_at).format("MM/DD/YYYY")}</div>;
      },
      sorter: (a, b) => a.created_at < b.created_at,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: () => {
        return (
          <div>
            Reply{" "}
            <CSVLink
              data={csvData}
              filename={"all-employee-feedback.csv"}
              target="_blank"
            >
              <Tooltip
                placement="left"
                title="Download Employee Feedback (CSV)"
              >
                <DownloadOutlined className="exportCSV" />
              </Tooltip>
            </CSVLink>
          </div>
        );
      },
      dataIndex: "",
      key: "",
      width: "5%",
      render: (_, record) => {
        return (
          <div>
            <Button
              type={"secondary"}
              shape="round"
              icon={<CommentOutlined />}
              onClick={() => {
                openFeedbackModal(record.id);
              }}
            >
              Open Replies
            </Button>
          </div>
        );
      },
    },
  ];

  // Build table area
  const tableData = getFeedbackQuery.data.feedback.map((feedback) => {
    return {
      id: feedback.id,
      sender_name: feedback.sender_real_name,
      sender_email: feedback.sender_email,
      message: feedback.message,
      created_at: feedback.created_at,
    };
  });

  return (
    <>
      {feedbackConversationModal}
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    </>
  );
}
