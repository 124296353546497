import React from "react";
import { FireOutlined, CrownOutlined, UserOutlined } from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import ReactionsTable from "./ReactionsTable";
import BarChartComponent from "./BarChartComponent";
import { Card, Typography, Alert, DatePicker, Col, Row } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import moment from "moment";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function ReactionsOverview() {
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const firstDateFromStorage = sessionStorage.getItem(
    "reaction_stats_first_date"
  );
  const secondDateFromStorage = sessionStorage.getItem(
    "reaction_stats_second_date"
  );

  const defaultFirstDate = moment().subtract(30, "d");
  const defaultSecondDate = moment();
  const [tempDates, setTempDates] = useState(null); // Temporary store for dates for validation of 30-day max window

  const [firstDate, setFirstDate] = useState(
    firstDateFromStorage ? moment(firstDateFromStorage) : defaultFirstDate
  );
  const [secondDate, setSecondDate] = useState(
    secondDateFromStorage ? moment(secondDateFromStorage) : defaultSecondDate
  );
  const [topReactionGivers, setTopReactionGivers] = useState([]);
  const [topPosts, setTopPosts] = useState([]);

  // fetch reaction messages
  const getReactionMessagesQuery = useQuery(
    ["GET_REACTION_MESSAGES", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment.utc(firstDate.startOf("day")).unix();
      const secondDateUnix = moment.utc(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getReactionMessages`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // fetch reaction metrics
  const getReactionMetricsQuery = useQuery(
    ["GET_REACTION_METRICS", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment.utc(firstDate.startOf("day")).unix();
      const secondDateUnix = moment.utc(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getReactionMetrics`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // fetch last sync time
  const getLastReactionsMetricsSync = useQuery(
    ["GET_REACTION_LAST_SYNC_TIME"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getLastReactionsMetricsSync`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  // console.log("getReactionMessagesQuery: ", getReactionMessagesQuery);
  // console.log("getReactionMetricsQuery: ", getReactionMetricsQuery.data);
  // console.log("getLastReactionsMetricsSync: ", getLastReactionsMetricsSync);

  // ---- UI handlers ---- //
  async function onChange(dates) {
    setTempDates(dates); // Store the dates temporarily for validation upon picker close
  }

  const onOpenChange = (open) => {
    // Perform validation when the picker is closed
    if (!open && tempDates && tempDates.length === 2) {
      const [start, end] = tempDates;
      const difference = end.diff(start, "days");

      // Check if the range exceeds 365 days
      if (difference > 365) {
        window.alert("The selected range cannot exceed 365 days.");
      } else {
        // Update the selected dates to show in the picker if validation passes
        setFirstDate(start);
        setSecondDate(end);
        // cache values in browser so user doesn't lose place when refreshing window
        sessionStorage.setItem("reaction_stats_first_date", start);
        sessionStorage.setItem("reaction_stats_second_date", end);
      }
    }
  };

  // re-render form after date selection changes
  useEffect(() => {
    if (
      getReactionMetricsQuery.data &&
      !("error" in getReactionMetricsQuery.data)
    ) {
      setTopReactionGivers(getReactionMetricsQuery.data.top_reaction_givers);
      setTopPosts(getReactionMetricsQuery.data.top_posts);
    }
  }, [firstDate, secondDate, getReactionMetricsQuery.data]);

  // ---- stats section ---- //
  const StatsSection = () => (
    <Row gutter={16}>
      <Col span={6}>
        <Card
          title={
            <div>
              <UserOutlined /> Top Reaction Givers
            </div>
          }
        >
          {!topReactionGivers.length ? 'No Data Available' : ''}
          {topReactionGivers.map((giver, index) => {
            if (index > 9) {
              return undefined;
            } // show max 10
            if (index == topReactionGivers.length - 1) {
              return giver.name + ": " + giver.count;
            } else {
              return giver.name + ": " + giver.count + ", ";
            }
          })}
        </Card>
      </Col>
      <Col span={11}>
        <Card
          title={
            <div>
              <CrownOutlined /> Top Posts
            </div>
          }
        >
          {!topPosts.length ? 'No Data Available' : ''}
          {topPosts.map((post, index) => {
            if (index > 4) {
              return null; // Returning `undefined` is not recommended, use `null` for conditional rendering
            } else {
              return (
                <div>
                  {index + 1}. {post.message} ({post.count})
                </div>
              );
            }
          })}
        </Card>
      </Col>
      <Col span={7}>
        <Alert
          message="Engagement Tips & Advice"
          description={
            <>
              📣 Drive more engagement with shoutouts by aligning types with
              your cultural values{" "}
              <a href={`/app/custom_shoutouts`}>(learn more)</a>
              <br />
              🎙 Engage more employees with water cooler by specifying more
              specific topics using AI instances
              <br />
              ✨ Spark consistent engagement with water cooler by toggling 
              on the option to choose a conversation lead for each post
            </>
          }
          type="success"
          showIcon
          style={{ width: "100%" }}
        />
      </Col>
    </Row>
  );

  // Page Frame
  let mainArea;
  if (getReactionMetricsQuery.isLoading) {
    mainArea = <div>Loading...</div>;
  } else if (
    getReactionMetricsQuery.isError ||
    getReactionMetricsQuery.data.error
  ) {
    mainArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    mainArea = (
      <div>
        <div>
          <Title>
            <FireOutlined style={{ fontSize: "32px" }} /> Reactions Reporting
          </Title>
          <p>
            <Alert
              description={
                <>
                  The table below shows all the reactions to various types of
                  posts made by CultureBot. Reactions are accumulated over a
                  period of up to 30 days from the date of the original post
                  only. To ensure that data below is accurate, make sure to
                  `/invite @CultureBot` to all channels where any water cooler,
                  shoutout, or celebration messages are being sent.
                  <br />
                  <strong>
                    Last Data Collected:{" "}
                    {getLastReactionsMetricsSync.data && getLastReactionsMetricsSync.data.last_updated
                      ? moment
                          .unix(getLastReactionsMetricsSync.data.last_updated)
                          .format("MM/DD/YYYY (HH:mm)")
                      : null}
                  </strong>
                </>
              }
              type="info"
              style={{ width: "100%" }}
            />
          </p>
          <div>
            <h3>Date Range:</h3>
            <RangePicker
              onChange={onChange}
              onOpenChange={onOpenChange}
              allowClear={false}
              value={[firstDate, secondDate]}
            />
          </div>
          <br />
          <StatsSection />
          <br />
          <BarChartComponent rawData={getReactionMetricsQuery} />
          <br />
          <ReactionsTable getReactionMessagesQuery={getReactionMessagesQuery} />
        </div>
      </div>
    );
  }

  return (
    <>
      <MainContainer>
        <div className="main-container">
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}
