import { Table, Tooltip, Tag, Button, Input, Space } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../helpers/requestHelpers";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";

import { useState, useEffect, useRef } from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import useColumnSearch from "../../hooks/columnSearch";

export default function MeetingsTable({ getIntroPairingsQuery }) {
  const [csvData, setCsvData] = useState([]);
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [updatingShoutoutActivities, setUpdatingShoutoutActivities] = useState(
    []
  );
  const queryClient = useQueryClient();
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // ---- export to csv ---- //
  // https://www.npmjs.com/package/react-csv
  async function formatCsvData() {
    const tmpData = [];
    tmpData.push([
      "Users Paired Names",
      "Users Paired Emails",
      "Introduced On",
      "",
    ]);
    getIntroPairingsQuery.data.intros.forEach((row) => {
      tmpData.push([
        row.users_paired_names,
        row.users_paired_emails,
        moment
          .unix(row.created_at)
          .format("MM/DD/YYYY"),
      ]);
    });
    setCsvData(tmpData);
  }

  useEffect(() => {
    if (getIntroPairingsQuery.data) {
      formatCsvData();
    }
  }, [getIntroPairingsQuery.data]);

  // table fields
  const columns = [
    {
      title: "Users Paired Names",
      dataIndex: "users_paired_names",
      key: "users_paired_names",
      width: "25%",
      render: (_, record) => {
        return (
          <div>
            {record.users_paired_names.map((name) => {
              return <Tag>{name}</Tag>;
            })}
          </div>
        );
      },
      ...useColumnSearch("users_paired_names"),
    },
    {
      title: "Users Paired Emails",
      dataIndex: "users_paired_emails",
      key: "users_paired_emails",
      width: "15%",
      render: (_, record) => {
        return (
          <div>
            {record.users_paired_emails.map((email) => {
              return <Tag>{email}</Tag>;
            })}
          </div>
        );
      },
      ...useColumnSearch("users_paired_emails"),
    },
    {
      title: "Introduced On",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (_, record) => {
        return (
          <div>
            {moment
              .unix(record.created_at)
              .format("MM/DD/YYYY")}
          </div>
        );
      },
      sorter: (a, b) =>
        a.created_at <
        b.created_at,
        sortDirections: ["descend", "ascend"],
    },
    {
      title: () => {
        return (
          <div>
            <CSVLink
              data={csvData}
              filename={"all-intro-pairings.csv"}
              target="_blank"
            >
              <Tooltip placement="left" title="Download Intro Pairings (CSV)">
                <DownloadOutlined className="exportCSV" />
              </Tooltip>
            </CSVLink>
          </div>
        );
      },
      dataIndex: "",
      key: "",
      width: "5%",
    },
  ];

  // build table area
  const tableData = getIntroPairingsQuery.data.intros.map((pairing) => {
    return {
      id: pairing.id,
      users_paired_names: pairing.users_paired_names,
      users_paired_emails: pairing.users_paired_emails,
      created_at: pairing.created_at,
    };
  });

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={{
        ...pagination,
        onChange: (page, pageSize) => {
          setPagination((prev) => ({ ...prev, pageSize }));
        },
      }}
    />
  );
}
