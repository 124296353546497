import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import 'antd/dist/antd.css';
import CustomShoutoutPage from './components/CustomShoutout/CustomShoutoutPage';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './components/ErrorPage';
import NotFoundPage from './components/NotFoundPage';
import CreateFormPage from './components/CreateForm/CreateFormPage';
import PublicResultsTable from './components/CampaignResults/PublicResultsTable';
import DevServicesPage from './components/DevServices/DevServicesPage';
import LoginPage from './components/Login/LoginPage';
import ShoutoutStatsPage from './components/ShoutoutStats/ShoutoutStatsPage';
import IntroStatsPage from './components/IntroStats/IntroStatsPage';
import EmployeeFeedback from './components/EmployeeFeedback/FeedbackStatsPage';
import RewardsPage from './components/PeerRewards/RewardsPage';
import ExperiencesPage from './components/Experiences/ExperiencesPage';
import ReactionsOverview from './components/Reactions/ReactionsOverview';
import Merge from './components/Merge/IntegrationSetup';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import NoTeamFoundPage from './components/NoTeamFoundPage';
import TeamNotConfiguredPage from './components/TeamNotConfiguredPage';
import EmployeeAssistantPage from './components/EmployeeAssistant/Assistant';
import GraphicsLibraryPage from './components/GraphicsLibrary/GraphicsLibraryPage';
import PermissionsPage from './components/Permissions/PermissionsPage';
import { TeamLookupPage } from './components/TeamLookup/TeamLookup';
import { ActivityLookupPage } from './components/ActivityLookup/ActivityLookup';
import CelebrationAlertsPage from './components/CelebrationAlerts/CelebrationAlertsPage';
import CelebrationCard from './components/CelebrationCards/CelebrationCard';
import Celebrations from './components/MicrosoftTeams/Celebrations/Celebrations';
import MemberView from './components/MicrosoftTeams/Members/MemberView';
import WaterCooler from './components/MicrosoftTeams/WaterCooler';
import Games from './components/MicrosoftTeams/Games/Games';
import Home from './components/MicrosoftTeams/Home';
import Shoutouts from './components/MicrosoftTeams/Shoutouts';
import Billing from './components/MicrosoftTeams/Billing';
import { useMSTeams } from './hooks/useMSTeams';

// init bugsnag
Bugsnag.start({
  apiKey: '9cfa2b90d5a4c64494eb236044bc2e65',
  plugins: [new BugsnagPluginReact()],
  onError: function (event) {
    let token = localStorage.getItem('token');
    event.setUser(token);
  },
});
BugsnagPerformance.start({ apiKey: '9cfa2b90d5a4c64494eb236044bc2e65' });
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: '/app/custom_shoutouts',
    element: <CustomShoutoutPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/dev_services',
    element: <DevServicesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/login',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/create_form',
    element: <CreateFormPage />,
    errorElement: <ErrorPage />,
  },
  // As of 4/4/2025 multi part urls like this are busted on the test server
  // We serve react differently based on env
  {
    path: '/app/campaign_run_results/:campaign_id/:campaign_run_id',
    element: <PublicResultsTable />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/shoutout_stats',
    element: <ShoutoutStatsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/intro_stats',
    element: <IntroStatsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/employee_feedback',
    element: <EmployeeFeedback />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/rewards',
    element: <RewardsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/experiences',
    element: <ExperiencesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/reactions',
    element: <ReactionsOverview />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/hris_setup',
    element: <Merge />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/no_team_found',
    element: <NoTeamFoundPage />,
  },
  {
    path: '/app/team_not_configured',
    element: <TeamNotConfiguredPage />,
  },
  {
    path: '/app/*',
    element: <NotFoundPage />,
  },
  {
    path: '/app/employee_assistant',
    element: <EmployeeAssistantPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/graphics_library',
    element: <GraphicsLibraryPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/permissions',
    element: <PermissionsPage />,
    errorElement: <ErrorPage />,
  },
  // As of 4/4/2025 multi part urls like this are busted on the test server
  // We serve react differently based on env
  {
    path: '/app/team_lookup/:team_id?',
    element: <TeamLookupPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/activity_lookup',
    element: <ActivityLookupPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/celebrations',
    element: <Celebrations />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/celebration_alerts',
    element: <CelebrationAlertsPage />,
    errorElement: <ErrorPage />,
  },
  // As of 4/4/2025 multi part urls like this are busted on the test server
  // We serve react differently based on env
  {
    path: '/app/birthday/:team_id/:user_id/:year',
    element: <CelebrationCard />,
    errorElement: <ErrorPage />,
  },
  // As of 4/4/2025 multi part urls like this are busted on the test server
  // We serve react differently based on env
  {
    path: '/app/birthday/:team_id/:user_id/:year/:showcase/:showcase_submitter_row_id',
    element: <CelebrationCard />,
    errorElement: <ErrorPage />,
  },
  // As of 4/4/2025 multi part urls like this are busted on the test server
  // We serve react differently based on env
  {
    path: '/app/anniversary/:team_id/:user_id/:year',
    element: <CelebrationCard />,
    errorElement: <ErrorPage />,
  },
  // As of 4/4/2025 multi part urls like this are busted on the test server
  // We serve react differently based on env
  {
    path: '/app/anniversary/:team_id/:user_id/:year/:showcase/:showcase_submitter_row_id',
    element: <CelebrationCard />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/water_cooler',
    element: <WaterCooler />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/shoutouts',
    element: <Shoutouts />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/games',
    element: <Games />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/members',
    element: <MemberView />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/home',
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app/billing',
    element: <Billing />,
    errorElement: <ErrorPage />,
  }
]);

function App() {
  // Initialize MS Teams SDK
  useMSTeams();
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// there is a transitive library (used by a dependency) that causes random errors in Teams (it is trying to interact with the window object directly, suppress these for now)
// const originalConsoleWarn = console.warn;
// console.warn = (...args) => {
//   if (args[0].includes("[rc-image]")) return;
//   originalConsoleWarn(...args);
// };