// src/hooks/useAuth.js
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useAuth = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const navigate = useNavigate();

  const validTokenChecker = (token) => {
    if (typeof token !== 'string' || !token || token == "undefined" || token == "null") { // MS Teams can response with undefined or null as a string :/ 
      return false;
    }
    let dot_matches = token.match(/\./g); 
    // cannot be missing access token, and needs to be valid JWT (2 dots)
    if (dot_matches.length != 2) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const token = localStorage.getItem("token"); 
    const validToken = validTokenChecker(token); 
    if (token && validToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate("/app/login"); // redirect to login if not authenticated
    }
  }, [navigate]);

  return isAuthenticated;
};

export default useAuth;