import { Card, Typography } from "antd";
import base64 from 'react-native-base64'
import Bugsnag from '@bugsnag/js';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const current_token_string = urlParams.get("token_string");
const TOKEN_EXPIRED_ERROR_STRING = "token_expired";
const NO_TOKEN_FOUND_ERROR_STRING = "no_token";
const NO_ACCESS_TO_PAGE_FOR_USER_ERROR_STRING = "no_access_to_page_for_user";
const INVALID_PERMISSION_ERROR_STRING = "invalid_permission";

const access_token = localStorage.getItem("token");

export const TYPES_OF_TOKEN_ERRORS = {
  NO_TOKEN_ERROR: 1,
  INVALID_TOKEN_ERROR: 2,
  NO_TOKEN_FOUND_ERROR: 3, // No token found on db
  NO_ACCESS_TO_PAGE_FOR_USER_ERROR: 4,
  INVALID_PERM_ERROR: 5
};
const { Title } = Typography;

// Base URL for API requests 
export function getBaseUrl() {
  if (
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("test.getculturebot") ||
    window.location.origin.includes("becoming-moderately-jawfish.ngrok-free.app")
  ) {
    return "https://test.getculturebot.com";
  } else {
    return "https://getculturebot.com";
  }
}

// Error text for the UI in case of bad token (returns null if no error)
export function getTokenErrorAreaIfNeeded(tokenErrorType) {
  if (tokenErrorType) {
    let tokenErrorText = "";
    if (tokenErrorType === TYPES_OF_TOKEN_ERRORS.INVALID_TOKEN_ERROR) {
      tokenErrorText =
        "Your session has expired. Please open this page again from the slack app.";
    } else if (
      tokenErrorType === TYPES_OF_TOKEN_ERRORS.NO_TOKEN_ERROR ||
      tokenErrorType === TYPES_OF_TOKEN_ERRORS.NO_TOKEN_FOUND_ERROR ||
      tokenErrorType ===
      TYPES_OF_TOKEN_ERRORS.NO_ACCESS_TO_PAGE_FOR_USER_ERROR_STRING
    ) {
      tokenErrorText = "Invalid url. Please open this page again from the slack app.";
    } else if (tokenErrorType === TYPES_OF_TOKEN_ERRORS.INVALID_PERM_ERROR){
      tokenErrorText = "Insufficient permission to view page.";
    }
    return (
      <Card style={{ padding: 10, margin: 20, marginTop: 25 }}>
        <h1>Uh-oh, you hit an error :(</h1>
        <div style={{ marginTop: "10px" }}></div>
        {tokenErrorText}
      </Card>
    );
  }
}

// Checks to see if token is valid before making any requests
function validTokenChecker(token) {
    let dot_matches = []
    if (typeof token === 'string' && token != "undefined" && token != "null") { // MS Teams can response with undefined or null as a string :/ 
      dot_matches = token.match(/\./g); 
    } else {
      Bugsnag.notify("bad token in validTokenChecker: ", token);
    }
    // cannot be missing access token, and needs to be valid JWT (2 dots)
    if (!token || token === "" || dot_matches.length !== 2) { 
        localStorage.setItem('token', '');
        // might be logged out and trying to visit a certain page (such as managing custom shoutouts or viewing peer rewards) -- save current page in localStorage so user can get back to
        const page = window.location.pathname.split("/app/");
        localStorage.setItem('redirect', page[1]);
        window.location.href = "/app/login";
    }
}

// Basic GET requests 
export const doGetRequest = async (url, setTokenErrorFunction, additionalParams = [], options = {}) => {
  validTokenChecker(access_token);

  if (additionalParams.length > 0) {
    additionalParams.forEach((param, index) => index == 0 ? url += `?${param.name}=${param.value}` : url += `&${param.name}=${param.value}`)
  }
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${access_token}`,
      "Accept": options.responseType === 'blob' ? '*/*' : 'application/json'
    },
  });

  if (!res.ok) {
    console.log("Error response:", res);
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  
  // If the response type is blob, return the blob
  if (options.responseType === 'blob') {
    return await res.blob();
  }

  const returnJson = await res.json();
  if (returnJson.error) {
    if (returnJson.error == TOKEN_EXPIRED_ERROR_STRING) {
      setTokenErrorFunction(TYPES_OF_TOKEN_ERRORS.INVALID_TOKEN_ERROR);
    } else if (returnJson.error == NO_TOKEN_FOUND_ERROR_STRING) {
      setTokenErrorFunction(TYPES_OF_TOKEN_ERRORS.NO_TOKEN_FOUND_ERROR);
    } else if (returnJson.error == NO_ACCESS_TO_PAGE_FOR_USER_ERROR_STRING) {
      setTokenErrorFunction(
        TYPES_OF_TOKEN_ERRORS.NO_ACCESS_TO_PAGE_FOR_USER_ERROR
      );
    } else if(returnJson.error == INVALID_PERMISSION_ERROR_STRING){
      setTokenErrorFunction(
        TYPES_OF_TOKEN_ERRORS.INVALID_PERM_ERROR
      );
    }
  }
  return returnJson;
};

// Basic POST requests 
export const doPostRequest = async (url, body, setTokenErrorFunction) => {
  validTokenChecker(access_token);

  let headers = {
    "Accept": "application/json",
    "Authorization": `Bearer ${access_token}`,
  };

  let requestBody;

  // Check if the body is FormData (for file uploads)
  if (body instanceof FormData) {
    requestBody = body;
    console.log('Sending FormData');
    // Log FormData contents again
    for (let [key, value] of body.entries()) {
      console.log(`${key}:`, value);
    }
  } else {
    headers["Content-Type"] = "application/json";
    requestBody = JSON.stringify(body);
    console.log('Sending JSON data');
  }

  console.log('Request URL:', url);
  console.log('Request headers:', headers);

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: headers,
      body: requestBody,
    });

    if (!res.ok) {
      console.log('Response status:', res.status);
      console.log('Response headers:', res.headers);
      const errorText = await res.text();
      console.log('Error response:', errorText);
      throw new Error(`${errorText}`);
    }

    const returnJson = await res.json();
    console.log('Response JSON:', returnJson);

    if (returnJson.error) {
      if (returnJson.error === TOKEN_EXPIRED_ERROR_STRING) {
        setTokenErrorFunction(TYPES_OF_TOKEN_ERRORS.INVALID_TOKEN_ERROR);
      } else if (returnJson.error === NO_TOKEN_FOUND_ERROR_STRING) {
        setTokenErrorFunction(TYPES_OF_TOKEN_ERRORS.NO_TOKEN_FOUND_ERROR);
      } else if (returnJson.error === NO_ACCESS_TO_PAGE_FOR_USER_ERROR_STRING) {
        setTokenErrorFunction(
          TYPES_OF_TOKEN_ERRORS.NO_ACCESS_TO_PAGE_FOR_USER_ERROR
        );
      } else if (returnJson.error === INVALID_PERMISSION_ERROR_STRING) {
        setTokenErrorFunction(
          TYPES_OF_TOKEN_ERRORS.INVALID_PERM_ERROR
        );
      }
    }
    return returnJson;
  } catch (error) {
    console.error('Error in doPostRequest:', error);
    throw error;
  }
};