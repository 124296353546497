import {
  Button,
  Switch,
  Modal,
  Popconfirm,
  Input,
  InputNumber,
  Select,
  message,
  Alert
} from "antd";
import { filterOptionFunction } from "../CreateCampaign/CreateCampaignPageHelpers";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBaseUrl,
  doPostRequest,
  doGetRequest,
} from "../../helpers/requestHelpers";
import { useState, useEffect } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "./Rewards.css";
import { getCountriesList } from "../../helpers/utilityHelpers"; 

// non-state constants
const { Option } = Select;

export default function Settings({
  rewardSettings,
  tangoCardInfo,
  isMainAdmin,
}) {

  const [tokenErrorType, setTokenErrorType] = useState(false);

  // Initialize pointValues based on countries from rewardSettings
  const initialPointValues = {};
  if (rewardSettings.countries_whitelist && rewardSettings.countries_whitelist.length > 0) {
    rewardSettings.countries_whitelist.forEach((country) => {
      country = JSON.parse(country);
      initialPointValues[country.code] = rewardSettings.points_dollar_values[country.code] || 0.1; // Set value from points_dollar_values or default to 0.1
    });
  } else {
    // Use getCountriesList() if no countries in whitelist
    const countries = getCountriesList();
    countries.forEach((country) => {
      initialPointValues[country.code] = rewardSettings.points_dollar_values[country.code] || 0.1; // Set value from points_dollar_values or default to 0.1
    });
  }

  const [pointValues, setPointValues] = useState(initialPointValues);
  const [savingPointValues, setSavingPointValues] = useState(false);
  const [approvalAdminsArray, setApprovalAdminsArray] = useState(
    rewardSettings.approval_admins
  );
  const [savingApprovalAdmins, setSavingApprovalAdmins] = useState(false);

  const queryClient = useQueryClient();

  const [savingCreditCard, setSavingCreditCard] = useState(false);
  const [saveCardError, setSaveCardError] = useState("");
  const [cardFundingSuccess, setCardFundingSuccess] = useState("");
  const [achModalOpen, setAchModalOpen] = useState(false);

  // add card states
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [number, setNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // add funds states
  const [fundsModalOpen, setFundsModalOpen] = useState(false);
  const [fundingAmount, setFundingAmount] = useState("");

  // reward settings
  async function updateRewardSettings(newSettingsHidden) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/updateRewardSettings`,
      { reward_settings_hidden: newSettingsHidden },
      setTokenErrorType
    );
    queryClient.invalidateQueries("GET_CUSTOM_REWARD_SETTINGS");
    queryClient.invalidateQueries("GET_REWARDS_CATALOG");
  }

  async function updateAutoApprovalSettings(autoApproval) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/updateRewardSettings`,
      { auto_approval: autoApproval },
      setTokenErrorType
    );
    queryClient.invalidateQueries("GET_CUSTOM_REWARD_SETTINGS");
  }

  // switch handler
  const onCatalogSwitchChange = (id, checked) => {
    // new setting hidden
    let newSettingsHidden = [];
    if (checked) {
      if (rewardSettings.reward_categories_hidden.length == 0) {
        newSettingsHidden = [id];
      } else {
        newSettingsHidden = rewardSettings.reward_categories_hidden.filter(
          (category) => category !== id
        ); // show this category (by removing from array)
      }
    } else {
      newSettingsHidden = rewardSettings.reward_categories_hidden.slice();
      newSettingsHidden.push(id); // hide this category
    }
    updateRewardSettings(newSettingsHidden);
  };

  const onAutoApprovalChange = (checked) => {
    updateAutoApprovalSettings(checked);
  }

  // Modal Open & Close Handlers
  const openCardModal = () => {
    setCardModalOpen(true);
    setSavingCreditCard(false);
    setSaveCardError("");
    setCardFundingSuccess("");
  };

  const openAchModal = () => {
    setAchModalOpen(true)
  }

  const openFundsModal = () => {
    setFundsModalOpen(true);
    setSavingCreditCard(false);
    setSaveCardError("");
    setCardFundingSuccess("");
  };

  const closeModals = () => {
    setCardModalOpen(false);
    setFundsModalOpen(false);
    setAchModalOpen(false);
    setSavingCreditCard(false);
    setSaveCardError("");
    setCardFundingSuccess("");

    // reset all input fields
    setNumber("");
    setCvc("");
    setExpiry("");
    setFirstName("");
    setLastName("");
    setAddressOne("");
    setAddressTwo("");
    setCity("");
    setState("");
    setCountry("");
    setPostalCode("");
    setFundingAmount("");
  };

  // Save New Card OR Save New Funds
  async function saveCreditCard() {
    // info is missing
    if (
      !number ||
      !cvc ||
      !expiry ||
      !firstName ||
      !lastName ||
      !addressOne ||
      !city ||
      !state ||
      !country ||
      !postalCode
    ) {
      setSaveCardError("All fields below must be filled out before saving");
    } else {
      setSavingCreditCard(true);

      // find user's IP (required for cc saving call)
      const response = await fetch("https://geolocation-db.com/json/");
      const data = await response.json();
      const ip = data.IPv4;

      const new_card_resp = await doPostRequest(
        `${getBaseUrl()}/api/upsertTangoCard`,
        {
          ip: ip,
          number: number,
          cvc: cvc,
          expiry: expiry,
          firstName: firstName,
          lastName: lastName,
          addressOne: addressOne,
          addressTwo: addressTwo,
          city: city,
          state: state,
          country: country,
          postalCode: postalCode,
        },
        setTokenErrorType
      );

      queryClient.invalidateQueries("GET_TANGO_CARD_INFO");
      setSavingCreditCard(false);
      setSaveCardError(new_card_resp["error"]);
      closeModals();
    }
  }

  async function saveFunds() {
    setSavingCreditCard(true);
    const response = await doPostRequest(
      `${getBaseUrl()}/api/fundTangoCard`,
      { fundingAmount: fundingAmount },
      setTokenErrorType
    );
    setSavingCreditCard(false);
    if (response["success"]) {
      setCardFundingSuccess(
        "Funding request successfully submitted. If this is your first funding transaction, it may take up to 24 hrs to process the transaction for security and verification purposes. You will be emailed a receipt when the transaction is complete – feel free to close this modal."
      );
    } else {
      setSaveCardError(
        "There was an issue funding your account - please try again later."
      );
    }
  }

  async function deleteCard() {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteTangoCard`,
      setTokenErrorType
    );
    if (response["error"]) {
      setSaveCardError(response["error"]); // error removing card
    } else {
      closeModals()
      queryClient.invalidateQueries("GET_TANGO_CARD_INFO");
    }
  }

  // Extra settings (points to dollars value, admin(s) who can approve of gift cards)
  async function savePointValue() {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/savePointsValueForOrg`,
      { points_values: pointValues },
      setTokenErrorType
    );
    if (response["error"]) {
      window.alert(
        "There was an issue saving the points value - please try again."
      );
    } else {
      message.success("Successfully updated point values", 3);
    }
  }

  const getSlackUsersQuery = useQuery(
    ["getSlackUsersQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "users" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  async function saveApprovalAdmins() {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/saveApprovalAdmins`,
      { approval_admins: approvalAdminsArray },
      setTokenErrorType
    );
    if (response["error"]) {
      window.alert(
        "There was an issue saving the approval admins - please try again."
      );
    } else {
      setSavingApprovalAdmins(true);
      setTimeout(function () {
        setSavingApprovalAdmins(false);
      }, 2000);
    }
    queryClient.invalidateQueries("GET_CUSTOM_REWARD_SETTINGS");
  }

  // Modal Input Changes
  // Test cards: 4111111111111111 (Visa, 3 digit cvc) or 378282246310005 (Amex, 4 digit cvc)
  const handleCardInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "number":
        setNumber(value);
        break;
      case "cvc":
        setCvc(value);
        break;
      case "expiry":
        setExpiry(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "addressOne":
        setAddressOne(value);
        break;
      case "addressTwo":
        setAddressTwo(value);
        break;
      case "city":
        setCity(value);
        break;
      case "state":
        setState(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "postalCode":
        setPostalCode(value);
        break;
      case "fundingAmount":
        setFundingAmount(value);
        break;
    }
  };

  // Add New Card Modal
  const CreditCardModal = (
    <Modal
      title={"Add Credit Card"}
      open={cardModalOpen}
      onCancel={closeModals}
      footer={[
        <Button key="back" onClick={closeModals}>
          Close
        </Button>,
        <Button
          form="campaignForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={savingCreditCard}
          onClick={saveCreditCard}
        >
          Save
        </Button>,
      ]}
    >
      {/* https://github.com/amaroteam/react-credit-cards */}
      <div id="PaymentForm" className="creditCard">
        <Cards
          number={number}
          cvc={cvc}
          expiry={expiry}
          name={firstName + " " + lastName}
        />
        <br />
        {saveCardError ? (
          <>
            <Alert
              description={<strong>{saveCardError}</strong>}
              type="error"
            />
            <br />
          </>
        ) : null}
        <form>
          <h3>Card Info:</h3>
          <Input
            type="tel"
            name="number"
            value={number}
            placeholder="Card Number"
            className="billingInput"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="tel"
            name="expiry"
            value={expiry}
            placeholder="Valid Thru (use MM/YY format, e.g. 01/28)"
            className="billingInput"
            pattern="\d\d/\d\d"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="tel"
            name="cvc"
            value={cvc}
            className="billingInput"
            placeholder="CVC"
            pattern="\d{3,4}"
            onChange={handleCardInputChange}
          />
          <br />
          <br />
          <h3>Card Billing Info:</h3>
          <Input
            type="text"
            name="firstName"
            value={firstName}
            className="billingInput"
            placeholder="First name (on card)"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="text"
            name="lastName"
            value={lastName}
            className="billingInput"
            placeholder="Last name (on card)"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="text"
            name="addressOne"
            value={addressOne}
            className="billingInput"
            placeholder="Address (Line 1)"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="text"
            name="addressTwo"
            value={addressTwo}
            className="billingInput"
            placeholder="Address (Line 2)"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="text"
            name="city"
            value={city}
            className="billingInput"
            placeholder="City"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="text"
            name="state"
            value={state}
            className="billingInput"
            placeholder="State (use abbrevation, e.g. NY)"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="text"
            name="postalCode"
            value={postalCode}
            className="billingInput"
            placeholder="Postal Code"
            onChange={handleCardInputChange}
          />
          <br />
          <Input
            type="text"
            name="country"
            value={country}
            className="billingInput"
            placeholder="Country (use abbrevation, e.g. US)"
            onChange={handleCardInputChange}
          />
          <br />
        </form>
      </div>
    </Modal>
  );

  // Add Funds Modal
  const AddFundsModal = (
    <Modal
      title={"Add Funds"}
      open={fundsModalOpen}
      onCancel={closeModals}
      footer={[
        <Button key="back" onClick={closeModals}>
          Close
        </Button>,
        <Button
          form="campaignForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={savingCreditCard}
          onClick={saveFunds}
        >
          Save
        </Button>,
      ]}
    >
      {/* https://github.com/amaroteam/react-credit-cards */}
      <div>
        <h3>{tangoCardInfo.card.label}</h3> (*
        {tangoCardInfo.card.lastFourDigits} Exp:{" "}
        {tangoCardInfo.card.expirationDate})
        <br />
        <Popconfirm
          title="Are you sure you want to remove this card?"
          onConfirm={deleteCard}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger className="deleteCardButton">
            Remove Card
          </Button>
        </Popconfirm>
        <br />
        <br />
        <form>
          <h3>Add Funds:</h3>
          <p>(Existing funds: ${tangoCardInfo.funds})<br/>
          We recommend adding as many funds as you'll need for 1 month at a time. Your card can only be funded up to 3 times per day.
          </p>
          <Input
            type="number"
            addonBefore="$"
            name="fundingAmount"
            value={fundingAmount}
            className="fundingInput"
            placeholder="Amount"
            onChange={handleCardInputChange}
          />
          {fundingAmount ? (
            <>
              <h4>Fee (3.5%): ${(fundingAmount * 0.035).toFixed(2)}</h4>
              <h4>
                Total: $
                {(
                  parseFloat(fundingAmount) + parseFloat(fundingAmount * 0.035)
                ).toFixed(2)}
              </h4>
            </>
          ) : null}
          {cardFundingSuccess ? (
            <>
              <Alert
                description={<strong>{cardFundingSuccess}</strong>}
                type="success"
              />
              <br />
            </>
          ) : null}
          {saveCardError ? (
            <>
              <br />
              <br />
              <Alert
                description={<strong>{saveCardError}</strong>}
                type="error"
              />
              <br />
            </>
          ) : null}
        </form>
      </div>
    </Modal>
  );

  // ACH Modal
  const AchModal = (
    <Modal
      title={"Add Funds via ACH"}
      open={achModalOpen}
      onCancel={closeModals}
      footer={[
        <Button key="back" onClick={closeModals}>
          Close
        </Button>
      ]}
    >
      To fund via ACH (no fee), please contact support@getculturebot.com.
      <br /><br />
      <p><strong>Existing funds: </strong>${tangoCardInfo.funds}</p>
    </Modal>
  )

  return (
    <div style={{ marginLeft: "20px" }}>
      <h3>Shoutout Point Values</h3>
      <p>Set how much a single shoutout point is worth in dollars for each country.</p>
      <div style={{ maxHeight: "250px", overflowY: "auto" }}>
        {Object.keys(pointValues).map((country) => (
          <div key={country} style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
            <label style={{ paddingRight: "10px", width: "30px", textAlign: "left" }}>{country}:</label>
            <InputNumber
              defaultValue={pointValues[country]}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              min={0.01}
              className="billingInput"
              style={{ width: "200px", textAlign: "left" }}
              step={0.05}
              onChange={(val) => setPointValues((prev) => ({ ...prev, [country]: val }))}
            />
          </div>
        ))}
      </div>
      <Button
        type="primary"
        shape="round"
        className="billingInputSave"
        onClick={savePointValue}
      >
        Save All Values
      </Button>
      <br />
      {savingPointValues ? (
        <Alert
          description={<strong>Successfully updated points value</strong>}
          type="success"
          style={{ width: "310px", marginTop: "15px" }}
        />
      ) : null}
      <br />
      {isMainAdmin ? (
        <>
          <h3>Approval Rights</h3>
          <p>
            Set below which admin(s) have the right to approve redeemed rewards
            (from the 'redemptions' tab).
            <br />
            (Note: as the main admin you will be included automatically by default)
          </p>
          <Select
            showSearch
            mode="multiple"
            style={{ width: "360px" }}
            onChange={setApprovalAdminsArray}
            value={approvalAdminsArray}
            filterOption={filterOptionFunction}
          >
            {getSlackUsersQuery.data
              ? getSlackUsersQuery.data.conversations.map((conversation) => {
                  // Check platform and set display name accordingly
                  const displayName = getSlackUsersQuery.data.platform === "slack" 
                    ? conversation.real_name 
                    : conversation.displayName; // Use displayName for Teams

                  return (
                    <Option
                      key={conversation.id}
                      value={conversation.id}
                      label={displayName}
                    >
                      {displayName}
                    </Option>
                  );
                })
              : []}
          </Select>
          <Button
            type="primary"
            shape="round"
            className="billingInputSave"
            onClick={saveApprovalAdmins}
            style={{width: 100, marginLeft: 10}}
          >
            Save
          </Button>
          <br />
        </>
      ) : null}
      {savingApprovalAdmins ? (
        <Alert
          description={<strong>Successfully updated appproval admins</strong>}
          type="success"
          style={{ width: "330px" }}
        />
      ) : null}
      <br />
      <h3>Catalog Settings</h3>
      <p>
        Want to limit the number of gift card brands and countries available in the public catalog? <br/>Please reach out to support@getculturebot.com in order to do so.
      </p>
      <div>
        <br />
        <Switch
          onChange={(checked) => onCatalogSwitchChange("gift card", checked)}
          checked={
            !rewardSettings.reward_categories_hidden.includes("gift card")
          }
        />
        <label> Show gift cards in catalog</label>

        {/* <br />
        <br />
        <Switch
          onChange={(checked) => onCatalogSwitchChange("donation", checked)}
          checked={
            !rewardSettings.reward_categories_hidden.includes("donation")
          }
        />
        <label> Show donations in catalog</label> */}

        <br />
        <br />
        <Switch
          onChange={(checked) => onCatalogSwitchChange("cash equivalent", checked)}
          checked={
            !rewardSettings.reward_categories_hidden.includes("cash equivalent")
          }
        />
        <label> Show prepaid cards in catalog</label>

        <br />
        <br />
        <Switch
          onChange={(checked) => onCatalogSwitchChange("custom reward", checked)}
          checked={
            !rewardSettings.reward_categories_hidden.includes("custom reward")
          }
          // Might be nice to add loading eventually but we only want to target the selected <Switch>
          // loading={useIsFetching({queryKey: ['GET_CUSTOM_REWARD_SETTINGS']})}
        />
        <label> Show custom rewards in catalog</label>

        <br />
        <br />
        <Switch
          onChange={(checked) => onAutoApprovalChange(checked)}
          checked={rewardSettings.auto_approve_gift_cards}
        />
        <label> Auto-approve gift cards when redeemed</label>

      </div>
      <br />
      {isMainAdmin ? (
        <>
          <h3>Fund Your Account</h3>
          {(tangoCardInfo.card.token && tangoCardInfo.tango_on) ? (
            <><Button onClick={openFundsModal}>Fund via Card</Button> <Button onClick={openAchModal}>Fund via ACH</Button></>
          ) : (
            tangoCardInfo.tango_on ? <><Button onClick={openCardModal}>Add/Modify Card</Button> <Button onClick={openAchModal}>Fund via ACH</Button></> : "To enable initial funding of your account, please reach out to support@getculturebot.com to begin the approval process."
          )}
          {AddFundsModal}
          {CreditCardModal}
          {AchModal}
        </>
      ) : null }
    </div>
  );
}
