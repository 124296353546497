import React, { useState, useEffect } from "react";
import { Tabs, Form, Input, Select, Checkbox, Button, message, Alert, Modal } from "antd";
import {
  doPostRequest,
  getBaseUrl,
  doGetRequest,
} from "../../helpers/requestHelpers";
import { useQuery } from "@tanstack/react-query";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const AlertsDetails = () => {
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [activeTab, setActiveTab] = useState("birthdays");
  const [settings, setSettings] = useState({
    birthdays: {
      managerMessage: "",
      managerImageUrl: "",
      employeeMessage: "",
      employeeImageUrl: "",
      managerTiming: "1 day",
      employeeTiming: "1 day",
      sendECard: false,
      backupUsers: [],
      employeeAlertsEnabled: false,
    },
    anniversaries: {
      managerMessage: "",
      managerImageUrl: "",
      employeeMessage: "",
      employeeImageUrl: "",
      managerTiming: "1 day",
      employeeTiming: "1 day",
      sendECard: false,
      backupUsers: [],
      employeeAlertsEnabled: false,
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleInputChange = (event, type, field) => {
    const value =
      field === "sendECard" ? event.target.checked : event.target.value;
    setSettings({
      ...settings,
      [type]: {
        ...settings[type],
        [field]: value,
      },
    });
  };

  const handleCelebrationAlertsSubmit = async (type) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/updateCelebrationAlerts`,
        {
          type,
          managerMessage: settings[type].managerMessage,
          managerImageUrl: settings[type].managerImageUrl,
          managerTiming: settings[type].managerTiming,
          sendECard: settings[type].sendECard,
          employeeMessage: settings[type].employeeMessage,
          employeeImageUrl: settings[type].employeeImageUrl,
          employeeTiming: settings[type].employeeTiming,
          backupUsers: settings[type].backupUsers,
        },
        setTokenErrorType
      );

      message.success("Celebration alerts updated successfully!");
      console.log("Success:", response);
    } catch (error) {
      message.error(`Error updating celebration alerts – ${error}`);
      console.error("Error:", error);
    }
  };

  const getCelebrationAlertsQuery = useQuery(
    ["getCelebrationAlerts"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getCelebrationAlerts`);
    },
    { refetchOnWindowFocus: false }
  );

  const getSlackUsersQuery = useQuery(
    ["getSlackUsersQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "users" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  useEffect(() => {
    if (getCelebrationAlertsQuery.data) {
      const alertsData = getCelebrationAlertsQuery.data.celebration_alerts;
      setSettings(alertsData);
    }
    if (getCelebrationAlertsQuery.error) {
      console.error("Error fetching alerts:", getCelebrationAlertsQuery.error);
    }
  }, [getCelebrationAlertsQuery.data, getCelebrationAlertsQuery.error]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      {getSlackUsersQuery.data && getSlackUsersQuery.data.platform == "teams" ? (
        <p>
          To upload/sync a list of managers for employees, visit the <a href={`/app/hris_setup`}>HRIS sync</a> page or contact our <a href="mailto:support@getculturebot.com">support team</a> for more assistance.
        </p>
      ) : (
        <p>
          To upload/sync a list of managers for employees, visit the 'employee
          info' tab of the <a href={`/app/experiences`}>Experiences</a> page or
          the <a href={`/app/hris_setup`}>HRIS sync</a> page.
        </p>
      )}
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <TabPane tab="Birthdays" key="birthdays">
          <Form layout="vertical">
            <Form.Item
              label={
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Manager Alerts
                </span>
              }
              style={{
                backgroundColor: "#f7f7f7",
                padding: "15px",
                borderRadius: "5px",
              }}
            >
              <Form.Item label="Message to Manager">
                <TextArea
                  rows={3}
                  value={settings.birthdays.managerMessage}
                  onChange={(e) =>
                    handleInputChange(e, "birthdays", "managerMessage")
                  }
                />
                <span style={{ fontSize: "12px", color: "#888" }}>
                  If left blank, this message will not send.
                </span>
              </Form.Item>
              <Form.Item label="Image URL to Include in Message">
                <Input
                  value={settings.birthdays.managerImageUrl}
                  onChange={(e) =>
                    handleInputChange(e, "birthdays", "managerImageUrl")
                  }
                />
              </Form.Item>
              <Form.Item label="When to Send Message to Manager">
                <Select
                  value={settings.birthdays.managerTiming}
                  onChange={(value) =>
                    handleInputChange(
                      { target: { value } },
                      "birthdays",
                      "managerTiming"
                    )
                  }
                >
                  <Option value="1 day">1 day (ahead of the birthday)</Option>
                  <Option value="2 days">2 days (ahead of the birthday)</Option>
                  <Option value="3 days">3 days (ahead of the birthday)</Option>
                  <Option value="1 week">1 week (ahead of the birthday)</Option>
                  <Option value="2 weeks">2 weeks (ahead of the birthday)</Option>
                </Select>
                <span style={{ fontSize: "12px", color: "#888" }}>
                  The time of day the alert will be sent will be the same as the
                  time of day birthday messages are configured to send.
                </span>
                <Checkbox
                  checked={settings.birthdays.sendECard}
                  onChange={(e) =>
                    handleInputChange(e, "birthdays", "sendECard")
                  }
                  style={{ marginTop: "10px" }}
                >
                  Send eCard along with the alert above
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: "normal",
                      display: "block",
                    }}
                  >
                    The eCard option (below) will send a digital card to an
                    employee's manager ahead of their birthday or work
                    anniversary that they can then send to other employees to
                    sign digitally and/or with a video. On the day of the
                    celebration, the employee will then receive this card.
                  </span>
                  <Button type="link" onClick={showModal} style={{ paddingLeft: "0", "fontSize": "12px" }}>
                    What does this look like?
                  </Button>
                </Checkbox>
              </Form.Item>
              <Form.Item label="Backup Users">
                <Select
                  showSearch
                  placeholder="Backup User"
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    handleInputChange(
                      { target: { value } },
                      "birthdays",
                      "backupUsers"
                    )
                  }
                  value={settings.birthdays.backupUsers}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {getSlackUsersQuery.data
                    ? getSlackUsersQuery.data.conversations.map(
                        (conversation) => {
                          // Check platform and set display name accordingly
                          const displayName =
                            getSlackUsersQuery.data.platform === "slack"
                              ? conversation.real_name
                              : conversation.displayName; // Use displayName for Teams

                          return (
                            <Option
                              key={conversation.id}
                              value={conversation.id}
                              label={displayName}
                            >
                              {displayName}
                            </Option>
                          );
                        }
                      )
                    : []}
                </Select>
                <small
                  style={{ display: "block", marginTop: "5px", color: "#888" }}
                >
                  The user to send to if a manager does not exist for this
                  employee
                </small>
              </Form.Item>
              <Button
                type="primary"
                shape="round"
                onClick={() => handleCelebrationAlertsSubmit("birthdays")}
              >
                Save
              </Button>
            </Form.Item>
            {!settings.birthdays.employeeAlertsEnabled && (
              <Alert
                message="Employee Alerts Feature"
                description={
                  <div>
                    <p>
                      This feature allows you to send a special message to employees before their birthday, like giving them a chance to pick out a charity gift. Contact <a href="mailto:support@getculturebot.com">support@getculturebot.com</a> to enable it.
                    </p>
                  </div>
                }
                type="info"
                showIcon
              />
            )}
            {settings.birthdays.employeeAlertsEnabled && (
              <Form.Item
                label={
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Employee Alerts
                  </span>
                }
                style={{
                  backgroundColor: "#f7f7f7",
                  padding: "15px",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              >
                <span style={{ fontSize: "12px", color: "#888" }}>
                  These are useful in the event you would like to share a special
                  note, gift or link with an employee ahead of their birthday.
                </span>
                <Form.Item label="Employee Message">
                  <TextArea
                    rows={3}
                    value={settings.birthdays.employeeMessage}
                    onChange={(e) =>
                      handleInputChange(e, "birthdays", "employeeMessage")
                    }
                  />
                  <span style={{ fontSize: "12px", color: "#888" }}>
                    If left blank, this message will not send.
                  </span>
                </Form.Item>
                <Form.Item label="Employee Image URL">
                  <Input
                    value={settings.birthdays.employeeImageUrl}
                    onChange={(e) =>
                      handleInputChange(e, "birthdays", "employeeImageUrl")
                    }
                  />
                </Form.Item>
                <Form.Item label="Employee Timing">
                  <Select
                    value={settings.birthdays.employeeTiming}
                    onChange={(value) =>
                      handleInputChange(
                        { target: { value } },
                        "birthdays",
                        "employeeTiming"
                      )
                    }
                  >
                    <Option value="0 days">On the day of the birthday</Option>
                    <Option value="1 day">1 day (ahead of the birthday)</Option>
                    <Option value="2 days">2 days (ahead of the birthday)</Option>
                    <Option value="3 days">3 days (ahead of the birthday)</Option>
                    <Option value="1 week">1 week (ahead of the birthday)</Option>
                    <Option value="2 weeks">2 weeks (ahead of the birthday)</Option>
                  </Select>
                  <span style={{ fontSize: "12px", color: "#888" }}>
                    The time of day the alert will be sent will be the same as the
                    time of day birthday messages are configured to send.
                  </span>
                </Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => handleCelebrationAlertsSubmit("birthdays")}
                >
                  Save
                </Button>
              </Form.Item>
            )}
          </Form>
        </TabPane>
        <TabPane tab="Work Anniversaries" key="anniversaries">
          <Form layout="vertical">
            <Form.Item
              label={
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Manager Alerts
                </span>
              }
              style={{
                backgroundColor: "#f7f7f7",
                padding: "15px",
                borderRadius: "5px",
              }}
            >
              <Form.Item label="Message to Manager">
                <TextArea
                  rows={3}
                  value={settings.anniversaries.managerMessage}
                  onChange={(e) =>
                    handleInputChange(e, "anniversaries", "managerMessage")
                  }
                />
                <span style={{ fontSize: "12px", color: "#888" }}>
                  If left blank, this message will not send.
                </span>
              </Form.Item>
              <Form.Item label="Image URL to Include in Message">
                <Input
                  value={settings.anniversaries.managerImageUrl}
                  onChange={(e) =>
                    handleInputChange(e, "anniversaries", "managerImageUrl")
                  }
                />
              </Form.Item>
              <Form.Item label="When to Send Message to Manager">
                <Select
                  value={settings.anniversaries.managerTiming}
                  onChange={(value) =>
                    handleInputChange(
                      { target: { value } },
                      "anniversaries",
                      "managerTiming"
                    )
                  }
                >
                  <Option value="1 day">1 day (ahead of the anniversary)</Option>
                  <Option value="2 days">2 days (ahead of the anniversary)</Option>
                  <Option value="3 days">3 days (ahead of the anniversary)</Option>
                  <Option value="1 week">1 week (ahead of the anniversary)</Option>
                  <Option value="2 weeks">2 weeks (ahead of the anniversary)</Option>
                </Select>
                <span style={{ fontSize: "12px", color: "#888" }}>
                  The time of day the alert will be sent will be the same as the
                  time of day anniversary messages are configured to send.
                </span>
                <Checkbox
                  checked={settings.anniversaries.sendECard}
                  onChange={(e) =>
                    handleInputChange(e, "anniversaries", "sendECard")
                  }
                  style={{ marginTop: "10px" }}
                >
                  Send eCard along with the alert above
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: "normal",
                      display: "block",
                    }}
                  >
                    The eCard option (below) will send a digital card to an
                    employee's manager ahead of their birthday or work
                    anniversary that they can then send to other employees to
                    sign digitally and/or with a video. On the day of the
                    celebration, the employee will then receive this card.
                  </span>
                  <Button type="link" onClick={showModal} style={{ paddingLeft: "0", "fontSize": "12px" }}>
                    What does this look like?
                  </Button>
                </Checkbox>
              </Form.Item>
              <Form.Item label="Backup Users">
                <Select
                  showSearch
                  placeholder="Backup User"
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    handleInputChange(
                      { target: { value } },
                      "anniversaries",
                      "backupUsers"
                    )
                  }
                  value={settings.anniversaries.backupUsers}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {getSlackUsersQuery.data
                    ? getSlackUsersQuery.data.conversations.map(
                        (conversation) => {
                          // Check platform and set display name accordingly
                          const displayName =
                            getSlackUsersQuery.data.platform === "slack"
                              ? conversation.real_name
                              : conversation.displayName; // Use displayName for Teams

                          return (
                            <Option
                              key={conversation.id}
                              value={conversation.id}
                              label={displayName}
                            >
                              {displayName}
                            </Option>
                          );
                        }
                      )
                    : []}
                </Select>
                <small
                  style={{ display: "block", marginTop: "5px", color: "#888" }}
                >
                  The user to send to if a manager does not exist for this
                  employee
                </small>
              </Form.Item>
              <Button
                type="primary"
                shape="round"
                onClick={() => handleCelebrationAlertsSubmit("anniversaries")}
              >
                Save
              </Button>
            </Form.Item>
            {!settings.anniversaries.employeeAlertsEnabled && (
              <Alert
                message="Employee Alerts Feature"
                description={
                  <div>
                    <p>
                      This feature allows you to send a special message to employees before their anniversary, like giving them a chance to pick out a charity gift. Contact <a href="mailto:support@getculturebot.com">support@getculturebot.com</a> to enable it.
                    </p>
                  </div>
                }
                type="info"
                showIcon
              />
            )}
            {settings.anniversaries.employeeAlertsEnabled && (
              <Form.Item
                label={
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Employee Alerts
                  </span>
                }
                style={{
                  backgroundColor: "#f7f7f7",
                  padding: "15px",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              >
                <span style={{ fontSize: "12px", color: "#888" }}>
                  These are useful in the event you would like to share a special
                  note, gift or link with an employee ahead of their anniversary.
                </span>
                <Form.Item label="Employee Message">
                  <TextArea
                    rows={3}
                    value={settings.anniversaries.employeeMessage}
                    onChange={(e) =>
                      handleInputChange(e, "anniversaries", "employeeMessage")
                    }
                  />
                  <span style={{ fontSize: "12px", color: "#888" }}>
                    If left blank, this message will not send.
                  </span>
                </Form.Item>
                <Form.Item label="Employee Image URL">
                  <Input
                    value={settings.anniversaries.employeeImageUrl}
                    onChange={(e) =>
                      handleInputChange(e, "anniversaries", "employeeImageUrl")
                    }
                  />
                </Form.Item>
                <Form.Item label="Employee Timing">
                  <Select
                    value={settings.anniversaries.employeeTiming}
                    onChange={(value) =>
                      handleInputChange(
                        { target: { value } },
                        "anniversaries",
                        "employeeTiming"
                      )
                    }
                  >
                    <Option value="0 days">On the day of the anniversary</Option>
                    <Option value="1 day">1 day (ahead of the anniversary)</Option>
                    <Option value="2 days">2 days (ahead of the anniversary)</Option>
                    <Option value="3 days">3 days (ahead of the anniversary)</Option>
                    <Option value="1 week">1 week (ahead of the anniversary)</Option>
                    <Option value="2 weeks">2 weeks (ahead of the anniversary)</Option>
                  </Select>
                  <span style={{ fontSize: "12px", color: "#888" }}>
                    The time of day the alert will be sent will be the same as the
                    time of day anniversary messages are configured to send.
                  </span>
                </Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => handleCelebrationAlertsSubmit("anniversaries")}
                >
                  Save
                </Button>
              </Form.Item>
            )}
          </Form>
        </TabPane>
      </Tabs>
      <Modal
        title="eCard Preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>When the employee receives a birthday or anniversary card from their manager, they will be able to sign/draw on it digitally, add text, emojis, and a video.</p>
        <img
          src="https://graphics.getculturebot.com/message_previews/birthday-card-example.png"
          alt="eCard Preview"
          style={{ width: '100%' }}
        />
        <br /><br />
      </Modal>
    </div>
  );
};

export default AlertsDetails;
