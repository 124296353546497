import React from "react";
import CampaignResultsTable from "./CampaignResultsTable";
import { useParams } from "react-router-dom";
import { Card } from "antd";
import cbLogoBlue from "../../images/logo-blue.png";

export default function PublicResultsTable() {
  
  let params = useParams();

  return (
    <>
      <div>
        <img src={cbLogoBlue} alt="Culturebot Logo" style={{ width: "220px", height: "auto", "marginTop": "20px" }} />
        <div style={{ marginTop: "20px" }}></div>
        <Card style={{ padding: 10, margin: 20 }}>
            <CampaignResultsTable
            campaignId={params.campaign_id}
            setCampaignIdResultsToShow={true}
            isPublicResults={true}
            publicCampaignRunId={params.campaign_run_id}
            />
        </Card>
      </div>
    </>
  );
}
