import React, { useState, useEffect } from "react";
import { Tabs, Card, Select, message } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import MainContainer from "../../Container/MainContainer";
import {
  getBaseUrl,
  doGetRequest,
} from "../../../helpers/requestHelpers";
import {
  CalendarOutlined,
  GiftOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import BirthdaysTab from "./BirthdaysTab";
import AnniversariesTab from "./AnniversariesTab";
import CustomCelebrationsTab from "./CustomCelebrationsTab";
import moment from "moment";
import { useTenantUsers, renderUserOptions } from "../Shared/useTenantUsers";
import {
  useTenantChannels,
  renderChannelOptions,
  mapChannels
} from "../Shared/useTenantChannels";

const { TabPane } = Tabs;
const { Option } = Select;

const Celebrations = () => {

  const [tokenErrorType, setTokenErrorType] = useState(false);

  // Shared state vars
  const [channelMap, setChannelMap] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get("tab") || "1";

  // gets all tenant's settings
  const getTenantSettingsQuery = useQuery(
    ["GET_TENANT_SETTINGS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSettingsForTenant`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  // get all custom celebrations
  const getAllCustomCelebrationsQuery = useQuery(
    ["GET_ALL_CUSTOM_CELEBRATIONS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllCustomCelebrations`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  // get token permissions (to see if partial admins have rights to view bdays/anniversaries/custom celebrations)
  const getTokenPermissionsQuery = useQuery(
    ["GET_TOKEN_PERMISSIONS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getTokenPermissions`, () => {});
    },
    { refetchOnWindowFocus: false, retry: 1 }
  );

  // gets all tenant's channels across all teams
  const getTenantChannelsQuery = useTenantChannels(setTokenErrorType);
  const channelOptions = renderChannelOptions(getTenantChannelsQuery?.data);

  // gets all tenant's users across all teams
  const getTenantUsersQuery = useTenantUsers(setTokenErrorType);
  const userOptions = renderUserOptions(getTenantUsersQuery);

  // Pre-populate settings when GET_TENANT_SETTINGS returns
  useEffect(() => {
    if (getTenantSettingsQuery.data && getTenantChannelsQuery.data) {
      const newChannelMap = mapChannels(getTenantChannelsQuery.data);
      setChannelMap(newChannelMap);
    }
  }, [
    getTenantSettingsQuery.data,
    getTenantChannelsQuery.data,
    getTenantUsersQuery.data
  ]);

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">
          <Tabs defaultActiveKey={defaultTab}>
            
            {/* show for full admins, or partial admins */}
            {getTokenPermissionsQuery.data?.is_admin || getTokenPermissionsQuery.data?.bday_admin_rights ? (
              <TabPane
                tab={
                  <span>
                    <GiftOutlined /> Birthdays
                  </span>
                }
                key="1"
              >
                <BirthdaysTab
                  getTenantSettingsQuery={getTenantSettingsQuery}
                  getTenantChannelsQuery={getTenantChannelsQuery}
                  getTenantUsersQuery={getTenantUsersQuery}
                  channelMap={channelMap}
                  channelOptions={channelOptions}
                  userOptions={userOptions}
                />
              </TabPane>
            ) : null}

            {/* show for full admins, or partial admins */}
            {getTokenPermissionsQuery.data?.is_admin || getTokenPermissionsQuery.data?.anniv_admin_rights ? (
              <TabPane
                tab={
                  <span>
                    <CalendarOutlined /> Work Anniversaries
                  </span>
                }
                key="2"
              >
                <AnniversariesTab
                  getTenantSettingsQuery={getTenantSettingsQuery}
                  getTenantChannelsQuery={getTenantChannelsQuery}
                  getTenantUsersQuery={getTenantUsersQuery}
                  channelMap={channelMap}
                  channelOptions={channelOptions}
                  userOptions={userOptions}
                />
              </TabPane>
            ) : null}

            {/* show for full admins, or partial admins */}
            {getTokenPermissionsQuery.data?.is_admin || getTokenPermissionsQuery.data?.custom_celebration_admin_rights ? (
              <TabPane
                tab={
                  <span>
                    <SmileOutlined /> Custom Celebrations
                  </span>
                }
                key="3"
              >
                <CustomCelebrationsTab
                  channelOptions={channelOptions}
                  allCustomCelebrations={
                    getAllCustomCelebrationsQuery.data
                      ? getAllCustomCelebrationsQuery.data.custom_celebrations
                      : []
                  }
                  channelMap={channelMap}
                />
              </TabPane>
            ) : null}
            
          </Tabs>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Celebrations;
