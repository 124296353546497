import { Space, Table, Popconfirm } from "antd";
import moment from "moment";
import { useState } from "react";

export default function BuddiesManagersTable({deletePairing, editPairing, getEmployeeBuddyPairingsQuery}) {
  
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      width: 150,
      fixed: "left",
      render: (_, record) => {
        return <div>{record.employee_real_name ? record.employee_real_name : record.employee_id}</div>;
      },
    },
    {
        title: "Employee Email",
        dataIndex: "email",
        key: "email",
        width: 150,
        fixed: "left",
        render: (_, record) => {
          return <div>{record.employee_email}</div>;
        },
    },
    {
        title: "Manager",
        dataIndex: "manager_real_name",
        key: "manager_real_name",
        width: 200,
        render: (_, record) => {
          return <div>{record.manager_real_name ? record.manager_real_name : record.manager_id}</div>;
        },
    },
    {
      title: "Buddies",
      dataIndex: "buddies",
      key: "buddies",
      width: 250,
      render: (_, record) => {
        return <div>{record.buddy_real_names ? record.buddy_real_names.join(", ") : record.buddy_ids.join(", ")}</div>;
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: 150,
      render: (_, record) => {
        return <div>{record.start_date ? moment.tz(record.start_date, 'UTC').format("MM/DD/YYYY") : null}</div>;
      },
    },
    {
      title: "Title",
      dataIndex: "employee_title",
      key: "employee_title",
      width: 150,
      render: (_, record) => {
        return <div>{record.employee_title || 'N/A'}</div>;
      },
    },
    {
      title: "Department",
      dataIndex: "employee_department",
      key: "employee_department",
      width: 150,
      render: (_, record) => {
        return <div>{record.employee_department || 'N/A'}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "employee_location",
      key: "employee_location",
      width: 150,
      render: (_, record) => {
        return <div>{record.employee_location || 'N/A'}</div>;
      },
    },
    {
      title: "Groups",
      dataIndex: "employee_groups",
      key: "employee_groups",
      width: 150,
      render: (_, record) => {
        return <div>{record.employee_groups ? record.employee_groups.join(", ") : 'N/A'}</div>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 175,
      render: (_, record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => editPairing(record)}
            >
              Edit
            </a>
            <Popconfirm
            title={
                <div>
                Are you sure you want to delete this employee's pairing/info?
                <br />
                You will not be able to undo this action.
                </div>
            }
            onConfirm={() => deletePairing(record)}
            okText="Yes"
            cancelText="No"
            >
            <a style={{color: 'red', marginLeft: '10px'}}>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Added At",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (_, record) => {
        return <div>{moment.unix(record.created_at).format("MM/DD/YYYY")}</div>;
      },
      sorter: (a, b) => a.created_at < b.created_at,
      sortDirections: ["descend", "ascend"],
    },
  ];

  var tableArea;
  if (getEmployeeBuddyPairingsQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (
    getEmployeeBuddyPairingsQuery.isError ||
    getEmployeeBuddyPairingsQuery.data.error
  ) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getEmployeeBuddyPairingsQuery.data.pairings.map((pairing) => {
      return {
        id: pairing.id,
        employee_id: pairing.employee_id,
        employee_real_name: pairing.employee_real_name,
        buddy_ids: pairing.buddy_ids,
        buddy_real_names: pairing.buddy_real_names,
        manager_id: pairing.manager_id,
        manager_real_name: pairing.manager_real_name,
        employee_email: pairing.employee_email,
        start_date: pairing.start_date,
        created_at: pairing.created_at,
        employee_title: pairing.title,
        employee_department: pairing.department,
        employee_location: pairing.location,
        employee_groups: pairing.groups,
      };
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
        style={{ maxWidth: '100%' }}
        scroll={{ x: 'max-content' }}
      />
    );
  }
  return tableArea;
}
