import React, { useState, useEffect } from "react";
import { Space, Button, Modal, Card, Input, TimePicker, Select, Checkbox, message, Row, Col } from "antd";
import { UsergroupAddOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { templateDays, getGoodFriday, getMemorialDay, getLaborDay, getThanksgivingDay, getMlkDay, getPresidentsDay } from "../../../helpers/celebrationHelpers";
import { getBaseUrl, doPostRequest } from "../../../helpers/requestHelpers";
import { useQueryClient } from "@tanstack/react-query";
import moment from 'moment-timezone';
import { clearUrlParams } from "../../../helpers/utilityHelpers";

const { Option } = Select;

// Convert objects to arrays for easier mapping
const awarenessDaysArray = Object.values(templateDays).filter(day => !day.is_holiday);
const holidaysArray = Object.values(templateDays).filter(day => day.is_holiday);

const CustomCelebrationsTab = ({channelOptions, allCustomCelebrations, channelMap}) => {

  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];
  
  const getDaysInMonth = (month) => {
    return new Date(1970, month, 0).getDate();
  };
  
  const queryClient = useQueryClient();
  
  const [isAddCelebrationModalVisible, setAddCelebrationModalVisible] = useState(false);
  const [celebrationId, setCelebrationId] = useState(null);
  const [celebrationName, setCelebrationName] = useState('');
  const [celebrationHour, setCelebrationHour] = useState(null);
  const [celebrationImageUrl, setCelebrationImageUrl] = useState('');
  const [celebrationMessage, setCelebrationMessage] = useState('');
  const [celebrationSendOption, setCelebrationSendOption] = useState(null);
  const [celebrationChannel, setCelebrationChannel] = useState(null);
  const [sendAsAdminForCelebration, setSendAsAdminForCelebration] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const handleTemplateChange = (value) => {
    const template = templateDays[value];
    if (template) {
      setSelectedMonth(template.month_number || null);
      setSelectedDay(template.day || null);
      setCelebrationName(template.name || '');
      setCelebrationHour(template.hour ? new Date(`1970-01-01T${template.hour}:00`) : null);
      setCelebrationImageUrl(template.image_url || '');
      setCelebrationMessage(template.message || '');
      setCelebrationSendOption(template.sendOption || null);
      setCelebrationChannel(template.channel || null);
      setSendAsAdminForCelebration(template.sendAsAdmin || false);
    }
    setSelectedTemplate(template);
  };

  const resetFields = () => {
    setCelebrationId('');
    setSelectedMonth(null);
    setSelectedDay(null);
    setCelebrationName('');
    setCelebrationHour(null);
    setCelebrationImageUrl('');
    setCelebrationMessage('');
    setCelebrationSendOption(null);
    setCelebrationChannel(null);
    setSendAsAdminForCelebration(false);
    setSelectedTemplate(null);
  };

  const prefillCelebrationData = (celebration) => {

    setCelebrationId(celebration.celebration_id);
    setCelebrationName(celebration.celebration_name || '');
    
    // Ensure the hour is correctly formatted and parsed (also use local time, BE saves in UTC)
    const hour = celebration.celebration_hour_of_day;
    const formattedHour = hour.includes(':') ? hour : `${hour}:00`;
    const utcTime = moment.tz(`1970-01-01T${formattedHour}`, "HH:mm");

    const localTime = utcTime.local().format("HH:mm");
    setCelebrationHour(moment(localTime, "HH:mm"));

    setCelebrationImageUrl(celebration.celebration_image || '');
    setCelebrationMessage(celebration.celebration_message || '');
    setCelebrationSendOption(celebration.celebration_sending_option || null);
    setCelebrationChannel(celebration.channel_id || null);
    setSendAsAdminForCelebration(celebration.celebration_send_as_admin || false);
    setSelectedMonth(celebration.celebration_month || null);
    setSelectedDay(celebration.celebration_day || null);
    setAddCelebrationModalVisible(true);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('modalOpen') === 'manageGift') {
      setAddCelebrationModalVisible(true);
    }
    if (urlParams.get('celebrationId')) {
      setCelebrationId(urlParams.get('celebrationId'));
      if (allCustomCelebrations.length > 0) {
        prefillCelebrationData(allCustomCelebrations.find(celebration => celebration.celebration_id === urlParams.get('celebrationId')));
      }
    }
  }, []);
  
  return (
    <>
      <Space direction="vertical" style={{ width: "100%", textAlign: "left", alignItems: "flex-start" }}>
        <img 
          src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/home_header_graphics%2Fcustom-celebrations.png" 
          alt="Custom Celebrations Banner" 
          style={{ width: "100%", maxWidth: "600px", height: 'auto', borderRadius: '10px' }} 
        />
        <div style={{ padding: '10px 0' }}>
          <h4>Use custom celebrations to celebrate holidays and awareness/diversity days with your team. We provide over a dozen different templates for recommended awareness days each month to get you started.</h4>
          <i>Have dozens of custom celebrations? Send your spreadsheet over to our <a href="mailto:support@culturebot.com">support team</a> and we'll import your list for you.</i>
        </div>
        <Button 
          icon={<UsergroupAddOutlined />} 
          shape="round"
          onClick={() => setAddCelebrationModalVisible(true)}
        >
          Add Celebration
        </Button>
        <h3 style={{ marginTop: '20px' }}>All Custom Celebrations</h3>
      </Space>

      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        {allCustomCelebrations.map((celebration, index) => (
          <Col key={index} span={12}>
            <Card style={{ height: 'auto', overflow: 'hidden' }}>
              <Row>
                <Col span={16}>
                  <h4 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {celebration.celebration_name}
                  </h4>
                  <p><strong>Date:</strong> {celebration.celebration_month}/{celebration.celebration_day}</p>
                  <p>
                    <strong>Hour:</strong> {
                      moment.tz(
                        `1970-01-01T${celebration.celebration_hour_of_day.includes(':') ? celebration.celebration_hour_of_day : celebration.celebration_hour_of_day.padEnd(5, ':00')}`,
                        "HH:mm",
                      ).tz(moment.tz.guess()).format("hh:mm A z")
                    }
                  </p>
                  <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <strong>Channel:</strong> {
                      (channelMap.find(channel => channel.id === celebration.channel_id) || {}).displayName || 'Unknown Channel'
                    }
                  </p>
                  <Space style={{ justifyContent: 'flex-start' }}>
                    <Button icon={<EditOutlined />} shape="round" onClick={() => prefillCelebrationData(celebration)}>
                      Edit
                    </Button>
                    <Button 
                      icon={<DeleteOutlined />}
                      danger 
                      shape="round"
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure you want to delete this celebration?',
                          onOk: async () => {
                            try {
                              await doPostRequest(`${getBaseUrl()}/api/deleteCustomCelebration`, {
                                celebrationId: celebration.celebration_id,
                              });
                              message.success("Celebration deleted successfully!");
                              queryClient.invalidateQueries("GET_ALL_CUSTOM_CELEBRATIONS");
                            } catch (error) {
                              message.error(error.error || "An error occurred while deleting the celebration.");
                            }
                          },
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </Space>
                </Col>
                <Col span={8}>
                  <img
                    alt="celebration"
                    src={celebration.celebration_image || 'https://via.placeholder.com/150?text=No+Image'}
                    style={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'cover' }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Add Celebration"
        open={isAddCelebrationModalVisible}
        onCancel={() => {
          setAddCelebrationModalVisible(false);
          resetFields();
          clearUrlParams();
        }}
        footer={[
          <Button key="close" onClick={() => {
            setAddCelebrationModalVisible(false);
            resetFields();
            clearUrlParams();
          }}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={async () => {
            const payload = {
              id: celebrationId,
              name: celebrationName,
              hour: celebrationHour ? celebrationHour.utc().format("HH:mm") : null,
              imageUrl: celebrationImageUrl,
              message: celebrationMessage,
              sendOption: celebrationSendOption,
              channel: celebrationChannel,
              sendAsAdmin: sendAsAdminForCelebration,
              month: selectedMonth,
              day: selectedDay,
              isHoliday: holidaysArray.some(holiday => holiday.name === selectedTemplate?.name),  
            };

            try {
              const response = await doPostRequest(
                `${getBaseUrl()}/api/addOrUpdateCustomCelebration`,
                payload
              );

              message.success("Celebration saved successfully!");
              queryClient.invalidateQueries("GET_ALL_CUSTOM_CELEBRATIONS");
              setAddCelebrationModalVisible(false);
              resetFields();
              clearUrlParams();
              
            } catch (error) {
              message.error(error.error || "An error occurred while saving the celebration.");
            }

            
          }}>
            Submit
          </Button>,
        ]}
      >
        <div>
          <label>Suggested Templates</label>
          <Select
            placeholder="Select a template"
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={handleTemplateChange}
            value={celebrationName || undefined} // Use undefined to show placeholder when value is empty
          >
            <Select.OptGroup label="Awareness Days">
              {awarenessDaysArray.map(day => (
                <Option key={day.name} value={day.name}>{day.name}</Option>
              ))}
            </Select.OptGroup>
            <Select.OptGroup label="Holidays">
              {holidaysArray.map(holiday => (
                <Option key={holiday.name} value={holiday.name}>{holiday.name}</Option>
              ))}
            </Select.OptGroup>
          </Select>
          {selectedTemplate && selectedTemplate.is_holiday && (
            <small style={{ display: 'block', marginBottom: '10px', color: 'gray' }}>
              Note: If this is a major holiday, please be mindful that celebrations should normally occur a day or two ahead of time when employees are not on vacation.
            </small>
          )}
          <Space direction="horizontal" style={{ width: '100%', marginBottom: '10px' }}>
            <div style={{ flex: 1 }}>
              <label>Month of Celebration</label>
              <Select
                placeholder="Select Month"
                style={{ width: '230px' }}
                onChange={(value) => setSelectedMonth(value)}
                value={selectedMonth || undefined}
              >
                {months.map(month => (
                  <Option key={month.value} value={month.value}>{month.label}</Option>
                ))}
              </Select>
            </div>
            <div style={{ flex: 1 }}>
              <label>Day of Celebration</label>
              <Select
                placeholder="Select Day"
                style={{ width: '230px' }}
                onChange={(value) => setSelectedDay(value)}
                value={selectedDay || undefined}
                disabled={!selectedMonth}
              >
                {Array.from({ length: getDaysInMonth(selectedMonth) }, (_, i) => i + 1).map(day => (
                  <Option key={day} value={day}>{day}</Option>
                ))}
              </Select>
            </div>
          </Space>
          <label>Name of Celebration</label>
          <Input
            placeholder="Enter celebration name"
            style={{ marginBottom: '10px' }}
            value={celebrationName}
            onChange={(e) => setCelebrationName(e.target.value)}
          />
          <label>Hour of day to celebrate</label>
          <TimePicker
            format="hh:mm A"
            minuteStep={30}
            use12Hours
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={(time) => setCelebrationHour(time)}
            value={celebrationHour}
          />
          <label>URL of image/gif to include</label>
          <Input
            placeholder="Enter URL here"
            style={{ marginBottom: '10px' }}
            value={celebrationImageUrl}
            onChange={(e) => setCelebrationImageUrl(e.target.value)}
          />
          <small style={{display: 'block', marginBottom: '10px'}}>
            {/* tab 3 is the celebrations tab */}
            You can upload external files to our <strong><a href={`/app/graphics_library?fromModal=true&tab=3&celebrationId=${celebrationId}`}>graphics library</a></strong> and copy the URL from there
          </small>
          <label>Celebration Message</label>
          <Input.TextArea
            rows={4}
            style={{ marginBottom: '10px' }}
            value={celebrationMessage}
            onChange={(e) => setCelebrationMessage(e.target.value)}
          />
          <label>Sending Options</label>
          <Select
            placeholder="Select Sending Option"
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={(value) => setCelebrationSendOption(value)}
            value={celebrationSendOption || undefined}
          >
            <Option value="Send every year">Send every year</Option>
            <Option value="Send this year">Send this year</Option>
            <Option value="Send next year">Send next year</Option>
          </Select>
          <label>Channel to send message to</label>
          <Select
            showSearch
            placeholder="Select Channel"
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={(value) => setCelebrationChannel(value)}
            value={celebrationChannel || undefined}
            loading={!channelOptions}
            filterOption={(input, option) => {
              const optionText = option.children ? option.children.toLowerCase() : '';
              return optionText.includes(input.toLowerCase());
            }}
          >
            {channelOptions}
          </Select>
          <Checkbox
            checked={sendAsAdminForCelebration}
            onChange={(e) => setSendAsAdminForCelebration(e.target.checked)}
          >
            Send messages as the main CultureBot admin instead of as CultureBot
          </Checkbox>
        </div>
      </Modal>
    </>
  );
};

export default CustomCelebrationsTab; 