const templateFields = {
  blank: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
    ],
    initialValues: { "Form Name": "New Form", "Question 1": "" },
  },
  onboarding: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
      {
        name: "Question 3",
        checkbox_name: "Checkbox 3",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 3",
        link_name: "Link Name 3",
        link_url: "Link URL 3",
      },
      {
        name: "Question 4",
        checkbox_name: "Checkbox 4",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 4",
        link_name: "Link Name 4",
        link_url: "Link URL 4",
      },
      {
        name: "Question 5",
        checkbox_name: "Checkbox 5",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 5",
        link_name: "Link Name 5",
        link_url: "Link URL 5",
      },
      {
        name: "Question 6",
        checkbox_name: "Checkbox 6",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 6",
        link_name: "Link Name 6",
        link_url: "Link URL 6",
      },
      {
        name: "Question 7",
        checkbox_name: "Checkbox 7",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 7",
        link_name: "Link Name 7",
        link_url: "Link URL 7",
      },
    ],
    initialValues: {
      "Form Name": "Onboarding Survey",
      "Question 1": "Where are you originally from?",
      "Question 2": "Where did you go to school?",
      "Question 3": "What are your favorite sports teams?",
      "Question 4": "What pets do you have?",
      "Question 5": "Can you play any instruments?",
      "Question 6": "What's something you like to do the old-fashioned way?",
      "Question 7": "Name one odd skill you have that most people don't",
    },
  },
  team_health: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
      {
        name: "Question 3",
        checkbox_name: "Checkbox 3",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 3",
        link_name: "Link Name 3",
        link_url: "Link URL 3",
      },
      {
        name: "Question 4",
        checkbox_name: "Checkbox 4",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 4",
        link_name: "Link Name 4",
        link_url: "Link URL 4",
      },
      {
        name: "Question 5",
        checkbox_name: "Checkbox 5",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 5",
        link_name: "Link Name 5",
        link_url: "Link URL 5",
      },
      {
        name: "Question 6",
        checkbox_name: "Checkbox 6",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 6",
        link_name: "Link Name 6",
        link_url: "Link URL 6",
      },
      {
        name: "Question 7",
        checkbox_name: "Checkbox 7",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 7",
        link_name: "Link Name 7",
        link_url: "Link URL 7",
      },
      {
        name: "Question 8",
        checkbox_name: "Checkbox 8",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 8",
        link_name: "Link Name 8",
        link_url: "Link URL 8",
      },
      {
        name: "Question 9",
        checkbox_name: "Checkbox 9",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 9",
        link_name: "Link Name 9",
        link_url: "Link URL 9",
      },
      {
        name: "Question 10",
        checkbox_name: "Checkbox 10",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 10",
        link_name: "Link Name 10",
        link_url: "Link URL 10",
      },
    ],
    initialValues: {
      "Form Name": "Team Health Survey",
      "Question 1":
        "I feel like the company does enough to promote employee well-being (true or false)",
      "Question 2":
        "I feel like I can maintain a healthy balance between work and my personal life (true or false)",
      "Question 3":
        "I feel supported at the company when I am dealing with personal or family issues (true or false)",
      "Question 4":
        "I am able to arrange time off work when I need to (true or false)",
      "Question 5":
        "I feel like the company cares about the mental health of our team members (true or false)",
      "Question 6":
        "I feel like the company offers the support I need to deal with work-related stress (true or false)",
      "Question 7": "Generally, I feel happy at work (true or false)",
      "Question 8": "I enjoy the work, I do (true or false)",
      "Question 9": "I find my workload manageable (true or false)",
      "Question 10":
        "I feel supported if I choose to make use of flexible working arrangements (true or false)",
    },
  },
  employee_intro: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
      {
        name: "Question 3",
        checkbox_name: "Checkbox 3",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 3",
        link_name: "Link Name 3",
        link_url: "Link URL 3",
      },
    ],
    initialValues: {
      "Form Name": "Employee Introduction (2 Truths and a Lie)",
      "Question 1": "Fact one about you",
      "Question 2": "Fact two about you",
      "Question 3": "Fact three about you",
    },
  },
  nps: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        number_input_name: "Number 1",
        typeOfQuestion: "NUMBER_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
    ],
    initialValues: {
      "Form Name": "Employee NPS Survey",
      "Question 1": "How much would you recommend working here? (Scale of 1-10)",
      "Number 1 min": 1,
      "Number 1 max": 10,
      "Question 2": "Tell us why you feel this way",
    },
  },
  three_sixty_review: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
      {
        name: "Question 3",
        checkbox_name: "Checkbox 3",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 3",
        link_name: "Link Name 3",
        link_url: "Link URL 3",
      },
    ],
    initialValues: {
      "Form Name": "Employee 360 Review",
      "Question 1": "What do you think this person is really good at?",
      "Question 2":
        "What do you suggest this person should work on to compliment their skill set?",
      "Question 3": "Is there anything else you would like to add?",
    },
  },
  standup: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
      {
        name: "Question 3",
        checkbox_name: "Checkbox 3",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 3",
        link_name: "Link Name 3",
        link_url: "Link URL 3",
      },
    ],
    initialValues: {
      "Form Name": "Standup",
      "Question 1": "What have you completed since the last meeting?",
      "Question 2": "What are you going to finish by the next meeting?",
      "Question 3": "Is there anything that stands in your way?",
    },
  },
  nominations: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
      {
        name: "Question 3",
        checkbox_name: "Checkbox 3",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 3",
        link_name: "Link Name 3",
        link_url: "Link URL 3",
      },
      {
        name: "Question 4",
        checkbox_name: "Checkbox 4",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 4",
        link_name: "Link Name 4",
        link_url: "Link URL 4",
      },
    ],
    initialValues: {
      "Form Name": "Employee Nominations",
      "Question 1": "Who is going above and beyond lately?",
      "Question 2": "What is the accomplishment you are nominating them for?",
      "Question 3": "Who is going under-recognized lately?",
      "Question 4": "Why are they under-recognized?",
    },
  },
  check_in: {
    inputs: [
      {
        name: "Form Name",
      },
      {
        name: "Question 1",
        checkbox_name: "Checkbox 1",
        number_input_name: "Number 1",
        typeOfQuestion: "NUMBER_QUESTION",
        use_link: "Include Link 1",
        link_name: "Link Name 1",
        link_url: "Link URL 1",
      },
      {
        name: "Question 2",
        checkbox_name: "Checkbox 2",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 2",
        link_name: "Link Name 2",
        link_url: "Link URL 2",
      },
      {
        name: "Question 3",
        checkbox_name: "Checkbox 3",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 3",
        link_name: "Link Name 3",
        link_url: "Link URL 3",
      },
      {
        name: "Question 4",
        checkbox_name: "Checkbox 4",
        typeOfQuestion: "FREE_TYPE_QUESTION",
        use_link: "Include Link 4",
        link_name: "Link Name 4",
        link_url: "Link URL 4",
      },
    ],
    initialValues: {
      "Form Name": "Employee Check-In",
      "Question 1": "On a scale of 1-10, how would you rate your overall mood at work this week?",
      "Number 1 min": 1,
      "Number 1 max": 10,
      "Question 2": "Did you feel emotionally supported by your team or manager this week?",
      "Question 3": "What went well this week?",
      "Question 4": "What's your biggest challenge right now and how can your manager help?",
    },
  },
};

export default templateFields;
