import { Table, Tooltip, Button } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../helpers/requestHelpers";
import {
  DownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import { useState, useEffect, useRef } from "react";
import {escapeStringForCSV} from "../../helpers/utilityHelpers"
import moment from "moment";
import useColumnSearch from '../../hooks/columnSearch';

export default function ShoutoutsTable({ firstDate, secondDate }) {
  const [csvData, setCsvData] = useState([]);
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [updatingShoutoutActivities, setUpdatingShoutoutActivities] = useState(
    []
  );
  const queryClient = useQueryClient();
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // fetch shoutouts
  const getShoutoutsQuery = useQuery(
    ["GET_SHOUTOUTS", firstDate, secondDate],
    async () => {
      const firstDateUnix = moment.utc(firstDate.startOf("day")).unix();
      const secondDateUnix = moment.utc(secondDate.endOf("day")).unix();
      return doGetRequest(
        `${getBaseUrl()}/api/getShoutouts`,
        setTokenErrorType,
        [
          { name: "first_date", value: firstDateUnix },
          { name: "second_date", value: secondDateUnix },
        ]
      );
    },
    { refetchOnWindowFocus: false }
  );

  // updates a shoutout activity to 'deleted' to remove from report
  async function deleteShoutoutActivity(shoutout_activity_id) {
    setUpdatingShoutoutActivities([shoutout_activity_id]);
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteShoutoutActivity`,
      { shoutout_activity_id: shoutout_activity_id },
      setTokenErrorType
    );
    setUpdatingShoutoutActivities([]); // avoid double button clicks
    queryClient.invalidateQueries("GET_SHOUTOUTS", firstDate, secondDate);
  }

  // ---- export to csv ---- //
  // https://www.npmjs.com/package/react-csv
  async function formatCsvData() {
    const tmpData = [];
    tmpData.push([
      "Created At",
      "Sender",
      "Sender Email",
      "Receivers",
      "Points",
      "Type",
      "Shoutout Message",
      "Channel",
    ]);
    getShoutoutsQuery.data.shoutouts.forEach((row) => {
      tmpData.push([
        moment.unix(row.created_at).format("MM/DD/YYYY"),
        row.sender_real_name,
        row.sender_email,
        row.receivers_real_names,
        row.metadata["points"],
        row.metadata["type"],
        escapeStringForCSV(row.metadata["message"]),
        row.metadata["channel_name"], // Might be better to use "channel_id" in future and pull live names to not get outdated, but this is much simpler approach for now
      ]);
    });
    setCsvData(tmpData);
  }

  useEffect(() => {
    if (getShoutoutsQuery.data) {
      formatCsvData();
    }
  }, [getShoutoutsQuery.data]);

  // table fields
  const columns = [
    {
      title: "Date sent",
      dataIndex: "date",
      key: "date",
      width: "100px",
      render: (_, record) => {
        return <div>{moment.unix(record.created_at).format("MM/DD/YYYY")}</div>;
      },
      sorter: (a, b) => a.created_at < b.created_at,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Creator",
      dataIndex: "creator",
      key: "creator",
      width: "150px",
      render: (_, record) => {
        return <div>{record.creator}</div>;
      },
      ...useColumnSearch('creator')
    },
    {
      title: "Creator Email",
      dataIndex: "creator_email",
      key: "creator_email",
      width: "150px",
      render: (_, record) => {
        return <div>{record.creator_email}</div>;
      },
      ...useColumnSearch('creator_email')
    },
    {
      title: "Receivers",
      dataIndex: "receivers",
      key: "receivers",
      width: "200px",
      render: (_, record) => {
        return record.receivers.map((name, index) => {
          if (index == record.receivers.length - 1) {
            return name;
          } else {
            return name + ", ";
          }
        });
      },
      ...useColumnSearch('receivers')
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "250px",
      render: (_, record) => {
        return <div>{record.message}</div>;
      },
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      width: "100px",
      render: (_, record) => {
        return <div>{record.points}</div>;
      },
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      width: "150px",
      render: (_, record) => {
        return <div>{record.channel}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "200px",
      render: (_, record) => {
        return <div>{record.type}</div>;
      },
      ...useColumnSearch('type')
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Delete{" "}
            <span style={{ marginLeft: '10px' }}>
              <CSVLink
                data={csvData}
                filename={"all-shoutouts.csv"}
                target="_blank"
              >
                <Tooltip placement="left" title="Download shoutouts (CSV)">
                  <DownloadOutlined className="exportCSV" />
                </Tooltip>
              </CSVLink>
            </span>
          </div>
        );
      },
      dataIndex: "delete",
      key: "delete",
      width: "150px",
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type={"secondary"}
              shape="round"
              disabled={updatingShoutoutActivities.includes(record.id)}
              loading={updatingShoutoutActivities.includes(record.id)}
              icon={<CloseOutlined />}
              onClick={() => {
                deleteShoutoutActivity(record.id);
              }}
            >
            Delete
            </Button>
          </div>
        );
      },
    },
  ];

  // build table area
  var tableArea;
  if (getShoutoutsQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (getShoutoutsQuery.isError || getShoutoutsQuery.data.error) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getShoutoutsQuery.data.shoutouts.map((shoutout) => {
      return {
        id: shoutout.id,
        creator: shoutout.sender_real_name,
        creator_email: shoutout.sender_email,
        receivers: shoutout.receivers_real_names,
        created_at: shoutout.created_at,
        message: shoutout.metadata["message"],
        points: shoutout.metadata["points"],
        type: "type" in shoutout.metadata ? shoutout.metadata["type"] : "",
        channel: shoutout.metadata["channel_name"],
      };
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
        style={{ maxWidth: '100%' }}
        scroll={{ x: 'max-content' }}
      />
    );
  }
  return tableArea;
}
