import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, Typography, Spin, Alert, Space, Input, Select } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import MainContainer from '../Container/MainContainer';
import { doGetRequest, getBaseUrl } from '../../helpers/requestHelpers';
import { Link } from 'react-router-dom'; // Ensure this import is at the top

const { Title, Text } = Typography;

export function ActivityLookupPage() {
  
  const [activityType, setActivityType] = useState('');
  const [typeSuggestions, setTypeSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // Add state for error message
  const [teams, setTeams] = useState([]); // Add state for teams

  useEffect(() => {
    // Logic to refresh dropdown or any other side effects when typeSuggestions changes
  }, [typeSuggestions, errorMessage, activityType]); // Dependency array includes typeSuggestions

  const getTeamDataQuery = useQuery(
    ['GET_TEAM_DATA_QUERY', activityType],
    async () => {
      const response = await doGetRequest(`${getBaseUrl()}/api/getActivityLookupPageData`, () => {}, [{ name: "type", value: activityType}]);
      if (!response || response.length === 0) {
        setErrorMessage('No usage found. Please try a different activity type.');
      } else {
        console.log('Response data:', response); // Debugging line
        setTeams(response); // Set teams state with the response data
        setErrorMessage(''); // Clear error message if usage is found
      }
      return response;
    },
    { refetchOnWindowFocus: false, retry: 1 }
  );

  const fetchTeams = async (activityType) => {
    // Fetch teams based on the activity type input
    const response = await doGetRequest(`${getBaseUrl()}/api/getActivityTypes`, () => {}, [{ name: "type", value: activityType }]);
    if (response.error) {
      setTypeSuggestions([]); // Set typeSuggestions to an empty array if there's an error
    } else if (response && response.length > 0) {
      setTypeSuggestions(response);
    }
  };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setActivityType(newType);
    fetchTeams(newType); // Call fetchTeams on input change
  };

  const handleSelectType = (activityType) => {
    setActivityType(activityType);
    getTeamDataQuery.refetch(); // Refetch the query with the selected type
  };

  return (
    <MainContainer noNav={true}>
      <Card
        title={
          <Title level={2}>
            <TeamOutlined /> Activity Lookup
          </Title>
        }
        style={{ maxWidth: 800, margin: '20px auto' }}
      >
        <>
        <Text type="secondary">
            Search for the top activity types by team, as well as the total activity by type over the last 30 and 90 days.
            <br/>
            <Link to="/app/team_lookup">Go to Team Lookup</Link> 
        </Text>
        <br/ ><br/ >
          <Input
            placeholder="Search by activity type..."
            value={activityType}
            onChange={handleTypeChange}
            style={{ marginBottom: 20 }}
          />
          <Select
            key={typeSuggestions.length} // Add this line to force re-render
            placeholder="Select an activity type"
            onChange={handleSelectType}
            style={{ width: '100%', marginBottom: 20 }}
            dropdownMatchSelectWidth={false} // Add this line
          >
            {typeSuggestions.map((row) => (
              <Select.Option key={row.activity_type}>
                {row.activity_type}
              </Select.Option>
            ))}
          </Select>
          {/* Optionally, you can add a button to trigger the search */}
        </>
        {getTeamDataQuery.isLoading ? (
          <Spin size="large" />
        ) : getTeamDataQuery.isError || errorMessage ? (
          <Alert message={errorMessage} type="error" showIcon />
        ) : (
          <>
            <Title level={4}>
              {activityType ? 'Top Teams Using Selected Activity Type (Past 30 Days)' : 'Top Activity Types (Past 30 Days)'}
            </Title> 
            <Space direction="vertical" size="small">
              {Array.isArray(teams) && teams.length > 0 ? ( // Check if teams is an array and has elements
                teams.map((row, index) => (
                  'activity_type' in row ? (
                    <Text key={index}>{row.activity_type}: {row.count}</Text> // Handle the first format
                  ) : (
                    <Text key={index}>{row.team_name}: {row.team_id} - {row.activity_count}</Text> // Handle the second format
                  )
                ))
              ) : (
                <Text>No usage found</Text> 
              )}
            </Space>
          </>
        )}
      </Card>
    </MainContainer>
  );
}
