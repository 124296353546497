import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Card, Typography, Spin, Alert, Space, Input, Select } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import MainContainer from '../Container/MainContainer';
import { doGetRequest, getBaseUrl } from '../../helpers/requestHelpers';
import { Area } from "@ant-design/plots"; // Import Bar and Area from ant-design/plots
import { Link } from 'react-router-dom'; // Ensure this import is at the top
 
const { Title, Text } = Typography;

export function TeamLookupPage() {
  
  let params = useParams();
  const [teamId, setTeamId] = useState('');
  const [email, setEmail] = useState('');
  const [teamSuggestions, setTeamSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // Add state for error message

  useEffect(() => {
  }, [teamSuggestions, teamId, errorMessage]);

  useEffect(() => {
    // Logic to refresh dropdown or any other side effects when teamSuggestions changes
    console.log('Team suggestions updated:', teamSuggestions);
  }, [teamSuggestions, teamId, errorMessage]); // Dependency array includes teamSuggestions

  useEffect(() => {
    if(params.team_id){
      setTeamId(params.team_id)
    }
  }, [])
  
  const getTeamDataQuery = useQuery(
    ['GET_TEAM_DATA_QUERY', teamId, params.team_id],
    async () => {
      const response = await doGetRequest(`${getBaseUrl()}/api/getTeamLookupPageData`, () => {}, [{ name: "team_id", value: teamId}]);
      console.log('Fetched Team Data:', response); // Log the fetched data
      if (!response || response.length === 0) {
        setErrorMessage('No team found. Please try a different search.'); // Set error message if no team is found
      } else {
        setErrorMessage(''); // Clear error message if team is found
      }
      return response;
    },
    // enabled to prevent on query running on page load
    { refetchOnWindowFocus: false, retry: 1, enabled: teamId !== ''}
  );

  console.log("getTeamDataQuery.dataUpdatedAt: ", getTeamDataQuery.data?.activities_by_month);

  const handleSearch = () => {
    // Logic to handle search by email
    console.log(`Searching for team with email: ${email}`);
    // You can implement the search request here
  };

  const fetchTeams = async (email) => {
    // Fetch teams based on the email input
    const response = await doGetRequest(`${getBaseUrl()}/api/getTeamsByEmail`, () => {}, [{ name: "email", value: email }]);
    if (response.error) {
      setTeamSuggestions([]); // Set teamSuggestions to an empty array if there's an error
    } else if (response && response.length > 0) {
      setTeamSuggestions(response);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    fetchTeams(newEmail); // Call fetchTeams on input change
  };

  const handleSelectTeam = (teamId) => {
    if(teamId){ // attempt to ensure teamId exists because we were seeing the api called with "undefined"
      setTeamId(teamId);
      getTeamDataQuery.refetch(); // Refetch the query with the selected teamId
    }
  };

  const renderActivityChart = () => {
    console.log('Activity Data:', getTeamDataQuery.data.activities_by_month);
    return (
      <Area
        data={getTeamDataQuery.data.activities_by_month}
        xField="month"
        yField="count"
        legend={{ position: "bottom-left" }}
        areaStyle={{
          fill: "rgba(0,0,0,0.1)",
        }}
        xAxis={{
          title: {
            text: 'Month',
          },
        }}
        yAxis={{
          min: Math.min(...getTeamDataQuery.data.activities_by_month.map(item => item.count)),
          max: Math.max(...getTeamDataQuery.data.activities_by_month.map(item => item.count)),
        }}
      />
    );
  };

  return (
    <MainContainer noNav={true}>
      <Card
        title={
          <Title level={2}>
            <TeamOutlined /> Team Lookup
          </Title>
        }
        style={{ maxWidth: 800, margin: '20px auto' }}
      >
        <>
          <Link to="/app/activity_lookup">Go to Activity Lookup</Link> 
          <br /><br />
          <Input
            placeholder="Search by email..."
            value={email}
            onChange={handleEmailChange}
            onPressEnter={handleSearch}
            style={{ marginBottom: 20 }}
          />
          <Select
            key={teamSuggestions.length} // Add this line to force re-render
            placeholder="Select a team"
            onChange={handleSelectTeam}
            style={{ width: '100%', marginBottom: 20 }}
            dropdownMatchSelectWidth={false} // Add this line
          >
            {teamSuggestions.map((team, index) => (
              <Select.Option 
                key={`${team.id}-${index}`} // Combine team.id with index for unique key
                value={team.id} // Set value to team.id
                label={`${team.name} - ${team.id}`} // Set label to match the display text
              >
                {team.name} - {team.id} {/* Display text */}
              </Select.Option>
            ))}
          </Select>
          {/* Optionally, you can add a button to trigger the search */}
        </>
        {getTeamDataQuery.isLoading && teamId ? (
          <Spin size="large" />
        ) : getTeamDataQuery.isError || errorMessage ? (
          <Alert message={errorMessage} description={errorMessage} type="error" showIcon />
        ) : (
          <>
            <Title level={4}>Team Details</Title>
            <Space direction="vertical" size="small" key={getTeamDataQuery.dataUpdatedAt}>
              {getTeamDataQuery.data && getTeamDataQuery.data.data && getTeamDataQuery.data.data.map((item, index) => {
                const [key, value] = Object.entries(item)[0];
                return (
                  <div key={index}>
                    <Text strong>{key}: </Text>
                    <Text>{value}</Text>
                  </div>
                );
              })}
            </Space>
            {getTeamDataQuery.data && Array.isArray(getTeamDataQuery.data.activities_by_month) && getTeamDataQuery.data.activities_by_month.length > 0 ? (
              <>
                <Title level={4}>Activity Chart</Title>
                {renderActivityChart()}
                <Title level={4}>Message Reaction Counts (Last 4 Weeks)</Title> 
                <Area
                  data={getTeamDataQuery.data.reaction_counts_by_week.map(item => ({
                    week: item.week_start,
                    reactions: typeof item.total_reactions === 'object' && item.total_reactions !== null 
                      ? item.total_reactions.toNumber() // Convert Decimal to number if it's an object
                      : item.total_reactions // Otherwise, use it directly
                  }))}
                  xField="week" // Categories on the x-axis
                  yField="reactions" // Values on the y-axis
                  legend={{ position: "bottom-left" }}
                  areaStyle={{
                    fill: "rgba(0, 0, 255, 0.1)", // Adjust the fill color for the area
                  }}
                  xAxis={{
                    title: {
                      text: 'Week', // Optional: Add title for x-axis
                    },
                  }}
                  yAxis={{
                    min: 0, // Set min to 0
                    type: 'linear', // Ensure y-axis is treated as linear
                    tickInterval: 1, // Set interval for ticks (adjust as needed)
                    reverse: false, // Ensure the y-axis is not reversed
                  }}
                />

                <Title level={4}>Top Activity Types (Last 30 Days)</Title> {/* Title for the activity types */}
                <Space direction="vertical" size="small" style={{ marginTop: 20 }}>
                  {getTeamDataQuery.data.top_activity_types_last_30 && getTeamDataQuery.data.top_activity_types_last_30.length > 0 ? (
                    getTeamDataQuery.data.top_activity_types_last_30.map((activity, index) => (
                      <Text key={index}>{activity[0]}: {activity[1]}</Text> // Accessing the tuple elements
                    ))
                  ) : (
                    <Text>No activity data available.</Text> // Fallback message if no data
                  )}
                </Space>
                <Title level={4}>Top Activity Types (Last 90 Days)</Title> {/* Title for the activity types */}
                <Space direction="vertical" size="small" style={{ marginTop: 20 }}>
                  {getTeamDataQuery.data.top_activity_types_last_90 && getTeamDataQuery.data.top_activity_types_last_90.length > 0 ? (
                    getTeamDataQuery.data.top_activity_types_last_90.map((activity, index) => (
                      <Text key={index}>{activity[0]}: {activity[1]}</Text> // Accessing the tuple elements
                    ))
                  ) : (
                    <Text>No activity data available.</Text> // Fallback message if no data
                  )}
                </Space>
              </>
            ) : (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <Typography.Text type="danger">Not enough data</Typography.Text>
              </div>
            )}
          </>
        )}
      </Card>
    </MainContainer>
  );
}
