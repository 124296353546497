import React, { useState, useEffect } from "react";
import MainContainer from "../../Container/MainContainer";
import { Card, Button, Modal, DatePicker, Select, message } from "antd";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doPostRequest,
  doGetRequest,
} from "../../../helpers/requestHelpers";
import { GiftOutlined, CalendarOutlined, CoffeeOutlined, SmileOutlined, NotificationOutlined, PlaySquareOutlined, UsergroupAddOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useTeams } from "msteams-react-base-component";

const { Option } = Select;

const MemberView = () => {

  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [{ inTeams, theme, context }] = useTeams();

  const [isBirthdayModalVisible, setIsBirthdayModalVisible] = useState(false);
  const [isAnniversaryModalVisible, setIsAnniversaryModalVisible] = useState(false);
  const [selectedBirthday, setSelectedBirthday] = useState(null);
  const [selectedAnniversary, setSelectedAnniversary] = useState(null);
  const [selectedBirthdayMonth, setSelectedBirthdayMonth] = useState(null);
  const [selectedBirthdayDay, setSelectedBirthdayDay] = useState(null);
  const [isHowToShoutoutModalVisible, setIsHowToShoutoutModalVisible] = useState(false);

  // get all custom celebrations
  const getHomePageInfoQuery = useQuery(
    ["GET_HOME_PAGE_INFO"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getHomePageInfoForMemberOrPartialAdmin`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, enabled: !!localStorage.getItem("token") }
  );

  // gets all shoutout stats for user
  const getShoutoutStatsQuery = useQuery(
    ["GET_SHOUTOUT_STATS_FOR_USER"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getShoutoutStatsForUser`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, enabled: !!localStorage.getItem("token") }
  );
  const { data: shoutoutStats } = getShoutoutStatsQuery;

  useEffect(() => {
    if (getHomePageInfoQuery.data?.user_birthday) {
      const birthdayMoment = moment().month(getHomePageInfoQuery.data.user_birthday.birth_month - 1).date(getHomePageInfoQuery.data.user_birthday.birth_day)
      setSelectedBirthdayMonth(birthdayMoment.month() + 1);
      setSelectedBirthdayDay(birthdayMoment.date());
    }
    if (getHomePageInfoQuery.data?.user_work_anniversary) {
      setSelectedAnniversary(moment(getHomePageInfoQuery.data.user_work_anniversary));
    }
  }, [getHomePageInfoQuery.data]);

  const handleBirthdayMonthChange = (month) => {
    setSelectedBirthdayMonth(month);
  };

  const handleBirthdayDayChange = (day) => {
    setSelectedBirthdayDay(day);
  };

  const handleAnniversaryChange = (date) => {
    setSelectedAnniversary(date);
  };

  const showBirthdayModal = () => {
    setIsBirthdayModalVisible(true);
  };

  const showAnniversaryModal = () => {
    setIsAnniversaryModalVisible(true);
  };

  // user updated birthday 
  const handleBirthdayOk = async () => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/updateUserBirthday`,
        {
          bday_user_id: getHomePageInfoQuery.data.user_data.id,
          birth_month: selectedBirthdayMonth,
          birth_day: selectedBirthdayDay,
        }
      );
      console.log(response);
      if (response.message) {
        message.success("Your birthday has been successfully updated.");
        queryClient.invalidateQueries("GET_HOME_PAGE_INFO");
      } else {
        message.error("There was an issue updating your birthday. Please try again.");
      }
    } catch (response) {
      message.error(response.error);
    }
    setIsBirthdayModalVisible(false);
  };

  // user updated anniversary
  const handleAnniversaryOk = async () => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/updateUserAnniversary`,
        {
          anniv_user_id: getHomePageInfoQuery.data.user_data.id,
          user_anniversary: selectedAnniversary.format("YYYY-MM-DD"),
        }
      );
      if (response.message) {
        message.success("Your anniversary has been successfully updated.");
        queryClient.invalidateQueries("GET_HOME_PAGE_INFO");
      } else {
        message.error("There was an issue updating your anniversary. Please try again.");
      }
    } catch (response) {
      message.error(response.error);
    }
    setIsAnniversaryModalVisible(false);
  };

  // user closed birthday or anniversary update
  const handleCancel = () => {
    setIsBirthdayModalVisible(false);
    setIsAnniversaryModalVisible(false);
  };
  
  const handleGiveShoutoutClick = () => {
    if (inTeams) {
      setIsHowToShoutoutModalVisible(true);
    } else {
      message.error("You must be in the Teams app to give a shoutout");
    }
  };

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">
          
          <img
            src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/other/home-cover-october-23.png"
            alt="Welcome To CultureBot Banner"
            style={{ width: "100%", maxWidth: "600px", height: "auto", borderRadius: "10px" }}
          />
          <div style={{ marginTop: "10px", fontSize: "18px", fontWeight: "bold", color: "#333" }}>
            Hi
            {getHomePageInfoQuery.data?.user_data?.displayName
              ? ` ${getHomePageInfoQuery.data.user_data.displayName},`
              : ","}
          </div>
          <div style={{ marginTop: "10px", fontSize: "16px", color: "#555" }}>
            Welcome to CultureBot! We exist to bring together your remote team and improve your overall culture. Use our platform to encourage employee engagement, build stronger cross-team relationships, and help your company culture bloom.
          </div>
          <div style={{ marginTop: "20px", fontSize: "12px", color: "#777" }}>
            <strong>Note:</strong> You're viewing this page as a non-admin or partial admin, please contact your main admin {getHomePageInfoQuery.data?.main_admin_email} if you'd like to become an admin.
          </div>

          {/* Birthday Messages Section */}
          <Card style={{ margin: 10, marginLeft: 0, marginTop: 20, borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
            <div style={{ fontSize: "16px", fontWeight: "bold", color: "#0050b3" }}>
              <GiftOutlined /> Birthday Messages
            </div>
            <div style={{ marginTop: "10px", fontSize: "16px", color: "#333" }}>
              {getHomePageInfoQuery.data?.user_birthday ? (
                <span>
                  Your current birthday is{" "}
                  {moment()
                    .month(getHomePageInfoQuery.data.user_birthday.birth_month - 1)
                    .date(getHomePageInfoQuery.data.user_birthday.birth_day)
                    .format("MMMM Do")}.
                </span>
              ) : (
                "Birthday celebrations are currently running. Modify your birthday using the button below."
              )}
            </div>
            <Button shape="round" style={{ marginTop: "10px", backgroundColor: "#1890ff", color: "#fff", border: "none" }} onClick={showBirthdayModal}>
              Change Your Birthday
            </Button>
            <Modal
              title="Change Birthday"
              visible={isBirthdayModalVisible}
              onOk={handleBirthdayOk}
              onCancel={handleCancel}
            >
              <Select
                value={selectedBirthdayMonth || (getHomePageInfoQuery.data?.user_birthday?.birth_month)}
                onChange={handleBirthdayMonthChange}
                placeholder="Select Month"
                style={{ width: 120, marginRight: 10 }}
              >
                {moment.months().map((month, index) => (
                  <Option key={index + 1} value={index + 1}>
                    {month}
                  </Option>
                ))}
              </Select>
              <Select
                value={selectedBirthdayDay || (getHomePageInfoQuery.data?.user_birthday?.birth_day)}
                onChange={handleBirthdayDayChange}
                placeholder="Select Day"
                style={{ width: 80 }}
              >
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <Option key={day} value={day}>
                    {day}
                  </Option>
                ))}
              </Select>
            </Modal>
          </Card>

          {/* Work Anniversary Messages Section */}
          <Card style={{ margin: 10, marginLeft: 0, marginTop: 20, borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
            <div style={{ fontSize: "16px", fontWeight: "bold", color: "#0050b3" }}>
              <CalendarOutlined /> Work Anniversary Messages
            </div>
            <div style={{ marginTop: "10px", fontSize: "16px", color: "#333" }}>
              {getHomePageInfoQuery.data?.user_work_anniversary ? (
                <span>
                  Your current work anniversary is{" "}
                  {moment(getHomePageInfoQuery.data.user_work_anniversary.anniversary).format("MMMM Do, YYYY")}.
                </span>
              ) : (
                "Work anniversaries are currently running. Modify your anniversary using the button below."
              )}
            </div>
            <Button shape="round" style={{ marginTop: "10px", backgroundColor: "#1890ff", color: "#fff", border: "none" }} onClick={showAnniversaryModal}>
              Change Your Anniversary
            </Button>
            <Modal
              title="Change Anniversary"
              visible={isAnniversaryModalVisible}
              onOk={handleAnniversaryOk}
              onCancel={handleCancel}
            >
              <DatePicker
                value={selectedAnniversary}
                onChange={handleAnniversaryChange}
                format="MMMM Do, YYYY"
                style={{ width: 200 }}
              />
            </Modal>
          </Card>

          {/* Custom Celebrations Section */}
          <Card style={{ margin: 10, marginLeft: 0, marginTop: 20, borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
            <div style={{ fontSize: "16px", fontWeight: "bold", color: "#0050b3" }}>
              <SmileOutlined /> Custom Celebrations
            </div>
            <div style={{ marginTop: "10px", fontSize: "16px" }}>
              Holidays or awareness days set up by your admin to celebrate with your team.
            </div>
            {getHomePageInfoQuery.data?.custom_celebrations?.map((celebration, index) => (
              <div
                key={index}
              >
                {index === 0 && <div style={{ borderTop: "1px solid #eee", margin: "10px 0" }}></div>}
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {celebration.celebration_name}
                </div>
                {celebration.celebration_image && (
                  <img
                    src={celebration.celebration_image}
                    alt={celebration.celebration_name}
                    style={{ width: "100px", height: "auto", marginTop: "10px" }}
                  />
                )}
                <div style={{ marginTop: "10px", fontSize: "14px" }}>
                  <strong>Date:</strong> {moment().month(celebration.celebration_month - 1).date(celebration.celebration_day).format("MMMM Do")}<br />
                  <strong>Channel Name:</strong> {celebration.channel_name || "N/A"}<br />
                  <strong>Time:</strong> {moment.utc(celebration.celebration_hour_of_day, "HH").local().format("h A")}<br />
                  <strong>Message:</strong> {celebration.celebration_message || "No message available"}
                </div>
                <div style={{ borderTop: "1px solid #eee", margin: "10px 0" }}></div>
              </div>
            ))}
          </Card>

          {/* Shoutouts Section */}
          {getHomePageInfoQuery.data?.plan_name === "standard" || getHomePageInfoQuery.data?.plan_name === "pro" ? (
            <Card style={{ margin: 10, marginLeft: 0, marginTop: 20, borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
              <div style={{ fontSize: "16px", fontWeight: "bold", color: "#0050b3" }}>
                <NotificationOutlined /> Shoutouts & Peer Rewards
              </div>
              <div style={{ marginTop: "10px", fontSize: "16px" }}>
                Recognize and appreciate your team members for their hard work and achievements.
              </div>
              <Button shape="round" style={{ marginTop: "10px", backgroundColor: "#1890ff", color: "#fff", border: "none" }} onClick={handleGiveShoutoutClick}>
                Give a Shoutout
              </Button>
              <p style={{ marginTop: "10px", fontStyle: "italic" }}>
                (Points left to give this month: {shoutoutStats?.points_left_to_give} | 
                Total points given: {shoutoutStats?.total_points_given} | 
                Total shoutouts given: {shoutoutStats?.total_shoutouts_given})
              </p>
              <Button
                shape="round"
                icon={<GiftOutlined />}
                style={{ marginBottom: "10px", backgroundColor: "#1890ff", color: "#fff", border: "none" }}
                onClick={() => window.location.href = "/app/rewards"}
              >
                View Gift Catalog
              </Button>
              <p style={{ marginTop: "-10px", fontStyle: "italic" }}>
                (Your redeemable points: {shoutoutStats?.redeemable_points} | 
                Total points received: {shoutoutStats?.total_points_received} | 
                Total shoutouts received: {shoutoutStats?.total_shoutouts_received})
              </p>
            </Card>
          ) : null}

          {/* Water Cooler Conversation Starters Section */}
          {getHomePageInfoQuery.data?.plan_name === "standard" || getHomePageInfoQuery.data?.plan_name === "pro" ? (
            <Card style={{ margin: 10, marginLeft: 0, marginTop: 20, borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
              <div style={{ fontSize: "16px", fontWeight: "bold", color: "#0050b3" }}>
                <CoffeeOutlined /> Water Cooler Conversation Starters
              </div>
              <div style={{ marginTop: "10px", fontSize: "16px" }}>
                Click below to add a water cooler instance. Choose from classic or AI-generated topics.
              </div>
              {getHomePageInfoQuery.data?.water_cooler_instances?.map((instance, index) => (
                <div key={index}>
                  {index === 0 && <div style={{ borderTop: "1px solid #eee", margin: "10px 0" }}></div>}
                  <strong>Channel Names:</strong> {instance.channel_names?.join(", ") || "N/A"}<br />
                  {instance.pack_names && instance.pack_names.length > 0 && (
                    <>
                      <strong>Topics:</strong> {instance.pack_names.join(", ")}<br />
                    </>
                  )}
                  {instance.is_ai_instance && instance.ai_topics && instance.ai_topics.length > 0 && (
                    <>
                      <strong>AI Topics:</strong> {instance.ai_topics.join(", ")}<br />
                    </>
                  )}
                  <strong>Hour of Day:</strong> {moment.utc(instance.water_cooler_hour_of_day, "HH").local().format("h A")}<br />
                  <strong>Days of Week:</strong> {instance.water_cooler_days_of_week.join(", ")}<br />
                  <strong>Frequency:</strong> {instance.water_cooler_frequency} {instance.water_cooler_frequency == 1 ? "week" : "weeks"}
                  <div style={{ borderTop: "1px solid #eee", margin: "10px 0" }}></div>
                </div>
              ))}
            </Card>
          ) : null}

          {/* Team Games Section */}
          {getHomePageInfoQuery.data?.plan_name === "standard" || getHomePageInfoQuery.data?.plan_name === "pro" ? (
            <Card style={{ margin: 10, marginLeft: 0, marginTop: 20, borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
              <div style={{ fontSize: "16px", fontWeight: "bold", color: "#0050b3" }}>
                <PlaySquareOutlined /> Team Games 
              </div>
              <div style={{ marginTop: "10px", fontSize: "16px" }}>
                Play fun games with your team like DoodleDash (real-time pictionary) and Fishbowl. 
              </div>
              <Button
                shape="round"
                style={{ marginTop: "10px", backgroundColor: "#1890ff", color: "#fff", border: "none" }}
                onClick={() => window.location.href = '/app/games'}
              >
                Play Games
              </Button>
            </Card>

          ) : null}

          <Modal
            title="How to Send a Shoutout"
            visible={isHowToShoutoutModalVisible}
            onOk={() => setIsHowToShoutoutModalVisible(false)}
            onCancel={() => setIsHowToShoutoutModalVisible(false)}
          >
            <p>To send a shoutout, visit the compose message box where you can choose 'CultureBot' as the app to send the shoutout from in the menu that appears.</p>
            <img
              src="https://graphics.getculturebot.com/other/where-is-compose-box-teams.png"
              alt="How to Send a Shoutout"
              style={{ width: "100%", height: "auto" }}
            />
          </Modal>

          <div style={{ marginTop: "40px", fontSize: "16px", fontWeight: "bold" }}>
            ❤️ CultureBot? We'd love to hear how you're using it!
          </div>

          <div style={{ marginTop: "10px", fontSize: "16px" }}>
            💬 For support or feedback, contact us via live chat below or e-mail: <strong><a href="mailto:support@getculturebot.com">support@getculturebot.com</a></strong>
          </div>

        </Card>
      </div>
    </MainContainer>
  );
};

export default MemberView;
