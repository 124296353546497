export const templateDays = {
    "🕌 World Religion Day": {"is_holiday": false, "month_number": "01", "day": "16", "name": "🕌 World Religion Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/religion_day.gif", "message": "Happy World Religion Day! This day encourages us to explore shared values across different faiths – what's one belief or practice from another religion that you find inspiring?"},
    "⚖️ World Day of Social Justice": {"is_holiday": false, "month_number": "02", "day": "20", "name": "⚖️ World Day of Social Justice", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/social_justice_day.gif", "message": "Happy World Day of Social Justice! This day reminds us to reflect on the importance of fairness and equality – what's one social issue you believe we should focus on to create a more just society?"},
    "💁‍♀️ International Women's Day": {"is_holiday": false, "month_number": "03", "day": "08", "name": "💁‍♀️ International Women's Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/womens_day.gif", "message": "Happy International Women's Day! Today we celebrate the achievements of women and gender equality – who is the woman you look up to most in your life and why?"},
    "🌎 Earth Day": {"is_holiday": false, "month_number": "04", "day": "22", "name": "🌎 Earth Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/earth_day.gif", "message": "Happy Earth Day! Earth Day is a reminder of our responsibility to protect the planet – what's one change you're making to live more sustainably?"},
    "👨‍❤️‍💋‍👨 International Day Against Homophobia, Transphobia, and Biphobia": {"is_holiday": false, "month_number": "05", "day": "17", "name": "👨‍❤️‍💋‍👨 International Day Against Homophobia, Transphobia, and Biphobia", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/day_against_homophobia.gif", "message": "Happy International Day Against Homophobia, Transphobia, and Biphobia! How do you think we can create safer and more inclusive spaces for everyone, regardless of their sexual orientation or gender identity?"},
    "🏳️‍🌈 Pride Month": {"is_holiday": false, "month_number": "06", "day": "01", "name": "🏳️‍🌈 Pride Month", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/pride_month.gif", "message": "Start of Pride Month! Pride Month is a celebration of LGBTQ+ identity and the ongoing fight for equality – what's one way you're showing support for the LGBTQ+ community this month?"},
    "🌊 World Oceans Day": {"is_holiday": false, "month_number": "06", "day": "08", "name": "🌊 World Oceans Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/ocean_day.gif", "message": "Happy World Oceans Day! This day highlights the importance of our oceans and the need to protect them – what's one action you're taking to help preserve marine life?"},
    "💆‍♀️ International Self Care Day": {"is_holiday": false, "month_number": "07", "day": "24", "name": "💆‍♀️ International Self Care Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/self_care_day.gif", "message": "Happy International Self Care Day! What's one self-care practice you're embracing to prioritize your well-being?"},
    "👦 International Youth Day": {"is_holiday": false, "month_number": "08", "day": "12", "name": "👦 International Youth Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/youth_day.gif", "message": "Happy International Youth Day! Today we celebrate the potential of young people to shape the future – what's one way we can better support and empower the youth in our communities?"},
    "✌️ International Day of Peace": {"is_holiday": false, "month_number": "09", "day": "21", "name": "✌️ International Day of Peace", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/day_of_peace.gif", "message": "Happy International Day of Peace! This day invites us to reflect on ways to foster harmony – what's one step you think we can take to create a more peaceful world?"},
    "🧠 World Mental Health Day": {"is_holiday": false, "month_number": "10", "day": "10", "name": "🧠 World Mental Health Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/mental_health_day.gif", "message": "Happy World Mental Health Day! This day encourages us to prioritize mental well-being – what's one thing you do to take care of your mental health?"},
    "🥸 Movember (Mens Health Month)": {"is_holiday": false, "month_number": "11", "day": "01", "name": "🥸 Movember (Mens Health Month)", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/mens_health_awareness.gif", "message": "Mens Health Awareness Month! This month highlights the importance of men's well-being – what's one health habit you think more men should adopt?"},
    "☺️ World Kindness Day": {"is_holiday": false, "month_number": "11", "day": "13", "name": "☺️ World Kindness Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/kindness_day.gif", "message": "Happy World Kindness Day! Today reminds us of the power of small acts – what's one simple way you plan to spread kindness today?"},
    "🐶 International Animal Rights Day":  {"is_holiday": false, "month_number": "12", "day": "10", "name": "🐶 International Animal Rights Day", "image_url": "https://graphics.getculturebot.com/awareness_days/gifs/animal_rights_day.gif", "message": "Happy International Animal Rights Day! This day raises awareness about the ethical treatment of animals – what's one change you can make to help protect animal rights?"},
    "🎆 New Years Day": {"is_holiday": true, "month_number": "01", "day": "01", "name": "🎆 New Years Day", "image_url": "https://graphics.getculturebot.com/holidays/new_years_day.gif", "message": "Happy New Year's Day! This day marks the beginning of a new year – what's one resolution you're setting for the new year?"},
    "🗣 Martin Luther King Jr. Day": {"is_holiday": true, "month_number": "01", "day": getMlkDay(), "name": "🗣 Martin Luther King Jr. Day", "image_url": "https://graphics.getculturebot.com/holidays/mlk_day.gif", "message": "Happy Martin Luther King Jr. Day! This day honors the legacy of Dr. Martin Luther King Jr. and his fight for civil rights – what's one way you're celebrating his legacy?"},
    "💖 Valentines Day": {"is_holiday": true, "month_number": "02", "day": "14", "name": "💖 Valentines Day", "image_url": "https://graphics.getculturebot.com/holidays/valentines_days.gif", "message": "Happy Valentines Day! This day celebrates love and friendship – what's one way you're showing appreciation for your loved ones?"},
    "👴 Presidents Day": {"is_holiday": true, "month_number": "02", "day": getPresidentsDay(), "name": "👴 Presidents Day", "image_url": "https://graphics.getculturebot.com/holidays/presidents_day.gif", "message": "Happy Presidents Day! This day honors the memory of George Washington, the first President of the United States – what's one thing you admire about our nation's leaders?"},
    "⛪️ Good Friday": {"is_holiday": true, "month_number": getGoodFriday('month'), "day": getGoodFriday('day'), "name": "⛪️ Good Friday", "image_url": "https://graphics.getculturebot.com/holidays/good_friday.gif", "message": "Happy Good Friday! This day commemorates the crucifixion of Jesus Christ – what's one way you're celebrating Easter this year?"},
    "🇺🇸 Memorial Day": {"is_holiday": true, "month_number": "05", "day": getMemorialDay(), "name": "🇺🇸 Memorial Day", "image_url": "https://graphics.getculturebot.com/holidays/memorial_day.gif", "message": "Happy Memorial Day! This day honors those who have died while serving in the U.S. military – what's one way you're honoring the sacrifices of our veterans?"},
    "🖤 Juneteenth": {"is_holiday": true, "month_number": "06", "day": "19", "name": "🖤 Juneteenth", "image_url": "https://graphics.getculturebot.com/holidays/juneteenth.gif", "message": "Happy Juneteenth! This day commemorates the end of slavery in the United States – what's one way you're celebrating the history and culture of African Americans?"},
    "🌭 Independence Day": {"is_holiday": true, "month_number": "07", "day": "04", "name": "🌭 Independence Day", "image_url": "https://graphics.getculturebot.com/holidays/july_4th.gif", "message": "Happy Independence Day! This day celebrates the adoption of the Declaration of Independence on July 4, 1776 – what's one thing you're proud of about the United States?"},
    "👷‍♂️ Labor Day": {"is_holiday": true, "month_number": "09", "day": getLaborDay(), "name": "👷‍♂️ Labor Day", "image_url": "https://graphics.getculturebot.com/holidays/labor_day.gif", "message": "Happy Labor Day! This day honors the American labor movement and the contributions of workers to the strength, prosperity, and well-being of our country – what's one way you're celebrating the hard work of your team?"},
    "🎃 Halloween": {"is_holiday": true, "month_number": "10", "day": "31", "name": "🎃 Halloween", "image_url": "https://graphics.getculturebot.com/holidays/halloween.gif", "message": "Happy Halloween! This day is a time to celebrate the spooky season – what's one Halloween tradition you're looking forward to this year?"},
    "🪖 Veterans Day": {"is_holiday": true, "month_number": "11", "day": "11", "name": "🪖 Veterans Day", "image_url": "https://graphics.getculturebot.com/holidays/veterans_day.gif", "message": "Happy Veterans Day! This day honors the service of all U.S. military veterans – what's one way you're honoring the sacrifices of our veterans?"},
    "🦃 Thanksgiving Day": {"is_holiday": true, "month_number": "11", "day": getThanksgivingDay(), "name": "🦃 Thanksgiving Day", "image_url": "https://graphics.getculturebot.com/holidays/thanksgiving.gif", "message": "Happy Thanksgiving Day! This day is a time to give thanks for the blessings in our lives – what's one thing you're thankful for this year?"},
    "🎄 Christmas Day": {"is_holiday": true, "month_number": "12", "day": "25", "name": "🎄 Christmas Day", "image_url": "https://graphics.getculturebot.com/holidays/christmas.gif", "message": "Happy Christmas Day! This day celebrates the birth of Jesus Christ – what's one way you're celebrating the holiday season?"}
};  

export function getDayForHoliday(holidayDict, dayOrMonth = 'day') {
    const currentYear = new Date().getFullYear();
    let daysStr = holidayDict[currentYear];
    const today = new Date();

    if (!daysStr || today > new Date(daysStr)) {
        daysStr = holidayDict[currentYear + 1];
    }

    if (dayOrMonth === 'day') {
        return String(daysStr.split('-')[2]).padStart(2, '0');
    } else if (dayOrMonth === 'month') {
        return String(daysStr.split('-')[1]).padStart(2, '0');
    }
}

export function getMlkDay() {
    const mlkDays = {
        2025: "2025-01-20",
        2026: "2026-01-19",
        2027: "2027-01-18",
        2028: "2028-01-17",
        2029: "2029-01-15",
        2030: "2030-01-21",
        2031: "2031-01-20",
        2032: "2032-01-19",
        2033: "2033-01-17",
        2034: "2034-01-16"
    };
    return getDayForHoliday(mlkDays);
}

export function getPresidentsDay() {
    const presidentsDay = {
        2025: "2025-02-17",
        2026: "2026-02-16",
        2027: "2027-02-15",
        2028: "2028-02-21",
        2029: "2029-02-19",
        2030: "2030-02-18",
        2031: "2031-02-17",
        2032: "2032-02-16",
        2033: "2033-02-21",
        2034: "2034-02-20"
    };
    return getDayForHoliday(presidentsDay);
}

export function getGoodFriday(dayOrMonth) {
    const goodFriday = {
        2025: "2025-04-18",
        2026: "2026-04-03",
        2027: "2027-03-26",
        2028: "2028-04-14",
        2029: "2029-03-30",
        2030: "2030-04-19",
        2031: "2031-04-11",
        2032: "2032-03-26",
        2033: "2033-04-15",
        2034: "2034-04-07"
    };

    return getDayForHoliday(goodFriday, dayOrMonth);
}

export function getMemorialDay() {
    const memorialDay = {
        2025: "2025-05-26",
        2026: "2026-05-25",
        2027: "2027-05-31",
        2028: "2028-05-29",
        2029: "2029-05-28",
        2030: "2030-05-27",
        2031: "2031-05-26",
        2032: "2032-05-31",
        2033: "2033-05-30",
        2034: "2034-05-29"
    };
    return getDayForHoliday(memorialDay);
}

export function getLaborDay() {
    const laborDay = {
        2025: "2025-09-01",
        2026: "2026-09-07",
        2027: "2027-09-06",
        2028: "2028-09-04",
        2029: "2029-09-03",
        2030: "2030-09-02",
        2031: "2031-09-01",
        2032: "2032-09-06",
        2033: "2033-09-05",
        2034: "2034-09-04"
    };
    return getDayForHoliday(laborDay);
}

export function getIndigenousPeoplesDay() {
    const indigenousPeoplesDay = {
        2025: "2025-10-13",
        2026: "2026-10-12",
        2027: "2027-10-11",
        2028: "2028-10-09",
        2029: "2029-10-08",
        2030: "2030-10-14",
        2031: "2031-10-13",
        2032: "2032-10-11",
        2033: "2033-10-10",
        2034: "2034-10-09"
    };
    return getDayForHoliday(indigenousPeoplesDay);
}

export function getThanksgivingDay() {
    const thanksgiving = {
        2024: "2024-11-28",
        2025: "2025-11-27",
        2026: "2026-11-26",
        2027: "2027-11-25",
        2028: "2028-11-23",
        2029: "2029-11-22",
        2030: "2030-11-28",
        2031: "2031-11-27",
        2032: "2032-11-25",
        2033: "2033-11-24",
        2034: "2034-11-23"
    };
    return getDayForHoliday(thanksgiving);
}
