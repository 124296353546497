import React from "react";
import MainContainer from "../Container/MainContainer";
import { Card, Button, Space } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./DevServices.css";

export default function TestPage() {
  
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const checkAccessQuery = useQuery(
    ["hasAccessToTestPage"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/hasAccessToTestPage`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  async function sendTestPageCommand(commandString) {
    const jsonToSend = {
      test_page_command: commandString,
    };
    await doPostRequest(
      `${getBaseUrl()}/api/testPageCommand`,
      jsonToSend,
      setTokenErrorType
    );
    // Todo check response
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/app/login"
    } 
  }, []);

  let mainArea;
  if (checkAccessQuery.isLoading) {
    mainArea = <div>Loading...</div>;
  } else if (checkAccessQuery.isError) {
    mainArea = <div>Error loading dev services page</div>;
  } else if (checkAccessQuery.data.error) {
    window.location.href = "/app/login";

  } else {
    mainArea = (
      <div>
        <div>
          <Space wrap>
            Go to page:
            <Button
              type="primary"
              onClick={() => {
                window.location.href =
                  "custom_shoutouts" + document.location.search;
              }}
            >
              Custom Shoutouts
            </Button>
            <Button
              type="primary"
              onClick={() => {
                window.location.href = "create_form" + document.location.search;
              }}
            >
              Forms & Surveys
            </Button>
            <Button
              type="primary"
              onClick={() => {
                window.location.href =
                  "shoutout_stats" + document.location.search;
              }}
            >
              Shoutout Stats
            </Button>
          </Space>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Space wrap>
            Set services to:
            <Button
              type="primary"
              onClick={() =>
                sendTestPageCommand("set_services_for_current_half_hour")
              }
            >
              Current half hour
            </Button>
            <Button
              type="primary"
              onClick={() =>
                sendTestPageCommand("set_services_for_next_half_hour")
              }
            >
              Next half hour
            </Button>
          </Space>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Space wrap>
            Call service
            <Button
              type="primary"
              onClick={() => sendTestPageCommand("call_service_water_cooler")}
            >
              Water cooler
            </Button>
          </Space>
        </div>
      </div>
    );
  }

  return (
    <>
      <MainContainer>
        <div style={{ marginLeft: '200px', padding: '24px' }}>
          <Card style={{ padding: 10, margin: 20 }}>{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );

}
