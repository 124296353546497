import moment from "moment-timezone";

// returns base64 encoded file
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// retrieving image dimensions for a URL
export function getImageSize(url) {
  const img = document.createElement("img");

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      // Resolve promise with the width and height
      resolve({ width, height });
    };

    // Reject promise on error
    img.onerror = reject;
  });

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url;

  return promise;
}
 
// capitalize first letter of a word
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// return nice formatting for a snake_cased name
export const getNiceName = (fileName) => {
  const returnString = capitalizeFirstLetter(fileName);
  return returnString.replaceAll("_", " ");
};

export function roundToLastHalfHour(inputDate) {
  // Clone the inputDate to avoid modifying it directly
  const date = moment(inputDate);

  // Check if the minutes are not exactly on a 30-minute interval
  if (date.minutes() % 30 !== 0) {
    // Round down to the last half-hour
    date.minutes(Math.floor(date.minutes() / 30) * 30);
    date.seconds(0);
  }

  // Check if the date was adjusted
  const adjusted = !inputDate.isSame(date);

  return { date, adjusted };
}

// Function to convert Mrkdwn to HTML
export const convertMrkdwnToHtml = (mrkdwn) => {
  // Convert links first
  let html = mrkdwn.replace(/<(http[^\s]+)\|([^>]+)>/g, (match, url, text) => {
    // Return the link with the text
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;
  });

  // Convert bold text
  html = html.replace(/\*(.*?)\*/g, '<strong>$1</strong>'); // Bold
  // Convert italic text
  html = html.replace(/_(.*?)_/g, '<em>$1</em>'); // Italic

  // Convert line breaks
  html = html.replace(/\n/g, '<br />'); // Line breaks

  return html;
};

export const getCountriesList = () => {
  return [
    { code: "US", long: "United States" },
    { code: "AR", long: "Argentina" },
    { code: "AU", long: "Australia" },
    { code: "BR", long: "Brazil" },
    { code: "BG", long: "Bulgaria" },
    { code: "CA", long: "Canada" },
    { code: "CY", long: "Cyprus" },
    { code: "FR", long: "France" },
    { code: "DE", long: "Germany" },
    { code: "HK", long: "Hong Kong" },
    { code: "IN", long: "India" },
    { code: "ID", long: "Indonesia" },
    { code: "LT", long: "Lithuania" },
    { code: "MX", long: "Mexico" },
    { code: "MY", long: "Malaysia" },
    { code: "NL", long: "Netherlands" },
    { code: "PH", long: "Philippines" },
    { code: "PL", long: "Poland" },
    { code: "PT", long: "Portugal" },
    { code: "SG", long: "Singapore" },
    { code: "ZA", long: "South Africa" },
    { code: "ES", long: "Spain" },
    { code: "SE", long: "Sweden" },
    { code: "TR", long: "Turkey" },
    { code: "AE", long: "United Arab Emirates" },
    { code: "UK", long: "United Kingdom" },
    { code: "UY", long: "Uruguay" },
  ];
};

export const getCurrencies = () => {
  return {
    US: "USD",
    AE: "AED",
    AR: "ARS",
    AU: "AUD",
    BR: "BRL",
    BG: "BGN",
    CA: "CAD",
    CY: "EUR",
    DE: "EUR",
    ES: "EUR",
    FR: "EUR",
    HK: "HKD",
    ID: "IDR",
    IN: "INR",
    LT: "EUR",
    MY: "MYR",
    NL: "EUR",
    PL: "PLN",
    PT: "EUR",
    SE: "SEK",
    SG: "SGD",
    TR: "TRY",
    UK: "GBP",
    UY: "UYU",
    ZA: "ZAR",
  };
};

export const clearUrlParams = () => {
  const url = new URL(window.location);
  url.search = '';
  window.history.replaceState({}, document.title, url);
};

// We were having issues with CSV formatting causing one line of input to go into multiple -> https://trello.com/c/q5mVEjs4/1063
// (Due to some combination of some or all of newlines, double quotes and commas)
// This seemed to be the simplest fix that fixed all the tested cases
export function escapeStringForCSV(inputString) {
  return inputString.replace(/\"/g, "\"\"");
}

