import {
  Table,
  Tooltip,
  Card,
  Row,
  Col,
  Progress,
  Button,
  Select,
  Modal,
  Alert,
  Divider,
  Input,
  Space,
  message,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import { useState, useEffect, useRef } from "react";
import {
  LeftOutlined,
  CheckSquareOutlined,
  BellOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  LineChartOutlined, 
  SearchOutlined
} from "@ant-design/icons";
import moment from "moment";
import "./CampaignResults.scss";
import SelectGraph from "./SelectGraph";
import useColumnSearch from "../../hooks/columnSearch";
import ResponseRateOverTime from "./OverviewGraphs";
import { NumberTypeAggregations } from "./OverviewGraphs";
import Highlighter from "react-highlight-words";
import { AIAnalysis } from "./AIAnalysis"

export default function CampaignResultsTable({
  campaignId,
  setCampaignIdResultsToShow,
  isPublicResults,
  publicCampaignRunId,
}) {
  const [campaignName, setCampaignName] = useState("");
  const [campaignStartTime, setCampaignStartTime] = useState("");
  const [campaignFrequency, setCampaignFrequency] = useState("");
  const [campaignFormUsed, setCampaignFormUsed] = useState("");
  const [submissionsCounts, setSubmissionsCounts] = useState(0);
  const [audienceSize, setAudienceSize] = useState(0);
  const [notSubmittedUsers, setNotSubmittedUsers] = useState([]);
  const [questionAnsweredCounter, setQuestionAnsweredCounter] = useState({});
  const [selectQuestionsFrequency, setSelectQuestionsFrequency] = useState({});
  const [numberQuestionsStats, setNumberQuestionsStats] = useState({});
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [campaignRunId, setCampaignRunId] = useState();
  const campaignFrequencyFormattedString = {
    ONE_TIME: "One Time",
    WEEKDAYS: "Weekdays",
    ONE_WEEK: "Weekly",
    TWO_WEEKS: "Bi-Weekly",
    FOUR_WEEKS: "Every 4 Weeks",
    EIGHT_WEEKS: "Every 8 Weeks",
    TWELVE_WEEKS: "Every 12 Weeks",
    NEW_USERS_DAILY: "All New Slack Users",
    NEW_USERS_30_DAYS: "All Slack Users 30 Days After Joining",
    NEW_USERS_60_DAYS: "All Slack Users 60 Days After Joining",
    NEW_USERS_90_DAYS: "All Slack Users 90 Days After Joining",
  };
  let campaignRunOptions = [];
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [reminderSent, setReminderSent] = useState(false);
  const [isNpsModalOpen, setIsNpsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // fetch campaign runs
  const getCampaignRunsQuery = useQuery(
    ["GET_CAMPAIGN_RUNS", campaignId],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCampaignRunsForId`,
        setTokenErrorType,
        [{ name: "campaign_id", value: campaignId }]
      );
    },
    { refetchOnWindowFocus: false, enabled: !isPublicResults }
  );

  // fetch campaign details
  const getCampaignResultsQuery = useQuery(
    ["GET_CAMPAIGN_RESULTS", campaignRunId],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCampaignResults`,
        setTokenErrorType,
        [
          { name: "campaign_id", value: campaignId },
          { name: "campaign_run_id", value: campaignRunId },
        ]
      );
    },
    { refetchOnWindowFocus: false, enabled: !!campaignRunId } // this query needs to have campaignRunId before running
  );

  console.log('getCampaignResultsQuery', getCampaignResultsQuery?.data);

  // ---- export to csv ---- //
  // https://www.npmjs.com/package/react-csv
  async function formatCsvData() {
    // table header
    const tmpData = [];
    const row = ["Time Submitted", "Time to Submit"];
    if (!isAnonymous) {
      row.push("Responder", "Department", "Title", "Location", "Groups");
    } else {
      row.push("Department");
    }
    for (const [key, value] of Object.entries(
      getCampaignResultsQuery.data.all_questions
    )) {
      row.push(value["question_text"]);
    }
    tmpData.push(row);

    // table rows
    getCampaignResultsQuery.data.all_responses.forEach((response) => {
      const row = [
        moment.unix(response.time).format("MM/DD/YYYY"),
        `${response.time_to_submit_sec} secs`,
      ];
      if (!isAnonymous) {
        row.push(
          response.responder,
          response.department,
          response.title,
          response.location,
          response.groups ? response.groups.join(", ") : 'N/A'
        );
      } else {
        row.push(response.department);
      }
      for (const [key, value] of Object.entries(response)) {
        if (!["submission_user_group", "time", "responder", "time_to_submit_sec", "department", "title", "location", "groups"].includes(key)) {
          row.push(value);
        }
      }
      tmpData.push(row);
    });
    setCsvData(tmpData);
  }

  // logout
  const logout = () => {
    localStorage.setItem('token', '');
    window.location.href = "/app/login"
  };

  useEffect(() => {
    // overview stats vars
    if (getCampaignResultsQuery.data) {
      setCampaignName(getCampaignResultsQuery.data.campaign_name);
      setCampaignStartTime(getCampaignResultsQuery.data.start_date);
      setCampaignFrequency(getCampaignResultsQuery.data.frequency);
      setCampaignFormUsed(getCampaignResultsQuery.data.form_name);
      setSubmissionsCounts(getCampaignResultsQuery.data.total_responses);
      setAudienceSize(getCampaignResultsQuery.data.audience_size);
      setIsAnonymous(getCampaignResultsQuery.data.is_anonymous);
      setNotSubmittedUsers(getCampaignResultsQuery.data.users_not_submitted);
      setQuestionAnsweredCounter(
        getCampaignResultsQuery.data.question_answered_counter
      );
      setSelectQuestionsFrequency(
        getCampaignResultsQuery.data.select_questions_answers_histo
      );
      setNumberQuestionsStats(
        getCampaignResultsQuery.data.number_questions_stats
      );
      if (campaignRunId != "Overview") {
        formatCsvData();
      }
    }

    // viewing public results => set campaign run id from value in url
    if (publicCampaignRunId) {
      setCampaignRunId(publicCampaignRunId);
    }
  }, [getCampaignResultsQuery.data, reminderSent, campaignRunId]);

  // options for choosing campaign run in dropdown
  if (getCampaignRunsQuery.data) {
    campaignRunOptions = getCampaignRunsQuery.data.runs.map((run) => {
      return {
        label: run.start_date_time_gmt,
        value: run.id,
      };
    });
    // set but only on first time if campaignRunId is null
    if (campaignRunOptions.length && campaignRunId == null) {
      let firstCampaignRunId = campaignRunOptions[0].value;
      setCampaignRunId(firstCampaignRunId);
    }
  }

  // handlers
  const handleCancel = () => {
    setIsProgressModalOpen(false);
  };

  async function sendReminderForRun() {
    setReminderSent(true);
    const response = await doPostRequest(
      `${getBaseUrl()}/api/sendReminderForCampaignRun`,
      { campaign_run_id: campaignRunId },
      setTokenErrorType
    );
  }

  // (for recurring campaigns) show an analytics overview
  const setOverviewOn = () => {
    setCampaignRunId("Overview");
  };

  // Search Component
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        `${text}`
      ),
  });

  // progress modal
  const progressModal =
  questionAnsweredCounter && (campaignRunId != "Overview") ? (
      <Modal
        title="Question Response Rate"
        open={isProgressModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {Object.keys(questionAnsweredCounter).map((key, index) => (
          <>
            <p>
              <strong>Question:</strong> {key}
            </p>

          {/* progress bar */}
          <Progress
            percent={Math.round(
              (questionAnsweredCounter[key] / submissionsCounts) * 100
            )}
            size="small"
          />

          {/* how many users answered the question */}
          <p>
            {questionAnsweredCounter[key]} out of {submissionsCounts} answered
          </p>

          {/* for number types only - stats */}
          {key in numberQuestionsStats ? (
            <p>
              <i>
                Average:{" "}
                {(numberQuestionsStats[key]["question_total"] /
                  numberQuestionsStats[key]["responses"]).toFixed(1)}
              </i>
            </p>
          ) : null}

          {/* for single and multi selects > bar graph */}
          {key in selectQuestionsFrequency ? (
            <SelectGraph answers={selectQuestionsFrequency[key]} />
          ) : null}
          <Divider dashed />
        </>
      ))}
    </Modal>
  ) : null;

  // survey results data to pass into AIAnalysis
  const surveyResults = getCampaignResultsQuery.isSuccess ? getCampaignResultsQuery.data : { all_responses: [], all_questions: [] };

  // overview & stats boxes
  const OverviewSection = () => (
    <Row gutter={16}>
      <Col span={24}>
        <div className="topBar">
          <div className="leftSide">
            {isPublicResults ? null : (
              <a
                onClick={() => {
                  setCampaignIdResultsToShow(null);
                }}
                className="backButton"
              >
                <LeftOutlined /> Back to Campaigns
              </a>
            )}
            {getCampaignRunsQuery.data &&
            !isPublicResults &&
            getCampaignRunsQuery.data.runs.length > 1 ? (
              <div className="chooseRun">
                <Select
                  value={campaignRunId}
                  onChange={(runId) => {
                    setCampaignRunId(runId);
                  }}
                  options={campaignRunOptions}
                  style={{ width: "260px" }}
                />
                {campaignRunOptions.length > 1 ? (
                  <a onClick={() => setOverviewOn()}>
                    <LineChartOutlined /> View Overview Metrics
                  </a>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="rightSide">
            {isPublicResults ? null : <AIAnalysis surveyResults={surveyResults} loadingSurveyResults={getCampaignResultsQuery.isLoading} />}
          </div>
        </div>
      </Col>
      
      {/* must have runs to show stats */}
      {campaignRunId && questionAnsweredCounter && campaignRunId != "Overview" ? (
        <>
          <Col span={6}>
            <Card
              title={
                <div>
                  <InfoCircleOutlined /> About this Campaign
                </div>
              }
            >
              <strong>Campaign Name:</strong> {campaignName}
              <br />
              {[
                "NEW_USERS_DAILY",
                "NEW_USERS_30_DAYS",
                "NEW_USERS_60_DAYS",
                "NEW_USERS_90_DAYS",
              ].includes(campaignFrequency) ? null : (
                <>
                  <strong>Start Time:</strong> {campaignStartTime}
                  <br />
                </>
              )}
              <strong>Frequency:</strong>{" "}
              {campaignFrequencyFormattedString[campaignFrequency]}
              <br />
              <strong>Form Used:</strong> {campaignFormUsed}
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={
                <div>
                  <CheckSquareOutlined /> Overall Response Rate
                </div>
              }
            >
              <div className="progressBox">
                <div className="progressBox-left">
                  <Progress
                    type="circle"
                    percent={
                      submissionsCounts > 0
                        ? Math.round((submissionsCounts / audienceSize) * 100)
                        : 0
                    }
                  />
                </div>
                <div className="progressBox-right">
                  <strong>Submissions: </strong>
                  {submissionsCounts}
                  <br />
                  <strong>Sent To: </strong>
                  {audienceSize}
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={
                <div>
                  <CheckSquareOutlined /> Question Response Rate
                </div>
              }
            >
              <strong># Questions: </strong>
              {Object.keys(questionAnsweredCounter).length}
              <br />
              <br />
              {
                // this reduces down the questionAnsweredCounter dict to just the first key
                Object.keys(
                  Object.fromEntries(
                    Object.entries(questionAnsweredCounter).slice(0, 1)
                  )
                ).map((key, index) => (
                  <>
                    <p>{key}</p>
                    <Progress
                      percent={Math.round(
                        (questionAnsweredCounter[key] / submissionsCounts) * 100
                      )}
                      size="small"
                    />
                    <p>
                      {questionAnsweredCounter[key]} out of {submissionsCounts}{" "}
                      answered
                    </p>
                  </>
                ))
              }
              <div
                onClick={() => {
                  setIsProgressModalOpen(true);
                }}
                id="viewQuestions"
              >
                View All Questions
              </div>
              {/* this was an eNPS campaign - show eNPS results */}
              {getCampaignResultsQuery?.data?.form_used === "nps" && (
                <a href="#" onClick={handleNpsButtonClick} style={{ marginTop: '10px' }}>
                  Show eNPS Score
                </a>
              )}
            </Card>
          </Col>
          {[
            "NEW_USERS_DAILY",
            "NEW_USERS_30_DAYS",
            "NEW_USERS_60_DAYS",
            "NEW_USERS_90_DAYS",
          ].includes(campaignFrequency) ? null : (
            <Col span={6}>
              <Card
                title={
                  <div>
                    <WarningOutlined /> Not Submitted Yet (
                    {notSubmittedUsers.length})
                  </div>
                }
              >
                {isAnonymous
                  ? "Results are anonymous"
                  : // for 10 users and under, formats as "name, name, name".
                    // for > 10 users, formats as "name, name, name (and X more)"
                    notSubmittedUsers.map((user, index) => {
                      if (index > 9) {
                        return "";
                      } else {
                        if (
                          index == notSubmittedUsers.length - 1 ||
                          index == 9
                        ) {
                          if (notSubmittedUsers.length > 10) {
                            return (
                              user +
                              " (and " +
                              (notSubmittedUsers.length - 10) +
                              " more)"
                            );
                          } else {
                            return user;
                          }
                        } else {
                          return user + ", ";
                        }
                      }
                    })}
                <br />
                <br />
                {isPublicResults ? null : (
                  <Button
                    type="primary"
                    shape="round"
                    disabled={reminderSent}
                    icon={
                      reminderSent ? <CheckSquareOutlined /> : <BellOutlined />
                    }
                    onClick={() => {
                      sendReminderForRun();
                    }}
                  >
                    {reminderSent ? "Reminder Sent!" : "Send Reminder Now"}
                  </Button>
                )}
              </Card>
            </Col>
          )}
        </>
      ) : campaignRunId != "Overview" ? (
        <Alert
          message="No runs yet!"
          description="This campaign has not been sent out yet, and has no results. Once submissions come in your results will start to display here."
          type="warning"
          showIcon
          style={{ width: "100%" }}
        />
      ) : null}
    </Row>
  );

  // table fields
  const columns = [
    {
      title: "Time Submitted",
      dataIndex: "time",
      key: "time",
      width: 200,
      fixed: "left",
      render: (_, record) => {
        return <div>{moment.unix(record.time).format("MM/DD/YYYY HH:mm")}</div>;
      },
      sorter: (a, b) => a.time < b.time,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Time to Submit",
      dataIndex: "time_to_submit_sec",
      key: "time_to_submit_sec",
      width: 175,
      fixed: "left",
      render: (_, record) => {
        if (!record.time_to_submit_sec) {
          return <div>--</div>;
        }
        const minutes = Math.floor(record.time_to_submit_sec / 60);
        const seconds = record.time_to_submit_sec % 60;
        return <div>{`${minutes}m ${seconds}s`}</div>;
      },
      sorter: (a, b) => a.time_to_submit_sec - b.time_to_submit_sec,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Responder",
      dataIndex: "responder",
      key: "responder",
      width: 200,
      fixed: "left",
      render: (_, record) => {
        return <div>{record.responder}</div>;
      },
      ...useColumnSearch("responder"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 150,
      render: (_, record) => {
        return <div>{record.department}</div>;
      },
      ...useColumnSearch("department"),
    },
    !isAnonymous && {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 150,
      render: (_, record) => {
        return <div>{record.title}</div>;
      },
    },
    !isAnonymous && {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 150,
      render: (_, record) => {
        return <div>{record.location}</div>;
      },
    },
    !isAnonymous && {
      title: "Groups",
      dataIndex: "groups",
      key: "groups",
      width: 150,
      render: (_, record) => {
        return <div>{record.groups ? record.groups.join(", ") : 'N/A'}</div>;
      },
    },
  ].filter(Boolean); // Filter out any falsey values (e.g., when isAnonymous is true)

  // token error
  const tokenErrorArea = getTokenErrorAreaIfNeeded(tokenErrorType);
  if (tokenErrorArea) {
    return tokenErrorArea;
  }

  // build table area (for campaign runs that are not "Overview")
  var tableArea;
  if (getCampaignResultsQuery.isLoading && campaignRunId) {
    tableArea = <div>Loading...</div>;
  } else if (getCampaignRunsQuery.isError || getCampaignResultsQuery.isError) {
    tableArea = (
      <div>
        Error loading campaign results...
        <br />
        <br />
        You do not have permission to view this page, or may need to <strong><a onClick={() => {logout()}}>logout</a></strong> above and login again with the same user account you're logged into Slack with.
      </div>
    );
  } else if (
    getCampaignResultsQuery.isSuccess &&
    getCampaignResultsQuery.data.error
  ) {
    tableArea = (
      <div>
        Token has expired. Please open this page again from the slack app to get
        a new token.{" "}
      </div>
    );
  } else if (campaignRunId != "Overview") {
    // can't show stateless table if no campaign runs yet, so here we check to make sure campaign results was successfully returned first
    const campaign_results = getCampaignResultsQuery.isSuccess
      ? getCampaignResultsQuery.data
      : { all_responses: [], all_questions: [] };

    // add all questions as column headers in table
    // console.log("campaign_results.all_questions: ", campaign_results.all_questions)
    for (const [key, value] of Object.entries(campaign_results.all_questions)) {
      columns.push({
        title: value["question_text"],
        dataIndex: key,
        key: key,
        width: 250,
        render: (_, record) => {
          return <div>{record[key]}</div>;
        },
      });
    }

    // add export button to table header
    columns.push({
      title: () => {
        return (
          <div>
            {
              <CSVLink
                data={csvData}
                filename={"all-campaign-run-responses.csv"}
                target="_blank"
              >
                <Tooltip placement="left" title="Download responses (CSV)">
                  <DownloadOutlined className="exportCSV" />
                </Tooltip>
              </CSVLink>
            }
          </div>
        );
      },
      dataIndex: "export",
      key: "export",
      width: 50,
      render: (_, record) => {
        return <div></div>;
      },
    });

    // data in table
    const tableData = campaign_results.all_responses.map((response) => {
      return response;
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
        scroll={{
          x: Object.keys(campaign_results.all_questions).length * 250,
          y: 300,
        }}
      />
    );
  }

  const handleNpsModalOpen = () => {
    setIsNpsModalOpen(true);
  };

  // NPS reults modal
  const npsModal = (
    <Modal
      title="Overall eNPS Score"
      open={isNpsModalOpen}
      onCancel={() => setIsNpsModalOpen(false)}
      footer={[
        <Button key="back" onClick={() => setIsNpsModalOpen(false)}>
          Close
        </Button>,
      ]}
    >
      <div style={{ textAlign: 'center' }}>
        {(() => {
          const overallNps = getCampaignResultsQuery?.data?.nps_metrics?.overall_nps;
          const scoreColor = overallNps < 0 ? 'red' : overallNps < 30 ? '#FFD700' : overallNps < 70 ? 'blue' : 'green';
          
          return (
            <>
              <h1 style={{ color: scoreColor }}>
                Your Score: {overallNps}
              </h1>

              {/* NPS Score Bar */}
              <div style={{ margin: '20px 0' }}>
                <Progress
                  percent={((overallNps + 100) / 200) * 100}
                  strokeColor={scoreColor}
                  showInfo={false}
                  style={{ height: '30px' }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <p style={{ color: 'red' }}>
                  Detractors: {((getCampaignResultsQuery?.data?.nps_metrics?.detractors || 0) / (getCampaignResultsQuery?.data?.nps_metrics?.total_responses || 1) * 100).toFixed(2)}%
                </p>
                <p style={{ color: 'orange' }}>
                  Passives: {((getCampaignResultsQuery?.data?.nps_metrics?.passives || 0) / (getCampaignResultsQuery?.data?.nps_metrics?.total_responses || 1) * 100).toFixed(2)}%
                </p>
                <p style={{ color: 'green' }}>
                  Promoters: {((getCampaignResultsQuery?.data?.nps_metrics?.promoters || 0) / (getCampaignResultsQuery?.data?.nps_metrics?.total_responses || 1) * 100).toFixed(2)}%
                </p>
              </div>

              <Divider />

              {/* eNPS Score Ranges */}
              <div style={{ marginBottom: '20px' }}>
                <p><strong>eNPS Score Ranges:</strong></p>
                <p>Bad: &lt; 0 (detractors outnumber promoters)</p>
                <p>Okay: 0 - 30 (more detractors than passives)</p>
                <p>Good: 30 - 70 (more promoters than passives)</p>
                <p>Excellent: &gt; 70 (strongly promoter-dominated)</p>
              </div>

            </>
          );
        })()}
      </div>
    </Modal>
  );

  // Function to handle eNPS button click
  const handleNpsButtonClick = () => {
    if (getCampaignResultsQuery?.data?.failed_to_calc_nps) {
      message.warning("eNPS could not be calculated - valid NPS questions not found in template used for this campaign.", 3); // 3 seconds duration
    } else {
      handleNpsModalOpen();
    }
  };

  return (
    <>
      <OverviewSection />
      {/* show progress modal only when viewing single runs */}
      {/* show overview graphs when viewing overview page */}
      {campaignRunId != "Overview" ? (
        <>
          {progressModal}
        </>
      ) : (
        <>
          <ResponseRateOverTime data={getCampaignResultsQuery.data} />
          <br />
          <NumberTypeAggregations data={getCampaignResultsQuery.data} />
        </>
      )}
      <br />
      {tableArea}
      {npsModal}
    </>
  );
}
