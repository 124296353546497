import React from 'react';
import { Space, Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

const FishbowlTab = () => {
    return (
        <>
            <Space direction="vertical" style={{ width: "100%", textAlign: "left", alignItems: "flex-start" }}>
                <img 
                src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/home_header_graphics/fishbowl.png" 
                alt="Fishbowl Banner" 
                style={{ width: "100%", maxWidth: "600px", height: 'auto', borderRadius: '10px' }} 
                />
                <div style={{ padding: '10px 0' }}>
                    <h4>Hop on a video call and play through rounds of Taboo, Charades, and Password with your team in this fun real-time guessing game.</h4>
                </div>

                <img 
                src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/message_previews/fishbowl-explainer.png" 
                alt="Fishbowl Example" 
                style={{ width: "100%", maxWidth: "500px", height: 'auto', borderRadius: '10px' }} 
                />

                <Button shape="round" href="https://fishbowl.getculturebot.com/" target="_blank">
                    <PlayCircleOutlined /> Start a Game
                </Button>
            </Space>
        </>
    );
}

export default FishbowlTab; 