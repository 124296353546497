import { Space, Table, Popconfirm } from "antd";
import { useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import { useState } from "react";

export default function CBFormTable({ editClickedOnForm, cloneClickedOnForm, deleteClickedOnId }) {
  
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const columns = [
    {
      title: "Form name",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (_, record) => {
        return <div>{record.form_name}</div>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => editClickedOnForm(record, record.active_campaign)}>
            Edit
          </a>
          <a onClick={() => cloneClickedOnForm(record)}>Clone</a>
          {record.active_campaign ? (
            ""
          ) : (
            <Popconfirm
              title={
                <div>
                  Are you sure you want to delete this form?
                  <br />
                  You will not be able to undo this action.
                </div>
              }
              onConfirm={() =>
                deleteClickedOnId(record.id, record.active_campaign)
              }
              okText="Yes"
              cancelText="No"
            >
              <a>Delete</a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const getCBFormsQuery = useQuery(
    ["GET_CB_FORMS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getCBForms`);
    },
    { refetchOnWindowFocus: false }
  );

  var tableArea;
  if (getCBFormsQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (getCBFormsQuery.isError || getCBFormsQuery.data.error) {
    tableArea =
    "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getCBFormsQuery.data.cbForms.map((currentForm) => {
      return {
        id: currentForm.id,
        form_name: currentForm.form_name,
        form_items: currentForm.form_items,
        active_campaign: currentForm.form_has_active_campaign,
        item_type: currentForm.item_type,
        question_options: currentForm.question_options,
      };
    });
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    );
  }
  return tableArea;
}
