import React, { useState, useEffect } from "react";
import { Card, Button, Modal, message, Checkbox, Select, TimePicker, Input } from "antd";
import { CommentOutlined, RobotOutlined, EyeOutlined, EditOutlined, DeleteOutlined, RedoOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import { getBaseUrl, doPostRequest, doGetRequest } from "../../helpers/requestHelpers";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useTenantChannels, renderChannelOptions, mapChannels, mapChannelIdsToDisplayNames, mapChannelNamesToIds } from "./Shared/useTenantChannels";
import moment from "moment";

const WaterCooler = () => {

  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentInstance, setCurrentInstance] = useState(null);
  const [topics, setTopics] = useState([]);
  const [isPromptsModalVisible, setIsPromptsModalVisible] = useState(false);
  const [upcomingPrompts, setUpcomingPrompts] = useState([]);
  const [selectedInstanceChannelIdsToDisplayNames, setSelectedInstanceChannelIdsToDisplayNames] = useState([]);
  const [isPromptPackModalVisible, setIsPromptPackModalVisible] = useState(false);
  const [isAddPackModalVisible, setIsAddPackModalVisible] = useState(false);
  const [newPackName, setNewPackName] = useState('');
  const [isEditQuestionsModalVisible, setIsEditQuestionsModalVisible] = useState(false);
  const [currentPack, setCurrentPack] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [isAddQuestionModalVisible, setIsAddQuestionModalVisible] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newImageUrl, setNewImageUrl] = useState('');
  const [currentQuestionId, setCurrentQuestionId] = useState(null);

   // gets all tenant's channels across all teams
   const getTenantChannelsQuery = useTenantChannels(setTokenErrorType);
   const channelOptions = renderChannelOptions(getTenantChannelsQuery?.data);
 
  // get all custom celebrations
  const getAllWaterCoolerInstancesQuery = useQuery(
    ["GET_ALL_WATER_COOLER_INSTANCES"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllWaterCoolerInstances`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  const getAllWaterCoolerPacksQuery = useQuery(
    ["GET_ALL_WATER_COOLER_PACKS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllWaterCoolerPacks`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await doGetRequest(`${getBaseUrl()}/api/getAllWaterCoolerTopics`);
        setTopics(response.water_cooler_topics || []);
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    };

    fetchTopics();
  }, []);

  const handleInputChange = (field, value) => {
    setCurrentInstance((prevInstance) => ({
      ...prevInstance,
      [field]: value,
    }));
  };

  const handleCreateInstance = async (isAi) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/createWaterCoolerInstance`,
        { isAi: isAi }
      );
      console.log('Instance created:', response);
      queryClient.invalidateQueries("GET_ALL_WATER_COOLER_INSTANCES");
      
      // Show modal after instance creation
      Modal.info({
        title: 'Instance Created',
        content: (
          <div>
            <p>An instance was added below. Please click 'edit' to configure it.</p>
          </div>
        ),
        onOk() {},
      });
    } catch (error) {
      console.error('Error creating instance:', error);
    }
  };

  const handleViewEditPrompts = async (instance) => {
    setCurrentInstance(instance);
    try {
      const response = await doGetRequest(
        `${getBaseUrl()}/api/getUpcomingWaterCoolerPrompts?waterCoolerInstanceId=${instance.id}`,
      );

      if (response.upcoming_prompts) {
        setUpcomingPrompts(response.upcoming_prompts);
        setIsPromptsModalVisible(true);
      } else {
        message.info(response);
      }
    } catch (error) {
      console.error('Error fetching upcoming prompts:', error);
    }
  };

  const showEditModal = (instance) => {
    setCurrentInstance(instance);
    setIsEditModalVisible(true);
  };
  
  useEffect(() => {

    // update channels in dropdown for current instance
    if (getTenantChannelsQuery.data && currentInstance) {
        const newChannelMap = mapChannels(getTenantChannelsQuery.data);
        const prepopulatedChannels = mapChannelIdsToDisplayNames(currentInstance.water_cooler_channels || [], newChannelMap);
        setSelectedInstanceChannelIdsToDisplayNames(prepopulatedChannels);
    }
  }, [currentInstance, getTenantChannelsQuery.data]);

  const formatHourOfDay = (hour) => {
    return hour.includes(':') ? hour : `${hour}:00`;
  };
  
  const handleEditOk = async () => {
    try {
      const payload = {
        waterCoolerInstanceId: currentInstance.id,
        waterCoolerOn: currentInstance.water_cooler_on,
        waterCoolerPackIds: currentInstance.water_cooler_pack_ids,
        waterCoolerHourOfDay: currentInstance.water_cooler_hour_of_day,
        waterCoolerDaysOfWeek: currentInstance.water_cooler_days_of_week,
        waterCoolerDaysRandomized: currentInstance.water_cooler_days_randomized,
        waterCoolerNotifyViaDm: currentInstance.water_cooler_notify_via_dm,
        waterCoolerFrequency: currentInstance.water_cooler_frequency,
        waterCoolerSendAsAdmin: currentInstance.water_cooler_send_as_admin,
        ...(currentInstance.ai_instance_type && { aiInstanceType: currentInstance.ai_instance_type }),
        ...(currentInstance.ai_topics && { aiTopics: currentInstance.ai_topics }),
      };

      if (currentInstance.water_cooler_channels) {
        const newChannelMap = mapChannels(getTenantChannelsQuery.data);
        const { channelIds, channelsTeamIds } = mapChannelNamesToIds(currentInstance.water_cooler_channels, newChannelMap);
        payload.waterCoolerChannels = channelIds;
        payload.waterCoolerChannelsTeamIds = channelsTeamIds;
      }

      // sometimes hour of day is not in HH:mm format, so we need to format it
      if (currentInstance.water_cooler_hour_of_day) {
        payload.waterCoolerHourOfDay = formatHourOfDay(currentInstance.water_cooler_hour_of_day);
      }

      await doPostRequest(`${getBaseUrl()}/api/updateWaterCoolerInstance`, payload);
      message.success("Instance updated successfully!");
      queryClient.invalidateQueries("GET_ALL_WATER_COOLER_INSTANCES");
      setIsEditModalVisible(false);
    } catch (error) {
      message.error("An error occurred while updating the instance.");
      console.error('Error updating instance:', error);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleDelete = (instance) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this instance? This action is irreversible.',
      onOk: async () => {
        try {
          await doPostRequest(
            `${getBaseUrl()}/api/deleteWaterCoolerInstance`,
            { waterCoolerInstanceId: instance.id }
          );
          message.success("Instance deleted successfully!");
          queryClient.invalidateQueries("GET_ALL_WATER_COOLER_INSTANCES");
        } catch (error) {
          message.error(error.error || "An error occurred while deleting the instance.");
        }
      },
    });
  };

  const handlePromptPackModalOpen = () => {
    setIsPromptPackModalVisible(true);
  };

  const handlePromptPackModalClose = () => {
    setIsPromptPackModalVisible(false);
  };

  const handleAddPack = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/createWaterCoolerPack`,
        { waterCoolerPackName: newPackName }
      );
      message.success("Pack added successfully!");
      queryClient.invalidateQueries("GET_ALL_WATER_COOLER_INSTANCES");
      setIsAddPackModalVisible(false);
    } catch (error) {
      message.error("An error occurred while adding the pack.");
      console.error('Error adding pack:', error);
    }
  };

  const fetchAllPromptsForPack = async (packId) => {
    try {
      const response = await doGetRequest(`${getBaseUrl()}/api/getAllPromptsForPack?waterCoolerPackId=${packId}`);
      setQuestions(response.prompts || []);
    } catch (error) {
      console.error('Error fetching all prompts for pack:', error);
    }
  };

  const handleEditPack = (pack) => {
    setCurrentPack(pack);
    fetchAllPromptsForPack(pack.id);
    setIsEditQuestionsModalVisible(true);
  };

  const handleUpsertQuestion = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/upsertQuestionInPromptPack`,
        { 
          waterCoolerPackId: currentPack.id, 
          question: newQuestion,
          imageUrl: newImageUrl,
          existingId: currentQuestionId
        }
      );
      message.success("Question added successfully!");
      fetchAllPromptsForPack(currentPack.id);
      setIsAddQuestionModalVisible(false);
      setNewQuestion('');
      setNewImageUrl('');
    } catch (error) {
      message.error(error.message);
      console.log('Error adding question:', error.message);
    }
  };

  const handleDeletePack = (pack) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this pack? This action is irreversible.',
      onOk: async () => {
        try {
          await doPostRequest(
            `${getBaseUrl()}/api/deleteWaterCoolerPack`,
            { waterCoolerPackId: pack.id }
          );
          message.success("Pack deleted successfully!");
          queryClient.invalidateQueries("GET_ALL_WATER_COOLER_PACKS");
        } catch (error) {
          message.error("An error occurred while deleting the pack.");
          console.error('Error deleting pack:', error);
        }
      },
    });
  };

  const handleEditQuestion = (question) => {
    setCurrentQuestionId(question.prompt_id);
    setNewQuestion(question.description);
    setNewImageUrl(question.image || '');
    setIsAddQuestionModalVisible(true);
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/deleteQuestionFromPromptPack`,
        { questionId }
      );
      message.success("Question deleted successfully!");
      fetchAllPromptsForPack(currentPack.id);
    } catch (error) {
      message.error("An error occurred while deleting the question.");
      console.error('Error deleting question:', error);
    }
  };

  const handleAddNewQuestion = () => {
    setNewQuestion('');
    setNewImageUrl('');
    setCurrentQuestionId(null);
    setIsAddQuestionModalVisible(true);
  };

  const handleSkipAndFill = async (index) => {
    try {
      // Call the API to skip the current prompt
      await doPostRequest(
        `${getBaseUrl()}/api/skipUpcomingWaterCoolerPrompt`,
        { promptIndex: index, waterCoolerInstanceId: currentInstance.id }
      );

      // Refresh the list of prompts
      handleViewEditPrompts(currentInstance);
    } catch (error) {
      console.error('Error skipping and filling prompt:', error);
      message.error("An error occurred while skipping the prompt.");
    }
  };

  const handleMovePrompt = async (index, direction) => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/moveUpcomingWaterCoolerPrompt`,
        { promptIndex: index, direction, waterCoolerInstanceId: currentInstance.id }
      );
  
      // Refresh the list of prompts
      handleViewEditPrompts(currentInstance);

    } catch (error) {
      console.error('Error moving prompt:', error);
      message.error("An error occurred while moving the prompt.");
    }
  };

  const handleMoveUp = (index) => {
    if (index > 0) {
      handleMovePrompt(index, 'up');
    }
  };
  
  const handleMoveDown = (index) => {
    if (index < upcomingPrompts.length - 1) {
      handleMovePrompt(index, 'down');
    }
  };

  console.log('currentInstance', currentInstance);

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">
          <img
            src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/home_header_graphics%2Fwater-cooler.png"
            alt="Water Cooler Banner"
            style={{ width: "100%", maxWidth: "600px", height: "auto", borderRadius: "10px" }}
          />
          <div style={{ padding: "10px 0" }}>
            <h4>
              Click below to add a water cooler instance - choose from either
              classic (pick from our preset topic categories) or AI-generated
              (name your own topics). After clicking, an instance will appear
              below - click 'edit/delete' to complete the setup of your water
              cooler.
            </h4>
          </div>
          <div>
            <h5>Create Instance for Channel(s)</h5>
            <Button shape="round" icon={<CommentOutlined />} style={{ marginRight: '4px' }} onClick={() => handleCreateInstance(false)}>
              Add Instance (Classic)
            </Button>
            <Button shape="round" icon={<RobotOutlined />} style={{ marginRight: '4px' }} onClick={() => handleCreateInstance(true)}>
              Add Instance (AI)
            </Button>
          </div>
          <div style={{ marginTop: '20px' }}>
            <h5>Additional Prompts</h5>
            <Button
              icon={<EditOutlined />}
              shape="round"
              onClick={handlePromptPackModalOpen}
            >
              View/Add Custom Packs
            </Button>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
            {getAllWaterCoolerInstancesQuery.data?.water_cooler_instances.map((instance, index) => (
              <Card 
                key={index} 
                style={{ width: '45%', margin: '10px' }}
                title={instance.is_ai_instance ? "AI Water Cooler Instance" : "Water Cooler Instance"}
              >
                <div>
                  <strong>Topics:</strong> {instance.is_ai_instance 
                    ? (instance.ai_topics && instance.ai_topics.length > 0 
                      ? instance.ai_topics.join(', ') 
                      : '⚠️ Not configured') 
                    : (instance.topic_names.length > 0 
                      ? instance.topic_names.join(', ') 
                      : '⚠️ Not configured')}<br />
                  <strong>Days:</strong> {instance.water_cooler_days_of_week.length > 0 ? instance.water_cooler_days_of_week.join(', ') : '⚠️ Not configured'}<br />
                  <strong>Hour:</strong> {instance.water_cooler_hour_of_day ? 
                    moment.tz(
                      `1970-01-01T${instance.water_cooler_hour_of_day.includes(':') ? instance.water_cooler_hour_of_day : instance.water_cooler_hour_of_day.padEnd(5, ':00')}`,
                      "HH:mm"
                    ).tz(moment.tz.guess()).format("hh:mm A z") 
                    : '⚠️ Not configured'}<br />
                  <strong>Frequency:</strong> {instance.water_cooler_frequency || '⚠️ Not configured'}<br />
                  <strong>Direct Reminders:</strong> {instance.water_cooler_notify_via_dm !== undefined ? (instance.water_cooler_notify_via_dm ? "Yes" : "No") : '⚠️ Not configured'}<br />
                  <strong>Creation Date:</strong> {new Date(instance.created_at * 1000).toLocaleDateString()}
                </div>
                <div>
                  <strong>Status:</strong> {instance.water_cooler_on ? "On" : "Off"}
                </div>
                <div style={{ marginTop: '10px' }}>
                  <Button shape="round" style={{ marginRight: '4px' }} onClick={() => handleViewEditPrompts(instance)}>
                    <EyeOutlined /> View Prompts
                  </Button>
                  <Button shape="round" style={{ marginRight: '4px' }} onClick={() => showEditModal(instance)}>
                    <EditOutlined /> Edit
                  </Button>
                  <Button shape="round" onClick={() => handleDelete(instance)} danger>
                    <DeleteOutlined /> Delete
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        </Card>
      </div>

      <Modal
        title={currentInstance?.is_ai_instance ? "Edit AI Water Cooler Instance" : "Edit Water Cooler Instance"}
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
      >
        {currentInstance && (
          <div>
            <Checkbox
              defaultChecked={currentInstance.water_cooler_on}
              onChange={(e) => handleInputChange('water_cooler_on', e.target.checked)}
            >
              Turn Water Cooler On/Off
            </Checkbox>
            
            <label style={{ marginTop: '10px', display: 'block' }}>Channel(s) to send messages to</label>
            <Select
              mode="multiple"
              placeholder="Select Channel(s)"
              value={selectedInstanceChannelIdsToDisplayNames}
              onChange={(value) => handleInputChange('water_cooler_channels', value)}
              style={{ width: '100%', marginTop: '5px' }}
              loading={!channelOptions}
              filterOption={(input, option) => {
                const optionText = option.children ? option.children.toString().toLowerCase() : '';
                return optionText.includes(input.toLowerCase());
              }}
            >
              {channelOptions}
            </Select>
            
            {currentInstance.is_ai_instance && (
              <>
                <label style={{ marginTop: '10px', display: 'block' }}>Select AI water cooler type</label>
                <Select
                  placeholder="Select AI Water Cooler Type"
                  value={currentInstance.ai_instance_type || "CUSTOM_TOPICS"}
                  onChange={(value) => handleInputChange('ai_instance_type', value)}
                  style={{ width: '100%', marginTop: '5px' }}
                >
                  <Select.Option value="CULTUREBOT_AI_RECENT_NEWS">Recent News Events</Select.Option>
                  <Select.Option value="CUSTOM_TOPICS">Custom Topics (Self-Added)</Select.Option>
                </Select>
                {currentInstance.ai_instance_type == "CULTUREBOT_AI_RECENT_NEWS" && (
                  <small style={{ display: 'block', marginTop: '5px', color: 'gray' }}>
                    💡 All recent news events are meant to be safe for work (we automatically remove any political, violent, or divisive content).
                  </small>
                )}
                {currentInstance.ai_instance_type == "CUSTOM_TOPICS" && (
                  <>
                    <label style={{ marginTop: '10px', display: 'block' }}>Topics (separate by hitting 'enter')</label>
                    <small style={{ display: 'block', marginTop: '5px', color: 'gray' }}>
                      We recommend no more than 2 to 3 topics per AI water cooler instance
                    </small>
                    <Select
                      mode="tags"
                      placeholder="Enter topics and press 'Enter'"
                      value={currentInstance.ai_topics || []}
                      onChange={(value) => handleInputChange('ai_topics', value)}
                      style={{ width: '100%', marginTop: '5px' }}
                    />
                    <small style={{ display: 'block', marginTop: '5px', color: 'gray' }}>
                      <strong>Current Topics:</strong> {currentInstance.ai_topics ? currentInstance.ai_topics.join(', ') : 'No topics set'}
                    </small>
                  </>
                )}
              </>
            )}
            
            {!currentInstance.is_ai_instance && (
              <>
                <label style={{ marginTop: '10px', display: 'block' }}>Select the topics for water cooler</label>
                <Select
                  mode="multiple"
                  placeholder="Select Topics"
                  value={currentInstance.water_cooler_pack_ids}
                  onChange={(value) => handleInputChange('water_cooler_pack_ids', value)}
                  style={{ width: '100%', marginTop: '5px' }}
                >
                  {topics.map((topic) => (
                    <Select.Option key={topic.id} value={topic.id}>
                      {topic.pack_name} ({topic.count_per_topic} topics)
                    </Select.Option>
                  ))}
                </Select>
                <small style={{ display: 'block', marginTop: '5px', color: 'gray' }}>
                  Note: changing the topics above won't change the next 6 pre-scheduled prompts. To modify upcoming prompts, click 'View/edit upcoming prompts'.
                </small>
              </>
            )}

            <label style={{ marginTop: '10px', display: 'block' }}>Select day(s) of week to send</label>
            <Select
              mode="multiple"
              placeholder="Select Day(s) of Week"
              value={currentInstance.water_cooler_days_of_week}
              onChange={(value) => handleInputChange('water_cooler_days_of_week', value)}
              style={{ width: '100%', marginTop: '5px' }}
            >
              <Select.Option value="Monday">Monday</Select.Option>
              <Select.Option value="Tuesday">Tuesday</Select.Option>
              <Select.Option value="Wednesday">Wednesday</Select.Option>
              <Select.Option value="Thursday">Thursday</Select.Option>
              <Select.Option value="Friday">Friday</Select.Option>
              <Select.Option value="Saturday">Saturday</Select.Option>
              <Select.Option value="Sunday">Sunday</Select.Option>
              <Select.Option value="Random Weekday">Random Weekday</Select.Option>
            </Select>

            <label style={{ marginTop: '10px', display: 'block' }}>Select frequency to send</label>
            <Select
              placeholder="Select Frequency"
              value={currentInstance.water_cooler_frequency}
              onChange={(value) => handleInputChange('water_cooler_frequency', value)}
              style={{ width: '100%', marginTop: '5px' }}
            >
              <Select.Option value="1">Every Week</Select.Option>
              <Select.Option value="2">Every 2 Weeks</Select.Option>
              <Select.Option value="3">Every 3 Weeks</Select.Option>
              <Select.Option value="4">Every 4 Weeks</Select.Option>
            </Select>

            <label style={{ marginTop: '10px', display: 'block' }}>Select hour of day to send</label>
            <TimePicker
              format="hh:mm A"
              minuteStep={30}
              use12Hours
              style={{ width: '100%', marginTop: '5px' }}
              placeholder="Select Hour of Day"
              value={currentInstance.water_cooler_hour_of_day ? moment.utc(currentInstance.water_cooler_hour_of_day, "HH:mm").local() : null}
              onChange={(time) => handleInputChange('water_cooler_hour_of_day', time ? time.utc().format("HH:mm") : null)}
            />
            <div style={{ display: 'block' }}>
                <Checkbox
                defaultChecked={currentInstance.water_cooler_notify_via_dm}
                style={{ marginTop: '10px' }}
                onChange={(e) => handleInputChange('water_cooler_notify_via_dm', e.target.checked)}
                >
                Send a reminder (via direct message) to teammates who are in the channel above whenever a new topic has been sent
                </Checkbox>
                <Checkbox
                defaultChecked={true}
                style={{ marginTop: '10px', marginLeft: '0' }}
                onChange={(e) => handleInputChange('water_cooler_days_randomized', e.target.checked)}
                >
                Randomly choose someone (who is also in the selected channel) to kickstart the conversation whenever a new topic has been sent
                </Checkbox>
                <Checkbox
                defaultChecked={currentInstance.send_as_admin}
                style={{ marginTop: '10px', marginLeft: '0' }}
                onChange={(e) => handleInputChange('water_cooler_send_as_admin', e.target.checked)}
                >
                Send message as the main CultureBot admin instead of as CultureBot
                </Checkbox>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Upcoming Prompts"
        visible={isPromptsModalVisible}
        onOk={() => setIsPromptsModalVisible(false)}
        onCancel={() => setIsPromptsModalVisible(false)}
      >
        {currentInstance && (
          <>
            {((!currentInstance.topic_names.length && !currentInstance.ai_topics) ||
              !currentInstance.water_cooler_days_of_week.length ||
              !currentInstance.water_cooler_hour_of_day ||
              !currentInstance.water_cooler_frequency) ? (
              <p>
                <strong>⚠️ No upcoming prompts can be shown.</strong>
                <br/><br/>
                Check to make sure you have the instance fully configured - including the day of week, hour of day, and topics. <br/><br/>If you do have the instance fully configured, it's possible you may have selected custom topics, in which case you will need to either add additional custom prompts (from the 'View/Add Prompts' button) or choose additional topic categories (from the 'Edit' button).
              </p>
            ) : (
              <>
                <p><strong>Below are the next 6 upcoming conversation starter prompts:</strong></p>
                {currentInstance.ai_topics.length > 0 && (
                  <p style={{ color: 'red', fontSize: '12px', fontStyle: 'italic' }}>
                    If you find any AI-generated content objectionable, please contact us at support@getculturebot.com.
                  </p>
                )}
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {upcomingPrompts.map((prompt, index) => (
                    <li key={index}>
                      <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <strong>Prompt {index + 1}:</strong> <br />
                          <strong>Topic:</strong> {prompt.topic || (prompt.ai_topic && `${prompt.ai_topic} (🤖 AI-generated)`)} <br />
                          <strong>Description:</strong> {prompt.description}
                        </div>
                        {prompt.image && (
                          <img src={prompt.image} alt="Prompt" style={{ width: '100px', height: 'auto', marginLeft: '10px' }} />
                        )}
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '5px' }}>
                        <Button size="small" shape="round" onClick={() => handleSkipAndFill(index)}><RedoOutlined /> Skip & Fill with New</Button>
                        <Button size="small" shape="round" onClick={() => handleMoveUp(index)} disabled={index === 0}><ArrowUpOutlined /> Move Up</Button>
                        <Button size="small" shape="round" onClick={() => handleMoveDown(index)} disabled={index === upcomingPrompts.length - 1}><ArrowDownOutlined /> Move Down</Button>
                      </div>
                      <hr style={{ border: '1px solid #eee' }} />
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </Modal>

      <Modal
        title="Manage Custom Prompt Packs"
        visible={isPromptPackModalVisible}
        footer={[
          <Button key="close" onClick={handlePromptPackModalClose}>
            Close
          </Button>,
          <Button key="add" type="primary" onClick={() => setIsAddPackModalVisible(true)}>
            Add New Pack
          </Button>,
        ]}
        onCancel={handlePromptPackModalClose}
      >
        <p>Custom water cooler packs allow you to create your own sets of water cooler questions – when you create a new water cooler instance you will be able to select from these packs you've added.
        </p>
        
        <div style={{ marginTop: '20px' }}>
          {getAllWaterCoolerPacksQuery.data?.water_cooler_packs.map((pack) => (
            <div key={pack.id} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span style={{ flexGrow: 1 }}><CommentOutlined /> {pack.water_cooler_pack_name}</span>
              <div style={{ display: 'flex', gap: '4px' }}>
                <Button
                  shape="round"
                  onClick={() => handleEditPack(pack)}
                >
                  <EditOutlined /> Edit Questions
                </Button>
                <Button
                  shape="round"
                  danger
                  onClick={() => handleDeletePack(pack)}
                >
                  <DeleteOutlined /> Delete
                </Button>
              </div>
            </div>
          ))}
        </div>

        <Modal
          title="Add New Water Cooler Pack"
          visible={isAddPackModalVisible}
          onOk={handleAddPack}
          onCancel={() => setIsAddPackModalVisible(false)}
        >
          <Input
            placeholder="Enter pack name"
            value={newPackName}
            onChange={(e) => setNewPackName(e.target.value)}
          />
        </Modal>
      </Modal>

      <Modal
        title={`Edit Questions in ${currentPack?.water_cooler_pack_name}`}
        visible={isEditQuestionsModalVisible}
        onCancel={() => setIsEditQuestionsModalVisible(false)}
        footer={null}
      >
        <div>
          {questions.length === 0 ? (
            <div>
              <p>This pack has no custom prompts – create one below by clicking 'Add Prompt'</p>
              <p><strong>💡 A few conversation/prompt ideas:</strong></p>
              <ul>
                <li>Ask your employees about their favorite something (e.g. team memory, company event, etc.)</li>
                <li>Ask your employees about something that has inspired them lately (e.g. why they joined the company, something they are excited for about the future of the product, etc.)</li>
                <li>Ask your employees about something that was surprising to them that they learned recently (e.g. perhaps about a certain role, department, business practice, etc.)</li>
              </ul>
            </div>
          ) : (
            <div>
              {questions.map((question, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                  <span><CommentOutlined /> {question.description}</span>
                  <div>
                    <Button
                      onClick={() => handleEditQuestion(question)}
                      shape="round"
                      style={{ marginRight: '8px' }}
                    >
                      Edit Prompt
                    </Button>
                    <Button
                      danger
                      shape="round"
                      onClick={() => handleDeleteQuestion(question.prompt_id)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button onClick={() => setIsEditQuestionsModalVisible(false)} style={{ marginRight: '8px' }}>
              Close
            </Button>
            <Button type="primary" onClick={handleAddNewQuestion}>
              Add New Question
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Add New Question"
        visible={isAddQuestionModalVisible}
        onOk={handleUpsertQuestion}
        onCancel={() => setIsAddQuestionModalVisible(false)}
      >
        <div>
          <label>Your Question</label>
          <Input
            placeholder="Enter your question"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <label>URL of Image/GIF to Include (Optional)</label>
          <Input
            placeholder="Enter image URL"
            value={newImageUrl}
            onChange={(e) => setNewImageUrl(e.target.value)}
          />
          <small style={{display: 'block', marginBottom: '10px'}}>
            You can upload external files to our <strong><a href="/app/graphics_library">graphics library</a></strong> and copy the URL from there
          </small>
        </div>
      </Modal>
    </MainContainer>
  );
};

export default WaterCooler;
