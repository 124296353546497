import React, { useState, useEffect } from "react";
import MainContainer from "../Container/MainContainer";
import { Card, Button, Checkbox, Modal, Select, Input, InputNumber, TimePicker, message } from "antd";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doPostRequest,
  doGetRequest,
} from "../../helpers/requestHelpers";
import {
  NotificationOutlined,
  SettingOutlined,
  CalendarOutlined,
  NumberOutlined,
  BarChartOutlined,
  GiftOutlined, 
  StarOutlined,
  MessageOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { useTenantUsers, renderUserOptions } from "./Shared/useTenantUsers";
import {
  useTenantChannels,
  renderChannelOptions,
  mapChannels
} from "./Shared/useTenantChannels";
import moment from "moment";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTeams } from "msteams-react-base-component";

const { Option } = Select;

const Shoutouts = () => {
  
  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [{ inTeams, theme, context }] = useTeams();

  // modals (on/off)
  const [isShoutoutDayModalVisible, setIsShoutoutDayModalVisible] = useState(false);
  const [isExampleModalVisible, setIsExampleModalVisible] = useState(false);
  const [isPointsRulesModalVisible, setIsPointsRulesModalVisible] = useState(false);
  const [isGivePointsModalVisible, setIsGivePointsModalVisible] = useState(false);
  const [isCelebrationRewardModalVisible, setIsCelebrationRewardModalVisible] = useState(false);
  const [isEditMessageModalVisible, setIsEditMessageModalVisible] = useState(false);
  const [isShoutoutMessageModalVisible, setIsShoutoutMessageModalVisible] = useState(false);
  const [isHowToShoutoutModalVisible, setIsHowToShoutoutModalVisible] = useState(false);

  // other vars (for modals)
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pointsToGive, setPointsToGive] = useState(0);
  const [rewardReason, setRewardReason] = useState("");
  const [birthdayPoints, setBirthdayPoints] = useState(0);
  const [anniversaryPoints, setAnniversaryPoints] = useState(0);
  const [redemptionMessage, setRedemptionMessage] = useState("");
  const [monthlyAllowance, setMonthlyAllowance] = useState(0);
  const [maxPointsPerShoutout, setMaxPointsPerShoutout] = useState(0);
  const [maxPointsPerDay, setMaxPointsPerDay] = useState(0);
  const [isShoutoutDayEnabled, setIsShoutoutDayEnabled] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [shoutoutTime, setShoutoutTime] = useState(null);
  const [shoutoutMessage, setShoutoutMessage] = useState("");
  const [remindTeammates, setRemindTeammates] = useState(false);
  const [reminderFrequency, setReminderFrequency] = useState("");

  // gets all shoutout settings
  const getTenantSettingsQuery = useQuery(
    ["GET_TENANT_SETTINGS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSettingsForTenant`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );
  const { data: tenantSettings } = getTenantSettingsQuery;

  // gets all shoutout stats for user
  const getShoutoutStatsQuery = useQuery(
    ["GET_SHOUTOUT_STATS_FOR_USER"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getShoutoutStatsForUser`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );
  const { data: shoutoutStats } = getShoutoutStatsQuery;

  // gets all tenant's users across all teams
  const getTenantUsersQuery = useTenantUsers(setTokenErrorType);
  const userOptions = renderUserOptions(getTenantUsersQuery);

  // gets all tenant's channels across all teams
  const getTenantChannelsQuery = useTenantChannels(setTokenErrorType);
  const channelOptions = renderChannelOptions(getTenantChannelsQuery?.data);

  const handleShoutoutDayModalOk = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/updateShoutoutSettings`,
        {
          shoutouts_prompt_on: isShoutoutDayEnabled,
          shoutouts_prompt_channel: selectedChannel,
          shoutout_day_frequency: frequency,
          shoutouts_prompt_days_of_week: selectedDays,
          shoutouts_prompt_hour_of_day: shoutoutTime ? shoutoutTime.utc().format("HH:mm") : null,
          shoutout_notify_via_dm: remindTeammates,
          shoutout_notify_via_dm_option: reminderFrequency
        },
        setTokenErrorType
      );

      // Show success message
      message.success("Shoutout Day settings updated successfully!");

      // Invalidate queries if needed
      queryClient.invalidateQueries(["GET_TENANT_SETTINGS"]);
      setIsShoutoutDayModalVisible(false);

    } catch (error) {
      console.error("Failed to update Shoutout Day settings", error);
      message.error(error.response?.data?.error || "An unexpected error occurred");
    }
  };

  const handlePointsRulesModalOk = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/updateShoutoutSettings`,
        {
          monthly_allowance: monthlyAllowance,
          max_points_per_shoutout: maxPointsPerShoutout,
          max_points_per_day: maxPointsPerDay
        },
        setTokenErrorType
      );

      // update local state
      setMonthlyAllowance(monthlyAllowance);
      setMaxPointsPerShoutout(maxPointsPerShoutout);
      setMaxPointsPerDay(maxPointsPerDay);

      // Show success message
      message.success("Points rules updated successfully!");

      // invalidate query
      queryClient.invalidateQueries(["GET_TENANT_SETTINGS"]);
      setIsPointsRulesModalVisible(false);

    } catch (error) {
      console.error("Failed to update points rules", error);
      message.error(error.response?.data?.error || "An unexpected error occurred");
    }
  };

  const handleGivePointsModalOk = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/giveShoutoutPoints`,
        {
          selected_users: selectedUsers,
          points_to_give: pointsToGive,
          reward_reason: rewardReason
        },
        setTokenErrorType
      );

      // Show success message
      message.success("Shoutout points given successfully!");

      // clear local state
      setSelectedUsers([]);
      setPointsToGive(0);
      setRewardReason("");

      // Optionally, update local state or invalidate queries if needed
      queryClient.invalidateQueries(["GET_TENANT_SETTINGS"]);
      setIsGivePointsModalVisible(false);

    } catch (error) {
      console.error("Failed to give shoutout points", error);
      message.error(error.response?.data?.error || "An unexpected error occurred");
    }
  };

  const handleCelebrationRewardModalOk = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/updateShoutoutSettings`,
        {
          birthday_points: birthdayPoints,
          anniversary_points: anniversaryPoints
        },
        setTokenErrorType
      );

      // update local state
      setBirthdayPoints(birthdayPoints);
      setAnniversaryPoints(anniversaryPoints);

      // Show success message
      message.success("Celebration rewards updated successfully!");

      // Invalidate queries
      queryClient.invalidateQueries(["GET_TENANT_SETTINGS"]);
      setIsCelebrationRewardModalVisible(false);

    } catch (error) {
      console.error("Failed to set celebration rewards", error);
      message.error(error.response?.data?.error || "An unexpected error occurred");
    }
  };

  const handleCheckboxChange = async (settingKey, checked) => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/updateShoutoutSettings`,
        { [settingKey]: checked },
        setTokenErrorType
      );
       
      // Show success message 
      message.success("Shoutout settings updated successfully!");

      queryClient.invalidateQueries(["GET_TENANT_SETTINGS"]);
      console.log(tenantSettings?.shoutouts_custom_shoutouts_only, tenantSettings?.shoutouts_notify_users, tenantSettings?.shoutouts_allow_anonymous);
      
    } catch (error) {
      console.error("Failed to update shoutout settings", error);
    }
  };

  const handleRedemptionMessageModalOk = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/updateShoutoutSettings`,
        { shoutout_custom_gift_redemption_message: redemptionMessage },
        setTokenErrorType
      );

      // Show success message
      message.success("Redemption message updated successfully!");

      // Update local state
      setRedemptionMessage(redemptionMessage);

      // Optionally, update local state or invalidate queries if needed
      queryClient.invalidateQueries(["GET_TENANT_SETTINGS"]);
      setIsEditMessageModalVisible(false);
      
    } catch (error) {
      console.error("Failed to update redemption message", error);
      message.error(error.response?.data?.error || "An unexpected error occurred");
    }
  };

  const handleShoutoutMessageModalOk = async () => {
    try {
      await doPostRequest(
        `${getBaseUrl()}/api/updateShoutoutSettings`,
        { shoutouts_message: shoutoutMessage },
        setTokenErrorType
      );

      // Show success message
      message.success("Shoutout message updated successfully!");

      // update local state
      setShoutoutMessage(shoutoutMessage);

      // Optionally, update local state or invalidate queries if needed
      queryClient.invalidateQueries(["GET_TENANT_SETTINGS"]);
      setIsShoutoutMessageModalVisible(false);

    } catch (error) {
      console.error("Failed to update shoutout message", error);
      message.error(error.response?.data?.error || "An unexpected error occurred");
    }
  };

  const handleGiveShoutoutClick = () => {
    if (inTeams) {
      setIsHowToShoutoutModalVisible(true);

      // Note: this could be used in the future to give a shoutout from the Teams app, but we're going to rely on the compose box extension alone for now 
      // (to avoid duplicative adaptive card code, see the endpoint below which will be used in the future to give a shoutout from the react app itself)
      // microsoftTeams.initialize(() => {
        
      //   const taskUrl = `${getBaseUrl()}/api/taskModuleContent`;
      //   console.log(taskUrl);

      //   microsoftTeams.tasks.startTask({
      //     title: "Give Shoutout",
      //     url: taskUrl,
      //     height: 'medium',
      //     width: 'medium'
      //   }, (err, result) => {
      //     if (err) {
      //       console.error("Error opening dialog:", err);
      //     }
      //   });
      // });

    } else {
      message.error("You must be in the Teams app to give a shoutout");
    }
  };
  
  // update local state with shoutout settings
  useEffect(() => {
    if (tenantSettings) {
      setMonthlyAllowance(tenantSettings?.monthly_allowance_points);
      setMaxPointsPerShoutout(tenantSettings?.points_cap);
      setMaxPointsPerDay(tenantSettings?.daily_points_cap);
      setBirthdayPoints(tenantSettings?.shoutout_points_for_bday);
      setAnniversaryPoints(tenantSettings?.shoutout_points_for_anniv);
      setRedemptionMessage(tenantSettings?.shoutout_custom_gift_redemption_message);
      setIsShoutoutDayEnabled(tenantSettings?.shoutouts_prompt_on);
      setSelectedChannel(tenantSettings?.shoutouts_prompt_channel);
      setFrequency(tenantSettings?.shoutout_day_frequency);
      setSelectedDays(tenantSettings?.shoutouts_prompt_days_of_week);
      if (tenantSettings?.shoutouts_prompt_hour_of_day) {
        setShoutoutTime(moment.utc(tenantSettings?.shoutouts_prompt_hour_of_day, "HH:mm").local());
      }
      setShoutoutMessage(tenantSettings?.shoutouts_message);
      setRemindTeammates(tenantSettings?.shoutout_notify_via_dm);
      setReminderFrequency(tenantSettings?.shoutout_notify_via_dm_option);
    }
  }, [tenantSettings]);

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">

          <img
            src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/home_header_graphics%2Fshoutouts.png"
            alt="ShoutoutsBanner"
            style={{ width: "100%", maxWidth: "600px", height: "auto", borderRadius: "10px" }}
          />
          <div style={{ padding: "10px 0" }}>
            <h4>
              Use shoutouts to share positive feedback with your team. Setup a
              'shoutout day' to encourage your team to send positive feedback on
              a regular basis. Optionally, setup a monthly points allowance to
              allow employees to add points to shoutouts, which they can then
              redeem for gift cards and other custom gifts you've setup.
            </h4>
          </div>

          {/* shoutout example */}
          <Button 
            shape="round" 
            icon={<NotificationOutlined />} 
            onClick={handleGiveShoutoutClick}
          >
            Give Shoutout
          </Button>
          <Button
            type="link"
            onClick={() => setIsExampleModalVisible(true)}
          >
            Shoutout Example
          </Button>
          <p style={{ marginTop: "10px", fontStyle: "italic" }}>
            (Points left to give this month: {shoutoutStats?.points_left_to_give} | 
            Total points given: {shoutoutStats?.total_points_given} | 
            Total shoutouts given: {shoutoutStats?.total_shoutouts_given})
          </p>
          <Modal
            title="Shoutout Example"
            visible={isExampleModalVisible}
            onOk={() => setIsExampleModalVisible(false)}
            onCancel={() => setIsExampleModalVisible(false)}
          >
            <img src="https://graphics.getculturebot.com/message_previews/gifs/shoutout-small.gif" alt="Shoutout Example" style={{ width: "100%", height: "auto" }} />
          </Modal>

          <Modal
            title="How to Send a Shoutout"
            visible={isHowToShoutoutModalVisible}
            onOk={() => setIsHowToShoutoutModalVisible(false)}
            onCancel={() => setIsHowToShoutoutModalVisible(false)}
          >
            <p>To send a shoutout, visit the compose message box where you can choose 'CultureBot' as the app to send the shoutout from in the menu that appears.</p>
            <img
              src="https://graphics.getculturebot.com/other/where-is-compose-box-teams.png"
              alt="How to Send a Shoutout"
              style={{ width: "100%", height: "auto" }}
            />
          </Modal>

          {/* shoutout section */}
          <div style={{ marginTop: "20px" }}>
            <h3>Configure Shoutouts</h3>
            <Button
              shape="round"
              icon={<SettingOutlined />}
              onClick={() => window.location.href = "/app/custom_shoutouts"}
              style={{ marginTop: "10px" }}
            >
              Manage Custom Types
            </Button>
            <Button
              shape="round"
              icon={<CalendarOutlined />}
              style={{ marginLeft: "10px" }}
              onClick={() => setIsShoutoutDayModalVisible(true)}
            >
              Edit/Disable Shoutout Day
            </Button>
            <Modal
              title="Edit/Disable Shoutout Day"
              visible={isShoutoutDayModalVisible}
              onOk={handleShoutoutDayModalOk}
              onCancel={() => setIsShoutoutDayModalVisible(false)}
            >
              <p>Shoutout days are days of the week where employees are encouraged to send shoutouts to each other. To enable shoutout days, check the box below.</p>
              
              <Checkbox
                checked={isShoutoutDayEnabled}
                onChange={(e) => setIsShoutoutDayEnabled(e.target.checked)}
              >
                📅 Click to turn on Shoutouts Day (a day to encourage the sending of shoutouts)
              </Checkbox>

              {isShoutoutDayEnabled && (
                <>

                  <br/><br/>

                  <label>Pick a channel to use for shoutout day</label>
                  <Select
                    showSearch
                    placeholder="Select a channel"
                    style={{ width: "100%", marginBottom: "10px" }}
                    value={selectedChannel}
                    onChange={setSelectedChannel}
                    loading={!channelOptions}
                    filterOption={(input, option) => {
                      const optionText = option.children ? option.children.toString().toLowerCase() : '';
                      return optionText.includes(input.toLowerCase());
                    }}
                  >
                    {channelOptions}
                  </Select>

                  <label>Select the day(s) of week to send a shoutout prompt</label>
                  <Select
                    mode="multiple"
                    placeholder="Select day(s)"
                    style={{ width: "100%", marginBottom: "10px" }}
                    value={selectedDays}
                    onChange={setSelectedDays}
                  >
                    <Option value="Monday">Monday</Option>
                    <Option value="Tuesday">Tuesday</Option>
                    <Option value="Wednesday">Wednesday</Option>
                    <Option value="Thursday">Thursday</Option>
                    <Option value="Friday">Friday</Option>
                    <Option value="Saturday">Saturday</Option>
                    <Option value="Sunday">Sunday</Option>
                  </Select>

                  <label>Select how often to run shoutout day</label>
                  <Select
                    placeholder="Select frequency"
                    style={{ width: "100%" }}
                    value={frequency}
                    onChange={setFrequency}
                  >
                    <Option value="1">Every Week</Option>
                    <Option value="2">Every 2 Weeks</Option>
                    <Option value="3">Every 3 Weeks</Option>
                    <Option value="4">Every 4 Weeks</Option>
                  </Select>

                  <div style={{ marginTop: "20px" }}>
                    <h4>🚀 Shoutout Day Kickoff Message</h4>
                    <p>Hour of day (beginning of work day suggested)</p>
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      minuteStep={30}
                      style={{ width: "200px", display: "block", marginBottom: "10px" }}
                      value={shoutoutTime}
                      onChange={setShoutoutTime}
                    />

                    <p>Edit the shoutout reminder message that gets sent</p>
                    <Button
                      onClick={() => setIsShoutoutMessageModalVisible(true)}
                    >
                      Edit Message
                    </Button>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <Checkbox
                      checked={remindTeammates}
                      onChange={(e) => setRemindTeammates(e.target.checked)}
                    >
                      🎗️ Remind teammates via direct message
                    </Checkbox>

                    {remindTeammates && (
                      <>
                        <p style={{ marginTop: "10px" }}>Send a shoutout day kickoff reminder (via direct message) to everyone in the channel above who hasn't sent a shoutout in:</p>
                        <Select
                          placeholder="Select reminder frequency"
                          style={{ width: "100%" }}
                          value={reminderFrequency}
                          onChange={setReminderFrequency}
                        >
                          <Option value="2">2 weeks</Option>
                          <Option value="3">4 weeks</Option>
                          <Option value="8">8 weeks</Option>
                          <Option value="12">12 weeks</Option>
                          <Option value="never_sent">Never sent a shoutout</Option>
                        </Select>
                      </>
                    )}
                  </div>
                </>
              )}
            </Modal>

            <Modal
              title="Edit Shoutout Message"
              visible={isShoutoutMessageModalVisible}
              onOk={handleShoutoutMessageModalOk}
              onCancel={() => setIsShoutoutMessageModalVisible(false)}
            >
              <Input.TextArea
                rows={4}
                value={shoutoutMessage}
                onChange={(e) => setShoutoutMessage(e.target.value)}
                placeholder="Type your message here"
              />
            </Modal>

            <div style={{ marginTop: "20px" }}>
              <Checkbox
                checked={tenantSettings?.shoutouts_custom_shoutouts_only}
                onChange={(e) => handleCheckboxChange("shoutouts_custom_shoutouts_only", e.target.checked)}
              >
                Only show custom shoutouts
              </Checkbox>
              <br/>
              <Checkbox
                checked={tenantSettings?.shoutouts_notify_users}
                onChange={(e) => handleCheckboxChange("shoutouts_notify_users", e.target.checked)}
              >
                Notify users via DM when they receive a shoutout
              </Checkbox>
              <br/>
              <Checkbox
                checked={tenantSettings?.shoutouts_allow_anonymous}
                onChange={(e) => handleCheckboxChange("shoutouts_allow_anonymous", e.target.checked)}
              >
                Allow shoutouts to be sent anonymously
              </Checkbox>
            </div>
          </div>

          {/* peer rewards section */}
          <div style={{ marginTop: "20px" }}>
            <h3>Configure Peer Rewards (Points & Gifts)</h3>
            <p>Shoutout points rules (e.g. monthly allowance, per shoutout maximums, daily caps, etc.)</p>
            <Button
              shape="round"
              icon={<NumberOutlined />}
              onClick={() => setIsPointsRulesModalVisible(true)}
              style={{ marginBottom: "10px" }}
            >
              Points Rules
            </Button>
            <p>View this month's shoutout point leaders</p>
            <Button
              shape="round"
              icon={<BarChartOutlined />}
              style={{ marginBottom: "10px" }}
              onClick={() => window.location.href = "/app/shoutout_stats"}
            >
              View Leaderboard
            </Button>
            <p>View and manage the peer rewards catalog</p>
            <p style={{ marginTop: "-10px", fontStyle: "italic" }}>
              (Your redeemable points: {shoutoutStats?.redeemable_points} | 
              Total points received: {shoutoutStats?.total_points_received} | 
              Total shoutouts received: {shoutoutStats?.total_shoutouts_received})
            </p>
            <Button
              shape="round"
              icon={<GiftOutlined />}
              style={{ marginBottom: "10px" }}
              onClick={() => window.location.href = "/app/rewards"}
            >
              View/Add Rewards
            </Button>
            <p>Manually give out points to an employee without sending a shoutout</p>
            <Button
              shape="round"
              icon={<PlusOutlined />}
              style={{ marginBottom: "10px" }}
              onClick={() => setIsGivePointsModalVisible(true)}
            >
              Give Points
            </Button>
            <p>Automatically give out points (redeemable for gifts) to an employee on their birthday or work anniversary</p>
            <Button
              shape="round"
              icon={<StarOutlined />}
              style={{ marginBottom: "10px" }}
              onClick={() => setIsCelebrationRewardModalVisible(true)}
            >
              Set Celebration Reward
            </Button>
            <p>Edit the message that gets sent after approving a custom gift</p>
            <Button
              shape="round"
              icon={<MessageOutlined />}
              style={{ marginBottom: "10px" }}
              onClick={() => setIsEditMessageModalVisible(true)}
            >
              Edit Message
            </Button>

            {/* modals */}
            <Modal
              title="Points Rules"
              visible={isPointsRulesModalVisible}
              onOk={handlePointsRulesModalOk}
              onCancel={() => setIsPointsRulesModalVisible(false)}
            >
              <p>Points rules content goes here...</p>
            </Modal>

            <Modal
              title="Give Shoutout Points"
              visible={isGivePointsModalVisible}
              onOk={handleGivePointsModalOk}
              onCancel={() => setIsGivePointsModalVisible(false)}
            >
              <p>Give one or multiple employees extra shoutout points to recognize them for a specific reason. Points can be redeemed for gifts (that you've setup inside CultureBot).</p>
              
              <label>Select users to give points to</label>
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Users"
                style={{ width: "100%", marginBottom: '10px' }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(users) => setSelectedUsers(users)}
              >
                {userOptions}
              </Select>
              
              <label>Points to give</label>
              <InputNumber
                min={1}
                step={1} // ensure only whole numbers
                style={{ width: '100%', marginBottom: '10px' }}
                placeholder="Type in a positive number"
                onChange={setPointsToGive}
              />
              
              <label>Reason for reward</label>
              <Input
                placeholder="Type in a reason for your reward"
                onChange={(e) => setRewardReason(e.target.value)}
              />
            </Modal>

            <Modal
              title="Set Celebration Reward"
              visible={isCelebrationRewardModalVisible}
              onOk={handleCelebrationRewardModalOk}
              onCancel={() => setIsCelebrationRewardModalVisible(false)}
            >
              <p>Automate a certain number of points to be awarded to employees on their birthday and/or work anniversary. Points are instantly redeemable for gifts (that you've setup inside CultureBot).</p>
              <p>If values are entered below, employees will be notified via Slack direct message on their birthday or anniversary of the gift amount and how to use it. To disable rewards, make each field below '0'.</p>
              
              <label>Points to give on birthday</label>
              <InputNumber
                min={0}
                style={{ width: '100%', marginBottom: '10px' }}
                value={birthdayPoints}
                onChange={setBirthdayPoints}
              />
              
              <label>Points to give on anniversary</label>
              <InputNumber
                min={0}
                style={{ width: '100%', marginBottom: '10px' }}
                value={anniversaryPoints}
                onChange={setAnniversaryPoints}
              />
            </Modal>

            <Modal
              title="Edit Message"
              visible={isEditMessageModalVisible}
              onOk={() => setIsEditMessageModalVisible(false)}
              onCancel={() => setIsEditMessageModalVisible(false)}
            >
              <p>Edit message content goes here...</p>
            </Modal>

            <Modal
              title="Redemption Message"
              visible={isEditMessageModalVisible}
              onOk={handleRedemptionMessageModalOk}
              onCancel={() => setIsEditMessageModalVisible(false)}
            >
              <p>Write below the message that will get shared to an employee after their custom gift redemption request is approved.</p>
              
              <Input.TextArea
                rows={4}
                value={redemptionMessage}
                onChange={(e) => setRedemptionMessage(e.target.value)}
                placeholder="Type your message here"
              />
            </Modal>

            <Modal
              title="Monthly Points Allowance"
              visible={isPointsRulesModalVisible}
              onOk={handlePointsRulesModalOk}
              onCancel={() => setIsPointsRulesModalVisible(false)}
            >
              <p>The monthly points allowance is the number of points an employee has per month to give away as a peer bonus to others.</p>
              <ul>
                <li>We suggest starting with 100 (typically around 60% of this allowance is used).</li>
                <li>Set all values below to 0 to remove any mention of points and peer rewards in shoutouts.</li>
              </ul>
              
              <label>Monthly Points Allowance (optional)</label>
              <InputNumber
                min={0}
                step={1} // ensure only whole numbers
                style={{ width: '100%', marginBottom: '10px' }}
                value={monthlyAllowance}
                onChange={setMonthlyAllowance}
              />
              
              <label>Max Points Per Shoutout (optional)</label>
              <InputNumber
                min={0}
                step={1} // ensure only whole numbers
                style={{ width: '100%', marginBottom: '10px' }}
                value={maxPointsPerShoutout}
                onChange={setMaxPointsPerShoutout}
              />
              
              <label>Max Points Per Day (optional)</label>
              <InputNumber 
                min={0}
                step={1} // ensure only whole numbers
                style={{ width: '100%', marginBottom: '10px' }}
                value={maxPointsPerDay}
                onChange={setMaxPointsPerDay}
              />
              
              <Checkbox
                checked={tenantSettings?.points_required}
                onChange={(e) => handleCheckboxChange("require_points", e.target.checked)}
              >
                Click to require employees to add points when sending a shoutout
              </Checkbox>
              
            </Modal>
          </div>

        </Card>
      </div>
    </MainContainer>
  );
};

export default Shoutouts;
