import React from "react";
import { Card, Space } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import "./LoginPage.scss";
import cbLogoBlue from "../../images/logo-blue.png";
import { PublicClientApplication } from "@azure/msal-browser";
import { useTeams } from "msteams-react-base-component";
import { app, authentication } from "@microsoft/teams-js";

export default function LoginPage() {
    
  const [searchParams] = useSearchParams();
  const [{ inTeams, theme, context }] = useTeams();
  const [authErrorLoggingIn, setAuthErrorLoggingIn] = useState(false);
  const [adminConsentUrl, setAdminConsentUrl] = useState("");
  
  // used for correctly adding link to login button
  let loginUrl = "";
  let teamsLoginUrlFromApp = "";
  let teamsLoginUrlFromBrowser = "";
  let authAPIUrl = "";
  let teamsClientId = "";
  if (
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("test.getculturebot") || window.location.origin.includes("becoming-moderately-jawfish.ngrok-free.app")
  ) {
    loginUrl = "https://slack.com/openid/connect/authorize?scope=openid%20profile&response_type=code&redirect_uri=https%3A%2F%2Ftest.getculturebot.com%2Fapi%2Fslack-oauth&client_id=2030080744801.3270001180101";
    teamsClientId = "31d982b8-f611-4417-ad2f-1458903c95c0";
    teamsLoginUrlFromApp = "https://test.getculturebot.com/api/teamsExchangeSsoTokenForOboToken";
    teamsLoginUrlFromBrowser = "https://test.getculturebot.com/api/teams-oauth";
    authAPIUrl = `api://becoming-moderately-jawfish.ngrok-free.app/${teamsClientId}/access_as_user`
  } else {
    loginUrl =
      "https://slack.com/openid/connect/authorize?scope=openid%20profile&response_type=code&redirect_uri=https%3A%2F%2Fgetculturebot.com%2Fapi%2Fslack-oauth&client_id=2030080744801.2002698740279";
    teamsClientId = "2f89839d-6910-4dbd-885c-adeca7821a2b";
    teamsLoginUrlFromApp = "https://getculturebot.com/api/teamsExchangeSsoTokenForOboToken";
    teamsLoginUrlFromBrowser = "https://getculturebot.com/api/teams-oauth";
    authAPIUrl = `api://getculturebot.com/${teamsClientId}/access_as_user`
  }

  // Microsoft Teams Authentication Configuration
  const msalConfig = {
    auth: {
      clientId: teamsClientId,
      authority: "https://login.microsoftonline.com/common", 
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
    }
  };
  const msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.initialize();

  // if in Teams, use Teams SDK directly to get user info
  if (inTeams) {
    
    authentication
    .getAuthToken({
      resources: [
        "https://graph.microsoft.com",
        authAPIUrl
      ],
      silent: false,
    })
    .then(async (response) => {

      const ssoToken = response;

      await fetch(teamsLoginUrlFromApp, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ssoToken }),
      })
      .then(response => {
        return response.json(); // Parse the JSON response
      })
      .then(data => {
        localStorage.setItem("token", searchParams.get("token_string") || data.jwt);
        localStorage.setItem("usingTeams", "true"); // if we don't set this, we have logic in redirect() below that will redirect to /app/rewards and not /app/home (b/c inTeams state var isn't set fast enough)
        localStorage.setItem("is_admin", data.is_admin); // need this just for Teams, b/c auth here is done before we call getTokenPermissions(), and we need to know if user is admin or not to redirect them to the correct page (/home or /members)
        if("error" in data){
          if("admin_consent_url" in data){
            setAdminConsentUrl(data.admin_consent_url); // if user is not admin, we need to redirect them to the admin consent page
          }
          setAuthErrorLoggingIn(true);
        } else {
          redirect();
        }
      })
      .catch(error => {
        setAuthErrorLoggingIn(true);
        console.error("Error with fetching OBO token.... ", teamsLoginUrlFromApp, error); 
      });

    })
    .catch((error) => {
      setAuthErrorLoggingIn(true);
      console.error("Error with getAuthToken: ", authAPIUrl, error); 
    });

  } 

  // handle Teams login outside of Teams (msalInstance.loginPopup())
  const handleTeamsLoginOutsideTeams = async () => {

    // if not in Teams, use pop-up login to get user info
    if (!inTeams) {

      const loginRequest = {
        scopes: ["User.Read"], // Permissions for Microsoft Graph
        redirectUri: window.location.origin,
      };

      msalInstance
      .loginPopup(loginRequest)
      .then(async (response) => {

        const token = response.accessToken;
        
        // Handle successful login (e.g., save tokens or user info)
        await fetch(teamsLoginUrlFromBrowser, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token }),
        })
        .then(response => {
          return response.json(); // Parse the JSON response
        })
        .then(data => {
          localStorage.setItem("token", searchParams.get("token_string") || data.jwt);
          localStorage.setItem("usingTeams", "true"); // if we don't set this, we have logic in redirect() below that will redirect to /app/rewards and not /app/home (b/c inTeams state var isn't set fast enough)
          localStorage.setItem("is_admin", data.is_admin); // need this just for Teams, b/c auth here is done before we call getTokenPermissions(), and we need to know if user is admin or not to redirect them to the correct page (/home or /members)
          if("error" in data){
            if("admin_consent_url" in data){
              setAdminConsentUrl(data.admin_consent_url); // if user is not admin, we need to redirect them to the admin consent page
            }
            setAuthErrorLoggingIn(true);
          } else {
            redirect();
          }
        })
        .catch(error => {
          setAuthErrorLoggingIn(true);
          console.error("Error fetching OBO token: ", error); 
        });

      })
      .catch((error) => {
        setAuthErrorLoggingIn(true);
        console.error("Login failed with msalInstance: ", error);
      });

    }

  };

  // determines where to redirect user after login
  const redirect = () => {

    setAuthErrorLoggingIn(false);
    
    // user has campaign info >> send them to view public campaign results page (and reset bookmark in storage)
    if (localStorage.getItem("campaign_id")) {
      let campaignId = localStorage.getItem("campaign_id");
      let campaignRunId = localStorage.getItem("campaign_run_id");
      localStorage.setItem("campaign_id", "");
      localStorage.setItem("campaign_run_id", "");
      window.location.href = `/app/campaign_run_results/${campaignId}/${campaignRunId}`;
    } else {
      // has redirect stored > take user here
      if (localStorage.getItem("redirect")) {
        const page = localStorage.getItem("redirect");
        localStorage.removeItem("redirect");
        window.location.href = `/app/${page}`;
      } else {
        
        if(inTeams || (localStorage.getItem("usingTeams") == "true")){
          // Teams has an  actual home page so we can use that
          if(localStorage.getItem("is_admin") == "true"){
            window.location.href = "/app/home";
          } else {
            window.location.href = "/app/members";
          }
        } else {
          // right now this is peer rewards by default since both admins and members can access it
          window.location.href = "/app/rewards";
        }
        
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      if (searchParams.get("token_string")) {
        localStorage.setItem("token", searchParams.get("token_string"));
        redirect();
      } 
    } else {
      if(!authErrorLoggingIn){  
        redirect();
      }
    }
  }, []);

  return (
    <div className="wrapper">
      
      {/* Warning box for auth error */}
      {authErrorLoggingIn && (
        <div className="warningBox">
          <h3>Installation Not Approved</h3>
          <p>
            <strong>You don't have permission to install the bot.</strong> Please reach out to one of your Teams admins to install it, or ask them to allow user consent for apps. If you need further assistance, contact support@getculturebot.com.
          </p>
          {adminConsentUrl && (
            <p>
              <a href={adminConsentUrl} target="_blank" rel="noopener noreferrer">
                Click here to provide admin consent.
              </a>
            </p>
          )}
        </div>
      )}

      {/* Only show sign-in boxes if there's no auth error */}
      {!authErrorLoggingIn && (

        <>
          {/* simple nav -- don't show if in Teams (required by Teams) */}
          {!inTeams && (
            <div className="navbar">
              <ul>
                <li>
                  <img width={175} src={cbLogoBlue} />
                </li>
              </ul>
            </div>
          )}

          {/* sign in box */}
          <div className="loginBox">
            <h2>CultureBot Web Portal</h2>

            {/* Slack Login */}
            {!inTeams && (
              <div style={{ marginTop: "20px" }}>
                <a
                  href={loginUrl}
                  className="slackButton"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="slackButton-svg"
                    viewBox="0 0 122.8 122.8"
                  >
                    <path
                      d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                      fill="#e01e5a"
                    ></path>
                    <path
                      d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                      fill="#36c5f0"
                    ></path>
                    <path
                      d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                      fill="#2eb67d"
                    ></path>
                    <path
                      d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                      fill="#ecb22e"
                    ></path>
                  </svg>
                  Sign in with Slack
                </a>
              </div>
            )}

            {/* Teams Login */}
            <div style={{ marginTop: "20px" }}>
              <button
                onClick={handleTeamsLoginOutsideTeams}
                className="teamsButton"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <circle cx="15" cy="15" r="8" fill="white" />
                  <text
                    x="11"
                    y="19"
                    fill="#6264A7"
                    fontSize="12"
                    fontFamily="Arial, sans-serif"
                    fontWeight="bold"
                  >
                    T
                  </text>
                </svg>
                <span style={{ marginLeft: "10px" }}>Sign in with Teams</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
