import { Space, Table, Popconfirm, Tag } from "antd";
import { useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doGetRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import { useState } from "react";

export default function CBCampaignTable({
  editClickedOnCampaign,
  viewCampaignResults,
  pauseCampaign,
  cloneCampaign,
  deleteClickedOnId,
}) {

  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const columns = [
    {
      title: "Campaign name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: "40%",
      render: (_, record) => {
        return (
          <div>
            {record.campaign_name}
            &nbsp;&nbsp;
            {record.frequency_to_run_campaign != "ONE_TIME" ? (
              <Tag color="blue">Recurring</Tag>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => viewCampaignResults(record)}>View Results</a>
          <a onClick={() => cloneCampaign(record)}>Clone</a>
          <a onClick={() => editClickedOnCampaign(record)}>Edit</a>
          <Popconfirm
            title={
              <div>
                Are you sure you want to delete this campaign?
                <br />
                You will not be able to undo this action.
              </div>
            }
            onConfirm={() => deleteClickedOnId(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
          {record.frequency_to_run_campaign != "ONE_TIME" ? (
            <Popconfirm
              title={
                record.paused ? (
                  <div>Users will start to receive survey messages again.</div>
                ) : (
                  <div>
                    Users who've already received old survey messages can still
                    respond to these.
                    <br />
                    New survey messages will not send until you unpause the
                    campaign again.
                  </div>
                )
              }
              onConfirm={() => pauseCampaign(record.id, record.paused)}
              okText={record.paused ? "Unpause" : "Pause"}
              cancelText={record.paused ? "Don't Unpause" : "Don't Pause"}
            >
              <a>{record.paused ? "Unpause" : "Pause"}</a>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  // gets all campaigns for user that are not deleted
  const getCBCampaignsQuery = useQuery(
    ["GET_CB_CAMPAIGNS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getCBCampaigns`);
    },
    { refetchOnWindowFocus: false }
  );

  // builds actual table
  var tableArea;
  if (getCBCampaignsQuery.isLoading) {
    tableArea = <div>Loading...</div>;
  } else if (getCBCampaignsQuery.isError || getCBCampaignsQuery.data.error) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    tableArea = (
      <Table
        columns={columns}
        dataSource={getCBCampaignsQuery.data.campaigns}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    );
  }
  return tableArea;
}
