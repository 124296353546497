import React from "react";
import {
  GiftOutlined,
  UnorderedListOutlined,
  CheckCircleOutlined,
  StarOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import { Card, Typography, DatePicker, Select, Menu } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { getBaseUrl, doGetRequest } from "../../helpers/requestHelpers";
import "./Rewards.css";
import CatalogGrid from "./CatalogGrid";
import Redemptions from "./Redemptions";
import CustomRewards from "./CustomRewards";
import Settings from "./Settings.js";
import { getCountriesList, getCurrencies } from "../../helpers/utilityHelpers";
import Bugsnag from '@bugsnag/js';
import useAuth from "../../hooks/basicAuthCheck";
import { useTeams } from "msteams-react-base-component";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function RewardsPage() {
  
  const isAuthenticated = useAuth();
  const [{ inTeams, theme, context }] = useTeams();

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("gift card");
  const [currentMenuItem, setCurrentMenuItem] = useState("catalog");
  const initialRewardCategories = [
    "gift card",
    "cash equivalent",
    "custom reward",
  ];
  const [rewardCategoriesToShow, setRewardCategoriesToShow] = useState(
    initialRewardCategories
  );
  const formattedCategories = {
    "gift card": "Gift Cards",
    "cash equivalent": "Prepaid Cards",
    "custom reward": "Custom Rewards",
  };
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [countries, setCountries] = useState(getCountriesList()); 
  const currencies = getCurrencies()

  // fetch rewards catalog
  const getRewardsCatalogQuery = useQuery(
    ["GET_REWARDS_CATALOG", selectedCategory, selectedCountry],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getRewardsCatalog`,
        setTokenErrorType,
        [
          { name: "category", value: selectedCategory },
          { name: "country", value: selectedCountry },
        ]
      );
    },
    { refetchOnWindowFocus: false, enabled: isAuthenticated }
  );

  // get points info for user
  const getPointsInfoForUser = useQuery(
    ["GET_POINTS_INFO_FOR_USER"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getPointsInfoForUser`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, enabled: isAuthenticated }
  );

  // settings for rewards
  const getCustomRewardSettings = useQuery(
    ["GET_CUSTOM_REWARD_SETTINGS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCustomRewardSettings`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, enabled: isAuthenticated }
  );

  // cards on file with tango
  const getTangoCardInfo = useQuery(
    ["GET_TANGO_CARD_INFO"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getTangoCardInfo`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, enabled: isAuthenticated }
  );

  // exchange rates
  const getExchangeRateInfo = useQuery(
    ["GET_REWARDS_CATALOG", selectedCountry],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getExchangeRateInfo`,
        setTokenErrorType,
        [
          { name: "baseCurrency", value: "USD" },
          { name: "rewardCurrency", value: currencies[selectedCountry] },
        ]
      );
    },
    { refetchOnWindowFocus: false, enabled: isAuthenticated }
  );

  // determine if admin or not
  const getTokenPermissionsQuery = useQuery(
    ["GET_TOKEN_PERMISSIONS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getTokenPermissions`);
    },
    { refetchOnWindowFocus: false, enabled: isAuthenticated }
  );

  // determine country user is in (for gift card dropdown)
  const getUserCountryCode = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      const countryCode = data.country;
      const isValidCountry = countries.some(
        (country) => country.code === countryCode
      );
      return isValidCountry ? countryCode : 'US';
    } catch (error) {
      console.error('Geolocation error: ', error);
      Bugsnag.notify(error);
      return 'US';
    }
  };

  // menu items (show admin all tabs, members just see catalog)
  let items = [];
  if (
    getTokenPermissionsQuery.data &&
    getCustomRewardSettings.data &&
    getTokenPermissionsQuery.data.is_admin
  ) {
    items = [
      {
        label: "Catalog",
        key: "catalog",
        icon: <UnorderedListOutlined />,
      },
      {
        label: "Redemptions",
        key: "redemptions",
        icon: <CheckCircleOutlined />,
      },
      {
        label: "Custom Rewards",
        key: "add custom rewards",
        icon: <StarOutlined />,
      },
      {
        label: "Settings",
        key: "settings",
        icon: <SettingOutlined />,
      },
    ];
    // if not main admin, and not an approved admin - do not show redemptions tab
    if (
      !getTokenPermissionsQuery.data.is_main_admin &&
      !getCustomRewardSettings.data.approval_admins.includes(
        getTokenPermissionsQuery.data.user_id
      )
    ) {
      items.splice(1, 1); // at index 1, remove 1 item (redemptions tab)
    }
  } else {
    items = [
      {
        label: "Catalog",
        key: "catalog",
        icon: <UnorderedListOutlined />,
      },
    ];
  }

  // ------- Input Handlers ------ //
  const updatedFilter = (newCategory) => {
    setSelectedCategory(newCategory);
  };

  const updatedCountry = (newCountry) => {
    setSelectedCountry(newCountry);
  };

  const updateNavItem = (e) => {
    setCurrentMenuItem(e.key);
  };

  useEffect(() => {
    // determine which reward categories & countries to show in drop downs
    if (
      getCustomRewardSettings.data &&
      getCustomRewardSettings.data.reward_categories_hidden
    ) {
      // categories drop down
      const newRewardCategories = initialRewardCategories.filter(
        (elementA) =>
          !getCustomRewardSettings.data.reward_categories_hidden.includes(
            elementA
          )
      );

      // remove hidden categories
      setRewardCategoriesToShow(newRewardCategories);
      setSelectedCategory(newRewardCategories[0]);

      // countries drop down
      if (getCustomRewardSettings.data.countries_whitelist) {
        const countriesToShow = [];
        getCustomRewardSettings.data.countries_whitelist.forEach((country) => {
          country = JSON.parse(country);
          countriesToShow.push({ code: country.code, long: country.long });
        });
        if (countriesToShow.length > 0) {
          setCountries(countriesToShow);
        }
      }

      // set country drop down to user's location
      getUserCountryCode().then((countryCode) => {
        setSelectedCountry(countryCode);
      });
      
    }
  }, [getCustomRewardSettings.data, getTangoCardInfo.data]);

  // filter select (type of gift card)
  const filterSelect = (
    <>
      <Select
        style={{ width: 175 }}
        value={selectedCategory}
        onChange={updatedFilter}
        size="large"
      >
        {rewardCategoriesToShow.map((category) => {
          return (
            <Option value={category} key={category}>
              {formattedCategories[category]}
            </Option>
          );
        })}
      </Select>
      <br />
      <br />
    </>
  );

  // country select (country of gift card)
  const countrySelect = (
    <>
      <Select
        style={{ width: 175 }}
        value={selectedCountry}
        onChange={updatedCountry}
        size="large"
      >
        {countries.map((country) => {
          return (
            <Option value={country.code} key={country.code}>
              {country.long}
            </Option>
          );
        })}
      </Select>
      <br />
      <br />
    </>
  );

  // check if user is authenticated first (if not, MS Teams shows a big warning banner)
  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  // Page Frame
  let mainArea = (
    <div>
      <div>
        <Title>
          <GiftOutlined style={{ fontSize: "32px" }} /> Rewards Catalog
        </Title>
        <Menu
          onClick={updateNavItem}
          selectedKeys={[currentMenuItem]}
          mode="horizontal"
          items={items}
        />
        <br />
        {/* show correct content based on menu */}
        {currentMenuItem == "catalog" ? (
          <>
            <h3>Category & Location:</h3>
            <p>
              Note: choose the country where you live (gift cards can only be
              used in the country of purchase)
            </p>
            <div className="catalogFiltersContainer">
              <div className="catalogFilter">{filterSelect}</div>
              {/* show country select only for tango categories (gift cards, prepaid cards, etc.) */}
              {selectedCategory != "custom reward" ? (
                <div className="catalogFilter">{countrySelect}</div>
              ) : null}
            </div>
            <CatalogGrid
              rewards={getRewardsCatalogQuery.data}
              points={getPointsInfoForUser.data}
              rewardSettings={getCustomRewardSettings.data}
              exchangeRateInfo={getExchangeRateInfo.data}
              currency={currencies[selectedCountry]}
              selectedCountry={selectedCountry}
              perms={getTokenPermissionsQuery}
              inTeams={inTeams}
              selectedCategory={selectedCategory}
            />
          </>
        ) : null}

        {currentMenuItem == "redemptions" ? (
          <>
            <Redemptions rewardSettings={getCustomRewardSettings.data} />
          </>
        ) : null}

        {currentMenuItem == "add custom rewards" ? (
          <>
            <CustomRewards />
          </>
        ) : null}

        {currentMenuItem == "settings" ? (
          <>
            <Settings
              rewardSettings={getCustomRewardSettings.data}
              tangoCardInfo={getTangoCardInfo.data}
              isMainAdmin={getTokenPermissionsQuery.data.is_main_admin}
            />
          </>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      <MainContainer>
        <div className="main-container">
          <Card className="main-container-card">{mainArea}</Card>
          <br />
        </div>
      </MainContainer>
    </>
  );
}
