import React from "react";
import {
  CompassOutlined,
  PlusOutlined,
  MessageOutlined,
  FormOutlined,
  HeartOutlined,
  StarOutlined,
  SmileOutlined,
  MinusCircleOutlined
} from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import {
  Alert,
  Card,
  Button,
  Space,
  Modal,
  Typography,
  Collapse,
  Tabs,
  Row,
  Col,
  Input,
  Popconfirm,
  InputNumber,
  Select,
  Checkbox,
  DatePicker,
  TimePicker, 
  Switch,
  message
} from "antd";
import { filterOptionFunction } from "../CreateCampaign/CreateCampaignPageHelpers";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./Experiences.css";
import ExperiencesTable from "./ExperiencesTable";
import PanelSteps from "./PanelSteps";
import BuddiesManagersTable from "./BuddiesManagersTable";
import MessagesSentTable from "./MessagesSentTable";
import {
  roundToLastHalfHour
} from "../../helpers/utilityHelpers";
import moment from "moment-timezone";
import happyHour from "../../images/happy-hour.png";
import onboarding from "../../images/onboarding.png";
import blank from "../../images/blank.png";

const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const timePickerFormat = "h:mm a";

export default function ExperiencesPage() {

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]); // array with strings corrsponding to error inputs
  const queryClient = useQueryClient();
  const [currentExpId, setCurrentExpId] = useState('');

  // ------------ Fetch Data ------------- //
  const getSlackChannelsQuery = useQuery(
    ["getSlackChannelsQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "channels" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  const getSlackUsersQuery = useQuery(
    ["getSlackUsersQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "users" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );
  
  const getCBExperiencesQuery = useQuery(
    ["GET_CB_EXPERIENCES"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getCBExperiences`);
    },
    { refetchOnWindowFocus: false }
  );

  const getCBExperienceSettings = useQuery(
    ["GET_CB_EXPERIENCE_SETTINGS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCBExperienceSettings`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  const getEmployeeBuddyPairingsQuery = useQuery(
    ["GET_CB_EMPLOYEE_BUDDY_PAIRINGS"],
    async () => {
        return doGetRequest(
            `${getBaseUrl()}/api/getCBEmployeeBuddyPairings`,
            setTokenErrorType,
        );
    },
    { refetchOnWindowFocus: false }
  );
  
  const getUniqueDepartmentsQuery = useQuery(
    ["GET_UNIQUE_DEPARTMENTS"],
    async () => {
        return doGetRequest(
            `${getBaseUrl()}/api/getAllEmployeeDepartments`,
            setTokenErrorType,
        );
    },
    { refetchOnWindowFocus: false }
  );

  const getUniqueLocationsQuery = useQuery(
    ["GET_UNIQUE_LOCATIONS"],
    async () => {
        return doGetRequest(
            `${getBaseUrl()}/api/getAllEmployeeLocations`,
            setTokenErrorType,
        );
    },
    { refetchOnWindowFocus: false }
  );

  const getUniqueGroupsQuery = useQuery(
    ["GET_UNIQUE_GROUPS"],
    async () => {
        return doGetRequest(
            `${getBaseUrl()}/api/getAllEmployeeGroups`,
            setTokenErrorType,
        );
    },
    { refetchOnWindowFocus: false }
  );

  // -------- Update Queries ------------- //
  async function saveExperience(jsonToSend) {
    setSaveButtonIsLoading(true);
    const response = await doPostRequest(
      `${getBaseUrl()}/api/addOrEditExperience`,
      jsonToSend,
      setTokenErrorType
    );
    console.log(jsonToSend);
    // Invalidate parts of the app using GET_CB_EXPERIENCES (like the table) so they refresh
    queryClient.invalidateQueries("GET_CB_EXPERIENCES");
    setIsModalOpen(false);
    setSaveButtonIsLoading(false);
  }

  async function deleteExperience(experience) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteExperience`,
      { experience_id: experience.id },
      setTokenErrorType
    );
    // Invalidate query to trigger table refresh
    queryClient.invalidateQueries("GET_CB_EXPERIENCES");
  }

  async function pauseExperience(experience) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/pauseExperience`,
      { experience_id: experience.id, status: experience.paused },
      setTokenErrorType
    );
    // Invalidate query to trigger table refresh
    queryClient.invalidateQueries("GET_CB_EXPERIENCES");
  }

  async function saveEmployeeBuddyPairing(jsonToSend) {
    setSaveButtonIsLoading(true);
    const response = await doPostRequest(
      `${getBaseUrl()}/api/addOrEditEmployeeBuddyPairing`,
      jsonToSend,
      setTokenErrorType
    );
    // Invalidate parts of the app so they refresh
    queryClient.invalidateQueries("GET_CB_EMPLOYEE_BUDDY_PAIRINGS");
    setIsBuddyModalOpen(false);
    setSaveButtonIsLoading(false);
  }

  async function deleteEmployeeBuddyPairing(pairing) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteEmployeeBuddyPairing`,
      { pairing_id: pairing.id },
      setTokenErrorType
    );
    // Invalidate query to trigger table refresh
    queryClient.invalidateQueries("GET_CB_EMPLOYEE_BUDDY_PAIRINGS");
  }

  async function updateNotifSettings(checked, users) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/updateExperienceSettings`,
      {'notifs_on': checked, 'user_ids': users},
      setTokenErrorType
    );
    queryClient.invalidateQueries("GET_CB_EXPERIENCE_SETTINGS");
  }

  // ------------ Create New Experience: Form & Methods ------------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isEditingExperience = !!currentExpId;
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);
  const [experienceRunning, setExperienceRunning] = useState();
  const [viewingMessagesSentForExperience, setViewingMessagesSentForExperience] = useState(false);
  const [isEditingPairing, setIsEditingPairing] = useState(false);

  // anchor timing
  const [experienceName, setExperienceName] = useState('');
  const [anchorTimeType, setAnchorTimeType] =
  useState("first_seen");
  const [anchorDate, setAnchorDate] = useState(null);
  const [anchorDateTime, setAnchorDateTime] = useState(null);
  const [isInvalidTimeModalOpen, setIsInvalidTimeModalOpen] = useState(false);
  const [anchorChannel, setAnchorChannel] = useState('');
  const [steps, setSteps] = useState([]);
  const [recurringSequence, setRecurringSequence] = useState(false);
  const [recurringSequenceDays, setRecurringSequenceDays] = useState(7);
  const [activeUsers, setActiveUsers] = useState([]);

  // example steps structure ^
  // [
  //   { name: "Day 1 - Message", id: 0, message: "Let's get you started!", type: "message" },
  //   { name: "Day 2 - Shoutout", id: 1, message: "Welcome to the team!", type: "shoutout" },
  //   { name: "Day 3 - Survey", id: 2, message: "Take this survey...", type: "survey" },
  // ]

  // var replacement on right side
  const [varNames, setVarNames] = useState([]);
  // example var names structure ^
  // [
  //   { name: "Manager", user_id: "U02177VDVBJ", id: 0 },
  //   { name: "CEO", user_id: "U0202LD65NK", id: 1 },
  //   { name: "People Leader", user_id: "U0357PWTS3C", id: 2 },
  // ]

  const handleCancel = () => {
    if(!isEditingExperience && steps.length > 0) {
      let confirm = window.confirm("You added a step to your experience, but have not saved it yet. Click 'cancel' below to close this message and save your work before closing.");
      if (!confirm) {
       return 
      }
    }

    // fully close and reset modal
    setIsModalOpen(false);
    setIsBuddyModalOpen(false);
    setIsAddingNewDepartment(false);
    setIsAddingNewLocation(false);
    setIsAddingNewGroup(false);
    setErrorMessages([]);

  };

  // reset form to current (saved) experience state
  function clearAllStates() {

    // experience vars
    setCurrentExpId(null);
    setExperienceName('');
    setAnchorTimeType('first_seen');
    setAnchorDate(null);
    setAnchorDateTime(null);
    setAnchorChannel('');
    setRecurringSequence(false);
    setRecurringSequenceDays(7);
    setSteps([]);
    setVarNames([]);
    setExperienceRunning(null);

    // buddy vars
    setIsEditingPairing(false);
    setEmployeeSelectedForBuddy(null);
    setEmployeeBuddiesSelected([]);
    setEmployeeSelectedEmail(null);
    setEmployeeManagerSelected(null);
    setEmployeeStartDateSelected(null);
    setEmployeeTitle('');
    setEmployeeDepartment('');
    setEmployeeLocation('');
    setEmployeeGroups([]);

    // clear errors 
    setErrorMessages([]);

  }

  // clone experience (show creation form with pre-populated values)
  async function cloneExperience(experience) {
    clearAllStates();
    setIsModalOpen(true);
    setExperienceName(`${experience.name} - COPY`);
    setAnchorTimeType(experience.anchor_time_type);
    setAnchorDate(moment(experience.anchor_date_time));
    setAnchorDateTime(moment(experience.anchor_date_time));
    setAnchorChannel(experience.anchor_channel);
    setRecurringSequence(experience.recurring_sequence);
    setRecurringSequenceDays(experience.recurring_sequence_days);
    setSteps(experience.steps);
    setVarNames(experience.var_names);
  }

  async function editExperience(experience) {
    clearAllStates();
    setCurrentExpId(experience.id);
    setExperienceRunning(experience.running);
    setExperienceName(experience.name);
    setAnchorTimeType(experience.anchor_time_type);
    setAnchorDate(moment(experience.anchor_date_time));
    setAnchorDateTime(moment(experience.anchor_date_time));
    setAnchorChannel(experience.anchor_channel);
    setRecurringSequence(experience.recurring_sequence);
    setRecurringSequenceDays(experience.recurring_sequence_days);
    setSteps(experience.steps);
    setActiveUsers(experience.users_active)
    setVarNames(experience.var_names);
    setIsModalOpen(true);
  }

  // finds any and all errors in saving the experience
  function getErrorMessagesForForm() {

    let currentErrorMessages = [];
    if (!experienceName) {
      currentErrorMessages.push("Experience name is required");
    }
    if (!anchorTimeType) {
      currentErrorMessages.push("Experience start time type is required");
    }
    if (anchorTimeType == "first_seen_channel" && !anchorChannel) {
      currentErrorMessages.push("Channel selection is required");
    }
    if (anchorTimeType == "specific_date") {

      if (!anchorDate || !anchorDateTime) {
        currentErrorMessages.push("Date and time selection are both required");
      }

      if(anchorDate && anchorDate.startOf("day") < moment().startOf("day")) {
        currentErrorMessages.push("Start date cannot be in the past");
      }

      if(anchorDate && anchorDate.startOf("day").diff(moment().startOf("day")) == 0) {
        if (anchorDateTime && anchorDateTime < moment()) {
          currentErrorMessages.push("Start time cannot be in the past");
        }
      }

    }
    if (recurringSequence && !recurringSequenceDays) {
      currentErrorMessages.push("Recurrence interval is required since this experience restarts");
    }

    // experiences that have running = true or false means that it is either actively running or is about to run again (after recurring), only recurring = null means it has never run before 
    if (experienceRunning !== null) {
      currentErrorMessages.push(
        "This experience is already running – cannot edit a running experience. To make edits, try pausing and cloning this experience."
      );
    }

    // --------- check all varNames (all vars need a name and user_id before saving) --------- //
    let varNamesIncomplete = false;
    varNames.forEach((varObj) => {
      if (!varObj.name || !varObj.user_id) {
        varNamesIncomplete = true;
      }
    });
    if(varNamesIncomplete) {
      currentErrorMessages.push("All custom user variables need both a name and user mapping before saving");
    }

    // --------- all step checks (different steps need different information completed before saving) --------- //
    if(!steps.length) {
      currentErrorMessages.push("Experiences need at least one step added before saving");
    }

    // all step types
    let stepWhentToStartValueMissing = false;
    let stepWhentToStartTimeframeMissing = false;
    let stepTimeOfDayToSendMissing = false;
    let stepTimezoneSelectionMissing = false;
    let isNotWholeOrHalf = false;
    
    // message step types
    let stepMessageMissing = false;
    let messageWhereToSendMissing = false;
    let messageWhereToSendChannelMissing = false;
    let messageWhereToSendSpecificUsersMissing = false;
    let messageWhereToSendChannelOfUsersMissing = false;

    // shoutout step types
    let shoutoutWhereToSendMissing = false;
    let shoutoutTypeMissing = false;
    let shoutoutSenderMissing = false;
    let shoutoutToMissing = false;
    let messageNotCorrectLength = false;

    // survey step types 
    let stepNameMissing = false;
    let surveyFormMissing = false;
    let surveyVisiblityMissing = false;
    let surveyTimeValue = false;
    let surveyTimeframe = false;
    let surveySendToUsersMissing = false;
    let surveySendToChannelMissing = false;
    let surveyAudienceChannelMissing = false;
    let surveyAudienceUsersMissing = false;
    let surveyAudienceGroupsMissing = false;
    let surveyAudienceLocationMissing = false;

    steps.forEach((stepObj) => {

      if(!stepObj.name) {
        stepNameMissing = true;
      }
      if(!stepObj.timeValue && stepObj.timeValue != 0) {
        stepWhentToStartValueMissing = true;
      }
      if(stepObj.timeValue) {
        isNotWholeOrHalf = stepObj.timeValue * 2 % 1 !== 0;
      }
      if(!stepObj.timeframe) {
        stepWhentToStartTimeframeMissing = true;
      }
      if(!stepObj.timeDay && stepObj.timeframe != 'hours') {
        stepTimeOfDayToSendMissing = true;
      }
      if(!stepObj.tz && stepObj.timeframe != 'hours') {
        stepTimezoneSelectionMissing = true;
      }

      // message types only
      if(stepObj.type == "message") {
        if(!stepObj.message) {
          stepMessageMissing = true;
        }
        if(!stepObj.whereToSend) {
          messageWhereToSendMissing = true;
        }
        if(stepObj.whereToSend == "specific_channel" && !stepObj.whereToSendChannel) {
          messageWhereToSendChannelMissing = true;
        }
        if(stepObj.whereToSend == "direct_message_specific_users" && !stepObj.whereToSendSpecificUsers) {
          messageWhereToSendSpecificUsersMissing = true;
        }
        if(stepObj.whereToSend == "direct_message_users_specific_channel" && !stepObj.whereToSendSpecificChannelOfUsers) {
          messageWhereToSendChannelOfUsersMissing = true;
        }
      }

      // shoutout types only
      if(stepObj.type == "shoutout") {
        if(!stepObj.whereToSend || !stepObj.whereToSendChannel) {
          shoutoutWhereToSendMissing = true;
        }
        if(!stepObj.shoutoutType) {
          shoutoutTypeMissing = true;
        }
        if(!stepObj.shoutoutSender) {
          shoutoutSenderMissing = true;
        }
        if(!stepObj.shoutoutTo) {
          shoutoutToMissing = true;
        }
        if(!stepObj.message || stepObj.message.length < 10 || stepObj.message.length > 1000) {
          messageNotCorrectLength = true; 
        }
      }

      // survey types only
      if(stepObj.type == "survey") {
        if(!stepObj.formId) {
          surveyFormMissing = true;
        }
        if(!stepObj.visiblityValue) {
          surveyVisiblityMissing = true;
        }
        if(!stepObj.surveyTimeToShareValue) {
          surveyTimeValue = true;
        }
        if(!stepObj.surveyTimeframeToShareValue) {
          surveyTimeframe = true;
        }
        if(stepObj.surveyWhereToSendValue == "SPECIFIC_USERS_VIA_DM" && !stepObj.surveyWhereToSendSecondary.length) {
          surveySendToUsersMissing = true;
        }
        if(stepObj.surveyWhereToSendValue == "SELECTED_CHANNEL" && !stepObj.surveyWhereToSendSecondary.length) {
          surveySendToChannelMissing = true;
        }
        if(stepObj.audienceSelection == "USERS_IN_CHANNEL" && !stepObj.audienceSelectionSecondary.length) {
          surveyAudienceChannelMissing = true;
        }
        if(stepObj.audienceSelection == "SPECIFIC_USERS" && !stepObj.audienceSelectionSecondary.length) {
          surveyAudienceUsersMissing = true;
        }
        if(stepObj.audienceSelection == "USERS_BY_GROUP" && !stepObj.audienceSelectionSecondary.length) {
          surveyAudienceGroupsMissing = true;
        }
        if(stepObj.audienceSelection == "USERS_BY_WORK_LOCATION" && !stepObj.audienceSelectionSecondary.length) {
          surveyAudienceLocationMissing = true;
        }

      }

    });

    // all types
    if(stepNameMissing) {
      currentErrorMessages.push("A name for each step is required before saving");
    }
    if(stepWhentToStartValueMissing) {
      currentErrorMessages.push("Time value for each step is required before saving");
    }
    if(isNotWholeOrHalf) {
      currentErrorMessages.push("All time values must be on either the half hour or top of the hour.");
    }
    if(stepWhentToStartTimeframeMissing) {
      currentErrorMessages.push("Timeframe for each step (days/hours) is required before saving");
    }
    if(stepTimeOfDayToSendMissing) {
      currentErrorMessages.push("Time of day for each step is required before saving");
    }
    if(stepTimezoneSelectionMissing) {
      currentErrorMessages.push("Timezone selection for each step is required before saving");
    }

    // message types
    if(stepMessageMissing) {
      currentErrorMessages.push("A message is required for all message steps before saving");
    }
    if(messageWhereToSendMissing) {
      currentErrorMessages.push("A specific user/channel/group to send to is required for all message steps before saving");
    }
    if(messageWhereToSendChannelMissing) { 
      currentErrorMessages.push("A selection for a specific channel is missing for a message step");
    }
    if(messageWhereToSendSpecificUsersMissing) { 
      currentErrorMessages.push("A selection of users is missing for a message step");
    }
    if(messageWhereToSendChannelOfUsersMissing) { 
      currentErrorMessages.push("A selection for a specific channel is missing for a message step");
    }

    // shoutout types
    if(shoutoutWhereToSendMissing) {
      currentErrorMessages.push("A channel to send to is required for all shoutout steps before saving");
    }
    if(shoutoutTypeMissing) {
      currentErrorMessages.push("A shoutout type is required for all shoutout steps before saving");
    }
    if(shoutoutSenderMissing) {
      currentErrorMessages.push("A shoutout sender is required for all shoutout steps before saving");
    }
    if(shoutoutToMissing) {
      currentErrorMessages.push("A user to shoutout to is required for all shoutout steps before saving");
    }
    if(messageNotCorrectLength) {
      currentErrorMessages.push("All shoutout messages must be between 10 and 1,000 characters long before saving");
    }

    // survey types
    if(surveyFormMissing) {
      currentErrorMessages.push("A survey form selection is required for all survey steps before saving");
    }
    if(surveyVisiblityMissing) {
      currentErrorMessages.push("A survey visibility selection (public/anonymous) is required for all survey steps before saving");
    }
    if(surveyTimeValue || surveyTimeframe) {
      currentErrorMessages.push("A time value for when to share survey results is required for all survey steps before saving");
    }
    if(surveySendToUsersMissing) {
      currentErrorMessages.push("When sharing survey results with specific users, user selection is required before saving");
    }
    if(surveySendToChannelMissing) {
      currentErrorMessages.push("When sharing survey results to a channel, channel selection is required before saving");
    }
    if(surveyAudienceChannelMissing) {
      currentErrorMessages.push("When sending a survey to a channel, channel selection is required before saving");
    }
    if(surveyAudienceUsersMissing) {
      currentErrorMessages.push("When sending a survey to specific users, user selection is required before saving");
    }
    if(surveyAudienceGroupsMissing) {
      currentErrorMessages.push("When sending a survey to certain departments, department selection is required before saving");
    }
    if(surveyAudienceLocationMissing) {
      currentErrorMessages.push("When sending a survey to certain locations, location selection is required before saving");
    }

    return currentErrorMessages;

  }

  // saves OR updates existing experience (entirely)
  const addOrEditExperience = () => {

    // check for any errors (mandatory fields missing)
    const currentErrorMessages = getErrorMessagesForForm();
    setErrorMessages(currentErrorMessages);
    if (currentErrorMessages.length) {
      return; // errors found > stop flow 
    }

    // format anchor date time correctly for POST request
    let anchorDateTimeToPost = moment.unix(0);
    if(anchorDate && anchorDateTime) {
      anchorDateTimeToPost = anchorDate.clone();
      anchorDateTimeToPost.hour(anchorDateTime.hour());
      anchorDateTimeToPost.minute(anchorDateTime.minute());
      anchorDateTimeToPost.second(0);
    }

    // final json to send
    let jsonToSend = {'id': currentExpId, 'name': experienceName, 'anchorTimeType': anchorTimeType, 'anchorDateTime': anchorDateTimeToPost.utc().format("YYYY-MM-DD HH:mm:ss"), 'anchorChannel': anchorChannel, 'recurringSequence': recurringSequence, 'recurringSequenceDays': recurringSequenceDays, 'steps': steps, 'varNames': varNames }
    console.log("jsonToSend: ", jsonToSend);
    saveExperience(jsonToSend)
  }

  const updatingStepTitle = (newValue, index) => {
   let updatedStep = steps.map((item) => {
      if (item.id === index) {
         return { ...item, name: newValue };
      }
      return item;
   });
   setSteps(updatedStep);
  };

  const genStepOptionsMenu = (step) => (
    <>
      {/* note: stop propagation prevents collapse from triggering b/c of button click */}
      <a
        onClick={(event) => {
          // event.stopPropagation();
          // window.alert("editing...");
        }}
      >
        Edit{" "}
      </a>{" "}
      &nbsp;&nbsp;
      <Popconfirm
        title={
          <div>
            Are you sure you want to delete this step?
            <br />
            You will not be able to undo this action.
          </div>
        }
        onConfirm={(event) => {
          event.stopPropagation();
          deleteStep(step.id);
        }}
        onCancel={(event) => {
          event.stopPropagation();
        }}
        onClick={(event) => event.stopPropagation()}
        okText="Yes"
        cancelText="No"
      >
        <a id="deleteStep">Delete</a>
      </Popconfirm>
      <div>
        {/* <h5>January 30, 2024</h5> */}
      </div>
    </>
  );

  const addStep = (stepType) => {
    message.success('A new step has been added below');
    setSteps([
        ...steps,
        { name: `${stepType}`, id: steps.length > 0 ? steps[steps.length - 1].id + 1 : 0, type: stepType },
    ]);
  };

  const deleteStep = (index) => {
    const newSteps = [];
    let newIndex = 0
    steps.forEach(function (step) {
      if (step.id !== index) {
        newSteps.push({ ...step, id: newIndex }); // rebuild using forEach so that we can re-assign an index
        newIndex++;
      }
    });
    setSteps(newSteps);
  };

  const updatingVarNames = (field, value, index) => {
    let updatedVarNames = varNames.map((item) => {
       if (item.id === index) {
          return { ...item, [field]: value };
       }
       return item;
    });
    setVarNames(updatedVarNames);
   };

  const removeVar = (index) => {
    const newVarNames = [];
    let newIndex = 0
    varNames.forEach(function (item) {
      if (item.id !== index) {
        newVarNames.push({ ...item, id: newIndex }); // rebuild using forEach so that we can re-assign an index
        newIndex++;
      }
    });
    setVarNames(newVarNames);
  };

  const addVar = () => {
    let newId = 0
    if (varNames.length) {
      newId = varNames[varNames.length - 1].id + 1
    }
    setVarNames([...varNames, { name: "", user_id: "", id: newId }]);
  };

  const replaceWithSequenceTemplate = (templateName) => {
    if(templateName == 'blank') {
      setExperienceName('')
      setAnchorTimeType('first_seen')
      setSteps([])
    } else if(templateName == 'new_hire_onboarding') {
      setExperienceName('New Hire Onboarding')
      setAnchorTimeType('first_seen')
      setSteps([
        { name: "(Day One) Welcome DM", id: 0, message: "Welcome to the team, {new_hire}! We are so glad to have you here.", type: "message" },
        { name: "(Day One) Welcome Shoutout", id: 1, message: "Everyone, please welcome {new_hire} to the team!", type: "shoutout" },
        { name: "(End of Week One) Survey", id: 2, message: "Hi {new_hire} - now that we are at the end of your first week, please take a moment to let us know how we're doing, and how we may be able to help.", type: "survey" },
      ])
    } else if(templateName == 'happy_hour') {
      setExperienceName('Team Happy Hour')
      setAnchorTimeType('specific_date')
      setSteps([
        { name: "Happy Hour Scheduled!", id: 0, message: "Hi team! We're going to do a happy hour this Friday at 4pm (remotely). Get excited! 🎉", type: "message" },
        { name: "Happy Hour Starting!", id: 1, message: "Happy hour is beginning now! Please join the link below 😎🍸", type: "message" },
        { name: "Happy Hour - Recap Survey", id: 2, message: "Let us know how you enjoyed the happy hour, and where we could improve next time! 🍻", type: "survey" }
      ])
    }
  }

  // ---------- Buddy Pairing Methods ---------- //
  const showEditingPairingModel = (pairing) => {
    setIsEditingPairing(true);
    setIsBuddyModalOpen(true);
    setEmployeeSelectedForBuddy(pairing.employee_id);
    setEmployeeBuddiesSelected(pairing.buddy_ids);
    setEmployeeManagerSelected(pairing.manager_id);
    setEmployeeSelectedEmail(pairing.employee_email);
    if(pairing.start_date) {
      setEmployeeStartDateSelected(moment.tz(pairing.start_date, 'UTC'));
    } else {
      setEmployeeStartDateSelected(null);
    }
    setEmployeeTitle(pairing.employee_title || '');
    setEmployeeDepartment(pairing.employee_department || '');
    setEmployeeLocation(pairing.employee_location || '');
    setEmployeeGroups(pairing.employee_groups || []);
  }

  function isValidEmail(email) {
    // Regular expression to match emails with "@" sign
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Check if email matches the regex and doesn't have leading or trailing spaces
    return email.trim() !== '' && email.match(emailRegex) !== null;
  }  

  const addOrEditEmployeeBuddyPairing = () => {

    // check for any errors (mandatory fields missing)
    const currentErrorMessages = [];

    // checks if editing an existing buddy (via email/user_id)
    if(getEmployeeBuddyPairingsQuery.data && getEmployeeBuddyPairingsQuery.data.pairings && !isEditingPairing) {
      const employeeDetailsFlat = getEmployeeBuddyPairingsQuery.data.pairings.flatMap(pairing => [pairing.employee_id, pairing.employee_email]);
      if(employeeDetailsFlat.includes(employeeSelectedEmail) || employeeDetailsFlat.includes(employeeSelectedForBuddy)) {
        currentErrorMessages.push("This employee already has a pairing, please edit them in the table to modify their pairing.");
      }
    }

    // all other logic checks
    if (!employeeSelectedForBuddy && !employeeSelectedEmail) {
      currentErrorMessages.push("An employee (either their name or work email) for pairing must be selected before saving.");
    }
    if(employeeSelectedEmail && !isValidEmail(employeeSelectedEmail)) {
      currentErrorMessages.push("The employee email does not appear to be valid.");
    }
    // remove this check for now since we are now allowing for the EmployeeBuddyPairings table to be used for user demographics (e.g. location, department, title, etc.)
    // if (!employeeManagerSelected && !employeeBuddiesSelected.length) {
    //   currentErrorMessages.push("At least one buddy or one manager must be selected before saving.");
    // }
    if (employeeSelectedForBuddy && employeeBuddiesSelected.includes(employeeSelectedForBuddy)) {
      currentErrorMessages.push("The employee selected cannot also be a buddy.");
    }
    if (employeeSelectedForBuddy && (employeeManagerSelected == employeeSelectedForBuddy)) {
      currentErrorMessages.push("The employee selected cannot also be the manager.");
    }

    setErrorMessages(currentErrorMessages);
    if (currentErrorMessages.length) {
      return; // errors found > stop flow 
    }

    let jsonToSend = {
      'employee_id': employeeSelectedForBuddy, 
      'employee_email': employeeSelectedEmail, 
      'buddy_ids': employeeBuddiesSelected, 
      'manager_id': employeeManagerSelected, 
      'start_date': employeeStartDateSelected,
      'employee_title': employeeTitle,
      'employee_department': employeeDepartment,
      'employee_location': employeeLocation, 
      'employee_groups': employeeGroups
    }
    saveEmployeeBuddyPairing(jsonToSend)
  }

  useEffect(() => {
    // console.log("updatedSteps: ", steps)
    // console.log("varNames: ", varNames)
    // console.log("departments: ", getUniqueDepartmentsQuery?.data?.departments)
    // console.log("locations: ", getUniqueLocationsQuery?.data?.locations)
    setDepartmentOptions(getUniqueDepartmentsQuery?.data?.departments || []);
    setLocationOptions(getUniqueLocationsQuery?.data?.locations || []);
    setGroupOptions(getUniqueGroupsQuery?.data?.groups || []);
  }, [steps, varNames, getUniqueDepartmentsQuery, getUniqueLocationsQuery, getUniqueGroupsQuery]);

  const createNewExperience = (
    <Modal
      width={"1100px"}
      forceRender // pre-render children
      title={isEditingExperience ? "Edit Experience" : "Create New Experience"}
      open={isModalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          form="experienceForm"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={addOrEditExperience}
          loading={saveButtonIsLoading}
          disabled={saveButtonIsLoading} 
        >
          Save
        </Button>,
      ]}
    >
      {/* -------- form itself -------- */}
      <>
        <Row gutter={16}>
          <Col span={14}>
            {/* ------------ templates ------------ */}
            <h3>1. Choose a starting template</h3>
            <div class="sectionWrapper">
              <div className="templateButton" onClick={() => replaceWithSequenceTemplate('blank')}>
                <img src={blank} />
              </div>
              <div className="templateButton" onClick={() => replaceWithSequenceTemplate('new_hire_onboarding')}>
                <img src={onboarding} />
              </div>
              <div className="templateButton" onClick={() => replaceWithSequenceTemplate('happy_hour')}>
                <img src={happyHour} />
              </div>
            </div>

            {/* ------------ anchor timing ------------ */}
            <br />
            <div className="sectionWrapper">
              <h3>2. Name your experience & choose when to start</h3>
              <h5>Experience name</h5>
              <Input
                onChange={(e) => {
                  setExperienceName(e.target.value);
                }}
                value={experienceName}
                style={{ width: "275px", marginBottom: "5px", display: "block" }}
              />
              <h5>Start timing</h5>
              <Select
                style={{ width: 260 }}
                value={anchorTimeType}
                onChange={(value) => {
                  setAnchorTimeType(value);
                  setAnchorDate(null);
                  setAnchorDateTime(null);
                  setAnchorChannel('');
                }}
              >
                <Option value="first_seen" key="first_seen">
                  When user is first seen in Slack
                </Option>
                <Option value="first_seen_channel" key="first_seen_channel">
                  When user is first seen in a channel
                </Option>
                <Option value="start_date" key="start_date">
                  On an employee's start date
                </Option>
                <Option value="specific_date" key="specific_date">
                  A specific date & time
                </Option>
              </Select>

              {/* show channel picker if first_seen_channel chosen */}
              {anchorTimeType == "first_seen_channel" ? (
                <div id="stepDatePicker" style={{'float': 'none'}}>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    onChange={(val) => setAnchorChannel(val)}
                    placeholder="Select channel..."
                    value={anchorChannel}
                    filterOption={filterOptionFunction}
                  >
                    {getSlackChannelsQuery.data
                      ? getSlackChannelsQuery.data.conversations.map((conversation) => {
                          return (
                            <Option
                              key={conversation.id}
                              value={conversation.id}
                              label={conversation.name}
                            >
                              {conversation.name}
                            </Option>
                          );
                        })
                      : []}
                  </Select>
                  <br />
                  <span style={{fontSize: '12px'}}><i>Note: channels above will update every 24 hrs</i></span>
                </div>
              ) : null}

              {/* show date picker if specific_date chosen */}
              {anchorTimeType == "specific_date" ? (
                <div className="anchorSecondaryLine">
                  <div id="stepDatePicker">
                    <DatePicker onChange={(date) => setAnchorDate(date)} value={anchorDate} />
                  </div>
                  <div id="stepDatePicker">
                    <TimePicker 
                      value={anchorDateTime}
                      minuteStep={30}
                      use12Hours={true}
                      allowClear={false}
                      onChange={(date) => {
                        const result = roundToLastHalfHour(date);
                        if (result.adjusted) {
                          setIsInvalidTimeModalOpen(true);
                        }
                        setAnchorDateTime(result.date);
                      }}
                      format={timePickerFormat}
                      style={{'marginLeft': '5px'}}
                    />
                  </div>
                  <div style={{clear: 'both', 'paddingTop': '10px'}}>
                  <i>{`The time selected above will be in your timezone (${moment.tz.guess()})`}</i>
                  </div>
                </div>
              ) : null}
            </div>

            {/* ------------ steps/actions ------------ */}
            <br />
              <div className="sectionWrapper">
              <h3>3. Add a step to your experience</h3>
              <Button
                className="stepButton"
                type="dashed"
                onClick={() => addStep("message")}
              >
                <MessageOutlined /> Send Message
              </Button>
              <Button className="stepButton" type="dashed" onClick={() => addStep("shoutout")}>
                <HeartOutlined /> Send Shoutout
              </Button>
              <Button className="stepButton" type="dashed" onClick={() => addStep("survey")}>
                <FormOutlined /> Send Survey
              </Button>
            </div>

            {/* ------------ actual steps added ------------ */}
            <br />
            <h3>4. All experience steps</h3>
            {steps.length == 0 ? (
              <Alert message="Add a step above to start building your experience!" type="info" />
              ) : (
                <Collapse defaultActiveKey={["1"]}>
                  {steps.map((step, index) => {
                    return (
                      <Panel
                        header={
                          <Input
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) =>
                              updatingStepTitle(e.target.value, index)
                            }
                            value={step.name}
                            style={{ width: "90%", marginTop: "5px" }}
                          />
                        }
                        key={step.id}
                        extra={genStepOptionsMenu(step)}
                      >
                        <PanelSteps step={step} index={index} slackUsersQuery={getSlackUsersQuery} slackChannelsQuery={getSlackChannelsQuery} steps={steps} varNames={varNames} setSteps={setSteps} anchorTimeType={anchorTimeType} /> 
                      </Panel>
                    );
                  })}
                </Collapse>
              )}

            {/* ------------ error messages ------------ */}  
            {errorMessages.length ? (
              <>
                <br></br>
                <Alert
                  description={
                    <>
                      <strong>The following errors occurred:</strong>
                      {errorMessages.map((message) => {
                        return <div>• {message}</div>;
                      })}
                    </>
                  }
                  type="error"
                />
                <br />
              </>
            ) : null}

          </Col>

          {/* ------------ spacer ------------ */}
          <Col span={1}></Col>

          {/* ------------ experience options & active users ------------ */}
          <Col span={9}>

            <Card
              size="small"
              title="Experience Options"
              // extra={<a href="#">Save</a>}
            >
              <Checkbox
                disabled={
                  anchorTimeType == "specific_date" ? false : true
                }
                checked={recurringSequence}
                onChange={() => setRecurringSequence(!recurringSequence)}
              >
                Make this experience restart after it completes{" "}
                <span id="recurringSequenceDisclaimer">
                  <br /> (only available for experiences that start after a
                  specified date)
                </span>
              </Checkbox>
              {recurringSequence == true ? (
                <div id="recurringSequenceDaysInput">
                  Recur after &nbsp;
                  <InputNumber
                    min={1}
                    max={180}
                    style={{ width: 70 }}
                    step={1}
                    value={recurringSequenceDays}
                    onChange={(value) => {
                      setRecurringSequenceDays(value);
                    }}
                  />
                  &nbsp; days
                </div>
              ) : null}

              {/* ----------- variables ----------- */}
              <div className="leftSideBar">
                <h4>Custom User Variables</h4>
                <p id="userVarsDisclaimer">
                  (use these in messages with the notation {"{variable name}"} inside of message steps on the left hand side)
                </p>
                {varNames.map((userVar, index) => {
                  return (
                    <div id="userVarList">
                      <Input
                        placeholder="Variable name"
                        style={{ width: 110 }}
                        value={userVar['name']}
                        onChange={(e) => {updatingVarNames('name', e.target.value, index)}}
                      />{" "}
                      &nbsp;
                      <Select
                        showSearch
                        style={{ width: 190 }}
                        onChange={(value) => {updatingVarNames('user_id', value, index)}}
                        placeholder="Select user..."
                        value={userVar['user_id']}
                        filterOption={filterOptionFunction}
                      >
                        {getSlackUsersQuery.data
                          ? getSlackUsersQuery.data.conversations.map(
                              (user) => {
                                return (
                                  <Option
                                    key={user.id}
                                    value={user.id}
                                    label={user.real_name}
                                  >
                                    {user.real_name}
                                  </Option>
                                );
                              }
                            )
                          : []}
                      </Select>
                      <MinusCircleOutlined id="deleteUserVar" onClick={() => removeVar(index)} /> 
                    </div>
                  );
                })}

                <br />
                <Button
                  className="stepButton"
                  type="dashed"
                  onClick={() => addVar()}
                >
                  <PlusOutlined /> Add User Variable
                </Button>
              </div>
            </Card>

            {/* ----------- active users (in-sequence) ----------- */}
            {activeUsers && activeUsers.length ? (
              <>
                <br />
                <Card
                  size="small"
                  title="Current Employees in Experience"
                >
                  {activeUsers.join(", ")}
                </Card>
              </>
            ) : null}
          </Col>
        </Row>
      </>
    </Modal>
  );

  // ------------ Add New Buddy: Form & Methods ------------
  const [isBuddyModalOpen, setIsBuddyModalOpen] = useState(false);
  const [employeeSelectedForBuddy, setEmployeeSelectedForBuddy] = useState(null);
  const [employeeBuddiesSelected, setEmployeeBuddiesSelected] = useState([]);
  const [employeeManagerSelected, setEmployeeManagerSelected] = useState(null);
  const [employeeSelectedEmail, setEmployeeSelectedEmail] = useState(null);
  const [employeeStartDateSelected, setEmployeeStartDateSelected] = useState(null);
  const [employeeTitle, setEmployeeTitle] = useState('');
  const [employeeDepartment, setEmployeeDepartment] = useState('');
  const [employeeLocation, setEmployeeLocation] = useState('');
  const [employeeGroups, setEmployeeGroups] = useState([]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');

  const [isAddingNewDepartment, setIsAddingNewDepartment] = useState(false);
  const [isAddingNewLocation, setIsAddingNewLocation] = useState(false);
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);

  const addNewBuddy = (
    <Modal
      forceRender // pre-render children
      title={isEditingPairing ? "Edit Employee Pairing/Info" : "Add New Employee Pairing/Info"}
      open={isBuddyModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          form="buddyForm"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={addOrEditEmployeeBuddyPairing}
          loading={saveButtonIsLoading}
          disabled={saveButtonIsLoading}
        >
          Save
        </Button>,
      ]}
    >
      <>
          <h5>Employee Slack User</h5>
          <Select
            showSearch
            style={{ width: '100%' }}
            onChange={setEmployeeSelectedForBuddy}
            placeholder="Select user..."
            value={employeeSelectedForBuddy}
            filterOption={filterOptionFunction}
            >
            {getSlackUsersQuery.data
              ? getSlackUsersQuery.data.conversations.map((user) => {
                  return (
                    <Option
                      key={user.id}
                      value={user.id}
                      label={user.real_name}
                    >
                      {user.real_name}
                    </Option>
                  );
                })
              : []}
          </Select>   

          <h5 style={{ marginTop: "15px" }}>Employee Work Email (if not in Slack yet)</h5>
          <Input
            onChange={(e) => {
              setEmployeeSelectedEmail(e.target.value);
            }}
            value={employeeSelectedEmail}
            style={{ width: '100%', marginBottom: "5px", display: "block" }}
            placeholder="Enter work email..."
          />

          <h5 id="stepBlock">Onboarding Buddies (optional)</h5>
          <Select
            showSearch
            mode="multiple"
            style={{ width: '100%' }}
            onChange={setEmployeeBuddiesSelected}
            placeholder="Select user(s)..."
            value={employeeBuddiesSelected}
            filterOption={filterOptionFunction}
            >
            {getSlackUsersQuery.data
              ? getSlackUsersQuery.data.conversations.map((user) => {
                  return (
                    <Option
                      key={user.id}
                      value={user.id}
                      label={user.real_name}
                    >
                      {user.real_name}
                    </Option>
                  );
                })
              : []}
          </Select>   

          <h5 id="stepBlock">Manager (optional)</h5>
          <Select
            showSearch
            style={{ width: '100%' }}
            onChange={setEmployeeManagerSelected}
            placeholder="Select user..."
            value={employeeManagerSelected}
            filterOption={filterOptionFunction}
            >
            {getSlackUsersQuery.data
              ? getSlackUsersQuery.data.conversations.map((user) => {
                  return (
                    <Option
                      key={user.id}
                      value={user.id}
                      label={user.real_name}
                    >
                      {user.real_name}
                    </Option>
                  );
                })
              : []}
          </Select>   
          
          <h5 id="stepBlock">Start Date (optional)</h5>
          <div>
            <DatePicker style={{'width': '100%'}} onChange={(date) => setEmployeeStartDateSelected(date)} value={employeeStartDateSelected ? employeeStartDateSelected : null} />
          </div>

          <h5 style={{ marginTop: "15px" }}>Employee Title (optional)</h5>
          <Input
            onChange={(e) => setEmployeeTitle(e.target.value)}
            value={employeeTitle}
            style={{ width: '100%', marginBottom: "5px", display: "block" }}
            placeholder="Enter employee title..."
          />

          <h5 style={{ marginTop: "15px" }}>Employee Department (optional)</h5>
          <Select
            showSearch
            style={{ width: '100%' }}
            onChange={(value) => {
              if (value === 'new') {
                setIsAddingNewDepartment(true);
                setEmployeeDepartment('');
              } else {
                setIsAddingNewDepartment(false);
                setEmployeeDepartment(value);
              }
            }}
            placeholder="Select or enter department..."
            value={isAddingNewDepartment ? 'new' : employeeDepartment}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {departmentOptions.map((department) => (
              <Option key={department} value={department}>
                {department}
              </Option>
            ))}
            <Option key="new" value="new">
              New...
            </Option>
          </Select>
          {isAddingNewDepartment && (
            <div style={{ marginTop: '8px' }}>
              <Input
                style={{ width: '100%' }}
                value={employeeDepartment}
                onChange={(e) => {
                  setEmployeeDepartment(e.target.value);
                }}
                placeholder="Enter new department (and click enter)..."
                onPressEnter={() => {
                  if (employeeDepartment && !departmentOptions.includes(employeeDepartment)) {
                    setDepartmentOptions(prev => [...prev, employeeDepartment]);
                    setIsAddingNewDepartment(false);
                  }
                }}
              />
            </div>
          )}

          <h5 style={{ marginTop: "15px" }}>Employee Location (optional)</h5>
          <Select
            showSearch
            style={{ width: '100%' }}
            onChange={(value) => {
              if (value === 'new') {
                setIsAddingNewLocation(true);
                setEmployeeLocation('');
              } else {
                setIsAddingNewLocation(false);
                setEmployeeLocation(value);
              }
            }}
            placeholder="Select or enter location..."
            value={isAddingNewLocation ? 'new' : employeeLocation}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {locationOptions.map((location) => (
              <Option key={location} value={location}>
                {location}
              </Option>
            ))}
            <Option key="new" value="new">
              New...
            </Option>
          </Select>
          {isAddingNewLocation && (
            <div style={{ marginTop: '8px' }}>
              <Input
                style={{ width: '100%' }}
                value={employeeLocation}
                onChange={(e) => {
                  setEmployeeLocation(e.target.value);
                }}
                placeholder="Enter new location (and click enter)..."
                onPressEnter={() => {
                  if (employeeLocation && !locationOptions.includes(employeeLocation)) {
                    setLocationOptions(prev => [...prev, employeeLocation]);
                    setIsAddingNewLocation(false);
                  }
                }}
              />
            </div>
          )}

          <h5 style={{ marginTop: "15px" }}>Employee Groups (optional)</h5>
          <Select
            showSearch
            style={{ width: '100%' }}
            mode="multiple"
            onChange={(value) => {
              if (value.includes('new')) {
                setIsAddingNewGroup(true);
                setEmployeeGroups(value.filter(v => v !== 'new'));
              } else {
                setIsAddingNewGroup(false);
                setEmployeeGroups(value);
              }
            }}
            placeholder="Select or enter group..."
            value={employeeGroups}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {groupOptions.map((group) => (
              <Option key={group} value={group}>
                {group}
              </Option>
            ))}
            <Option key="new" value="new">
              New...
            </Option>
          </Select>
          {isAddingNewGroup && (
            <div style={{ marginTop: '8px' }}>
              <Input
                style={{ width: '100%' }}
                value={newGroupName}
                placeholder="Enter new group (and click enter)..."
                onChange={(e) => {
                  setNewGroupName(e.target.value);
                }}
                onPressEnter={() => {
                  if (newGroupName && !groupOptions.includes(newGroupName)) {
                    setGroupOptions(prev => [...prev, newGroupName]);
                    setEmployeeGroups(prev => [...prev, newGroupName]);
                    setNewGroupName('');
                    setIsAddingNewGroup(false);
                  }
                }}
              />
            </div>
          )}

          {/* ------------ error messages ------------ */}  
          {errorMessages.length ? (
            <>
              <br/><br/>
              <Alert
                description={
                  <>
                    <strong>The following errors occurred:</strong>
                    {errorMessages.map((message) => {
                      return <div>• {message}</div>;
                    })}
                  </>
                }
                type="error"
              />
              <br />
            </>
          ) : null}

        </>
    </Modal>
  );

  // ------------ Actual Page ------------ //

  // tab 1 content (list of experiences)
  const experiencesTabContent = (
    <>
      {createNewExperience}
      <Space wrap>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          onClick={() => {
            clearAllStates();
            setIsModalOpen(true);
          }}
        >
          Create New Experience
        </Button>
      </Space>
      <div style={{ marginTop: "30px" }}></div>
      {viewingMessagesSentForExperience ? <MessagesSentTable expId={viewingMessagesSentForExperience} setExpId={setViewingMessagesSentForExperience} /> : <ExperiencesTable getCBExperiencesQuery={getCBExperiencesQuery} editClickedOnExperience={(exp) => editExperience(exp)} deletedClickedOnExperience={deleteExperience} cloneExperience={cloneExperience} pauseExperience={pauseExperience} setExpId={setViewingMessagesSentForExperience} /> }
    </>
  );

  // tab 2 content (list of buddies/managers)
  const buddiesTabContent = (
    <>
      {addNewBuddy}
      <Space wrap>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          onClick={() => {
            clearAllStates();
            setIsBuddyModalOpen(true);
          }}
        >
          Add Pairing/Employee Info
        </Button>
      </Space>
      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
        <Switch
          onChange={(checked) => updateNotifSettings(checked, getCBExperienceSettings.data.user_to_notify)}
          checked={getCBExperienceSettings.data ? getCBExperienceSettings.data.new_user_notifs_on : false}
        />
        <label> Notify certain admins when new users are added to Slack for the first time (to fill in their onboarding buddy/manager and info)</label>
        {getCBExperienceSettings.data && getCBExperienceSettings.data.new_user_notifs_on ? (
          <>
            <br /><br />
            <Select
                showSearch
                mode="multiple"
                style={{ width: "360px" }}
                onChange={(value) => updateNotifSettings(getCBExperienceSettings.data.new_user_notifs_on, value)}
                value={getCBExperienceSettings.data ? getCBExperienceSettings.data.user_to_notify : []}
                filterOption={filterOptionFunction}
                placeholder="Add users to notify here..."
              >
                {getSlackUsersQuery.data
                  ? getSlackUsersQuery.data.conversations.map((conversation) => {
                      return (
                        <Option
                          key={conversation.id}
                          value={conversation.id}
                          label={conversation.real_name}
                        >
                          {conversation.real_name}
                        </Option>
                      );
                    })
                  : []}
              </Select>
            </>
        ) : null }
        <p style={{ marginTop: "10px", fontSize: "12px" }}>
          <strong>Note:</strong> If you would like to import a list of employees and their data to begin with, please <a href="mailto:support@getculturebot.com">email us</a> and we will import the list for you.
        </p>
      </div>
      <BuddiesManagersTable deletePairing={deleteEmployeeBuddyPairing} editPairing={showEditingPairingModel} getEmployeeBuddyPairingsQuery={getEmployeeBuddyPairingsQuery} />
    </>
  );

  let mainArea = (
    <div>
      <div>
        <Title>
          <CompassOutlined style={{ fontSize: "32px" }} /> Employee Experiences
        </Title>
        <p>
          Create pre-configured experiences with sequences of messages and
          surveys to send to employees and/or channels. For example, setup an
          experience for new hires to properly onboard and meet certain people
          in their first 30 days. 
          <br/><br/>
          Use the 'Employee Pairings & Info' tab to add
          employee pairings (e.g. onboarding buddy and manager) and employee info 
          (e.g. department, location, title, groups) to the experience (this information 
          can also be used to send targeted <a href={`/app/surveys`}>surveys</a>). 
        </p>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="middle"
          items={[
            {
              label: "Experiences",
              key: 1,
              children: experiencesTabContent,
            },
            {
              label: "Employee Pairings & Info",
              key: 2,
              children: buddiesTabContent,
            },
          ]}
        />
      </div>
    </div>
  );

  return (
    <>
      <MainContainer>
        <div className="main-container">
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}
