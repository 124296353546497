import React from 'react';
import Navbar from "./Navbar/Navbar";
import { Card, Typography} from 'antd';
const { Title, Text } = Typography;

export default function ErrorPage() {
  return (
    <div>
        <Navbar />
        <Card style={{ padding: 10, margin:20 }}>
          <Title>Page was not found</Title>
          <div style={{marginTop:'10px'}}></div>
          <Text>Please ensure the url is correct</Text>
        </Card>
    </div>
  );
}
