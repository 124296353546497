import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { SketchPicker } from "react-color";
import {
  VideoCameraOutlined,
  SaveOutlined,
  DragOutlined,
  UndoOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Webcam from "react-webcam";
import { Button, Input, message, Spin, Carousel, Card } from "antd";
import "antd/dist/antd.css";
import "./CelebrationCard.css";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import Rotatable from "react-rotatable";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../helpers/requestHelpers";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../../hooks/basicAuthCheck";

const { TextArea } = Input;

const CelebrationCard = ({ userName = "John Doe" }) => {

  // determine if admin or not (also used for auth checking to make sure user is logged in here)
  const getTokenPermissionsQuery = useQuery(
    ["GET_TOKEN_PERMISSIONS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getTokenPermissions`);
    },
    { refetchOnWindowFocus: false }
  );

  // type of card, user_id and team_id of person being celebrated, and year of the card (all from URL params)
  let params = useParams();
  const navigate = useNavigate();

  const celebration_user_id = params.user_id;
  const celebration_team_id = params.team_id;
  const celebration_year = params.year;
  const is_showcase = params.showcase || false; // params.showcase expected to be undefined in the non showcase case
  const showcase_submitter_row_id = params.showcase_submitter_row_id; // params.showcase_submitter_row_id expected to be undefined in the non showcase case
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const celebration_type = pathParts[2] || params.celebration_type;

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [signatures, setSignatures] = useState([]);
  const [color, setColor] = useState("#000");
  const [video, setVideo] = useState(null);
  const [userMessage, setUserMessage] = useState("");
  const sigCanvasRef = useRef(null);
  const webcamRef = useRef(null);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [videoPosition, setVideoPosition] = useState({ x: 0, y: 0 });
  const [penSize, setPenSize] = useState(1);
  const [isEraser, setIsEraser] = useState(false);
  const [canvasEmojis, setCanvasEmojis] = useState([]);
  const [canvasTexts, setCanvasTexts] = useState([]);
  const [fontSize, setFontSize] = useState(16);
  const [canvasSize, setCanvasSize] = useState({ width: 500, height: 300 });
  const [celebrateeUserName, setCelebrateeUserName] = useState("");
  const [submitterUserName, setSubmitterUserName] = useState("");
  const [totalCardSubmissions, setTotalCardSubmissions] = useState(0);
  const emojiList = ["🎉", "🎈", "🎂", "🎁", "🎊", "✨"];
  const cardRef = useRef();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isVideoHovered, setIsVideoHovered] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [webcamActive, setWebcamActive] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const recordedChunksRef = useRef([]);
  const [cards, setCards] = useState([]);
  const [nextCard, setNextCard] = useState(false);

  // loads the card formatting for the canvas (for those submitting cards)
  const cardFormattingQuery = useQuery(
    ["GET_CELEBRATION_CARD", celebration_user_id, celebration_team_id, celebration_year, celebration_type],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCelebrationCardSubmission`,
        setTokenErrorType,
        [{ name: "celebrationUserId", value: celebration_user_id }, { name: "celebrationTeamId", value: celebration_team_id }, { name: "celebrationYear", value: celebration_year }, { name: "celebrationType", value: celebration_type }]
      );
    },
    { refetchOnWindowFocus: false, 
      enabled: !is_showcase,
      onError: (error) => {
        console.log("Failed to find submission for birthday card:", error);
        clearCardState();
      }
    }
  );

  const clearCardState = () => {

    localStorage.removeItem("celebrationCardState");

    // Clear the canvas -- no data found for this year
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }

    // Reset state variables
    setCanvasTexts([]);
    setCanvasEmojis([]);
    setVideo(null);
    setVideoPosition({ x: 0, y: 0 });
    setIsCanvasEmpty(true);
    setShowInstructions(true);
  }

  useEffect(() => {
    if (cardRef.current) {
      const { offsetWidth, offsetHeight } = cardRef.current;
      setCanvasSize({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  // this will hide the instructions when the user starts drawing
  useEffect(() => {
    const canvas = sigCanvasRef.current?.getCanvas();
    if (canvas) {
      const handleMouseDown = () => {
        handleBeginDrawing();
      };
      canvas.addEventListener("mousedown", handleMouseDown);
      return () => {
        canvas.removeEventListener("mousedown", handleMouseDown);
      };
    }
  }, []);

  // Load from localStorage on mount
  useEffect(() => {
    const savedState = localStorage.getItem("celebrationCardState");
    if (savedState) {
      const { texts, emojis, videoUrl, videoPos, drawing, size } =
        JSON.parse(savedState);
      setCanvasTexts(texts || []);
      setCanvasEmojis(emojis || []);
      setVideo(videoUrl || null);
      setVideoPosition(videoPos || { x: 0, y: 0 });
      setCanvasSize(size || { width: 800, height: 600 });

      // Apply saved dimensions and font size
      texts.forEach((text, index) => {
        const { width, height, fontSize } = text;
        // Ensure these values are used when rendering
        // Example: set initial dimensions and font size
      });

      const hasContent =
        (texts && texts.length > 0) ||
        (emojis && emojis.length > 0) ||
        videoUrl ||
        drawing;
      setIsCanvasEmpty(!hasContent);
      setShowInstructions(!hasContent);

      if (drawing) {
        const img = new Image();
        img.src = drawing;
        img.onload = () => {
          const ctx = sigCanvasRef.current.getCanvas().getContext("2d");
          ctx.clearRect(0, 0, size.width, size.height);
          ctx.drawImage(img, 0, 0, size.width, size.height);
        };
      }
    }
  }, []);

  const cardShowcaseQuery = useQuery(
    ["GET_CELEBRATION_CARD_SHOWCASE", showcase_submitter_row_id, nextCard],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCelebrationCardShowcaseForUser`,
        setTokenErrorType,
        [
          { name: "submitter_row_id", value: showcase_submitter_row_id },
          { name: "celebration_user_id", value: celebration_user_id },
          { name: "year", value: celebration_year },
          { name: "type", value: celebration_type },
          { name: "next", value: nextCard },
        ]
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!is_showcase,
      onError: (error) => {
        console.log("Failed to find submission for birthday card:", error);
        clearCardState();
      },
      onSuccess: (data) => {
        if (data?.submission?.id && nextCard) {
          const newPath = `${pathParts.slice(0, -1).join('/')}/${data.submission.id}`;
          
          // Only navigate if the path is different
          if (location.pathname !== newPath) {
            navigate(newPath);
            setNextCard(false); // Reset nextCard after navigation
          }
        }
      }
    }
  );

  // loads the submitter and celebratee user names
  useEffect(() => {
    if (cardShowcaseQuery.data?.submission) {
      setSubmitterUserName(cardShowcaseQuery.data.submission.submitter_user_name || "");
      setCelebrateeUserName(cardShowcaseQuery.data.submission.celebration_user_name || "");
      setTotalCardSubmissions(cardShowcaseQuery.data.total_count);
    }
  }, [cardShowcaseQuery.data]);

  useEffect(() => {
    if (cardFormattingQuery.data?.submission) {
      setCelebrateeUserName(cardFormattingQuery.data.submission.name || "");
    } else {
      setCelebrateeUserName(cardFormattingQuery.data?.celebratee_name || "");
      clearCardState();
    }
  }, [cardFormattingQuery.data]);

  // -------------------------- CANVAS HANDLING METHODS ----------------------------------

  // loads the card formatting for the canvas 
  useEffect(() => {
    if (cardFormattingQuery.data?.submission || cardShowcaseQuery.data?.submission) {

      const cardFormatting = cardFormattingQuery.data?.submission || cardShowcaseQuery.data?.submission.card_formatting;
      
      setCanvasTexts(cardFormatting.texts || []);
      setCanvasEmojis(cardFormatting.emojis || []);
      setVideo(cardFormatting.videoUrl || null);
      setVideoPosition(cardFormatting.videoPos || { x: 0, y: 0 });
      setCanvasSize(cardFormatting.size || { width: 800, height: 600 });

      const hasContent =
        (cardFormatting.texts && cardFormatting.texts.length > 0) ||
        (cardFormatting.emojis && cardFormatting.emojis.length > 0) ||
        cardFormatting.videoUrl ||
        cardFormatting.drawing;
      setIsCanvasEmpty(!hasContent);
      setShowInstructions(!hasContent);

      if (cardFormatting.drawing) {
        const img = new Image();
        img.src = cardFormatting.drawing;
        img.onload = () => {
          const ctx = sigCanvasRef.current.getCanvas().getContext("2d");
          ctx.clearRect(0, 0, cardFormatting.size.width, cardFormatting.size.height);
          ctx.drawImage(img, 0, 0, cardFormatting.size.width, cardFormatting.size.height);
        };
      }
    }
  }, [cardFormattingQuery.data, cardShowcaseQuery.data]);

  const handleDrop = (e) => {
    const index = e.dataTransfer.getData("text/plain");
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setSignatures((prevSignatures) =>
      prevSignatures.map((sig, i) =>
        i === parseInt(index) ? { ...sig, x, y } : sig
      )
    );
  };

  const deleteElement = (type, index) => {
    if (type === "signature") {
      setSignatures((prevSignatures) =>
        prevSignatures.filter((_, i) => i !== index)
      );
    } else if (type === "message") {
      setCanvasTexts((prevTexts) => prevTexts.filter((_, i) => i !== index));
    } else if (type === "emoji") {
      setCanvasEmojis((prevEmojis) => prevEmojis.filter((_, i) => i !== index));
    } else if (type === "video") {
      setVideo(null);
    }
  };

  // Function to undo the last stroke
  const handleUndo = () => {
    if (sigCanvasRef.current) {
      const data = sigCanvasRef.current.toData();
      if (data) {
        data.pop(); // Remove the last stroke
        sigCanvasRef.current.fromData(data);
        if (data.length === 0) {
          localStorage.removeItem("drawing");
        } else {
          const canvas = sigCanvasRef.current.getCanvas();
          const drawing = canvas.toDataURL("image/png");
          localStorage.setItem("drawing", drawing);
        }
      }
    }
  };

  // Function to clear the canvas
  const handleClear = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
      localStorage.removeItem("drawing");
      setIsCanvasEmpty(true);
    }
  };

  // Function to handle emoji drop
  const handleEmojiDrop = (e, emoji) => {
    const newEmoji = {
      emoji,
      x: e.clientX - 150, // Adjust for canvas position
      y: e.clientY - 50, // Adjust for canvas position
      width: 50, // Initial width
      height: 50, // Initial height
      rotation: 0, // Initial rotation
    };
    setCanvasEmojis((prev) => {
      const updatedEmojis = [...prev, newEmoji];
      setIsCanvasEmpty(false);
      setShowInstructions(false);
      preserveDrawing();
      return updatedEmojis;
    });
  };

  const preserveDrawing = () => {
    const canvas = sigCanvasRef.current.getCanvas();
    const drawing = canvas.toDataURL("image/png");
    localStorage.setItem("drawing", drawing);
    console.log("preserving drawing: ", drawing);
  };

  // Function to handle text submission
  const handleTextSubmit = () => {
    const newText = {
      text: userMessage,
      x: 50,
      y: 100,
      width: 150,
      height: 50,
      fontSize,
    };
    setCanvasTexts((prev) => [...prev, newText]);
    setUserMessage("");
  };

  // Function to save the current state to localStorage
  const handleSave = async () => {
    try {
      const canvas = sigCanvasRef.current.getCanvas();
      const drawing = sigCanvasRef.current.isEmpty() ? localStorage.getItem("drawing") : canvas.toDataURL("image/png");
      console.log("drawing: ", drawing, sigCanvasRef.current.isEmpty());
      const card_state = {
        texts: canvasTexts,
        emojis: canvasEmojis,
        videoUrl: video,
        videoPos: videoPosition,
        drawing: drawing,
        size: { width: 800, height: 600 },
      };
      localStorage.setItem("celebrationCardState", JSON.stringify(card_state));

      // Call the API endpoint using doPostRequest
      const response = await doPostRequest(
        `${getBaseUrl()}/api/upsertCelebrationCardSubmission`, 
        { celebrationUserId: celebration_user_id, celebrationTeamId: celebration_team_id, celebrationYear: celebration_year, cardFormatting: card_state, celebrationType: celebration_type },
        setTokenErrorType
      );

      console.log(response);

      if (response.success) {
        message.success("Edits successfully saved!");
      } else {
        message.error("Failed to save edits, please try again.");
      }
    } catch (error) {
      message.error("Failed to save edits, please try again.", error);
    }
  };

  const handleDelete = (type, index) => {
    if (type === "text") {
      setCanvasTexts((prevTexts) => prevTexts.filter((_, i) => i !== index));
    } else if (type === "emoji") {
      setCanvasEmojis((prevEmojis) => prevEmojis.filter((_, i) => i !== index));
    }
    // Add more conditions if needed for other types
  };

  // Utility function to measure text size
  const measureText = (text, fontSize) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px Arial`;
    const lines = text.split('\n');
    const width = Math.max(...lines.map(line => context.measureText(line).width));
    const height = lines.length * fontSize * 1.2; // Approximate line height
    return { width, height };
  };

  const handleBeginDrawing = () => {
    setShowInstructions(false);
  };

  const handleStartCaptureClick = () => {
    
    // Clear the existing video and recorded chunks before starting a new recording
    setVideo(null);
    setRecordedChunks([]);
    recordedChunksRef.current = [];

    if (webcamRef.current && webcamRef.current.stream) {
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm;codecs=vp8,opus",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    } else {
      console.error("Webcam stream is not available.");
      message.error("Failed to access webcam stream. Please try again.");
    }
  };

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => {
        const updatedChunks = prev.concat(data);
        recordedChunksRef.current = updatedChunks;
        return updatedChunks;
      });
    }
  };

  const handleStopCaptureClick = () => {

    console.log("Stopping capture...");
    mediaRecorderRef.current.addEventListener("stop", async () => {
      if (recordedChunksRef.current.length) {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm",
        });
  
        const formData = new FormData();
        formData.append("video", blob);
  
        try {
          const response = await doPostRequest(
            `${getBaseUrl()}/api/saveCelebrationCardVideo`,
            formData,
            setTokenErrorType
          );
  
          if (response.success) {
            message.success("Video successfully saved!");
            setVideo(response.url);
            console.log("Video successfully saved!", response.url);
          } else {
            message.error("Failed to save video, please try again.");
          }
        } catch (error) {
          message.error("Failed to save video, please try again.");
        }
      } else {
        console.log("No recorded chunks available.");
      }
    });

    mediaRecorderRef.current.stop();
    setCapturing(false);
    setWebcamActive(false);
    setCountdown(30);
  };

  const handleStartWebcam = () => {
    setWebcamActive(true);
  };

  // Function to start the countdown timer (for recording video)
  useEffect(() => {
    let timer;
    if (capturing) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            handleStopCaptureClick();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [capturing]);

  // Use ctrl+s to save the card from keyboard commands
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        handleSave();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSave]);

  const handleNextCard = () => {
    setNextCard(true);
    cardShowcaseQuery.refetch();
  };

  const handleResizeStop = (e, data, index) => {
    const newTexts = [...canvasTexts];
    newTexts[index] = {
      ...canvasTexts[index],
      width: data.size.width,
      height: data.size.height,
    };
    setCanvasTexts(newTexts);
    preserveDrawing();
    saveStateToLocalStorage(newTexts, canvasEmojis, video, videoPosition);
  };

  const saveStateToLocalStorage = (texts, emojis, videoUrl, videoPos) => {
    const cardState = {
      texts,
      emojis,
      videoUrl,
      videoPos,
      size: canvasSize,
    };
    localStorage.setItem("celebrationCardState", JSON.stringify(cardState));
  };

  return (
    <div className="birthday-page">
      <div className="birthday-message">
        {celebration_type === 'birthday' ? 
          `Happy Birthday${celebrateeUserName ? `, ${celebrateeUserName}` : ''}!` : 
          `Happy Work Anniversary${celebrateeUserName ? `, ${celebrateeUserName}` : ''}!`}
      </div>
      <div
        className="canvas"
        ref={cardRef}
        style={{ position: "relative" }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {(cardFormattingQuery.isFetching || cardShowcaseQuery.isFetching) && (
          <div className="loading-overlay">
            <Spin size="large" />
          </div>
        )}
        {!cardFormattingQuery.isLoading && showInstructions && (
          <div className="canvas-instructions">
            Start creating your {celebration_type} card! Use the toolbar on the right to add text, emojis, and videos. 
            <br/><br/>
            Draw directly on the canvas or add your personal touch with colors and pen sizes. 
            <br/><br/>
            Click 'save edits' to save your changes. Your birthday card will be sent to {celebrateeUserName} on their birthday!
          </div>
        )}
        <SignatureCanvas
          penColor={isEraser ? "#fff" : color}
          canvasProps={{
            className: "sigCanvas",
            style: {
              position: "absolute",
              top: 0,
              left: 0,
              width: "800px",
              height: "600px",
              zIndex: 1,
            },
            width: 800,
            height: 600,
          }}
          ref={sigCanvasRef}
          minWidth={is_showcase ? 0 : penSize}
          maxWidth={is_showcase ? 0 : penSize}
        />
        <div className="messages">
          {canvasTexts.map((text, index) => {
            const { width, height } = measureText(text.text, text.fontSize);
            return (
              <ResizableBox
                key={index}
                width={text.width || 150}
                height={text.height || 50}
                minConstraints={[100, 50]}
                maxConstraints={[canvasSize.width * 0.8, 200]}
                onResizeStop={(e, data) => handleResizeStop(e, data, index)}
                handle={<span className="resize-handle" style={{ position: 'absolute', bottom: 0, right: 0, cursor: 'nwse-resize', padding: '5px' }}>↔</span>}
                style={{
                  zIndex: 3,
                  border: "1px dashed #ccc",
                  position: "absolute",
                  left: text.x,
                  top: text.y,
                  fontSize: `${text.fontSize}px`,
                }}
              >
                <div
                  style={{ width: "100%", height: "100%", position: "relative" }}
                >
                  <Draggable
                    position={{ x: 0, y: 0 }}
                    onStop={(e, data) => {
                      const newTexts = [...canvasTexts];
                      newTexts[index] = {
                        ...text,
                        x: text.x + data.x,
                        y: text.y + data.y,
                      };
                      setCanvasTexts(newTexts);
                      preserveDrawing();
                    }}
                  >
                    <div
                      className="element-container"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      style={{
                        fontSize: `${text.fontSize}px`,
                        cursor: "move",
                        wordWrap: "break-word",
                        position: "relative",
                        padding: "5px",
                        maxWidth: "100%",
                      }}
                    >
                      {text.text}
                      <div
                        className="delete-icon"
                        onClick={() => handleDelete("text", index)}
                        style={{
                          visibility: hoveredIndex === index ? "visible" : "hidden",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: "50%",
                          padding: "2px",
                          zIndex: 3,
                        }}
                      >
                        X
                      </div>
                    </div>
                  </Draggable>
                </div>
              </ResizableBox>
            );
          })}
          {canvasEmojis.map((emoji, index) => (
            <ResizableBox
              key={index}
              width={emoji.width}
              height={emoji.height}
              minConstraints={[30, 30]}
              maxConstraints={[150, 150]}
              onResizeStop={(e, data) => {
                const newEmojis = [...canvasEmojis];
                newEmojis[index] = { ...emoji, width: data.size.width, height: data.size.height };
                setCanvasEmojis(newEmojis);
                preserveDrawing();
              }}
              handle={<span className="resize-handle" style={{ position: 'absolute', bottom: 0, right: 0, cursor: 'nwse-resize', padding: '5px' }}>↔</span>}
              style={{ zIndex: 2, border: '1px dashed #ccc', position: 'absolute', left: emoji.x, top: emoji.y }}
            >
              <Draggable
                position={{ x: 0, y: 0 }}
                onStop={(e, data) => {
                  const newEmojis = [...canvasEmojis];
                  newEmojis[index] = { ...emoji, x: emoji.x + data.x, y: emoji.y + data.y };
                  setCanvasEmojis(newEmojis);
                  preserveDrawing();
                }}
              >
                <div
                  className="element-container"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{
                    fontSize: `${emoji.width}px`,
                    cursor: 'move',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }}
                >
                  {emoji.emoji}
                  <div
                    className="delete-icon"
                    onClick={() => handleDelete('emoji', index)}
                    style={{
                      visibility: hoveredIndex === index ? 'visible' : 'hidden',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      cursor: 'pointer',
                      backgroundColor: 'red',
                      color: 'white',
                      borderRadius: '50%',
                      padding: '2px',
                      zIndex: 3,
                    }}
                  >
                    X
                  </div>
                </div>
              </Draggable>
            </ResizableBox>
          ))}
          {video && (
            <Draggable
              defaultPosition={videoPosition}
              onStop={(e, data) => {
                setVideoPosition({ x: data.x, y: data.y });
                preserveDrawing();
              }}
            >
              <ResizableBox
                width={300}
                height={200}
                minConstraints={[150, 100]}
                maxConstraints={[600, 400]}
                style={{ position: "absolute", zIndex: 5 }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                  onMouseEnter={() => setIsVideoHovered(true)}
                  onMouseLeave={() => setIsVideoHovered(false)}
                >
                  {isVideoHovered && (
                    <>
                      <div
                        className="drag-handle"
                        style={{
                          cursor: "move",
                          position: "absolute",
                          top: 0,
                          left: -15,
                          padding: "5px",
                          zIndex: 10,
                        }}
                      >
                        <DragOutlined
                          style={{ fontSize: "16px", color: "black" }}
                        />
                      </div>
                      <div
                        className="delete-icon"
                        onClick={() => deleteElement("video")}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          cursor: "pointer",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: "50%",
                          padding: "2px",
                          zIndex: 10,
                        }}
                      >
                        X
                      </div>
                    </>
                  )}
                  <video
                    src={video}
                    controls
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </ResizableBox>
            </Draggable>
          )}
        </div>
        <SignatureCanvas
          penColor={isEraser ? "#fff" : color}
          canvasProps={{
            className: "sigCanvas",
            style: {
              position: "absolute",
              top: 0,
              left: 0,
              width: "800px",
              height: "600px",
              zIndex: 1,
            },
            width: 800,
            height: 600,
          }}
          ref={sigCanvasRef}
          minWidth={is_showcase ? 0 : penSize}
          maxWidth={is_showcase ? 0 : penSize}
        />
      </div>
      {!is_showcase && (
        <div className="toolbar">
          <h3 style={{ display: "flex", alignItems: "center" }}>
            <EditOutlined style={{ marginRight: "8px" }} />
            Drawing Tools
          </h3>
          <h4>Pen Color</h4>
          <div style={{ padding: "0 10px", width: "100%" }}>
            <SketchPicker
              color={color}
              onChange={(color) => setColor(color.hex)}
              width="100%"
              styles={{
                default: {
                  picker: {
                    boxShadow: "none",
                    borderRadius: "4px",
                  },
                  saturation: {
                    maxHeight: "80px",
                  },
                  hue: {
                    height: "10px",
                  }
                },
              }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <h4 htmlFor="penSizeSlider" style={{ marginRight: "10px" }}>
              Pen Size
            </h4>
            <Input
              id="penSizeSlider"
              type="range"
              min="1"
              max="10"
              value={penSize}
              onChange={(e) => setPenSize(parseInt(e.target.value))}
              style={{ width: "200px" }}
            />
          </div>
          <Button size="small" shape="round" onClick={handleUndo} icon={<UndoOutlined />}>
            Undo
          </Button>
          <Button size="small" shape="round" onClick={handleClear} icon={<DeleteOutlined />} style={{ marginLeft: "5px" }}>
            Reset
          </Button>
          <Button
            size="small"
            shape="round"
            onClick={() => setIsEraser(!isEraser)}
            icon={isEraser ? <EditOutlined /> : <DeleteOutlined />}
            style={{ marginLeft: "5px" }}
          >
            {isEraser ? "Pen" : "Eraser"}
          </Button>
          <Button
            shape="round"
            onClick={handleSave}
            icon={<SaveOutlined />}
            style={{
              position: "fixed",
              bottom: "10px",
              right: "10px",
              backgroundColor: "#1890ff", // Ant Design blue
              color: "white",
              zIndex: 1000,
            }}
          >
            Save Card Edits <span style={{ fontSize: "smaller", fontWeight: "bold" }}>&nbsp;&nbsp;(Ctrl/Cmd + S)</span>
          </Button>

          {/* Emoji section */}
          <div className="emoji-section">
            <h4 style={{ marginBottom: "0px" }}>Add Stickers/Emojis</h4>
            <small>Click and drag to add to card</small>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {emojiList.map((emoji, index) => (
                <div
                  key={index}
                  draggable
                  onDragStart={(e) => e.dataTransfer.setData("text/plain", emoji)}
                  onDragEnd={(e) => handleEmojiDrop(e, emoji)}
                  style={{ fontSize: "24px", cursor: "grab" }}
                >
                  {emoji}
                </div>
              ))}
            </div>
          </div>

          {/* video section */}
          <div className="video-section">
            <h4>Add a Video (30s max)</h4>
            {webcamActive ? (
              <>
                <Webcam audio={true} ref={webcamRef} muted={true} />
                {capturing ? (
                  <Button
                    size="small"
                    shape="round"
                    onClick={handleStopCaptureClick}
                    style={{ marginTop: "0px" }}
                  >
                    Stop Recording ({countdown}s)
                  </Button>
                ) : (
                  <Button
                    size="small"
                    shape="round"
                    icon={<VideoCameraOutlined />}
                    onClick={handleStartCaptureClick}
                    style={{ marginTop: "0px" }}
                  >
                    Start Recording ({countdown}s)
                  </Button>
                )}
              </>
            ) : (
              <Button
                size="small"
                shape="round"
                onClick={handleStartWebcam}
                style={{ marginTop: "0px" }}
              >
                Record Video
              </Button>
            )}
          </div>

          {/* text section */}
          <div className="text-section">
            <h4>Add a Message</h4>
            <TextArea
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Type your message..."
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
            <div
              style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
            >
              <label htmlFor="fontSizeSlider" style={{ marginRight: "10px" }}>
                Font Size
              </label>
              <Input
                id="fontSizeSlider"
                type="range"
                min="12"
                max="32"
                value={fontSize}
                onChange={(e) => setFontSize(parseInt(e.target.value))}
                style={{ width: "100px" }}
              />
            </div>
            <Button
              size="small"
              shape="round"
              onClick={handleTextSubmit}
              style={{ marginTop: "5px" }}
            >
              Add Message to Card
            </Button>
          </div>
        </div>
      )}

      {is_showcase && (
        <Card
          title="Showcase Info"
          bordered={false}
          className="showcase-card"
          headStyle={{ textAlign: "center" }}
        >
          <p><strong>Submitter:</strong> {submitterUserName}</p>
          <p><strong>Total Submissions:</strong> {totalCardSubmissions}</p>
          <Button type="primary" onClick={handleNextCard} className="next-button" style={{ marginTop: "10px", width: "100%" }}>
            Next Card
          </Button>
        </Card>
      )}
    </div>
  );
};

export default CelebrationCard;
