import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Space, Checkbox, Select, TimePicker, Button, List, Modal, Input, Radio, Upload, message, DatePicker } from "antd";
import moment from "moment";
import { UploadOutlined, SyncOutlined, GiftOutlined, UsergroupAddOutlined, ClockCircleOutlined } from '@ant-design/icons';
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../../helpers/requestHelpers";
import {
  mapChannels,
  mapChannelIdsToDisplayNames,
  mapChannelNamesToIds,
} from "../Shared/useTenantChannels";
import { clearUrlParams } from "../../../helpers/utilityHelpers";

const { Option } = Select;

const AnniversariesTab = ({
  getTenantSettingsQuery, 
  getTenantChannelsQuery,
  getTenantUsersQuery,
  channelMap,
  channelOptions,
  userOptions
}) => {
  
  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);

  // Anniversary settings
  const [anniversaryMessage, setAnniversaryMessage] = useState("");
  const [anniversaryGif, setAnniversaryGif] = useState(false);
  const [anniversaryCollage, setAnniversaryCollage] = useState(false);
  const [selectedAnniversaryChannels, setSelectedAnniversaryChannels] =
    useState([]);
  const [selectedAnniversaryTime, setSelectedAnniversaryTime] = useState(null);
  const [selectedAnniversarySendWhen, setSelectedAnniversarySendWhen] =
    useState("");
  const [isAnniversarySendingEnabled, setIsAnniversarySendingEnabled] =
    useState(false);
  const [
    collectingAnniversaryModalVisible,
    setCollectingAnniversaryModalVisible,
  ] = useState(false);
  const [
    editAnniversaryMessageModalVisible,
    setEditAnniversaryMessageModalVisible,
  ] = useState(false);
  const [anniversaryErrorMessage, setAnniversaryErrorMessage] = useState("");
  const [anniversarySendAsAdmin, setAnniversarySendAsAdmin] = useState(false);
  const [anniversaryOnboardingChannel, setAnniversaryOnboardingChannel] =
    useState("");
  const [anniversaryOnboardingEnabled, setAnniversaryOnboardingEnabled] =
    useState(false); 

  const [isCollectingModalVisible, setCollectingModalVisible] = useState(false);
  const [isCsvUploadVisible, setCsvUploadVisible] = useState(false);
  const [collectOption, setCollectOption] = useState("selected");
  const [isConfirmCollectModalVisible, setConfirmCollectModalVisible] = useState(false);
  const [isMessageSubmitting, setIsMessageSubmitting] = useState(false);
  const [isGiftSubmitting, setIsGiftSubmitting] = useState(false);
  const [isAllCollectedVisible, setAllCollectedVisible] = useState(false);
  const [isAllNotCollectedVisible, setAllNotCollectedVisible] = useState(false);
  const [isModifyAnniversaryModalVisible, setModifyAnniversaryModalVisible] = useState(false);
  const [selectedUserForModification, setSelectedUserForModification] = useState({ id: null, name: '' });
  const [isCollectingLoading, setIsCollectingLoading] = useState(false);
  const [isCollectingAllLoading, setIsCollectingAllLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  // selected user anniversary state
  const [userAnniversary, setUserAnniversary] = useState(null);

  const [anniversaryGiftModalVisible, setAnniversaryGiftModalVisible] =
  useState(false);

  // get all work anniversaries
  const getAllAnniversariesQuery = useQuery(
    ["GET_ALL_ANNIVERSARIES"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllAnniversaries`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  const anniversariesCollected = getAllAnniversariesQuery.data ? getAllAnniversariesQuery.data.anniversary_collected_list : [];
  const anniversariesNotCollected = getAllAnniversariesQuery.data ? getAllAnniversariesQuery.data.anniversary_not_collected_list : [];

  // updates work anniversary settings
  async function updateAnniversarySettings({
    channels,
    time,
    sendWhen,
    isSendingEnabled,
    anniversaryMessage,
    anniversaryGif,
    anniversaryCollage,
    sendAsAdmin,
    onboardingChannel,
    onboardingEnabled,
  }) {
    const payload = {};
    if (channels) {
      const newChannelMap = mapChannels(getTenantChannelsQuery.data);
      const { channelIds, channelsTeamIds } = mapChannelNamesToIds(
        channels,
        newChannelMap
      );
      payload.work_anniversary_channels = channelIds;
      payload.work_anniversary_channels_team_ids = channelsTeamIds;
    } else {
      payload.work_anniversary_channels = [];
      payload.work_anniversary_channels_team_ids = [];
    }
    if (time) {
      payload.work_anniversary_message_hour_of_day = time.utc().format("HH:mm");
    }
    if (sendWhen) {
      payload.anniversary_sending_type = sendWhen;
    }
    if (isSendingEnabled !== undefined) {
      payload.work_anniversary_on = isSendingEnabled;
    }
    if (anniversaryMessage !== undefined) {
      payload.work_anniversary_message = anniversaryMessage;
    }
    if (anniversaryGif !== undefined) {
      payload.work_anniversary_gif_on = anniversaryGif;
    }
    if (anniversaryCollage !== undefined) {
      payload.work_anniversary_graphic_on = anniversaryCollage;
    }
    if (sendAsAdmin !== undefined) {
      payload.work_anniversary_send_as_admin = sendAsAdmin;
    }
    if (onboardingChannel) {
      payload.work_anniversary_onboarding_channel = onboardingChannel;
    }
    if (onboardingEnabled !== undefined) {
      payload.work_anniversary_onboarding_on = onboardingEnabled;
    }

    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/updateAnniversarySettings`,
        payload,
        setTokenErrorType
      );

      if (!response.success) {
        setAnniversaryErrorMessage(response.message);
      } else {
        setAnniversaryErrorMessage(""); // Clear any previous error messages

        // Show success message
        message.success("Anniversary settings updated successfully!");

        // close all modals
        setEditAnniversaryMessageModalVisible(false);
        setAnniversaryGiftModalVisible(false);
        clearUrlParams();

        // Invalidate query to trigger table refresh
        queryClient.invalidateQueries("GET_TENANT_SETTINGS");
      }
    } catch (response) {
      setAnniversaryErrorMessage(response.message);
    }
  }
  
    
  useEffect(() => {
    const userAnniversary = anniversariesCollected.find(
      (anniv) => anniv.user_id === selectedUserForModification.id
    );
    console.log(userAnniversary);
    setUserAnniversary(userAnniversary ? userAnniversary.anniversary : null);
  }, [selectedUserForModification, anniversariesCollected]);

  // Pre-populate settings when GET_TENANT_SETTINGS returns
  useEffect(() => {
    if (getTenantSettingsQuery.data && getTenantChannelsQuery.data) {
    
      const newChannelMap = mapChannels(getTenantChannelsQuery.data);
      const prepopulatedAnnivChannelIds =
        getTenantSettingsQuery.data.work_anniversary_channels || [];
      const prepopulatedAnnivChannels = mapChannelIdsToDisplayNames(
        prepopulatedAnnivChannelIds,
        newChannelMap
      );
      setSelectedAnniversaryChannels(prepopulatedAnnivChannels);

      // Set the selectedAnniversaryTime from work_anniversary_message_hour_of_day
      const annivHourOfDay =
        getTenantSettingsQuery.data.work_anniversary_message_hour_of_day;
      if (annivHourOfDay) {
        setSelectedAnniversaryTime(moment.utc(annivHourOfDay, "HH:mm").local());
      }

      setSelectedAnniversarySendWhen(
        getTenantSettingsQuery.data.anniversary_sending_type || ""
      );
      setIsAnniversarySendingEnabled(
        getTenantSettingsQuery.data.work_anniversary_on || false
      );
      setAnniversaryMessage(
        getTenantSettingsQuery.data.work_anniversary_message || ""
      );
      setAnniversaryGif(
        getTenantSettingsQuery.data.work_anniversary_gif_on || false
      );
      setAnniversaryCollage(
        getTenantSettingsQuery.data.work_anniversary_graphic_on || false
      );
      setAnniversarySendAsAdmin(
        getTenantSettingsQuery.data.work_anniversary_send_as_admin || false
      );
      setAnniversaryOnboardingChannel(
        getTenantSettingsQuery.data.work_anniversary_onboarding_channel || ""
      );
      setAnniversaryOnboardingEnabled(
        getTenantSettingsQuery.data.work_anniversary_onboarding_on || false
      );
    }
  }, [
    getTenantSettingsQuery.data,
    getTenantChannelsQuery.data,
    getTenantUsersQuery.data,
    editAnniversaryMessageModalVisible,
    anniversaryGiftModalVisible,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('modalOpen') === 'manageGift') {
      setAnniversaryGiftModalVisible(true);
    }
  }, []);

  return (
    <>
      <Space direction="vertical" style={{ width: "100%", textAlign: "left", alignItems: "flex-start" }}>
        <img 
          src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/home_header_graphics%2Fwork-anniversary-celebrations.png" 
          alt="Work Anniversary Banner" 
          style={{ width: "100%", maxWidth: "600px", height: 'auto', borderRadius: '10px' }} 
        />
        <div style={{ padding: '10px 0' }}>
          <h4>Work anniversary celebrations allows you to collect work anniversaries automatically across your team and share fun, celebratory (and customizable) posts in Teams whenever it's someones special milestone.</h4>
        </div>
        <div>
          <Checkbox
            checked={isAnniversarySendingEnabled}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setIsAnniversarySendingEnabled(isChecked);
              updateAnniversarySettings({ isSendingEnabled: isChecked });
            }}
          >
            Enable Anniversary Sending
          </Checkbox>
        </div>
        <div>
          <label>Anniversary Channel(s)</label>
          <Select
            mode="multiple"
            placeholder="Select Channel"
            style={{ width: "100%" }}
            value={selectedAnniversaryChannels}
            onChange={(channelIds) => {
              updateAnniversarySettings({ channels: channelIds });
            }}
            loading={!channelOptions}
            filterOption={(input, option) => {
              const optionText = option.children ? option.children.toLowerCase() : '';
              return optionText.includes(input.toLowerCase());
            }}
          >
            {channelOptions}
          </Select>
          <small style={{ display: 'block', marginTop: '5px', color: '#888' }}>
            Custom options: send messages via DM, send in user's timezone <a href="mailto:support@getculturebot.com">(contact us)</a>
          </small>
        </div>
        <div>
          <label>Time to Celebrate Anniversaries</label>
          <TimePicker
            format="hh:mm A"
            minuteStep={30}
            use12Hours
            style={{ width: "200px", display: "block" }}
            value={selectedAnniversaryTime}
            onChange={(time) => {
              setSelectedAnniversaryTime(time);
              updateAnniversarySettings({ time });
            }}
          />
          <br />
          <span>Your Timezone: <br /> <strong>{Intl.DateTimeFormat().resolvedOptions().timeZone}</strong></span>
        </div>
        <div>
          <label>Select When to Send</label>
          <Select 
            placeholder="Select When to Send" 
            style={{ width: "300px", display: "block" }}
            value={selectedAnniversarySendWhen}
            onChange={(value) => {
              setSelectedAnniversarySendWhen(value);
              updateAnniversarySettings({ sendWhen: value });
            }}
          >
            <Option value="ON_DAY_OF">On day of anniversary</Option>
            <Option value="SKIP_WEEKENDS">On day of anniversary, skip weekends</Option>
            <Option value="ON_MONDAYS">On Mondays only</Option>
            <Option value="FIRST_MONDAY_OF_MONTH">First Monday of every month</Option>
          </Select>
        </div>
        <label>Anniversary Collection</label>
        <Button 
          icon={<UsergroupAddOutlined />} 
          shape="round"
          onClick={() => setCollectingModalVisible(true)}
        >
          Collecting Anniversaries
        </Button>
        <label>Customize Anniversary Message</label>
        <Button shape="round" type="default" onClick={() => setEditAnniversaryMessageModalVisible(true)}>
          Edit Message
        </Button>
        <div>
          <Checkbox
            checked={anniversaryOnboardingEnabled}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setAnniversaryOnboardingEnabled(isChecked);
              updateAnniversarySettings({ onboardingEnabled: isChecked });
            }}
          >
            Send a message to all new employees added to Teams to collect their anniversary
          </Checkbox>
        </div>
        <div>
          <label>Set a specific channel to watch</label>
          <Select
            showSearch
            placeholder="Select Channel"
            style={{ width: "100%" }}
            value={anniversaryOnboardingChannel}
            onChange={(value) => {
              setAnniversaryOnboardingChannel(value);
              updateAnniversarySettings({ onboardingChannel: value });
            }}
            loading={!channelOptions}
            filterOption={(input, option) => {
              const optionText = option.children ? option.children.toLowerCase() : '';
              return optionText.includes(input.toLowerCase());
            }}
          >
            {channelOptions}
          </Select>
          <small style={{ display: 'block', marginTop: '5px', color: '#888' }}>
            Note: if not set, anyone who joins Teams for the first time will be asked for their anniversary
          </small>
        </div>
        <div>
          <Checkbox
            checked={anniversarySendAsAdmin}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setAnniversarySendAsAdmin(isChecked);
              updateAnniversarySettings({ sendAsAdmin: isChecked });
            }}
          >
            Send messages as the main CultureBot admin instead of as CultureBot
          </Checkbox>
        </div>

        {/* New Section for Manager Alerts and Anniv Gifts */}
        <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
          <p>
            Manage advanced notifications for sending alerts to manager(s) and/or employees <strong>before</strong> their work anniversary.
            Also, set up the ability to send out a video eCard for teammates to sign and send to the employee.
          </p>
          <p>
            To send a gift card on someone's work anniversary, set it up from the shoutouts page.
          </p>
          <a href="/app/celebration_alerts">
            <Button icon={<ClockCircleOutlined />} shape="round" style={{ marginRight: '10px' }}>
              Setup Manager Alerts
            </Button>
          </a>
          <Button 
            icon={<GiftOutlined />} 
            shape="round"
            onClick={() => setAnniversaryGiftModalVisible(true)}
          >
            Setup Anniversary Gift
          </Button>
        </div>

      </Space>

      <Modal
        title="Collecting Anniversaries"
        open={isCollectingModalVisible}
        onCancel={() => setCollectingModalVisible(false)}
        footer={[
          <Button
            key="close"
            onClick={() => setCollectingModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '16px' }}>
          <h3>Uploading anniversaries for users</h3>
          <Button
            type="default"
            shape="round"
            icon={<UploadOutlined />}
            style={{ marginRight: '8px', border: '1px solid #1890ff', color: '#1890ff' }}
            onClick={() => {
              setCsvUploadVisible(!isCsvUploadVisible);
            }}
          >
            Upload via CSV
          </Button>
          <Button
            type="default"
            shape="round"
            icon={<SyncOutlined />}
            style={{ border: '1px solid #1890ff', color: '#1890ff' }}
            onClick={() => {
              window.location.assign('/app/hris_setup');
            }}
          >
            Sync with HRIS
          </Button>
        </div>
        {isCsvUploadVisible && (
          <>
            <div style={{ 
              backgroundColor: '#e6f7ff', 
              border: '1px solid #91d5ff', 
              borderRadius: '4px', 
              padding: '10px', 
              marginBottom: '10px' 
            }}>
              • Format your file to include at least 2 columns -- one for 'email' and one for 'anniversary'<br />
              • Emails must match the work email your employees use in Teams<br />
              • Formatting of anniversary dates must be 'YYYY-MM-DD' (e.g. 2021-05-12)
            </div>
            <Upload
              accept=".csv"
              maxCount={1}
              beforeUpload={(file) => {
                const isCSV = file.type === 'text/csv';
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isCSV) {
                  message.error('You can only upload CSV files!');
                }
                if (!isLt10M) {
                  message.error('File must be smaller than 10MB!');
                }
                return isCSV && isLt10M;
              }}
              customRequest={({ file, onSuccess, onError }) => {
                console.log("file: ", file);
                const formData = new FormData();
                formData.append('file', file);
                formData.append('type', 'anniversaries');

                doPostRequest(
                  `${getBaseUrl()}/api/processFileUpload`,
                  formData,
                  (error) => {
                    onError(error);
                    message.error('File upload failed, please try again...');
                  }
                ).then(data => {
                  onSuccess(data);
                  message.success('File uploaded successfully');
                  queryClient.invalidateQueries("GET_ALL_ANNIVERSARIES"); // Refresh anniversaries list
                });
              }}
            >
              <div id="csv-upload-area" style={{ border: '1px dashed #d9d9d9', padding: '20px', textAlign: 'center' }}>
                Drag and drop your CSV file here or click to upload.
              </div>
            </Upload>
          </>
        )}

        <div style={{ marginTop: '20px' }}>
          <h3>Collect work anniversaries from users (one-off)</h3>
          <p>The following message will be sent to users on your team after you click the button below.</p>
          <blockquote>
            "Hey Sally, If you'd like us to celebrate you when the time comes, please click below to enter your work anniversary!"
          </blockquote>
          <Radio.Group onChange={(e) => setCollectOption(e.target.value)} value={collectOption}>
            <Radio value="all" style={{ display: 'block', marginBottom: '8px' }}>
              Send to all users (who haven't already submitted their anniversary)
            </Radio>
            <Radio value="selected" style={{ display: 'block', marginBottom: '8px' }}>
              Send to selected users
            </Radio>
            <Radio value="channel" style={{ display: 'block', marginBottom: '8px' }}>
              Send to channel of users
            </Radio>
          </Radio.Group>
          {collectOption === "selected" && (
            <div style={{ marginTop: '10px' }}>
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Users"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(users) => setSelectedUsers(users)}
              >
                {userOptions}
              </Select>
            </div>
          )}
          {collectOption === "channel" && (
            <div style={{ marginTop: '10px' }}>
              <Select
                showSearch
                placeholder="Select Channel"
                style={{ width: "100%" }}
                onChange={(channel) => {
                  setSelectedChannel(channel);
                  const teamId = channelMap.find(item => item.id == channel).teamId; 
                  setSelectedTeamId(teamId);
                }}
                loading={!channelOptions}
                filterOption={(input, option) => {
                  const optionText = option.children ? option.children.toLowerCase() : '';
                  return optionText.includes(input.toLowerCase());
                }}
              >
                {channelOptions}
              </Select>
            </div>
          )}
          <Button
            type="default"
            shape="round"
            style={{ marginTop: '20px', border: '1px solid #1890ff', color: '#1890ff' }}
            loading={isCollectingLoading && collectOption !== "all"}
            disabled={isCollectingLoading}
            onClick={() => {

              const handleResponse = (error) => {
                setIsCollectingLoading(false);
                if (error && error.message) {
                  message.error(error.message);
                } else {
                  message.error('Failed to send messages, please try again...');
                }
              };

              const sendMessages = (url, data) => {
                doPostRequest(url, data, handleResponse).then(() => {
                  setIsCollectingLoading(false);
                  message.success('Messages sent successfully');
                });
              };

              if (collectOption === "all") {
                setConfirmCollectModalVisible(true);
              } else if (collectOption === "selected" && selectedUsers.length > 0) {
                setIsCollectingLoading(true);
                sendMessages(`${getBaseUrl()}/api/sendAnniversaryCollectionMessages`, { anniversary_user_ids: selectedUsers });
              } else if (collectOption === "channel" && selectedChannel) {
                setIsCollectingLoading(true);
                sendMessages(`${getBaseUrl()}/api/sendAnniversaryCollectionMessages`, { channel_id: selectedChannel, team_id: selectedTeamId });
              } 
              
            }}
          >
            <GiftOutlined /> Send Work Anniversary Collection Messages
          </Button>
        </div>

        <div style={{ marginTop: '20px' }}>
          <h3>Modify user work anniversaries as admin:</h3>
          <Select
            showSearch
            placeholder="Select User"
            style={{ width: "200px" }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(userId, option) => {
              const userName = option.children;
              setSelectedUserForModification({ id: userId, name: userName });
            }}
          >
            {userOptions}
          </Select>
          <Button
            type="default"
            shape="round"
            style={{ marginLeft: '5px', border: '1px solid #1890ff', color: '#1890ff' }}
            onClick={() => {
              if (selectedUserForModification.id) {
                setModifyAnniversaryModalVisible(true);
              } else {
                message.warning('Please select a user first.');
              }
            }}
          >
            Modify
          </Button>
        </div>

        <div style={{ marginTop: '20px' }}>
          <h3>📝 Anniversaries collected so far:</h3>
          <List
            dataSource={anniversariesCollected.slice(0, 10)}
            renderItem={item => {
              const formattedDate = moment(`${item.anniversary}`, "YYYY-MM-DD").format("MMM Do");
              return (
                <List.Item>
                  {item.user_name || item.email} ({formattedDate})
                </List.Item>
              );
            }}
          />
          {anniversariesCollected.length > 10 && (
            <Button onClick={() => setAllCollectedVisible(true)}>
              View all {anniversariesCollected.length} users
            </Button>
          )}
        </div>

        <Modal
          title="Anniversaries Collected"
          open={isAllCollectedVisible}
          onCancel={() => setAllCollectedVisible(false)}
          footer={[
            <Button key="close" onClick={() => setAllCollectedVisible(false)}>
              Close
            </Button>,
          ]}
        >
          <List
            dataSource={anniversariesCollected}
            renderItem={item => {
              const formattedDate = moment(`${item.anniversary}`, "YYYY-MM-DD").format("MMM Do");
              return (
                <List.Item>
                  {item.user_name || item.email} ({formattedDate})
                </List.Item>
              );
            }}
          />
        </Modal>

        <div style={{ marginTop: '20px' }}>
          <h3>📝 Anniversaries not collected:</h3>
          <List
            dataSource={anniversariesNotCollected.slice(0, 10)}
            renderItem={item => (
              <List.Item>
                {item.user_name || item.email} 
              </List.Item>
            )}
          />
          {anniversariesNotCollected.length > 10 && (
            <Button onClick={() => setAllNotCollectedVisible(true)}>
              View all {anniversariesNotCollected.length} users
            </Button>
          )}
        </div>

        <Modal
          title="All Anniversaries Not Collected"
          open={isAllNotCollectedVisible}
          onCancel={() => setAllNotCollectedVisible(false)}
          footer={[
            <Button key="close" onClick={() => setAllNotCollectedVisible(false)}>
              Close
            </Button>,
          ]}
        >
          <List
            dataSource={anniversariesNotCollected}
            renderItem={item => (
              <List.Item>
                {item.user_name || item.email} 
              </List.Item>
            )}
          />
        </Modal>
      </Modal>

      <Modal
        title="Confirm Collection"
        open={isConfirmCollectModalVisible}
        onCancel={() => setConfirmCollectModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setConfirmCollectModalVisible(false)}>
            Close
          </Button>,
          <Button key="collect" type="primary" loading={isCollectingAllLoading} disabled={isCollectingAllLoading} onClick={() => {
            setIsCollectingAllLoading(true);
            doPostRequest(`${getBaseUrl()}/api/sendAnniversaryCollectionMessages`, { send_to_all: true })
              .then(() => {
                message.success('Messages sent successfully');
              })
              .catch((error) => {
                message.error('Failed to send messages, please try again...');
              })
              .finally(() => {
                setConfirmCollectModalVisible(false);
                setIsCollectingAllLoading(false);
              });
          }}>
            Collect
          </Button>,
        ]}
        centered
      >
        <p>Clicking "Collect" will send a message to all team members on Teams to collect their work anniversaries.</p>
      </Modal>

      <Modal
        title="Edit Anniversary Message"
        open={editAnniversaryMessageModalVisible}
        onCancel={() => {
          setEditAnniversaryMessageModalVisible(false);
          setAnniversaryErrorMessage("");
        }}
        footer={[
          <Button key="close" onClick={() => {
            setEditAnniversaryMessageModalVisible(false);
            setAnniversaryErrorMessage("");
          }}>
            Close
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isMessageSubmitting}
            onClick={() => {
              setIsMessageSubmitting(true);
              updateAnniversarySettings({
                anniversaryMessage,
                anniversaryGif,
                anniversaryCollage,
              }).finally(() => {
                setIsMessageSubmitting(false);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        {anniversaryErrorMessage && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {anniversaryErrorMessage}
          </div>
        )}
        <p>Modify the message that is sent along with anniversary celebrations.</p>
        <label style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>🎉 Anniversary Celebration Message</label>
        <Input.TextArea
          rows={4}
          value={anniversaryMessage}
          onChange={(e) => setAnniversaryMessage(e.target.value)}
        />
        <div style={{ marginTop: '20px' }}>
          <Checkbox
            checked={anniversaryGif}
            onChange={(e) => setAnniversaryGif(e.target.checked)}
          >
            Celebration GIF (optional)
          </Checkbox>
          <p style={{ marginLeft: '24px' }}>
            Check here to send a celebratory (random) gif each time an anniversary is announced.
          </p>
          <Checkbox
            checked={anniversaryCollage}
            onChange={(e) => setAnniversaryCollage(e.target.checked)}
          >
            Celebration Collage (optional)
          </Checkbox>
          <p style={{ marginLeft: '24px' }}>
            Check here to send a custom collage with everyone's face on it each time an anniversary is announced (suggested for larger teams).
          </p>
        </div>
      </Modal>

      <Modal
        title={`Edit anniversary for user`}
        open={isModifyAnniversaryModalVisible}
        onCancel={() => setModifyAnniversaryModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModifyAnniversaryModalVisible(false)}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            
            const { id } = selectedUserForModification;

            // update user anniversary
            if (id && userAnniversary) {
              doPostRequest(
                `${getBaseUrl()}/api/updateUserAnniversary`,
                { anniv_user_id: id, user_anniversary: userAnniversary }
              )
              .then(() => {
                message.success('Anniversary updated successfully');
                queryClient.invalidateQueries("GET_ALL_ANNIVERSARIES"); // refresh anniversaries list given modified anniversary
                setModifyAnniversaryModalVisible(false);
              })
              .catch((error) => {
                message.error('Failed to update anniversary, please try again...');
              });
            } else {
              message.warning('Please select a valid date.');
            }
          }}>
            Save
          </Button>,
        ]}
      >
        {(() => {

          return (
            <>
              <p>Current anniversary for <strong>{selectedUserForModification.name}</strong>:
              &nbsp;{userAnniversary ? moment(`${userAnniversary}`, "YYYY-MM-DD").format("MMM Do, YYYY") : "Not set"}
              </p>
              <label style={{ display: 'block', marginBottom: '10px' }}>Add/edit anniversary:</label>
              <div style={{ display: 'flex', gap: '10px' }}>
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '300px' }}
                  value={userAnniversary ? moment(userAnniversary) : null}
                  onChange={(date) => {
                    setUserAnniversary(date ? date.format("YYYY-MM-DD") : null);
                  }}
                  format="YYYY-MM-DD"
                />
              </div>
            </>
          );
        })()}
      </Modal>

      <Modal
        title="Anniversary Gift Setup"
        open={anniversaryGiftModalVisible}
        onCancel={() => setAnniversaryGiftModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setAnniversaryGiftModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p>To setup an automated work anniversary gift, visit the "shoutouts" setup page and click on "Set Celebration Reward". This will send a specified amount of peer rewards points to the employee on their work anniversary (via Direct Message), with a button they can click to redeem a gift or gift card from your customizable catalog.</p>
        <img 
          src="https://graphics.getculturebot.com/message_previews/celebration_gifts_teams.png" 
          alt="Celebration Gift Setup"
          style={{ width: '100%', borderRadius: '10px' }}
        />
      </Modal>

      {/* <small style={{ display: 'block', marginTop: '20px', color: '#888', textAlign: 'left' }}>
        Learn more about <a href="https://getculturebot.com/getting-started.html#work-anniversary-messages">anniversary messages</a>
      </small> */}
    </>
  );
};

export default AnniversariesTab; 