import React, { useState } from "react";
import { Column } from "@ant-design/charts";
import { Checkbox } from "antd";

const BarChartComponent = ({ rawData }) => {
  const [visibleCategories, setVisibleCategories] = useState({
    "Birthday Posts": true,
    "Work Anniversary Posts": true,
    "Custom Celebrations Posts": true,
    "Water Cooler Posts": true,
    Shoutouts: true,
    "Health Tips": true, // Added new category
  });

  const handleCategoryChange = (category) => {
    setVisibleCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const filteredData = rawData.data.daily_results.filter(
    (item) => visibleCategories[item.category]
  );

  const config = {
    data: filteredData,
    xField: "date",
    yField: "value",
    seriesField: "category",
    isStack: true, // Change to false if you don't want stacked bars
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    tooltip: {
      formatter: (datum) => ({
        name: datum.category,
        value: `${datum.value} reactions`, // Adding "reactions" to the tooltip value
      }),
    },
    yAxis: {
      label: {
        formatter: (v) => `${v}`, // Format the y-axis labels
      },
      title: {
        text: "# Reactions", // y-axis title
        style: {
          fontSize: 14,
          fontWeight: "bold",
          fill: "#444", // Customize color if needed
        },
      },
    },
  };

  return (
    <div>
      {Object.keys(visibleCategories).map((category) => (
        <Checkbox
          key={category}
          checked={visibleCategories[category]}
          onChange={() => handleCategoryChange(category)}
        >
          {category}
        </Checkbox>
      ))}
      <Column {...config} />
    </div>
  );
};

export default BarChartComponent;
