import { Table } from "antd";
import moment from "moment";
import { useState, useEffect, useRef } from "react";

export default function SyncRuns({ syncRunsQuery }) {

  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // Table fields
  const columns = [
    {
      title: "Sync Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "20%",
      render: (_, record) => {
        return <div>{moment.unix(record.created_at).format("MM/DD/YYYY – HH:mm")}</div>;
      },
      sorter: (a, b) => a.created_at < b.created_at,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Sync Completed At",
      dataIndex: "completed_at",
      key: "completed_at",
      width: "20%",
      render: (_, record) => {
        return <div>{moment.unix(record.completed_at).format("MM/DD/YYYY – HH:mm")}</div>;
      },
      sorter: (a, b) => a.completed_at < b.completed_at,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "# New Employees",
      dataIndex: "num_new_employees",
      key: "num_new_employees",
      width: "60%",
      render: (_, record) => {
        return <div>{record.num_new_employees}</div>;
      },
      sorter: (a, b) => a.num_new_employees < b.num_new_employees,
      sortDirections: ["descend", "ascend"],
    }
  ];

  // Build table area
  const tableData = syncRunsQuery.data.sync_runs.map((run) => {
    return {
      id: run.id,
      created_at: run.created_at,
      completed_at: run.completed_at,
      num_new_employees: run.num_new_employees
    };
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
      />
    </>
  );
}
