import React, { useState, useEffect } from "react";
import MainContainer from "../Container/MainContainer";
import { Upload, message, Card, Typography, Image, Tooltip, Button } from "antd";
import {
  InboxOutlined,
  PictureOutlined,
  CopyOutlined,
  DeleteOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  doPostRequest,
  doGetRequest,
  getBaseUrl,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./GraphicsLibraryPage.scss";
import { useLocation } from "react-router-dom";

const { Dragger } = Upload;
const { Title, Paragraph } = Typography;

export default function GraphicsLibraryPage() {
  const [images, setImages] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromModal = queryParams.get('fromModal') === 'true';
  const tab = queryParams.get('tab') || '1';
  const celebrationId = queryParams.get('celebrationId') || null;

  useEffect(() => {
    fetchExistingImages();
  }, []);

  const fetchExistingImages = async () => {
    try {
      const existingImages = await doGetRequest(
        `${getBaseUrl()}/api/getImagesFromGraphicsLibrary`,
        getTokenErrorAreaIfNeeded
      );
      console.log("existingImages", existingImages);
      setImages(existingImages.images);
    } catch (error) {
      console.error("Error fetching images:", error);
      throw new Error("Failed to fetch images from the graphics library");
    }
  };

  const handleDeleteImage = async (image) => {
    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/deleteImageFromGraphicsLibrary`,
        { file_path: image.file_path }, // Send the image URL in the request body
        getTokenErrorAreaIfNeeded
      );

      if (!response.error) {
        message.success("Image deleted successfully");
        fetchExistingImages(); // Refresh the image list after deletion
      } else {
        message.error(`Delete failed: ${response.error}`);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      message.error(`Error deleting image: ${error.message}`);
    }
  };

  const handleCopyUrl = (url) => {
    navigator.clipboard.writeText(url);
    message.success("Image URL copied to clipboard!");
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/uploadImageToGraphicsLibrary`,
        formData,
        getTokenErrorAreaIfNeeded
      );

      if (!response.error) {
        message.success({
          content: `Image uploaded successfully - to use in the app, click the copy URL icon`,
          duration: 5,
          style: {
            marginTop: "20vh",
          },
        });
        fetchExistingImages(); // Refresh the image list after successful upload
      } else {
        message.error({
          content: `Upload failed: ${response.error}`,
          duration: 5,
          style: {
            marginTop: "20vh",
          },
        });
      }
    } catch (error) {
      console.error("Detailed error:", error);
      message.error({
        content: `Error uploading image: ${error.message}`,
        duration: 5,
        style: {
          marginTop: "20vh",
        },
      });
    }
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".jpg,.jpeg,.png,.gif",
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isSizeValid = file.size <= 10 * 1024 * 1024; // 10MB in bytes
      if (!isImage) {
        message.error(
          `${file.name} is not an image file. We only accept images.`
        );
      } else if (!isSizeValid) {
        message.error(
          `${file.name} is larger than 10MB. Please upload a smaller file.`
        );
      }
      return (isImage && isSizeValid) || Upload.LIST_IGNORE;
    },
    customRequest: ({ file, onSuccess, onError }) => {
      handleImageUpload(file)
        .then((result) => {
          onSuccess("Upload successful"); // Use a success message from the result if available
          fetchExistingImages();
        })
        .catch((error) => {
          onError(error.message || "Upload failed"); // Provide a meaningful error message
        });
    },
  };

  return (
    <MainContainer>
      <div className="graphics-library main-container">
        <Card className="graphics-library__card main-container-card">
          <div className="graphics-library__header-container">
            <Title level={2} className="graphics-library__title">
              <PictureOutlined className="graphics-library__title-icon" />
              Graphics Library
            </Title>

            {fromModal && (
              <div style={{ textAlign: 'left' }}>
                <Button
                  type="link"
                  onClick={() => {
                    const previousUrl = document.referrer;
                    const url = new URL(previousUrl);
                    url.searchParams.set('modalOpen', 'manageGift');
                    url.searchParams.set('tab', tab);
                    if (celebrationId) {
                      url.searchParams.set('celebrationId', celebrationId);
                    }
                    window.location.href = url.toString();
                  }}
                  style={{ paddingLeft: '0px' }}
                  icon={<LeftOutlined />}
                >
                  Back
                </Button>
              </div>
            )}
            
            <div className="graphics-library__content">
              <Paragraph>
                <i>You are able to upload up to 100 graphics for use throughout the application. You can add these graphics (via copying their URL) to:</i>
                <br /><br />
                • Custom water cooler questions<br />
                • Create personalized celebrations<br />
                • Provide engaging health tips to your team<br />
                • Incorporate them into survey forms<br />
                • Birthday and anniversary gift images<br />
                <br />
                After uploading, click the copy URL button on the top right of the image you want to use and then paste it into the desired location inside the CultureBot Slack app.
              </Paragraph>
            </div>
          </div>
          <br />
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single image upload (JPG, PNG or GIF) – keep to 10MB or under
            </p>
          </Dragger>
          <div className="graphics-library__content">
            <Title level={3}>Existing Images</Title>
            <div className="graphics-library__images-container">
              {images.map((image) => (
                <div key={image.url} className="graphics-library__image-wrapper">
                  <Image
                    width={200}
                    src={image.url}
                    alt="Uploaded Image"
                    preview={{ src: image.url }}
                    onClick={() => handleCopyUrl(image.url)}
                  />
                  <div className="graphics-library__image-actions">
                    <Tooltip title="Copy URL" placement="top">
                      <CopyOutlined
                        onClick={() => handleCopyUrl(image.url)}
                        className="graphics-library__action-icon graphics-library__action-icon--copy"
                      />
                    </Tooltip>
                    <Tooltip title="Delete Image" placement="top">
                      <DeleteOutlined
                        onClick={() => handleDeleteImage(image)}
                        className="graphics-library__action-icon graphics-library__action-icon--delete"
                      />
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </MainContainer>
  );
}
