import React, { useState } from 'react';
import { Space, Button, Modal, Form, Select, InputNumber, message } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import {
    getBaseUrl,
    doPostRequest,
  } from "../../../helpers/requestHelpers";

const DoodleDashTab = ({ channelOptions }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tokenErrorType, setTokenErrorType] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async (values) => {
        setIsLoading(true);
        const payload = {
            channel: values.channel,
            rounds: values.rounds,
            drawingTime: values.drawingTime
        };

        try {
            const response = await doPostRequest(
                `${getBaseUrl()}/api/startDoodleDashGame`,
                payload,
                setTokenErrorType
            );

            if (response) {
                message.info(`A new game has been started in the channel selected!`);
                setIsModalVisible(false);
            }
        } catch (error) {
            console.error("Error starting DoodleDash game:", error);
            message.error("Please make sure CultureBot has been added to the team and channel first.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Space direction="vertical" style={{ width: "100%", textAlign: "left", alignItems: "flex-start" }}>
                <img 
                src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/home_header_graphics/doodle-dash.png" 
                alt="DoodleDash Banner" 
                style={{ width: "100%", maxWidth: "600px", height: 'auto', borderRadius: '10px' }} 
                />
                <div style={{ padding: '10px 0' }}>
                    <h4>DoodleDash is a fun, interactive game where you can draw and guess what others are drawing. It's a great way to break the ice and get to know your team better.</h4>
                </div>
                <Button shape="round" onClick={showModal}><PlayCircleOutlined /> Start New Game</Button>
            </Space>

            <Modal
                title="Start New Game"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form layout="vertical" onFinish={handleOk}>
                    <Form.Item 
                        label="Select Channel" 
                        name="channel" 
                        rules={[{ required: true, message: 'Please select a channel!' }]}
                        style={{ marginBottom: '16px' }}
                    >
                        <Select 
                            style={{ width: '100%', fontSize: '14px' }}
                            loading={!channelOptions}
                            showSearch
                            filterOption={(input, option) => {
                                const optionText = option.children ? option.children.toString().toLowerCase() : '';
                                return optionText.includes(input.toLowerCase());
                            }}
                        >
                            {channelOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        label="Number of rounds" 
                        name="rounds" 
                        rules={[{ required: true, message: 'Please select the number of rounds!' }]}
                        style={{ marginBottom: '16px' }}
                    >
                        <Select style={{ width: '100%', fontSize: '14px' }}>
                            {[2, 3, 4, 5, 6].map(num => (
                                <Select.Option key={num} value={num}>{num}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        label="Drawing time per round" 
                        name="drawingTime" 
                        rules={[{ required: true, message: 'Please select the drawing time!' }]}
                        style={{ marginBottom: '16px' }}
                    >
                        <Select style={{ width: '100%', fontSize: '14px' }}>
                            {[40, 50, 60, 70, 80, 90].map(time => (
                                <Select.Option key={time} value={time}>{time} seconds</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button 
                            htmlType="submit" 
                            style={{ fontSize: '14px' }} 
                            disabled={isLoading}
                        >
                            {isLoading ? 'Starting...' : 'Start Game'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default DoodleDashTab; 