import React, { useEffect, useState } from "react";
import { Button, Image } from "antd";
import checkIconWhite from "../../images/checkIconWhite.png";
import { BlockPicker } from "react-color";

let colors = [
  "#FCBC54",
  "#B868EA",
  "#FFB9B9",
  "#EF5BD7",
  "#5AC36D",
  "#E08088",
  "#9F99FF",
  "#EDDE60",
  "#747AF9",
  "#6AECF4",
];

export default function ColorSelector({
  colorUpdated,
  currentColor = "#FCBC54",
}) {
  // Inform the parent of the color on first render, since default may have changed it
  useEffect(() => {
    colorUpdated(currentColor);
  }, []);

  function handleColorChange(hexColor) {
    // currentTarget seems to do better than target in cases such as clicking same color twice
    colorUpdated(hexColor);
  }

  return (
    <BlockPicker
      color={currentColor}
      triangle="hide"
      width="100%"
      colors={colors}
      onChange={(color) => {
        handleColorChange(color.hex);
      }}
    />
  );
}
