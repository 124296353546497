import React from "react";
import {
  Button,
  Modal,
  Input,
  InputNumber,
  DatePicker,
  Select,
  TimePicker,
  Radio,
  Checkbox
} from "antd"; 
import {
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons"
import { roundToLastHalfHour } from "../../helpers/utilityHelpers";
import GifPicker from "gif-picker-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import { filterOptionFunction } from "../CreateCampaign/CreateCampaignPageHelpers";
import moment from "moment-timezone";

const { Option } = Select;
const { TextArea } = Input;
const timePickerFormat = "h:mm a";

export default function PanelSteps({ step, index, slackUsersQuery, slackChannelsQuery, steps, varNames, setSteps, anchorTimeType }) {

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [isInvalidTimeModalOpen, setIsInvalidTimeModalOpen] = useState(false);
  const [gifPickerOpen, setGifPickerOpen] = useState(false);

  // ------------ queries & data -------------
  const getAllEmployeeDepartmentsQuery = useQuery(
    ["GET_ALL_EMPLOYEE_DEPARTMENTS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllEmployeeDepartments`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  const getAllEmployeeLocationsQuery = useQuery(
    ["GET_ALL_EMPLOYEE_LOCATIONS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllEmployeeLocations`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" }
  );

  const getSlackShoutoutTypesQuery = useQuery(
    ["getSlackShoutoutTypesQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getShoutoutTypes`,
        setTokenErrorType,
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );  

  const getCBFormsQuery = useQuery(
    ["GET_CB_FORMS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getCBForms`);
    },
    { refetchOnWindowFocus: false }
  );

  // ------ survey options ----- 
  let formSelectOptions = [];
  if (getCBFormsQuery.data) {
    formSelectOptions = getCBFormsQuery.data.cbForms.map((currentForm) => {
      return {
        label: currentForm.form_name,
        value: currentForm.id,
      };
    });
  }

  let surveySendToOptions = []
  // only show this option on surveys that are tied to new users seen in slack/slack channel
  if (anchorTimeType != "specific_date") {
    surveySendToOptions.push({
      value: "USER_SEEN_FIRST_STEP",
      label: "DM user seen in first step",
    })
  }
  surveySendToOptions.push(
    {
      value: "USERS_IN_CHANNEL",
      label: "DM all users in a single Slack channel",
    },
    {
      value: "SPECIFIC_USERS",
      label: "DM specific users by name",
    },
    {
      value: "USERS_MANAGER",
      label: "DM the user's manager",
    },
    {
      value: "USERS_BUDDIES",
      label: "DM the user's buddies",
    },
    {
      value: "USERS_BY_GROUP",
      label: "DM all users who match a certain department",
    },
    {
      value: "USERS_BY_WORK_LOCATION",
      label: "DM all users who match a certain work location",
    }
  )

  // ---------- UI handlers --------- 
  const updateStep = (field, value, secondaryField='', secondaryValue='') => {
    let updatedStep = steps.map((item) => {
      if (item.id === index) {
        if(secondaryField) {
          return { ...item, [field]: value, [secondaryField]: secondaryValue }; // updating two fields
        } else { 
          return { ...item, [field]: value }; // updating one field
        }
      }
      return item;
   });
    setSteps(updatedStep);
  }

  return (
    <>
      <h5>When to start this step</h5>
      <InputNumber
        min={steps[index]['timeframe'] == "hours" ? 0 : 1} // min can be 0 if time to send after previous step is in hours, else it is in days and must be at least 1  
        max={180}
        style={{ width: 80 }}
        step={steps[index]['timeframe'] == "hours" ? 0.5 : 1}
        value={steps[index]['timeValue']}
        onChange={(value) => {
          updateStep('timeValue', value)
        }}
      />
      &nbsp;
      <Select
        style={{ width: 125 }}
        value={steps[index]['timeframe']}
        onChange={(value) => {
          updateStep('timeframe', value)
        }}
      >
        <Option value="days" key="days">
          days
        </Option>
        <Option value="business_days" key="business_days">
          business days
        </Option>
        <Option value="hours" key="hours">
          hours
        </Option>
      </Select>
      &nbsp; from the {index == 0 ? 'start time above' : 'previous step'}

      {/* time to send step -- only if time to send after previous step was in days */}
      {(steps[index]['timeframe'] != "hours") && steps[index]['timeframe'] && steps[index]['timeValue'] ? (
        <>
          <h5 id="stepBlock">Time of day to send</h5>
          <TimePicker
            value={steps[index]['timeDay'] ? moment(steps[index]['timeDay']) : null}
            minuteStep={30}
            allowClear={false}
            use12Hours={true}
            onChange={(date) => {
              const result = roundToLastHalfHour(date);
              if (result.adjusted) {
                setIsInvalidTimeModalOpen(true);
              }
              updateStep('timeDay', result.date)
            }}
            format={timePickerFormat}
          />
          <Modal
            title="Invalid Time"
            open={isInvalidTimeModalOpen}
            cancelButtonProps={{ hidden: true }}
            closable={false} // This will remove the close "x" button
            onOk={() => {
              setIsInvalidTimeModalOpen(false);
            }}
          >
            <p>
              CultureBot only supports campaign times on the hour or on a half hour.
              The selected time has been adjusted back to the nearest hour or
              half hour mark.
            </p>
          </Modal>
          &nbsp;
          <Select
            style={{ width: 325 }}
            value={steps[index]['tz']}
            onChange={(value) => {
              updateStep('tz', value)
            }}
          >
            <Option value="admin_tz" key="admin_tz">
              in admin's timezone
            </Option>
            {
              // only show user tz option if experience was created to send to users when they're first seen in slack or a specific slack channel
              anchorTimeType != "specific_date" ? (
                <Option value="local_tz" key="local_tz">
                  in user's local timezone
                </Option>
              ) : null }
            }
          </Select>
        </>
      ) : null}

      {/* if survey type -- form options */}
      {
        step.type == "survey" ? (
          <>
            <div id="stepBlock">
              <h5>
                Form to use
                <br />
                <span style={{ "font-size": "10px", "font-weight": "normal" }}>
                  (Create a new form from the <a target="_blank" href={`/app/create_form`}>surveys page</a>)
                </span>
              </h5>
              <Select
                showSearch
                value={steps[index]['formId']}
                style={{ width: 325 }}
                onChange={(value) => {
                  updateStep('formId', value)
                }}
                filterOption={filterOptionFunction}
                options={formSelectOptions}
              />
            </div>
            <div id="stepBlock">
              <h5>
                Who to send survey to?
              </h5>
              <Select
                style={{ width: 335 }}
                onChange={(value) => {
                    updateStep('audienceSelection', value, 'audienceSelectionSecondary', [])
                  }
                }
                options={surveySendToOptions}
                value={steps[index]['audienceSelection']}
              />
              
              &nbsp;
              
              {steps[index]['audienceSelection'] == "USERS_IN_CHANNEL" ? (
                <Select
                  showSearch
                  style={{ width: 200, marginLeft: "10px" }}
                  onChange={(value) => { updateStep('audienceSelectionSecondary', value) }}
                  placeholder="Select channel..."
                  value={steps[index]['audienceSelectionSecondary']}
                  filterOption={filterOptionFunction}
                >
                  {slackChannelsQuery.data
                    ? slackChannelsQuery.data.conversations.map((conversation) => {
                        return (
                          <Option
                            key={conversation.id}
                            value={conversation.id}
                            label={conversation.name}
                          >
                            {conversation.name}
                          </Option>
                        );
                      })
                    : []}
                </Select>
              ) : null}

              {steps[index]['audienceSelection'] == "SPECIFIC_USERS" ? (
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: 200, marginLeft: "10px" }}
                  onChange={(value) => { updateStep('audienceSelectionSecondary', value) }}
                  placeholder="Select user..."
                  value={steps[index]['audienceSelectionSecondary']}
                  filterOption={filterOptionFunction}
                  >
                  {slackUsersQuery.data
                    ? slackUsersQuery.data.conversations.map((user) => {
                        return (
                          <Option
                            key={user.id}
                            value={user.id}
                            label={user.real_name}
                          >
                            {user.real_name}
                          </Option>
                        );
                      })
                    : []}
                </Select>
              ) : null}

              {steps[index]['audienceSelection'] == "USERS_BY_GROUP" ? (
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: 200, marginLeft: "10px" }}
                  onChange={(value) => { updateStep('audienceSelectionSecondary', value) }}
                  value={steps[index]['audienceSelectionSecondary']}
                  filterOption={filterOptionFunction}
                >
                  {getAllEmployeeDepartmentsQuery.data?.departments.map((group, index) => {
                      return (
                        <Option key={index} value={group} label={group}>
                          {group}
                        </Option>
                      );
                    })}
                </Select>
              ) : null}

              {steps[index]['audienceSelection'] == "USERS_BY_WORK_LOCATION" ? (
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: 200, marginLeft: "10px" }}
                  onChange={(value) => { updateStep('audienceSelectionSecondary', value) }}
                  value={steps[index]['audienceSelectionSecondary']}
                  filterOption={filterOptionFunction}
                >
                  {getAllEmployeeLocationsQuery?.data?.locations.map(
                        (location, index) => {
                          return (
                            <Option
                              key={index}
                              value={location}
                              label={location}
                            >
                              {location}
                            </Option>
                          );
                        }
                      )}
                </Select>
              ) : null}
              
            </div>

            <div id="stepBlock">
              <h5>
               Surveys answers to be
              </h5>
              <Radio.Group
                onChange={(e) => {
                  updateStep('visiblityValue', e.target.value) 
                }}
                value={steps[index]['visiblityValue']}
              >
                <Radio value={"public"}>Public (results with names)</Radio>
                <Radio value={"anonymous"}>
                  Anonymous (results with no names)
                </Radio>
              </Radio.Group>
            </div>

            <div id="stepBlock">
              <h5>
                Share results after
              </h5>
              <InputNumber
                min={1}
                max={180}
                style={{ width: 80 }}
                step={1}
                value={steps[index]['surveyTimeToShareValue']}
                onChange={(value) => {
                  updateStep('surveyTimeToShareValue', value) 
                }}
              />
              &nbsp;
              <Select
                style={{ width: 125 }}
                value={steps[index]['surveyTimeframeToShareValue']}
                onChange={(value) => {
                  updateStep('surveyTimeframeToShareValue', value)
                }}
              >
                <Option value="days" key="days">
                  days
                </Option>
                {/* <Option value="hours" key="hours">
                  hours
                </Option> */}
              </Select>
            </div>

            <div id="stepBlock">
              <h5>
                Share results with
              </h5>
              <Select
                  style={{ width: 325 }}
                  value={steps[index]['surveyWhereToSendValue']}
                  onChange={(value) => {
                      updateStep('surveyWhereToSendValue', value, 'surveyWhereToSendSecondary', [])
                    }
                  }
                  options={[
                    {
                      value: "ALL_ADMINS_VIA_DM",
                      label:
                        "CultureBot admins via DM (private link)",
                    },
                    {
                      value: "SPECIFIC_USERS_VIA_DM",
                      label: "Specific users via DM",
                    },
                    {
                      value: "SELECTED_CHANNEL",
                      label: "Channel",
                    }
                  ]}
              />
              {steps[index]['surveyWhereToSendValue'] == "SPECIFIC_USERS_VIA_DM" ? (
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: 200, marginLeft: "10px" }}
                  onChange={(value) => {
                    updateStep('surveyWhereToSendSecondary', value)
                    }
                  }
                  placeholder="Select user..."
                  value={steps[index]['surveyWhereToSendSecondary']}
                  filterOption={filterOptionFunction}
                  >
                  {slackUsersQuery.data
                    ? slackUsersQuery.data.conversations.map((user) => {
                        return (
                          <Option
                            key={user.id}
                            value={user.id}
                            label={user.real_name}
                          >
                            {user.real_name}
                          </Option>
                        );
                      })
                    : []}
                </Select>
              ) : null}

              {steps[index]['surveyWhereToSendValue'] == "SELECTED_CHANNEL" ? (
                <Select
                  showSearch
                  style={{ width: 200, marginLeft: "10px" }}
                  onChange={(value) => {
                    updateStep('surveyWhereToSendSecondary', value)
                    }
                  }
                  placeholder="Select channel..."
                  value={steps[index]['surveyWhereToSendSecondary']}
                  filterOption={filterOptionFunction}
                >
                  {slackChannelsQuery.data
                    ? slackChannelsQuery.data.conversations.map((conversation) => {
                        return (
                          <Option
                            key={conversation.id}
                            value={conversation.id}
                            label={conversation.name}
                          >
                            {conversation.name}
                          </Option>
                        );
                      })
                    : []}
                </Select>
              ) : null }

            </div>

          </>
        ) : null
      }

      {/* where to send to (don't show for surveys) */}
      {step.type != "survey" ? (
        <>
          <h5 id="stepBlock">
            Where to send this message
            <br />
            {step.type == "message" ? (
              <span style={{ "font-size": "10px", "font-weight": "normal" }}>
                (Connect your <a target="_blank" href={`/app/hris_setup`}>HRIS</a> to send to
                messaged based on employee department's and locations)
              </span>
            ) : null}
          </h5>
          <Select
            style={{ width: 350 }}
            value={steps[index]['whereToSend']}
            onChange={(value) => {
              updateStep('whereToSend', value)
            }}
          >
            {/* channel is always an option (for messages, shoutouts, surveys) */}
            <Option value="specific_channel" key="specific_channel">
              To a specific channel
            </Option>
            {anchorTimeType != "specific_date" && step.type == "message" ? ( // only show these options on message types that are tied to new users seen in slack/slack channel
              <>
                <Option value="direct_message" key="direct_message">
                  As a single direct message to the user
                </Option>
                <Option value="group_message" key="group_message">
                  As a group message to the user and
                </Option>
                <Option value="manager_only" key="manager_only">
                  As a message to the user's manager only
                </Option>
                <Option value="buddies_only" key="buddies_only">
                  As a message to the user's buddies only
                </Option>
              </>
            ) : null}
            {step.type == "message" ? ( // only show these options on message types
              <>
                <Option value="everyone_starting_today" key="everyone_starting_today">
                  As a group message to everyone starting today
                </Option>
                <Option value="direct_message_specific_users" key="direct_message_specific_users">
                  As individual messages to these specific users
                </Option>
                <Option value="direct_message_users_specific_channel" key="direct_message_users_specific_channel">
                  As individual messages to users in a channel
                </Option>
                <Option value="message_departments" key="message_departments">
                  As a DM to users in these departments
                </Option>
                <Option value="message_work_locations" key="message_work_locations">
                  As a DM to users in these work locations
                </Option>
              </>
            ) : null}
          </Select>

          {steps[index]['whereToSend'] == "specific_channel" ? (
            <Select
              showSearch
              style={{ width: 200, marginLeft: "10px" }}
              value={steps[index]['whereToSendChannel']}
              placeholder="Select channel..."
              onChange={(value) => {
                updateStep('whereToSendChannel', value)
              }}
              filterOption={filterOptionFunction}
            >
              {slackChannelsQuery.data
                ? slackChannelsQuery.data.conversations.map((conversation) => {
                    return (
                      <Option
                        key={conversation.id}
                        value={conversation.id}
                        label={conversation.name}
                      >
                        {conversation.name}
                      </Option>
                    );
                  })
                : []}
            </Select>
          ) : null}

          {steps[index]['whereToSend'] == "group_message" ? (
            <Select
              style={{ width: 200, marginLeft: "10px" }}
              value={steps[index]['whereToSendGroupUsers']}
              mode="multiple"
              onChange={(value) => {
                updateStep('whereToSendGroupUsers', value)
              }}
            >
              <Option value="users_buddies" key="users_buddies">
                the user's buddies
              </Option>
              <Option value="users_manager" key="users_manager">
                the user's manager
              </Option>
              {/* all custom user vars */}
              {
                varNames.map((userVar, index) => {
                  return (
                    <Option value={userVar['user_id']} key={userVar['user_id']}>
                      {userVar['name']}
                    </Option>
                  )
                })
              }
            </Select>
          ) : null}
          
          {steps[index]['whereToSend'] == "direct_message_specific_users" ? (
            <Select
              showSearch
              style={{ width: 200, marginLeft: "10px" }}
              value={steps[index]['whereToSendSpecificUsers']}
              placeholder="Select users..."
              mode="multiple"
              onChange={(value) => {
                updateStep('whereToSendSpecificUsers', value)
              }}
              filterOption={filterOptionFunction}
            >
              {slackUsersQuery.data
              ? slackUsersQuery.data.conversations.map((user) => {
                  return (
                    <Option
                      key={user.id}
                      value={user.id}
                      label={user.real_name}
                    >
                      {user.real_name}
                    </Option>
                  );
                })
              : []} 
            </Select>
          ) : null}

          {steps[index]['whereToSend'] == "direct_message_users_specific_channel" ? (
            <Select
              showSearch
              style={{ width: 200, marginLeft: "10px" }}
              value={steps[index]['whereToSendSpecificChannelOfUsers']}
              placeholder="Select channel..."
              onChange={(value) => {
                updateStep('whereToSendSpecificChannelOfUsers', value)
              }}
              filterOption={filterOptionFunction}
            >
              {slackChannelsQuery.data
                ? slackChannelsQuery.data.conversations.map((conversation) => {
                    return (
                      <Option
                        key={conversation.id}
                        value={conversation.id}
                        label={conversation.name}
                      >
                        {conversation.name}
                      </Option>
                    );
                  })
                : []}
            </Select>
          ) : null}

          {steps[index]['whereToSend'] == "message_departments" ? (
            <Select
              showSearch
              mode="multiple"
              style={{ width: 200, marginLeft: "10px" }}
              onChange={(value) => {
                updateStep('whereToSendGroupUsers', value)
              }}
              value={steps[index]['whereToSendGroupUsers']}
              filterOption={filterOptionFunction}
            >
              {getAllEmployeeDepartmentsQuery.data?.departments.map((group, index) => {
                    return (
                      <Option key={index} value={group} label={group}>
                        {group}
                      </Option>
                    );
                })}
            </Select>
          ) : null}
          {steps[index]['whereToSend'] == "message_work_locations" ? (
            <Select
              showSearch
              mode="multiple"
              style={{ width: 200, marginLeft: "10px" }}
              onChange={(value) => {
                updateStep('whereToSendGroupUsers', value)
              }}
              value={steps[index]['whereToSendGroupUsers']}
              filterOption={filterOptionFunction}
            >
              {getAllEmployeeLocationsQuery?.data?.locations.map(
                    (location, index) => {
                      return (
                        <Option
                          key={index}
                          value={location}
                          label={location}
                        >
                          {location}
                        </Option>
                      );
                    }
                  )}
            </Select>
          ) : null}

        </>
      ) : null }

      {/* shoutout type dropdown */}
      {step.type == "shoutout" ? (
        <div id="stepBlock">
          <h5>Shoutout type</h5>
          <Select
            style={{ width: 190 }}
            placeholder="Select shoutout type..."
            onChange={(value) => {
              updateStep('shoutoutType', value)
            }}
            value={steps[index]['shoutoutType']}
          >
            {getSlackShoutoutTypesQuery.data
              ? getSlackShoutoutTypesQuery.data.shoutout_types.map(
                  (shoutout) => {
                    return (
                      <Option
                        key={shoutout.filename}
                        value={shoutout.filename}
                        label={shoutout.niceName}
                      >
                        {shoutout.niceName}
                      </Option>
                    );
                  }
                )
              : []}
          </Select>
        </div>
      ) : null}
      
      {/* shoutout type - who to send from */}
      {step.type == "shoutout" ? (
        <div id="stepBlock">
          <h5>Who to send shoutout from</h5>
          <Select
            showSearch
            style={{ width: 190 }}
            placeholder="Select user..."
            onChange={(value) => {
              updateStep('shoutoutSender', value)
            }}
            value={steps[index]['shoutoutSender']}
            filterOption={filterOptionFunction}
          >
             
            {anchorTimeType != "specific_date" ? ( // only show user tz option if experience was created to send to users when they're first seen in slack or a specific slack channel
              <Option
                key='users_manager'
                value='users_manager'
                label='users_manager'
              >
                User's Manager
              </Option>
            ) : null }

            {slackUsersQuery.data
              ? slackUsersQuery.data.conversations.map((user) => {
                  return (
                    <Option
                      key={user.id}
                      value={user.id}
                      label={user.real_name}
                    >
                      {user.real_name}
                    </Option>
                  );
                })
              : []}
          </Select>
        </div>
      ) : null}
      
      {/* shoutout type - who to shoutout */}
      {step.type == "shoutout" ? (
        <div id="stepBlock">
          <h5>Who to shoutout</h5>
          {/* only show this option if 'user seen for the first time' (either in general or in a channel) was selected in the first step */}
          {steps[index]['timeType'] != "specific_date" && anchorTimeType != "specific_date" ? (
            <Select
              style={{ width: 325 }}
              onChange={(value) => {
                updateStep('shoutoutTo', value)
              }}
              value={steps[index]['shoutoutTo']}
            >
              <Option value="user_seen_first_step" key="user_seen_first_step">
                User seen in first step
              </Option>
            </Select>
          ) : (
            <Select
              showSearch
              mode="multiple"
              style={{ width: 275 }}
              placeholder="Select user(s)..."
              onChange={(value) => {
                updateStep('shoutoutTo', value)
              }}
              value={steps[index]['shoutoutTo']}
              filterOption={filterOptionFunction}
            >
              {slackUsersQuery.data
                ? slackUsersQuery.data.conversations.map((user) => {
                    return (
                      <Option
                        key={user.id}
                        value={user.id}
                        label={user.real_name}
                      >
                        {user.real_name}
                      </Option>
                    );
                  })
                : []}
            </Select>
          )}
        </div>
      ) : null}
      
      {step.type == "message" ? (
        <div id="stepBlock">
          <Checkbox
            checked={steps[index]['sendAsAdmin']}
            onChange={(e) => {
              updateStep('sendAsAdmin', e.target.checked);
            }}
          >
            Send messages as the main CultureBot admin (instead of as CultureBot)
          </Checkbox>
        </div>
      ) : null}

      {/* main message area */} 
      <div id="stepBlock">
        <h5>
          {step.type == "message" ? "Your message" : (step.type == "shoutout" ? "Your shoutout message" : "Your survey message (optional)")}
          <br />
          <span id="userVarsDisclaimer">
              - To reference users, add user variables on the right side of this screen and use {"{variable name}"}
              {anchorTimeType != "specific_date" ? (
                <>
                  <br />- Since this message is triggered off of someone seen in Slack for the first time, you can also reference users with the variables {"{buddies}"}, {"{manager}"}, {"{new_hire}"}, {"{title}"}, {"{location}"}, and {"{department}"}
                </>
              ) : null}
              {step.type == "message" || step.type == "survey" ? (
                <>
                  <br />- To reference channels, use the name of the channel plus a hashtag (e.g., #general)
                  <br />- To add hyperlinks, use the syntax: &lt;http://www.example.com|this message is a link&gt;
                  <br />- To add bold text, use the syntax: <strong>*this message is bold*</strong>
                </>
              ) : null}
          </span>
        </h5>
        <TextArea
          rows={10}
          onChange={(e) => {
            updateStep('message', e.target.value);
          }}
          
          placeholder="Message to send..."
          value={steps[index]['message']}
          maxLength={2500}
        />
      </div>
      {/* add GIF to message -- only for message steps */}
      {step.type == "message" ? (
        <>
          <h5 id="stepBlock">Include GIF at top of message (optional)</h5>
          <Button
            className="template-button"
            type="dashed"
            onClick={(value) => {
                updateStep('selectedGif', null);
                setGifPickerOpen(steps[index]['selectedGif'] ? false: true);
              }
            }
          >
            {steps[index]['selectedGif'] ? (
              <>
                <MinusCircleOutlined /> Remove GIF
              </>
            ) : (
              <>
                <PlusOutlined /> Click to add GIF
              </>
            )}
          </Button>
          <br />
          {steps[index]['selectedGif'] ? <img src={steps[index]['selectedGif']['url']} /> : null}
          <Modal
            title="Select a GIF"
            open={gifPickerOpen}
            cancelButtonProps={{ hidden: true }}
            closable={false} // This will remove the close "x" button
            footer={null}
          >
            <GifPicker
              width={"100%"}
              tenorApiKey={"AIzaSyDsuIXyxQ-QPxhlAcSVCbys7vpJIxBFpGo"}
              onGifClick={(gif) => {
                updateStep('selectedGif', gif);
                setGifPickerOpen(false);
              }
            }
            />
          </Modal>
        </>
      ) : null}
    </>
  );
}