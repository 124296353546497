import React from "react";
import {
  LockOutlined,
  PlusOutlined,
  FormOutlined,
  CommentOutlined,
  UserOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import MainContainer from "../Container/MainContainer";
import {
  Card,
  Typography,
  DatePicker,
  Tabs,
  Modal,
  Input,
  Button,
  Select,
  message,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./Permissions.scss";
import moment from "moment";
import FullAdmins from './FullAdmins';
import SurveyAdmins from './SurveyAdmins';
import IntrosAdmins from './IntrosAdmins';
import WaterCoolerAdmins from './WaterCoolerAdmins';
import BirthdayAdmins from './BirthdayAdmins';
import AnniversaryAdmins from './AnniversaryAdmins';
import CustomCelebrations from './CustomCelebrations';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function PermissionsPage() {

  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bulkUsers, setBulkUsers] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addAdminsClicked = async (userIds, refetchAdmins) => {
    try {
      const endpoint = activeTab === "1" 
        ? `${getBaseUrl()}/api/addAdmins` 
        : `${getBaseUrl()}/api/updatePartialAdmins`;

      let feature;
      switch (activeTab) {
        case "2":
          feature = "water_cooler";
          break;
        case "3":
          feature = "intro";
          break;
        case "4":
          feature = "survey";
          break;
        case "5":
          feature = "birthday";
          break;
        case "6":
          feature = "anniversary";
          break;
        case "7":
          feature = "custom_celebration";
          break;
        default:
          feature = undefined;
      }

      const body = activeTab === "1" 
        ? { user_ids: userIds } 
        : { user_ids: userIds, feature: feature, value: true };

      const response = await doPostRequest(endpoint, body, setTokenErrorType);

      if (response.success) {
        message.success(`Successfully added/updated admins.`);
        refetchAdmins();
      } else if (response.error) {
        throw new Error(response.error);
      } else {
        throw new Error("Failed to add/update admins");
      }
    } catch (error) {
      const errorMessage = JSON.parse(error.message);
      message.error(errorMessage.error);
    }
  };
  
  const handleOk = () => {
    addAdminsClicked(selectedUsers, getAdminsQuery.refetch);
    setIsModalVisible(false);
    setSelectedUsers([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getAdminsQuery = useQuery(
    ["GET_ADMINS", activeTab],
    async () => {
      if (activeTab === "1") {
        return doGetRequest(
          `${getBaseUrl()}/api/getAllAdminsForTeam`,
          setTokenErrorType,
          []
        );
      } else {
        let type;
        switch (activeTab) {
          case "2":
            type = "water_cooler";
            break;
          case "3":
            type = "intro";
            break;
          case "4":
            type = "survey";
            break;
          case "5":
            type = "birthday";
            break;
          case "6":
            type = "anniversary";
            break;
          case "7":
            type = "custom_celebration";
            break;
        }
        return doGetRequest(
          `${getBaseUrl()}/api/getPartialAdminsForTeam`,
          setTokenErrorType,
          [{ name: "feature", value: type }]
        );
      }
    },
    { refetchOnWindowFocus: false }
  );

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // Fetch users for the dropdown
  const getSlackUsersQuery = useQuery(
    ["getSlackUsersQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "users" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  // Page Frame
  let mainArea;
  if (getAdminsQuery.isLoading) {
    mainArea = <div>Loading...</div>;
  } else if (getAdminsQuery.isError) {
    mainArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    mainArea = (
      <div>
        <div>
          <Title>
            <LockOutlined style={{ fontSize: "32px" }} /> User Permissions
          </Title>
          <Button 
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={showModal}>
            Add Users
          </Button>
          <br /><br />
          <Modal
            title="Add Users"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Add"
          >
            <h5>Choose one or multiple users</h5>
            <Select
              placeholder="Select User"
              style={{ width: "100%", marginTop: 10 }}
              onChange={(value) => {
                if (value.length > 50) {
                  message.warning("You can only select up to 50 users at a time.");
                  return;
                }
                setSelectedUsers(value);
              }}
              loading={getSlackUsersQuery.isLoading}
              disabled={getSlackUsersQuery.isLoading || getSlackUsersQuery.isError}
              mode="multiple"
              value={selectedUsers}
              showSearch
              filterOption={(input, option) => 
                  option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {getSlackUsersQuery.data
              ? getSlackUsersQuery.data.conversations.map((conversation) => {
                  // Check platform and set display name accordingly
                  const displayName = getSlackUsersQuery.data.platform === "slack" 
                    ? conversation.real_name 
                    : conversation.displayName; // Use displayName for Teams

                  return (
                    <Option
                      key={conversation.id}
                      value={conversation.id}
                      label={displayName}
                    >
                      {displayName}
                    </Option>
                  );
                })
              : []}
            </Select>
          </Modal>
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            type="card"
            size="middle"
          >
            <Tabs.TabPane tab={<span><UserOutlined /> Full Admins</span>} key="1">
              <FullAdmins getAdminsQuery={getAdminsQuery} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><CommentOutlined /> Water Cooler Admins</span>} key="2">
              <WaterCoolerAdmins getAdminsQuery={getAdminsQuery} />
            </Tabs.TabPane>
            {getSlackUsersQuery?.data?.platform != "teams" && (
              <>
                <Tabs.TabPane tab={<span><CalendarOutlined /> Intros Admins</span>} key="3">
                  <IntrosAdmins getAdminsQuery={getAdminsQuery} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><FormOutlined /> Survey Admins</span>} key="4">
                  <SurveyAdmins getAdminsQuery={getAdminsQuery} />
                </Tabs.TabPane>
              </>
            )}
            <Tabs.TabPane tab={<span><FormOutlined /> Birthday Admins</span>} key="5">
              <BirthdayAdmins getAdminsQuery={getAdminsQuery} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><FormOutlined /> Anniversary Admins</span>} key="6">
              <AnniversaryAdmins getAdminsQuery={getAdminsQuery} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><FormOutlined /> Custom Celebrations</span>} key="7">
              <CustomCelebrations getAdminsQuery={getAdminsQuery} />
            </Tabs.TabPane>
          </Tabs>

        </div>
      </div>
    );
  }

  return (
    <>
      <MainContainer>
        <div className="main-container">
          <Card className="main-container-card">{mainArea}</Card>
        </div>
      </MainContainer>
    </>
  );
}
