import { Typography } from "antd";
import moment from "moment-timezone";
const { Text } = Typography;

// Might need to update translateFrequencyToRunValueToDays too if update
export function getFrequencyToSendStaticOptions() {
  return [
    {
      value: "ONE_TIME",
      label: "Only send once (at date to send)",
    },
    {
      value: "WEEKDAYS",
      label: "Only send on weekdays",
    },
    {
      value: "ONE_WEEK",
      label: "Every week",
    },
    {
      value: "TWO_WEEKS",
      label: "Every 2 weeks",
    },
    {
      value: "FOUR_WEEKS",
      label: "Every 4 weeks",
    },
    {
      value: "EIGHT_WEEKS",
      label: "Every 8 weeks",
    },
    {
      value: "TWELVE_WEEKS",
      label: "Every 12 weeks",
    },
    {
      value: "NEW_USERS_DAILY",
      label: "New users as they join Slack (daily)",
    },
    {
      value: "NEW_USERS_7_DAYS",
      label: "New users 7 days after they join Slack",
    },
    {
      value: "NEW_USERS_30_DAYS",
      label: "New users 30 days after they join Slack",
    },
    {
      value: "NEW_USERS_60_DAYS",
      label: "New users 60 days after they join Slack",
    },
    {
      value: "NEW_USERS_90_DAYS",
      label: "New users 90 days after they join Slack",
    },
  ];
}

function roundToNearestHalfHour(momentObj) {
  const minutes = momentObj.minutes();
  const roundedMinutes = Math.round(minutes / 30) * 30;
  return momentObj.clone().minutes(roundedMinutes).seconds(0).milliseconds(0);
}

// Gives text like "Results will be shared on Monday, June 10, 2024, 1:30 PM (1.5 hours after initial send)"
export function getShareResultsHelperTextIfNeeded(initialSendDate, initialSendTime, shareResultsValue, shareResultsTimeUnit) {

  if (!initialSendDate || !initialSendTime || !shareResultsValue) {
    return null;
  }

  let unit, futureDate, formattedValue;
  const initialMoment = moment(initialSendDate).hour(initialSendTime.hour()).minute(initialSendTime.minute()).second(0).millisecond(0);

  if (shareResultsTimeUnit === 'hours') {
    const minutesToAdd = shareResultsValue * 60;
    futureDate = initialMoment.clone().add(minutesToAdd, 'minutes');
    if (shareResultsValue === 0.5) {
      unit = 'half hour';
      formattedValue = '0.5';
    } else {
      unit = shareResultsValue === 1 ? 'hour' : 'hours';
      formattedValue = shareResultsValue.toString();
    }
  } else {
    unit = shareResultsValue === 1 ? 'day' : 'days';
    futureDate = initialMoment.clone().add(shareResultsValue, 'days');
    formattedValue = shareResultsValue.toString();
  }

  return (
    <Text italic>
      Results will be shared on {futureDate.format('dddd, MMMM Do YYYY, h:mm a')} ({formattedValue} {unit} after initial send)
    </Text>
  );
}

export function getReminderHelperTextIfNeeded(initialSendDate, numDaysAfter) {
  if (!initialSendDate || !numDaysAfter) {
    return null;
  }
  let newDate = initialSendDate.clone().add(numDaysAfter, "days");
  return (
    <>
      <br />
      <Text italic>
        Reminders will be sent on {newDate.format("dddd")}s (Since you selected{" "}
        {initialSendDate.format("dddd")} as first day to send)
      </Text>
    </>
  );
}

export function filterOptionFunction(input, option) {
  return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
}

// Works only for recurring campaigns
export function translateFrequencyToRunValueToDays(frequencyToRunValue) {
  return {
    ONE_WEEK: 7,
    TWO_WEEKS: 14,
    FOUR_WEEKS: 28,
  }[frequencyToRunValue];
}
