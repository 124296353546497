import {
  Button,
  Typography,
  Table,
  Space,
  Modal,
  Select,
  Input,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../helpers/requestHelpers";
import GifPicker from "gif-picker-react";

const { Option } = Select;
const { Text, Title } = Typography;

function App() {

  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCustomReward, setCurrentCustomReward] = useState();
  const [descriptionInputStatusText, setDescriptionInputStatusText] = useState("");
  const [pointsInputStatusText, setPointsInputStatusText] = useState("");
  const [isGifPickerOpen, setIsGifPickerOpen] = useState(false);
  const [pagination, setPagination] = useState({
    position: ["bottomCenter"],
    pageSize: 50,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  });

  // Networking requests for custom shoutouts
  const getCustomRewardsQuery = useQuery(
    ["GET_CUSTOM_REWARDS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getCustomRewards`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  const getCustomRewardsQueryRefetch = getCustomRewardsQuery.refetch;

  // save/edit custom reward
  async function saveCustomRewardClicked() {
  
    setDescriptionInputStatusText("");
    if (!currentCustomReward.description) {
        setDescriptionInputStatusText("error");
      return;
    }

    setPointsInputStatusText("");
    if (!currentCustomReward.points) {
      setPointsInputStatusText("error");
      return;
    }

    setIsModalOpen(false);

    const jsonToSend = {
      description: currentCustomReward.description,
      points:currentCustomReward.points,
      image: currentCustomReward.image,
      link: currentCustomReward.link,
    };
    
    if (currentCustomReward.id) {
      // is an edit
      jsonToSend.id = currentCustomReward.id;
    } else {
        jsonToSend.id = ""
    }

    const response = await doPostRequest(
      `${getBaseUrl()}/api/addOrEditCustomReward`,
      jsonToSend,
      setTokenErrorType
    );
    
    // todo check response?
    getCustomRewardsQueryRefetch(); // refresh the current table view
    queryClient.invalidateQueries("GET_REWARDS_CATALOG"); // refreshes the catalog tab

  }

  // delete a reward
  async function deleteCustomRewardClicked(rewardToDelete) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteCustomReward`,
      { custom_reward_id: rewardToDelete.id },
      setTokenErrorType
    );
    getCustomRewardsQueryRefetch(); // refresh the current table view
    queryClient.invalidateQueries("GET_REWARDS_CATALOG"); // refreshes the catalog tab
  }

  // Form handlers
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openEditCustomReward = (customReward) => {
    setDescriptionInputStatusText("");
    setPointsInputStatusText("");
    if (customReward) {
      setCurrentCustomReward({
        id: customReward.id,
        description:customReward.description,
        image: customReward.image,
        points: customReward.points,
        link: customReward.link,
      });
    } else {
        setCurrentCustomReward({
        name: "",
        description:"",
      }); 
    }
    setIsModalOpen(true);
  };

  const customRewardDescriptionChanged = (newDescriptionEvent) => {
    const clonedReward = Object.assign({}, currentCustomReward);
    clonedReward.description = newDescriptionEvent.target.value;
    setCurrentCustomReward(clonedReward);
  };

  const customRewardImageChanged = (newImageEvent) => {
    const clonedReward = Object.assign({}, currentCustomReward);
    clonedReward.image = newImageEvent.target.value;
    setCurrentCustomReward(clonedReward);
  };

  const customRewardLinkChanged = (newLinkEvent) => {
    const clonedReward = Object.assign({}, currentCustomReward);
    clonedReward.link = newLinkEvent.target.value;
    setCurrentCustomReward(clonedReward);
  };

  const customRewardPointsChanged = (newPointsEvent) => {
    const clonedReward = Object.assign({}, currentCustomReward);
    clonedReward.points = newPointsEvent;
    setCurrentCustomReward(clonedReward);
  };

  // modal to create custom reward
  const createCustomRewardModal = (
    <Modal
      title="Custom Reward"
      okText="Save"
      open={isModalOpen}
      onOk={saveCustomRewardClicked}
      onCancel={handleCancel}
    >
      {currentCustomReward ? (
        <>
          <Title level={5}>Reward Name:</Title>
          <Input
            style={{ width: 200 }}
            status={descriptionInputStatusText}
            value={currentCustomReward.description}
            onChange={customRewardDescriptionChanged}
            placeholder="Custom reward name"
          />
          <div style={{ marginTop: "20px" }}></div>
          <Title level={5}>Points to Redeem:</Title>
          <InputNumber
            style={{ width: 100 }}
            status={pointsInputStatusText}
            min={1} 
            max={1000} 
            type="number"
            value={currentCustomReward.points}
            onChange={customRewardPointsChanged}
          />
          <div style={{ marginTop: "20px" }}></div>
          <Title level={5}>Image URL:</Title>
          <p>(Select an image from Giphy)</p>
          <Input
            style={{ width: '100%' }}
            value={currentCustomReward.image}
            onChange={customRewardImageChanged}
            placeholder="Image for the reward (must use public link)"
          />
          <Button
            type="primary"
            shape="round"
            onClick={() => setIsGifPickerOpen(true)} // Open GIF picker modal
            style={{ marginTop: '10px' }}
          >
            Choose GIF
          </Button>
          <div style={{ marginTop: "10px" }}></div>
          {currentCustomReward.image && (
            <img
              src={currentCustomReward.image}
              alt="Selected GIF"
              style={{ width: '50%', marginTop: '10px' }} // Adjusted width for smaller preview
            />
          )}
          <div style={{ marginTop: "20px" }}></div>
          <Title level={5}>Link URL:</Title>
          <Input
            style={{ width: '100%' }}
            value={currentCustomReward.link}
            onChange={customRewardLinkChanged}
            placeholder="Link to share with user when reward redeemed"
          />
          <div style={{ marginTop: "20px" }}></div>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );

  // GIF Picker Modal
  const gifPickerModal = (
    <Modal
      title="Select a GIF"
      open={isGifPickerOpen}
      closable={false} // This will remove the close "x" button
      footer={null}
    >
      <GifPicker
          width={"100%"}
          tenorApiKey={"AIzaSyDsuIXyxQ-QPxhlAcSVCbys7vpJIxBFpGo"}
          onGifClick={(gif) => {
            setCurrentCustomReward((prev) => ({
              ...prev,
              image: gif.url, 
            }));
            setIsGifPickerOpen(false); 
          }
        }
      /> 
    </Modal>
  );

  // Main UI 
  const columns = [
    {
      title: "Reward Description",
      width: "15%",
      render: (_, record) => {
        return <div>{record.description}</div>;
      },
    },
    {
      title: "Reward Points",
      width: "15%",
      render: (_, record) => {
        return <div>{record.points}</div>;
      },
    },
    {
      title: "Reward Image",
      width: "30%",
      render: (_, record) => {
        return (
          <div style={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {record.image}
          </div>
        );
      },
    },
    {
      title: "Reward Link",
      width: "30%",
      render: (_, record) => {
          return <div>{record.link}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => openEditCustomReward(record)}>Edit Reward</a>
          <a onClick={() => deleteCustomRewardClicked(record)}>Delete Reward</a>
        </Space>
      ),
    },
  ];

  // Table Data
  var tableArea;
  if (getCustomRewardsQuery.isLoading) {
    tableArea = <div>Loading custom rewards...</div>;
  } else if (getCustomRewardsQuery.isError || getCustomRewardsQuery.data.error) {
    tableArea =
      "Something went wrong, please refresh and try again. If the problem persists please contact support@getculturebot.com";
  } else {
    const tableData = getCustomRewardsQuery.data.custom_rewards.map(
      (currentCustomReward) => {
        return {
          id: currentCustomReward.reward_id,
          key: currentCustomReward.id,
          description: currentCustomReward.description,
          points: currentCustomReward.points,
          image: currentCustomReward.image,
          link: currentCustomReward.link
        };
      }
    );
    console.log('getCustomRewardsQuery: ', getCustomRewardsQuery.data.custom_rewards)
    tableArea = (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, pageSize }));
          },
        }}
        scroll={{ x: 'max-content' }} // Allows horizontal scrolling if needed
      />
    );
  }

  // Page Frame
  let mainArea;
  mainArea = (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={() => openEditCustomReward()}
      >
        Add custom reward
      </Button>
      <div style={{ marginTop: "30px" }}></div>
      {tableArea}
    </>
  );

  return (
    <>
    <div>
        {createCustomRewardModal}
        {gifPickerModal} {/* Include the GIF picker modal */}
        {mainArea}
    </div>
    </>
  );
}

export default App;
