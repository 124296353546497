export const customTheme = {
    // ... other theme settings ...
    grid: {
      // Custom breakpoints
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      teams: 1060,
      xl: 1200,
      xxl: 1600,
    },
  };