import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Space, Checkbox, Select, TimePicker, Button, List, Modal, Input, Radio, Upload, message } from "antd";
import moment from "moment";
import { UploadOutlined, SyncOutlined, GiftOutlined, UsergroupAddOutlined, ClockCircleOutlined } from '@ant-design/icons';
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
} from "../../../helpers/requestHelpers";
import {
  mapChannels,
  mapChannelIdsToDisplayNames,
  mapChannelNamesToIds,
} from "../Shared/useTenantChannels";
import { clearUrlParams } from "../../../helpers/utilityHelpers";

const { Option } = Select;

const BirthdaysTab = ({
  getTenantSettingsQuery, 
  getTenantChannelsQuery,
  getTenantUsersQuery,
  channelMap,
  channelOptions,
  userOptions
}) => {
  
  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);

  const [bdayMessage, setBdayMessage] = useState("");
  const [rotateMessages, setRotateMessages] = useState(false);
  const [celebrationGif, setCelebrationGif] = useState(false);
  const [celebrationCollage, setCelebrationCollage] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSendWhen, setSelectedSendWhen] = useState("");
  const [isSendingEnabled, setIsSendingEnabled] = useState(false);
  const [collectingModalVisible, setCollectingModalVisible] = useState(false);
  const [editMessageModalVisible, setEditMessageModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [giftModalVisible, setGiftModalVisible] = useState(false);
  const [sendAsAdmin, setSendAsAdmin] = useState(false);
  const [onboardingChannel, setOnboardingChannel] = useState("");
  const [onboardingEnabled, setOnboardingEnabled] = useState(false);

  const [isCsvUploadVisible, setCsvUploadVisible] = useState(false);
  const [collectOption, setCollectOption] = useState("selected");
  const [isConfirmCollectModalVisible, setConfirmCollectModalVisible] = useState(false);
  const [isMessageSubmitting, setIsMessageSubmitting] = useState(false);
  const [isGiftSubmitting, setIsGiftSubmitting] = useState(false);
  const [isAllCollectedVisible, setAllCollectedVisible] = useState(false);
  const [isAllNotCollectedVisible, setAllNotCollectedVisible] = useState(false);
  const [isModifyBdayModalVisible, setModifyBdayModalVisible] = useState(false);
  const [selectedUserForModification, setSelectedUserForModification] = useState({ id: null, name: '' });
  const [isCollectingLoading, setIsCollectingLoading] = useState(false);
  const [isCollectingAllLoading, setIsCollectingAllLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  // selected user birthday state
  const [userBirthday, setUserBirthday] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  
  const [birthdayGiftModalVisible, setBirthdayGiftModalVisible] = useState(false);

  // get all birthdays
  const getAllBirthdaysQuery = useQuery(
    ["GET_ALL_BIRTHDAYS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllBirthdays`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false }
  );

  const birthdaysCollected = getAllBirthdaysQuery.data ? getAllBirthdaysQuery.data.birthday_collected_list : [];
  const birthdaysNotCollected = getAllBirthdaysQuery.data ? getAllBirthdaysQuery.data.birthday_not_collected_list : [];

  // updates birthday settings
  async function updateBirthdaySettings({
    channels,
    time,
    sendWhen,
    isSendingEnabled,
    bdayMessage,
    rotateMessages,
    celebrationGif,
    celebrationCollage,
    sendAsAdmin,
    onboardingChannel,
    onboardingEnabled,
  }) {
    const payload = {};
    if (channels) {
      const newChannelMap = mapChannels(getTenantChannelsQuery.data);
      const { channelIds, channelsTeamIds } = mapChannelNamesToIds(
        channels,
        newChannelMap
      );
      payload.birthday_channels = channelIds;
      payload.birthday_channels_team_ids = channelsTeamIds;
    } else {
      payload.birthday_channels = [];
      payload.birthday_channels_team_ids = [];
    }
    if (time) {
      payload.birthday_time = time.utc().format("HH:mm");
    }
    if (sendWhen) {
      payload.birthday_sending_type = sendWhen;
    }
    if (isSendingEnabled !== undefined) {
      payload.birthday_on = isSendingEnabled;
    }
    if (bdayMessage !== undefined) {
      payload.birthday_message = bdayMessage;
    }
    if (rotateMessages !== undefined) {
      payload.birthday_rotate_messages = rotateMessages;
    }
    if (celebrationGif !== undefined) {
      payload.birthday_celebration_gif = celebrationGif;
    }
    if (celebrationCollage !== undefined) {
      payload.birthday_celebration_collage = celebrationCollage;
    }
    if (sendAsAdmin !== undefined) {
      payload.birthday_send_as_admin = sendAsAdmin;
    }
    if (onboardingChannel) {
      payload.birthday_onboarding_channel = onboardingChannel;
    }
    if (onboardingEnabled !== undefined) {
      payload.birthday_onboarding_on = onboardingEnabled;
    }

    try {
      const response = await doPostRequest(
        `${getBaseUrl()}/api/updateBirthdaySettings`,
        payload,
        setTokenErrorType
      );

      if (!response.success) {
        console.log("Error updating birthday settings 1:", response);
        setErrorMessage(response.message);
      } else {
        setErrorMessage(""); // Clear any previous error messages

        // Show success message
        message.success("Birthday settings updated successfully!");

        // close all modals
        setEditMessageModalVisible(false);
        setGiftModalVisible(false);
        clearUrlParams();

        // Invalidate query to trigger table refresh
        queryClient.invalidateQueries("GET_TENANT_SETTINGS");
      }
    } catch (response) {
      console.log("Error updating birthday settings 2:", response.message);
      setErrorMessage(response.message);
    }
  }
    
  useEffect(() => {
    const userBirthday = birthdaysCollected.find(
      (bday) => bday.user_id === selectedUserForModification.id
    );
    setUserBirthday(userBirthday);
    setCurrentMonth(userBirthday ? userBirthday.birth_month : null);
    setCurrentDay(userBirthday ? userBirthday.birth_day : null);
  }, [selectedUserForModification, birthdaysCollected]);

  // Pre-populate settings when GET_TENANT_SETTINGS returns
  useEffect(() => {
    if (getTenantSettingsQuery.data && getTenantChannelsQuery.data) {
  
      const newChannelMap = mapChannels(getTenantChannelsQuery.data);

      // Use the new function to map prepopulated channel IDs to their display names
      const prepopulatedChannelIds =
        getTenantSettingsQuery.data.birthday_channels || [];
      const prepopulatedChannels = mapChannelIdsToDisplayNames(
        prepopulatedChannelIds,
        newChannelMap
      );
      setSelectedChannels(prepopulatedChannels);

      // Set the selectedTime from birthday_message_hour_of_day
      const hourOfDay =
        getTenantSettingsQuery.data.birthday_message_hour_of_day;
      if (hourOfDay) {
        setSelectedTime(moment.utc(hourOfDay, "HH:mm").local());
      }

      setSelectedSendWhen(
        getTenantSettingsQuery.data.birthday_sending_type || ""
      );
      setIsSendingEnabled(getTenantSettingsQuery.data.birthday_on || false);
      setBdayMessage(getTenantSettingsQuery.data.birthday_message || "");
      setRotateMessages(
        getTenantSettingsQuery.data.birthday_additional_messages || false
      );
      setCelebrationGif(getTenantSettingsQuery.data.birthday_gif_on || false);
      setCelebrationCollage(
        getTenantSettingsQuery.data.birthday_graphic_on || false
      );
      setSendAsAdmin(
        getTenantSettingsQuery.data.birthday_send_as_admin || false
      );
      setOnboardingChannel(
        getTenantSettingsQuery.data.birthday_onboarding_channel || ""
      );
      setOnboardingEnabled(
        getTenantSettingsQuery.data.birthday_onboarding_on || false
      );

    }
  }, [
    getTenantSettingsQuery.data,
    getTenantChannelsQuery.data,
    getTenantUsersQuery.data,
    editMessageModalVisible,
    giftModalVisible,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('modalOpen') === 'manageGift') {
      setGiftModalVisible(true);
    }
  }, []);

  return (
    <>
      <Space direction="vertical" style={{ width: "100%", textAlign: "left", alignItems: "flex-start" }}>
        <img 
          src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/home_header_graphics%2Fbirthday-celebrations.png" 
          alt="Birthday Celebrations Banner" 
          style={{ width: "100%", maxWidth: "600px", height: 'auto', borderRadius: '10px' }} 
        />
        <div style={{ padding: '10px 0' }}>
          <h4>Birthday celebrations allows you to collect birthdays automatically across your team and share fun, celebratory (and customizable) posts in Teams whenever it's someones special day.</h4>
        </div>
        <div>
          <Checkbox
            checked={isSendingEnabled}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setIsSendingEnabled(isChecked);
              updateBirthdaySettings({ isSendingEnabled: isChecked });
            }}
          >
            Enable Birthday Sending
          </Checkbox>
        </div>

        <div>
          <label>Birthdays Channel(s)</label>
          <Select
            mode="multiple"
            placeholder="Select Channel"
            style={{ width: "100%" }}
            value={selectedChannels}
            onChange={(channelIds) => {
              updateBirthdaySettings({ channels: channelIds });
            }}
            loading={!channelOptions}
            filterOption={(input, option) => {
              const optionText = option.children ? option.children.toLowerCase() : '';
              return optionText.includes(input.toLowerCase());
            }}
          >
            {channelOptions}
          </Select>
          <small style={{ display: 'block', marginTop: '5px', color: '#888' }}>
            Custom options: send messages via DM, send in user's timezone <a href="mailto:support@getculturebot.com">(contact us)</a>
          </small>
        </div>
        <div>
          <label>Time to Celebrate Birthdays</label>
          <TimePicker
            format="hh:mm A"
            minuteStep={30}
            use12Hours
            style={{ width: "200px", display: "block" }}
            value={selectedTime}
            onChange={(time) => {
              setSelectedTime(time);
              updateBirthdaySettings({ time });
            }}
          />
          <br />
          <span>Your Timezone: <br /> <strong>{Intl.DateTimeFormat().resolvedOptions().timeZone}</strong></span>
        </div>
        <div>
          <label>Select When to Send</label>
          <Select 
            placeholder="Select When to Send" 
            style={{ width: "300px", display: "block" }}
            value={selectedSendWhen}
            onChange={(value) => {
              setSelectedSendWhen(value);
              updateBirthdaySettings({ sendWhen: value });
            }}
          >
            <Option value="ON_DAY_OF">On day of birthday</Option>
            <Option value="SKIP_WEEKENDS">On day of birthday, skip weekends</Option>
            <Option value="ON_MONDAYS">On Mondays only</Option>
            <Option value="FIRST_MONDAY_OF_MONTH">First Monday of every month</Option>
          </Select>
        </div>
        <label>Birthday Collection</label>
        <Button 
          icon={<UsergroupAddOutlined />} 
          shape="round"
          onClick={() => setCollectingModalVisible(true)}
        >
          Collecting Birthdays
        </Button>
        <label>Customize Birthday Message</label>
        <Button shape="round" type="default" onClick={() => setEditMessageModalVisible(true)}>
          Edit Message
        </Button>
        <div>
          <Checkbox
            checked={onboardingEnabled}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setOnboardingEnabled(isChecked);
              updateBirthdaySettings({ onboardingEnabled: isChecked });
            }}
          >
            Send a message to all new employees added to Teams to collect their birthday
          </Checkbox>
        </div>
        <div>
          <label>Set a specific channel to watch</label>
          <Select
            showSearch
            placeholder="Select Channel"
            style={{ width: "100%" }}
            value={onboardingChannel}
            onChange={(value) => {
              setOnboardingChannel(value);
              updateBirthdaySettings({ onboardingChannel: value });
            }}
            loading={!channelOptions}
            filterOption={(input, option) => {
              const optionText = option.children ? option.children.toLowerCase() : '';
              return optionText.includes(input.toLowerCase());
            }}
          >
            {channelOptions}
          </Select>
          <small style={{ display: 'block', marginTop: '5px', color: '#888' }}>
            Note: if not set, anyone who joins Teams for the first time will be asked for their birthday
          </small>
        </div>
        <div>
          <Checkbox
            checked={sendAsAdmin}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setSendAsAdmin(isChecked);
              updateBirthdaySettings({ sendAsAdmin: isChecked });
            }}
          >
            Send messages as the main CultureBot admin instead of as CultureBot
          </Checkbox>
        </div>

        {/* New Section for Manager Alerts and Birthday Gifts */}
        <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
          <p>
            Manage advanced notifications for sending alerts to manager(s) and/or employees <strong>before</strong> their birthday.
            Also, set up the ability to send out a video eCard for teammates to sign and send to the employee.
          </p>
          <p>
            To send a gift card on someone's birthday, set it up from the shoutouts page.
          </p>
          <a href="/app/celebration_alerts">
            <Button icon={<ClockCircleOutlined />} shape="round" style={{ marginRight: '10px' }}>
              Setup Manager Alerts
            </Button>
          </a>
          <Button 
            icon={<GiftOutlined />} 
            shape="round"
            onClick={() => setBirthdayGiftModalVisible(true)}
          >
            Setup Birthday Gift
          </Button>
        </div>

      </Space>

      <Modal
        title="Collecting Birthdays"
        open={collectingModalVisible}
        onCancel={() => setCollectingModalVisible(false)}
        footer={[
          <Button
            key="close"
            onClick={() => setCollectingModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '16px' }}>
          <h3>Uploading birthdays for users</h3>
          <Button
            type="default"
            shape="round"
            icon={<UploadOutlined />}
            style={{ marginRight: '8px', border: '1px solid #1890ff', color: '#1890ff' }}
            onClick={() => {
              setCsvUploadVisible(!isCsvUploadVisible);
            }}
          >
            Upload via CSV
          </Button>
          <Button
            type="default"
            shape="round"
            icon={<SyncOutlined />}
            style={{ border: '1px solid #1890ff', color: '#1890ff' }}
            onClick={() => {
              window.location.assign('/app/hris_setup');
            }}
          >
            Sync with HRIS
          </Button>
        </div>
        {isCsvUploadVisible && (
          <>
            <div style={{ 
              backgroundColor: '#e6f7ff', 
              border: '1px solid #91d5ff', 
              borderRadius: '4px', 
              padding: '10px', 
              marginBottom: '10px' 
            }}>
              • Format your file to include at least 2 columns -- one for 'email' and one for 'birthday'<br />
              • Emails must match the work email your employees use in Teams<br />
              • Formatting of birthday dates must be 'YYYY-MM-DD' (e.g. 2021-05-12)
            </div>
            <Upload
              accept=".csv"
              maxCount={1}
              beforeUpload={(file) => {
              const isCSV = file.type === 'text/csv';
              const isLt10M = file.size / 1024 / 1024 < 10;
              if (!isCSV) {
                message.error('You can only upload CSV files!');
              }
              if (!isLt10M) {
                message.error('File must be smaller than 10MB!');
              }
              return isCSV && isLt10M;
            }}
            customRequest={({ file, onSuccess, onError }) => {
              console.log("file: ", file);
              const formData = new FormData();
              formData.append('file', file);
              formData.append('type', 'birthdays');

              doPostRequest(
                `${getBaseUrl()}/api/processFileUpload`,
                formData,
                (error) => {
                  onError(error);
                  message.error('File upload failed, please try again...');
                }
              ).then(data => {
                onSuccess(data);
                message.success('File uploaded successfully');
                queryClient.invalidateQueries("GET_ALL_BIRTHDAYS"); // refresh birthdays list given modified birthdays
              });
            }}
          >
            <div id="csv-upload-area" style={{ border: '1px dashed #d9d9d9', padding: '20px', textAlign: 'center' }}>
                Drag and drop your CSV file here or click to upload.
              </div>
            </Upload>
          </>
        )}

        <div style={{ marginTop: '20px' }}>
          <h3>Collect birthdays from users (one-off)</h3>
          <p>The following message will be sent to users on your team after you click the button below.</p>
          <blockquote>
            "Hey Sally, If you'd like us to celebrate you when the time comes, please click below to enter your birthday!"
          </blockquote>
          <Radio.Group onChange={(e) => setCollectOption(e.target.value)} value={collectOption}>
            <Radio value="all" style={{ display: 'block', marginBottom: '8px' }}>
              Send to all users (who haven't already submitted their birthday)
            </Radio>
            <Radio value="selected" style={{ display: 'block', marginBottom: '8px' }}>
              Send to selected users
            </Radio>
            <Radio value="channel" style={{ display: 'block', marginBottom: '8px' }}>
              Send to channel of users
            </Radio>
          </Radio.Group>
          {collectOption === "selected" && (
            <div style={{ marginTop: '10px' }}>
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Users"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(users) => setSelectedUsers(users)}
              >
                {userOptions}
              </Select>
            </div>
          )}
          {collectOption === "channel" && (
            <div style={{ marginTop: '10px' }}>
              <Select
                showSearch
                placeholder="Select Channel"
                style={{ width: "100%" }}
                filterOption={(input, option) => {
                  const optionText = option.children ? option.children.toString().toLowerCase() : '';
                  return optionText.includes(input.toLowerCase());
                }}
                onChange={(channel) => {
                  setSelectedChannel(channel);
                  const teamId = channelMap.find(item => item.id == channel).teamId; 
                  setSelectedTeamId(teamId);
                }}
                loading={!channelOptions}
              >
                {channelOptions}
              </Select>
            </div>
          )}
          <Button
            type="default"
            shape="round"
            style={{ marginTop: '20px', border: '1px solid #1890ff', color: '#1890ff' }}
            loading={isCollectingLoading && collectOption !== "all"}
            disabled={isCollectingLoading}
            onClick={() => {

              const handleResponse = (error) => {
                setIsCollectingLoading(false);
                if (error && error.message) {
                  message.error(error.message);
                } else {
                  message.error('Failed to send messages, please try again...');
                }
              };

              const sendMessages = (url, data) => {
                doPostRequest(url, data, handleResponse).then(() => {
                  setIsCollectingLoading(false);
                  message.success('Messages sent successfully');
                });
              };

              if (collectOption === "all") {
                setConfirmCollectModalVisible(true);
              } else if (collectOption === "selected" && selectedUsers.length > 0) {
                setIsCollectingLoading(true);
                sendMessages(`${getBaseUrl()}/api/sendBirthdayCollectionMessages`, { birthday_user_ids: selectedUsers });
              } else if (collectOption === "channel" && selectedChannel) {
                setIsCollectingLoading(true);
                sendMessages(`${getBaseUrl()}/api/sendBirthdayCollectionMessages`, { channel_id: selectedChannel, team_id: selectedTeamId });
              } 
              
            }}
          >
            <GiftOutlined /> Send Birthday Collection Messages
          </Button>
        </div>

        <div style={{ marginTop: '20px' }}>
          <h3>Modify user birthdays as admin:</h3>
          <Select
            showSearch
            placeholder="Select User"
            style={{ width: "200px" }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(userId, option) => {
              const userName = option.children;
              setSelectedUserForModification({ id: userId, name: userName });
            }}
          >
            {userOptions}
          </Select>
          <Button
            type="default"
            shape="round"
            style={{ marginLeft: '5px', border: '1px solid #1890ff', color: '#1890ff' }}
            onClick={() => {
              if (selectedUserForModification.id) {
                setModifyBdayModalVisible(true);
              } else {
                message.warning('Please select a user first.');
              }
            }}
          >
            Modify
          </Button>
        </div>

        <div style={{ marginTop: '20px' }}>
          <h3>📝 Birthdays collected so far:</h3>
          <List
            dataSource={birthdaysCollected.slice(0, 10)}
            renderItem={item => {
              const formattedDate = moment(`${item.birth_month}-${item.birth_day}`, "M-D").format("MMM Do");
              return (
                <List.Item>
                  {item.user_name || item.email} ({formattedDate})
                </List.Item>
              );
            }}
          />
          {birthdaysCollected.length > 10 && (
            <Button onClick={() => setAllCollectedVisible(true)}>
              View all {birthdaysCollected.length} users
            </Button>
          )}
        </div>

        <Modal
          title="All Birthdays Collected"
          open={isAllCollectedVisible}
          onCancel={() => setAllCollectedVisible(false)}
          footer={[
            <Button key="close" onClick={() => setAllCollectedVisible(false)}>
              Close
            </Button>,
          ]}
        >
          <List
            dataSource={birthdaysCollected}
            renderItem={item => {
              const formattedDate = moment(`${item.birth_month}-${item.birth_day}`, "M-D").format("MMM Do");
              return (
                <List.Item>
                  {item.user_name || item.email} ({formattedDate})
                </List.Item>
              );
            }}
          />
        </Modal>

        <div style={{ marginTop: '20px' }}>
          <h3>📝 Birthdays not collected:</h3>
          <List
            dataSource={birthdaysNotCollected.slice(0, 10)}
            renderItem={item => (
              <List.Item>
                {item.user_name || item.email} 
              </List.Item>
            )}
          />
          {birthdaysNotCollected.length > 10 && (
            <Button onClick={() => setAllNotCollectedVisible(true)}>
              View all {birthdaysNotCollected.length} users
            </Button>
          )}
        </div>

        <Modal
          title="All Birthdays Not Collected"
          open={isAllNotCollectedVisible}
          onCancel={() => setAllNotCollectedVisible(false)}
          footer={[
            <Button key="close" onClick={() => setAllNotCollectedVisible(false)}>
              Close
            </Button>,
          ]}
        >
          <List
            dataSource={birthdaysNotCollected}
            renderItem={item => (
              <List.Item>
                {item.user_name || item.email} 
              </List.Item>
            )}
          />
        </Modal>
      </Modal>

      <Modal
        title="Confirm Collection"
        open={isConfirmCollectModalVisible}
        onCancel={() => setConfirmCollectModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setConfirmCollectModalVisible(false)}>
            Close
          </Button>,
          <Button key="collect" type="primary" loading={isCollectingAllLoading} disabled={isCollectingAllLoading} onClick={() => {
            setIsCollectingAllLoading(true);
            doPostRequest(`${getBaseUrl()}/api/sendBirthdayCollectionMessages`, { send_to_all: true })
              .then(() => {
                message.success('Messages sent successfully');
              })
              .catch((error) => {
                message.error('Failed to send messages, please try again...');
              })
              .finally(() => {
                setConfirmCollectModalVisible(false);
                setIsCollectingAllLoading(false);
              });
          }}>
            Collect
          </Button>,
        ]}
        centered
      >
        <p>Clicking "Collect" will send a message to all team members on Teams to collect birthdays</p>
      </Modal>

      <Modal
        title="Edit Birthday Message"
        open={editMessageModalVisible}
        onCancel={() => {
          setEditMessageModalVisible(false);
          setErrorMessage("");
        }}
        footer={[
          <Button key="close" onClick={() => {
            setEditMessageModalVisible(false);
            setErrorMessage("");
          }}>
            Close
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isMessageSubmitting}
            onClick={() => {
              setIsMessageSubmitting(true);
              updateBirthdaySettings({
                bdayMessage,
                rotateMessages,
                celebrationGif,
                celebrationCollage,
              }).finally(() => {
                setIsMessageSubmitting(false);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        {errorMessage && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {errorMessage}
          </div>
        )}
        <p>Modify the message that is sent along with birthday celebrations.</p>
        <label style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>🎉 Birthday Celebration Message</label>
        <Input.TextArea
          rows={4}
          value={bdayMessage}
          onChange={(e) => setBdayMessage(e.target.value)}
        />
        <div style={{ marginTop: '20px' }}>
          <Checkbox
            checked={rotateMessages}
            onChange={(e) => setRotateMessages(e.target.checked)}
          >
            Rotate Messages (optional)
          </Checkbox>
          <p style={{ marginLeft: '24px' }}>
            Check this box to rotate (randomly) through all celebration messages above. Messages will be considered separated when they're on a different line.
          </p>
          <Checkbox
            checked={celebrationGif}
            onChange={(e) => setCelebrationGif(e.target.checked)}
          >
            Celebration GIF (optional)
          </Checkbox>
          <p style={{ marginLeft: '24px' }}>
            Check here to send a celebratory (random) gif each time a birthday is announced.
          </p>
          <Checkbox
            checked={celebrationCollage}
            onChange={(e) => setCelebrationCollage(e.target.checked)}
          >
            Celebration Collage (optional)
          </Checkbox>
          <p style={{ marginLeft: '24px' }}>
            Check here to send a custom collage with everyone's face on it each time a birthday is announced (suggested for larger teams).
          </p>
        </div>
      </Modal>

      <Modal
        title={`Edit birthday for user`}
        open={isModifyBdayModalVisible}
        onCancel={() => setModifyBdayModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModifyBdayModalVisible(false)}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            
            const { id } = selectedUserForModification;
            const birth_month = currentMonth; // Assuming currentMonth is set from the Select component
            const birth_day = currentDay; // Assuming currentDay is set from the Select component

            // update user birthday
            if (id && birth_month && birth_day) {
              doPostRequest(
                `${getBaseUrl()}/api/updateUserBirthday`,
                { bday_user_id: id, birth_month, birth_day }
              )
              .then(() => {
                message.success('Birthday updated successfully');
                queryClient.invalidateQueries("GET_ALL_BIRTHDAYS"); // refresh birthdays list given modified birthday
                setModifyBdayModalVisible(false);
              })
              .catch((error) => {
                message.error('Failed to update birthday, please try again...');
              });
            } else {
              message.warning('Please select a valid date.');
            }
          }}>
            Save
          </Button>,
        ]}
      >
        {(() => {

          return (
            <>
              <p>Current birthday for <strong>{selectedUserForModification.name}</strong>:
              &nbsp;{userBirthday ? moment(`${userBirthday.birth_month}-${userBirthday.birth_day}`, "M-D").format("MMM Do") : "Not set"}
              </p>
              <label style={{ display: 'block', marginBottom: '10px' }}>Add/edit birthday:</label>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Select 
                  placeholder="Month" 
                  style={{ width: '120px' }}
                  value={currentMonth}
                  onChange={(value) => {
                    setCurrentMonth(value);
                  }}
                >
                  {moment.months().map((month, index) => (
                    <Option key={index} value={index + 1}>{month}</Option>
                  ))}
                </Select>
                <Select 
                  placeholder="Day" 
                  style={{ width: '80px' }}
                  value={currentDay}
                  onChange={(value) => {
                    setCurrentDay(value);
                  }}
                >
                  {[...Array(31).keys()].map(day => (
                    <Option key={day} value={day + 1}>{day + 1}</Option>
                  ))}
                </Select>
              </div>
            </>
          );
        })()}
      </Modal>

      <Modal
        title="Birthday Gift Setup"
        open={birthdayGiftModalVisible}
        onCancel={() => setBirthdayGiftModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setBirthdayGiftModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p>To setup an automated birthday gift, visit the "shoutouts" setup page and click on "Set Celebration Reward". This will send a specified amount of peer rewards points to the employee on their birthday (via Direct Message), with a button they can click to redeem a gift or gift card from your customizable catalog.</p>
        <img 
          src="https://graphics.getculturebot.com/message_previews/celebration_gifts_teams.png" 
          alt="Celebration Gift Setup"
          style={{ width: '100%', borderRadius: '10px' }}
        />
      </Modal>

      {/* <small style={{ display: 'block', marginTop: '20px', color: '#888', textAlign: 'left' }}>
        Learn more about <a href="https://getculturebot.com/getting-started.html#birthday-messages">birthday messages</a>
      </small> */}
    </>
  );
};

export default BirthdaysTab; 