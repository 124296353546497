import React, { useState, useEffect } from "react";
import MainContainer from "../Container/MainContainer";
import { Card, Button } from "antd";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import {
  getBaseUrl,
  doPostRequest,
  doGetRequest,
} from "../../helpers/requestHelpers";
import { PlayCircleOutlined, MessageOutlined, UsergroupAddOutlined, StarOutlined, DollarOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const queryClient = useQueryClient();
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const navigate = useNavigate();

  // get all custom celebrations
  const getHomePageInfoForAdminQuery = useQuery(
    ["GET_HOME_PAGE_INFO_FOR_ADMIN"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getHomePageInfoForAdmin`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, enabled: !!localStorage.getItem("token")}
  );

  return (
    <MainContainer>
      <div className="main-container">
        <Card className="main-container-card">
          <img
            src="https://culturebot.nyc3.cdn.digitaloceanspaces.com/other/home-cover-october-23.png"
            alt="Welcome To CultureBot Banner"
            style={{ width: "100%", maxWidth: "600px", height: "auto", borderRadius: "10px" }}
          />
          <div
            style={{ marginTop: "10px", fontSize: "18px", fontWeight: "bold" }}
          >
            Hi
            {getHomePageInfoForAdminQuery.data?.user_data?.displayName
              ? ` ${getHomePageInfoForAdminQuery.data.user_data.displayName},`
              : ","}
          </div>
          <div style={{ marginTop: "10px", fontSize: "16px" }}>
            Welcome to CultureBot! We exist to bring together your remote team
            and improve your overall culture. Use our platform to encourage
            employee engagement, build stronger cross-team relationships, and
            help your company culture bloom.
          </div>
          <div style={{ marginTop: "20px", fontSize: "16px" }}>
            ‣ For an overview of how to use the bot, view our{" "}
            <a
              href="https://getculturebot.com/blog/microsoft-teams-getting-started-guide-for-culturebot/"
              target="_blank"
              rel="noopener noreferrer"
            >
              📖 getting started guide
            </a>{" "}
            or{" "}
            <a
              href="https://getculturebot.com/demo.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              schedule a set up call
            </a>
            <br />‣ Note: any times selected inside the features below will be
            in your timezone –{" "}
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
          </div>
          <div
            style={{ marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}
          >
            Setup Overview & Training Video
          </div>
          <div style={{ marginTop: "10px" }}>
            <div>
              <iframe
                src="https://player.vimeo.com/video/1051217821?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                title="Teams Demo"
                style={{
                  width: "100%",
                  maxWidth: "800px",
                  height: "460px",
                  border: "none",
                }}
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <div
            style={{ marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}
          >
            Next Steps
          </div>
          <div style={{ marginTop: "10px", fontSize: "16px" }}>
            1.{" "}
            <a href="/app/permissions" rel="noopener noreferrer">
              <UsergroupAddOutlined /> Add additional admins
            </a>
            <br />
            2.{" "}
            <a href="/app/celebrations" rel="noopener noreferrer">
              <StarOutlined /> Setup your first feature
            </a>
          </div>
          <div
            style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold" }}
          >
            ❤️ CultureBot? We'd love to hear how you're using it!
          </div>
          <div style={{ marginTop: "10px", fontSize: "16px" }}>
            💬 For support or feedback, contact us via live chat below or
            e-mail:{" "}
            <strong>
              <a href="mailto:support@getculturebot.com">
                support@getculturebot.com
              </a>
            </strong>
          </div>
          <div style={{ marginTop: "20px", textAlign: "left" }}>
            <Button
              shape="round"
              icon={<PlayCircleOutlined />}
              style={{ marginRight: "10px" }}
              onClick={() =>
                window.open("https://getculturebot.com/demo.html", "_blank")
              }
            >
              See live demo
            </Button>
            <Button
              shape="round"
              icon={<MessageOutlined />}
              style={{ marginRight: "10px" }}
              onClick={() =>
                window.open("https://testimonial.to/culturebot", "_blank")
              }
            >
              Leave testimonial
            </Button>
            <Button
              shape="round"
              icon={<DollarOutlined />}
              onClick={() => navigate("/app/billing")}
            >
              Manage Billing
            </Button>
          </div>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Home;
