import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  getFrequencyToSendStaticOptions,
  getReminderHelperTextIfNeeded,
  getShareResultsHelperTextIfNeeded,
  filterOptionFunction,
  translateFrequencyToRunValueToDays,
} from "./CreateCampaignPageHelpers";
import {
  Button,
  Space,
  Modal,
  Typography,
  Form,
  Tooltip,
  Input,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Radio,
  Card,
  Row,
  Col,
  Alert,
  Checkbox,
} from "antd";
import moment from "moment-timezone";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {
  getBaseUrl,
  doGetRequest,
  doPostRequest,
  getTokenErrorAreaIfNeeded,
} from "../../helpers/requestHelpers";
import "./CreateCampaign.scss";
import CBCampaignTable from "./CBCampaignTable";
import CampaignResultsTable from "../CampaignResults/CampaignResultsTable";
import {
  roundToLastHalfHour
} from "../../helpers/utilityHelpers";

// non-state constants
const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;
const timePickerFormat = "h:mm a";

export default function CreateCampaignPage() {
  const [form] = Form.useForm();
  const [currentCampaignId, setCurrentCampaignId] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]); //Array with strings corrsponding to error inputs
  const [tokenErrorType, setTokenErrorType] = useState(false);
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);
  const [campaignIdResultsToShow, setCampaignIdResultsToShow] = useState(false);
  const queryClient = useQueryClient();
  const [isInvalidTimeModalOpen, setIsInvalidTimeModalOpen] = useState(false);

  // ----- States having to do with form content -----
  // Note: These initial values do not matter so much since we are using clearAllStates on each create modal open
  const [campaignName, setCampaignName] = useState();
  const [weekdaysToSend, setWeekdaysToSend] = useState([]);
  const [cbFormId, setCbFormId] = useState();
  const [spotlightValue, setSpotlightValue] = useState();
  const [audienceSelectionValue, setAudienceSelectionValue] = useState();
  const [channelSelectionValue, setChannelSelectionValue] = useState();
  const [usersSelectionValue, setUsersSelectionValue] = useState();
  const [userGroupsSelectionValue, setUserGroupsSelectionValue] = useState();
  const [userWorkLocationsValue, setUserWorkLocationsValue] = useState();
  const [frequencyToRunValue, setFrequencyToRunValue] = useState();
  
  // null if not set and a moment date if not (to pull out just dates after)
  const [initialSendDate, setInitialSendDate] = useState();
  const [initialSendTime, setInitialSendTime] = useState();
  const [shouldSendAReminderValue, setShouldSendAReminderValue] = useState();
  const [shouldSendPeerRewardPoints, setShouldSendPeerRewardPoints] = useState();
  const [shareResultsTypeValue, setShareResultsTypeValue] = useState();
  const [shareChannelValue, setShareChannelValue] = useState();
  const [shareWithUsersValue, setShareWithUsersValue] = useState();
 
  // null if not set and a moment date if not (to pull out just dates after)
  // Only shareRecurringCampaignResultsValue or the others are used depending on form state
  const [shareResultsDate, setShareResultsDate] = useState();
  const [shareResultsTime, setShareResultsTime] = useState();
  const [shareRecurringCampaignResultsValue, setShareRecurringCampaignResultsValue] =
    useState();
  const [shareRecurringCampaignResultsTimeUnit, setShareRecurringCampaignResultsTimeUnit] = useState('days');
  const [messageWithSurveyValue, setMessageWithSurveyValue] = useState();
  const [sendAsAdmin, setSendAsAdmin] = useState(false); // New state for sending as admin
  const [bannerImageUrl, setBannerImageUrl] = useState("");

  // Only one of these two is used at a time depending on form state
  const [dateToSendReminderValue, setDateToSendReminderValue] = useState();
  const [daysAfterToSendReminderValue, setDaysAfterToSendReminderValue] =
    useState();
  const [whereToSendValue, setWhereToSendValue] = useState();
  const [rewardPointsOffered, setRewardPointsOffered] = useState();
  const [answerVisiblityValue, setAnswerVisiblityValue] = useState();

  // If we have an id then we are currently in edit instead of create mode
  const isEditingCampaign = !!currentCampaignId;
  const [campaignHasRunBefore, setCampaignHasRunBefore] = useState();
  const [tzForCampaign, setTzForCampaign] = useState();

  // We have this query a lot throughout the app should we use staleTime?
  const getCBFormsQuery = useQuery(
    ["GET_CB_FORMS"],
    async () => {
      return doGetRequest(`${getBaseUrl()}/api/getCBForms`);
    },
    { refetchOnWindowFocus: false }
  );

  let formSelectOptions = [];
  if (getCBFormsQuery.data) {
    formSelectOptions = getCBFormsQuery.data.cbForms.map((currentForm) => {
      return {
        label: currentForm.form_name,
        value: currentForm.id,
      };
    });
  }

  // ------- Campaigns CRUD ------- //

  // gets all slack channels
  const getSlackChannelsQuery = useQuery(
    ["getSlackChannelsQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "channels" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  const getSlackUsersQuery = useQuery(
    ["getSlackUsersQuery"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getSlackChannelsOrUsersForTeam`,
        setTokenErrorType,
        [{ name: "conversation_type", value: "users" }]
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  const getAllEmployeeDepartmentsQuery = useQuery(
    ["GET_ALL_EMPLOYEE_DEPARTMENTS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllEmployeeDepartments`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  );

  const getAllEmployeeLocationsQuery = useQuery(
    ["GET_ALL_EMPLOYEE_LOCATIONS"],
    async () => {
      return doGetRequest(
        `${getBaseUrl()}/api/getAllEmployeeLocations`,
        setTokenErrorType
      );
    },
    { refetchOnWindowFocus: false, staleTime: "Infinity" }
  );

  // Note: this is being deprecated in favor of grabbing locations from the employee buddy pairing table, instead of Users
  // const getUserWorkLocationsQuery = useQuery(
  //   ["getUserWorkLocationsQuery"],
  //   async () => {
  //     return doGetRequest(
  //       `${getBaseUrl()}/api/getUserGroupsOrLocationsForTeam`,
  //       setTokenErrorType,
  //       [{ name: "category", value: "work_locations" }]
  //     );
  //   },
  //   { refetchOnWindowFocus: false, staleTime: "Infinity" } // Limit slack api calls by requiring refresh for channels and users to pop up
  // );

  function clearAllStates() {
    setCampaignName(null);
    // set to first form whenever user opens modal to create a new campaign
    if (formSelectOptions.length) {
      let firstItemFormId = formSelectOptions[0].value;
      setCbFormId(firstItemFormId);
    }
    setCampaignHasRunBefore(false);
    setCurrentCampaignId(null);
    setFrequencyToRunValue("ONE_TIME");
    setSpotlightValue("no");
    setShouldSendAReminderValue("no");
    setShouldSendPeerRewardPoints("no");
    setAudienceSelectionValue("ALL_SLACK_USERS");
    setChannelSelectionValue(null);
    setUsersSelectionValue([]);
    setUserGroupsSelectionValue([]);
    setUserWorkLocationsValue([]);
    setInitialSendDate(null);
    setInitialSendTime(null);
    setDateToSendReminderValue(null);
    setDaysAfterToSendReminderValue(null);
    setRewardPointsOffered(0);
    setShareResultsTypeValue("AFTER_CERTAIN_TIME");
    setWhereToSendValue("ALL_ADMINS_VIA_DM");
    setShareChannelValue(null);
    setShareWithUsersValue([]);
    setMessageWithSurveyValue("");
    setShareResultsDate(null);
    setShareResultsTime(null);
    setShareRecurringCampaignResultsValue(null);
    setShareRecurringCampaignResultsTimeUnit('days');
    setAnswerVisiblityValue("public");
    setSendAsAdmin(false);
    setBannerImageUrl("");
  }

  // Things that may not exist, so only setting things when it makes sense to (depending on other states)
  // otherwise we want it to keep the default from the clearAllStates call at the beginning
  async function editCBCampaignClicked(clickedCampaign) {
    // Clearing all states at the beginning since we might not set them all depending on the current db shape
    clearAllStates();

    setCampaignHasRunBefore(clickedCampaign.campaign_has_run);
    setTzForCampaign(clickedCampaign.admin_tz);

    setCurrentCampaignId(clickedCampaign.id);
    setCampaignName(clickedCampaign.campaign_name);
    setCbFormId(clickedCampaign.cb_form_id);
    setFrequencyToRunValue(clickedCampaign.frequency_to_run_campaign);
    setAudienceSelectionValue(clickedCampaign.audience_to_send_to);
    setChannelSelectionValue(clickedCampaign.channel_to_send_campaign_to);

    // NOTE: it is important initialSendDate and initialSendTime reference different moment objects
    // We had issues with shareResultsDate and shareResultsTime when using same moment object (https://trello.com/c/Fkdi9OTW/1026)
    setInitialSendDate(
      moment.tz(clickedCampaign.start_date_time_gmt, clickedCampaign.admin_tz)
    );
    setInitialSendTime(
      moment.tz(clickedCampaign.start_date_time_gmt, clickedCampaign.admin_tz)
    );

    let reminderArrayToCheck;
    if (clickedCampaign.frequency_to_run_campaign == "ONE_TIME") {
      reminderArrayToCheck = clickedCampaign.dates_to_send_reminders_array;
      // Assume only 1 reminder for now
      setDateToSendReminderValue(moment(reminderArrayToCheck[0]));
    } else {
      reminderArrayToCheck = clickedCampaign.days_after_to_send_reminders_array;
      // Assume only 1 reminder for now
      setDaysAfterToSendReminderValue(reminderArrayToCheck[0]);
    }
    setShouldSendAReminderValue(reminderArrayToCheck.length ? "yes" : "no");

    if (clickedCampaign.users_to_send_campaign_to) {
      setUsersSelectionValue(clickedCampaign.users_to_send_campaign_to);
    }
    if (clickedCampaign.user_groups_to_send_campaign_to) {
      setUserGroupsSelectionValue(clickedCampaign.user_groups_to_send_campaign_to);
    }
    if (clickedCampaign.user_work_locations_to_send_campaign_to) {
      setUserWorkLocationsValue(clickedCampaign.user_work_locations_to_send_campaign_to);
    }

    setShareResultsTypeValue(clickedCampaign.when_type_to_share_results);
    setWhereToSendValue(clickedCampaign.where_to_share_results);
    if (clickedCampaign.where_to_share_results == "SELECTED_CHANNEL") {
      setShareChannelValue(clickedCampaign.channel_to_share_results);
    } else if (
      clickedCampaign.where_to_share_results == "SPECIFIC_USERS_VIA_DM"
    ) {
      setShareWithUsersValue(clickedCampaign.users_to_share_results);
    }

    setMessageWithSurveyValue(clickedCampaign.message_to_send_with_survey);
    setBannerImageUrl(clickedCampaign.banner_image_url);
    setSendAsAdmin(clickedCampaign.send_as_admin);

    setAnswerVisiblityValue(
      clickedCampaign.are_answers_anonymous ? "anonymous" : "public"
    );
    if (clickedCampaign.when_type_to_share_results == "AFTER_CERTAIN_TIME") {
      if (clickedCampaign.frequency_to_run_campaign == "ONE_TIME") {
        let moment_share_date_time_in_admin_tz = moment.tz(
          clickedCampaign.datetime_to_share_results,
          clickedCampaign.admin_tz
        );
        // It is important to make these both different moment objects
        // We had issues in the past when both state vars were referencing the same moment object (https://trello.com/c/Fkdi9OTW/1026)
        setShareResultsDate(moment_share_date_time_in_admin_tz.clone());
        setShareResultsTime(moment_share_date_time_in_admin_tz.clone());
      } else {
        //recurring
        setShareRecurringCampaignResultsValue(
          clickedCampaign.recurring_campaign_share_results_value
        );
        setShareRecurringCampaignResultsTimeUnit(clickedCampaign.recurring_campaign_share_results_unit);
      }
    } else {
      setSpotlightValue(
        clickedCampaign.include_spotlight_graphic ? "yes" : "no"
      );
    }

    setRewardPointsOffered(clickedCampaign.reward_points_for_completing)
    setShouldSendPeerRewardPoints(clickedCampaign.reward_points_for_completing > 0 ? "yes" : "no")

    setIsModalOpen(true);
  }

  // view campaign results
  async function viewCBCampaignResults(cbForm) {
    setCampaignIdResultsToShow(cbForm.id);
  }

  // clone campaign (show creation form with pre-populated values)
  async function cloneCBCampaignClicked(clickedCampaign) {
    clearAllStates();
    setIsModalOpen(true);
    setCampaignName(`${clickedCampaign.campaign_name} - COPY`);
    setCbFormId(clickedCampaign.cb_form_id);
    setFrequencyToRunValue(clickedCampaign.frequency_to_run_campaign);
    setMessageWithSurveyValue(clickedCampaign.message_to_send_with_survey);
    setAudienceSelectionValue(clickedCampaign.audience_to_send_to);
    setChannelSelectionValue(clickedCampaign.channel_to_send_campaign_to);
    setShareResultsTypeValue(clickedCampaign.when_type_to_share_results);
    setWhereToSendValue(clickedCampaign.where_to_share_results);
    setShareChannelValue(clickedCampaign.channel_to_share_results);
    setShareWithUsersValue(clickedCampaign.users_to_share_results);
    setAnswerVisiblityValue(
      clickedCampaign.are_answers_anonymous ? "anonymous" : "public"
    );
    setSpotlightValue(clickedCampaign.include_spotlight_graphic ? "yes" : "no");
  }

  // pause/un-pause campaign from running
  async function pauseCBCampaign(cbCampaignId, pausedStatus) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/pauseCBCampaign`,
      { campaign_id: cbCampaignId, status: pausedStatus },
      setTokenErrorType
    );
    // Invalidate query to trigger table refresh
    queryClient.invalidateQueries("GET_CB_CAMPAIGNS");
  }

  // delete individual form
  async function deleteCBCampaignClicked(cbCampaignId) {
    const response = await doPostRequest(
      `${getBaseUrl()}/api/deleteCBCampaign`,
      { campaign_id: cbCampaignId },
      setTokenErrorType
    );
    // Invalidate query to trigger table refresh
    queryClient.invalidateQueries("GET_CB_CAMPAIGNS");
  }

  // Return an array of all inputs that still need to be filled in
  // Note: We are assuming static dropdowns and radios are always okay
  // We are also assuming form to use is always okay since we check they have forms before opening modal
  function getErrorMessagesForForm() {
    // Validate form and build error messages
    let currentErrorMessages = [];
    if (!campaignName) {
      currentErrorMessages.push("Campaign name is required");
    }
    if (
      !initialSendDate &&
      ![
        "NEW_USERS_DAILY",
        "NEW_USERS_7_DAYS",
        "NEW_USERS_30_DAYS",
        "NEW_USERS_60_DAYS",
        "NEW_USERS_90_DAYS",
      ].includes(frequencyToRunValue)
    ) {
      currentErrorMessages.push("Initial send date is required");
    }
    if (!initialSendTime) {
      currentErrorMessages.push("Initial send time is required");
    }
    if (
      initialSendDate &&
      initialSendDate.startOf("day") < moment().startOf("day")
    ) {
      currentErrorMessages.push("Initial send date cannot be in the past");
    }
    if (
      initialSendDate &&
      initialSendDate.startOf("day").diff(moment().startOf("day")) == 0
    ) {
      if (initialSendTime && initialSendTime < moment()) {
        console.log(
          "initialSend now: ",
          moment(),
          initialSendTime.hour(),
          initialSendTime.minute()
        );
        currentErrorMessages.push("Initial send time cannot be in the past");
      }
    }
    if (campaignHasRunBefore) {
      currentErrorMessages.push(
        "This campaign is already running – cannot edit a running campaign"
      );
    }
    if (shouldSendAReminderValue == "yes") {
      if (frequencyToRunValue == "ONE_TIME") {
        if (!dateToSendReminderValue) {
          currentErrorMessages.push("Date to send reminder is required");
        } else if (
          initialSendDate &&
          dateToSendReminderValue.startOf("day") <=
            initialSendDate.startOf("day")
        ) {
          currentErrorMessages.push(
            "Reminders must be set to go out after campaign starts"
          );
        }
      } else {
        // recurring
        if (!daysAfterToSendReminderValue) {
          currentErrorMessages.push("Days after to send reminder is required");
        } else {
          const num_days =
            translateFrequencyToRunValueToDays(frequencyToRunValue);
          if (daysAfterToSendReminderValue >= num_days) {
            currentErrorMessages.push(
              `The campaign is currently set to run every ${num_days} days. Reminders must be sent before that many days.`
            );
          }
        }
      }
    }
    
    if (shouldSendPeerRewardPoints == "yes") {
      if(!rewardPointsOffered) {
        currentErrorMessages.push("Reward points to offer is required");
      }
    }

    if (bannerImageUrl) {
      if (!bannerImageUrl.startsWith("https://")) {
        currentErrorMessages.push("Banner image URL must start with https://");
      } else if (!/\.(png|jpe?g|gif)$/i.test(bannerImageUrl)) {
        currentErrorMessages.push("Banner image URL must end with .png, .jpeg, .jpg, or .gif");
      }
    }

    if (audienceSelectionValue == "USERS_IN_CHANNEL" || audienceSelectionValue == "CHANNEL") {
      if (!channelSelectionValue) {
        currentErrorMessages.push("Channel to send to is required");
      }
    } else if (audienceSelectionValue == "SPECIFIC_USERS") {
      if (!usersSelectionValue.length) {
        currentErrorMessages.push("Users to send to is required");
      }
    } else if (audienceSelectionValue == "USERS_BY_GROUP") {
      if (!userGroupsSelectionValue.length) {
        currentErrorMessages.push("User groups to send to is required");
      }
    } else if (audienceSelectionValue == "USERS_BY_WORK_LOCATION") {
      if (!userWorkLocationsValue.length) {
        currentErrorMessages.push("User work locations to send to is required");
      }
    }
    
    if (shareResultsTypeValue == "AFTER_CERTAIN_TIME") {
      if (frequencyToRunValue == "ONE_TIME") {
        if (!shareResultsDate) {
          currentErrorMessages.push("Share results date is required");
        }
        if (!shareResultsTime) {
          currentErrorMessages.push("Share results time is required");
        }

        // this voodoo is to make sure we use the date object for comparison, instead of the momemt time, messed with this for hours, painful, don't touch.
        // still exists an issue where if you save a campaign without changing the results time, it will auto set that to midnight, can't figure out why.
        let initialSendTimeDate = null;
        let shareResultsTimeDate = null;
        if (initialSendTime) {
          initialSendTimeDate = initialSendTime.clone().milliseconds(0).toDate();
        }
        if (shareResultsTime) {
          shareResultsTimeDate = shareResultsTime.clone().milliseconds(0).toDate();
        }
        if (
          initialSendDate &&
          shareResultsDate &&
          (shareResultsDate.startOf("day").isBefore(initialSendDate.startOf("day")) ||
           (shareResultsDate.startOf("day").isSame(initialSendDate.startOf("day")) &&
           ((shareResultsTimeDate.getTime() - initialSendTimeDate.getTime()) / 60000) < 30))
        ) {
          currentErrorMessages.push(
            "Sharing results must be set to at least 30 mins after the campaign starts"
          );
        }
      } else {
        if (!shareRecurringCampaignResultsValue) {
          currentErrorMessages.push("A time to share results is required");
        }
      }
    }

    if (whereToSendValue == "SELECTED_CHANNEL") {
      if (!shareChannelValue) {
        currentErrorMessages.push("Channel to share results is required");
      }
    } else if (whereToSendValue == "SPECIFIC_USERS_VIA_DM") {
      if (!shareWithUsersValue) {
        currentErrorMessages.push("Users to share results with is required");
      }
    }

    return currentErrorMessages;
  }

  // ------- Input Handlers ------ //
  // Note: if modifying how the form works and how the api data is built might need to modify the validation too in getErrorMessagesForForm
  const onFinish = (formValues) => {
    const currentErrorMessages = getErrorMessagesForForm();

    setErrorMessages(currentErrorMessages);

    // Stop flow if there were error messages
    if (currentErrorMessages.length) {
      return;
    }

    // Building data
    let initialSendDateToPost;
    if (
      [
        "NEW_USERS_DAILY",
        "NEW_USERS_7_DAYS",
        "NEW_USERS_30_DAYS",
        "NEW_USERS_60_DAYS",
        "NEW_USERS_90_DAYS",
      ].includes(frequencyToRunValue)
    ) {
      // build moment 1970 date
      initialSendDateToPost = moment.unix(0);
    } else {
      initialSendDateToPost = initialSendDate.clone();
    }

    initialSendDateToPost.hour(initialSendTime.hour());
    initialSendDateToPost.minute(initialSendTime.minute());
    initialSendDateToPost.second(0);

    let datesToSendRemindersArray = [];
    let daysAfterToSendRemindersArray = [];
    // Only populate array depending on type and if reminders is on, also convert to array because BE db is setup to support multiple reminders (although FE is not yet)
    if (shouldSendAReminderValue == "yes") {
      if (frequencyToRunValue == "ONE_TIME") {
        datesToSendRemindersArray = [
          dateToSendReminderValue.format("YYYY-MM-DD"),
        ];
      } else {
        daysAfterToSendRemindersArray = [daysAfterToSendReminderValue];
      }
    }
    let areAnswersAnonymous = answerVisiblityValue == "anonymous";
    let includeSpotlightGraphic = null;
    if (shareResultsTypeValue == "AFTER_EACH_RESPONSE") {
      includeSpotlightGraphic = !!(spotlightValue == "yes");
    }

    let channel_to_send_campaign_to = null;
    let users_to_send_campaign_to = [];
    let user_groups_to_send_campaign_to = [];
    let user_work_locations_to_send_campaign_to = [];
    // Only conditionally include these in certain cases
    if (audienceSelectionValue == "USERS_IN_CHANNEL" || audienceSelectionValue == "CHANNEL") {
      channel_to_send_campaign_to = channelSelectionValue;
    } else if (audienceSelectionValue == "SPECIFIC_USERS") {
      users_to_send_campaign_to = usersSelectionValue;
    } else if (audienceSelectionValue == "USERS_BY_GROUP") {
      user_groups_to_send_campaign_to = userGroupsSelectionValue;
    } else if (audienceSelectionValue == "USERS_BY_WORK_LOCATION") {
      user_work_locations_to_send_campaign_to = userWorkLocationsValue;
    }

    let datetime_to_share_results = null;
    // Only populate one of the arrays depending on type if sharing results at a cetain time
    if (shareResultsTypeValue == "AFTER_CERTAIN_TIME") {
      if (frequencyToRunValue == "ONE_TIME") {

        //datetime_to_share_results will be built off of two different states
        let shareResultsDateClone = shareResultsDate.clone();
        shareResultsDateClone.hour(shareResultsTime.hour());
        shareResultsDateClone.minute(shareResultsTime.minute());
        shareResultsDateClone.second(0);
        datetime_to_share_results = shareResultsDateClone
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"); // We want to send to server in UTC so convert
      } 
    }

    let channel_to_share_results = null;
    let users_to_share_results = null;
    if (whereToSendValue == "SELECTED_CHANNEL") {
      channel_to_share_results = shareChannelValue;
    } else if (whereToSendValue == "SPECIFIC_USERS_VIA_DM") {
      users_to_share_results = shareWithUsersValue;
    }

    let reward_points_offered = 0
    if (rewardPointsOffered > 0 && (shouldSendPeerRewardPoints == "yes")) {
      reward_points_offered = rewardPointsOffered
    }
    
    let apiJson = {};
    apiJson = {
      id: currentCampaignId,
      campaign_name: campaignName,
      cb_form_id: cbFormId,
      frequency_to_run_campaign: frequencyToRunValue,
      start_date_time_gmt: initialSendDateToPost
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"), // We want to send to server in UTC so convert
      dates_to_send_reminders_array: datesToSendRemindersArray,
      days_after_to_send_reminders_array: daysAfterToSendRemindersArray,
      audience_to_send_to: [
        "NEW_USERS_DAILY",
        "NEW_USERS_7_DAYS",
        "NEW_USERS_30_DAYS",
        "NEW_USERS_60_DAYS",
        "NEW_USERS_90_DAYS",
      ].includes(frequencyToRunValue)
        ? null
        : audienceSelectionValue, // Don't pass this value if user chose to send to new users daily
      are_answers_anonymous: areAnswersAnonymous,
      when_type_to_share_results: shareResultsTypeValue,
      datetime_to_share_results: datetime_to_share_results,
      include_spotlight_graphic: includeSpotlightGraphic,
      users_to_send_campaign_to: users_to_send_campaign_to,
      user_groups_to_send_campaign_to: user_groups_to_send_campaign_to,
      user_work_locations_to_send_campaign_to: user_work_locations_to_send_campaign_to,
      channel_to_send_campaign_to: channel_to_send_campaign_to,
      where_to_share_results: whereToSendValue,
      channel_to_share_results: channel_to_share_results,
      users_to_share_results: users_to_share_results,
      message_to_send_with_survey: messageWithSurveyValue,
      reward_points_offered: reward_points_offered,
      recurring_campaign_share_results_value: shareRecurringCampaignResultsValue,
      recurring_campaign_share_results_unit: shareRecurringCampaignResultsTimeUnit,
      send_as_admin: sendAsAdmin,
      banner_image_url: bannerImageUrl,
    };
    saveForm(apiJson);
  };
  async function saveForm(jsonToSend) {
    setSaveButtonIsLoading(true);
    const response = await doPostRequest(
      `${getBaseUrl()}/api/addOrEditCampaign`,
      jsonToSend,
      setTokenErrorType
    );
    // TODO make sure we use this same name when we use this api
    // Invalidate parts of the app using GET_CAMPAIGNS (like the table) so they refresh
    queryClient.invalidateQueries("GET_CAMPAIGNS");
    setIsModalOpen(false);
    setSaveButtonIsLoading(false);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setErrorMessages([]);
  };

  // -------- create new campaign -------- //

  // form layout
  const formItemLayout = {
    wrapperCol: {
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  let conversationSelectorArea;
  if (audienceSelectionValue == "USERS_IN_CHANNEL" || audienceSelectionValue == "CHANNEL") {
    conversationSelectorArea = (
      <>
        {audienceSelectionValue == "CHANNEL" ? (
          <h4>Channel to send to (will be sent publicly to this channel)</h4>
        ) : (
          <h4>Channel to send to (will DM users from this channel)</h4>
        )}
        {getSlackChannelsQuery.data && getSlackChannelsQuery.data.using_cache ? <span style={{fontSize: '12px'}}><i>Note: channels shown below will update every 24 hrs</i></span> : null}
        <Select
          showSearch
          style={{ width: "100%" }}
          onChange={setChannelSelectionValue}
          value={channelSelectionValue}
          filterOption={filterOptionFunction}
        >
          {getSlackChannelsQuery.data
            ? getSlackChannelsQuery.data.conversations.map((conversation) => {
                return (
                  <Option
                    key={conversation.id}
                    value={conversation.id}
                    label={conversation.name}
                  >
                    {conversation.name}
                  </Option>
                );
              })
            : []}
        </Select>
        <br />
        <br />
      </>
    );
  } else if (audienceSelectionValue == "SPECIFIC_USERS") {
    conversationSelectorArea = (
      <>
        <h4>Users to send survey message to:</h4>
        <Select
          showSearch
          mode="multiple"
          style={{ width: "100%" }}
          onChange={setUsersSelectionValue}
          value={usersSelectionValue}
          filterOption={filterOptionFunction}
        >
          {getSlackUsersQuery.data
            ? getSlackUsersQuery.data.conversations.map((conversation) => {
                return (
                  <Option
                    key={conversation.id}
                    value={conversation.id}
                    label={conversation.real_name}
                  >
                    {conversation.real_name}
                  </Option>
                );
              })
            : []}
        </Select>
        <br />
        <br />
      </>
    );
  } else if (audienceSelectionValue == "USERS_BY_GROUP") {
    conversationSelectorArea = (
      <>
        <h4>Departments to send survey message to:</h4>
        <Select
          showSearch
          mode="multiple"
          style={{ width: "100%" }}
          onChange={setUserGroupsSelectionValue}
          value={userGroupsSelectionValue}
          filterOption={filterOptionFunction}
        >
          {getAllEmployeeDepartmentsQuery.data?.departments.map((name, index) => {
                return (
                  <Option
                    key={index}
                    value={name}
                    label={name}
                  >
                    {name}
                  </Option>
                );
              })}
        </Select>
        <br />
        <br />
      </>
    );
  } else if (audienceSelectionValue == "USERS_BY_WORK_LOCATION") {
    conversationSelectorArea = (
      <>
        <h4>Locations to send survey message to:</h4>
        <Select
          showSearch
          mode="multiple"
          style={{ width: "100%" }}
          onChange={setUserWorkLocationsValue}
          value={userWorkLocationsValue}
          filterOption={filterOptionFunction}
        >
          {getAllEmployeeLocationsQuery.data
            ? getAllEmployeeLocationsQuery.data.locations.map((name, index) => {
                return (
                  <Option
                    key={index}
                    value={name}
                    label={name}
                  >
                    {name}
                  </Option>
                );
              })
            : []}
        </Select>
        <br />
        <br />
      </>
    );
  } // else keep it null

  // this is required to make sure that the share results date and time are set to the correct time when the user picks a different initial send date and time
  useEffect(() => {
  }, [initialSendDate, initialSendTime]);

  let whenToShareResultsSection = null;
  if (shareResultsTypeValue == "AFTER_CERTAIN_TIME") {
    if (frequencyToRunValue == "ONE_TIME") {
      whenToShareResultsSection = (
        <>
          <h4>When to share results:</h4>
          <DatePicker
            value={shareResultsDate}
            onChange={(date) => {
              setShareResultsDate(date);
            }}
          />
          <TimePicker
            value={shareResultsTime}
            allowClear={false}
            use12Hours={true}
            style={{ marginLeft: "10px" }}
            minuteStep={30}
            onChange={(date) => {
              const result = roundToLastHalfHour(date);
              if (result.adjusted) {
                setIsInvalidTimeModalOpen(true);
              }
              setShareResultsTime(result.date);
            }}
            format={timePickerFormat}
          />
          <br />
          <br />
        </>
      );
    } else {
      //recurring
      whenToShareResultsSection = (
        <>
          <h4>
            Time after survey send to share results:
          </h4>
          <InputNumber
            min={shareRecurringCampaignResultsTimeUnit === 'hours' ? 0.5 : 1}
            step={shareRecurringCampaignResultsTimeUnit === 'hours' ? 0.5 : 1}
            value={shareRecurringCampaignResultsValue}
            onChange={(value) => {
              setShareRecurringCampaignResultsValue(value);
            }}
            style={{ width: '100px', marginRight: '10px' }}
          />
          <Select
            style={{ width: '100px' }}
            value={shareRecurringCampaignResultsTimeUnit}
            onChange={(value) => {
              setShareRecurringCampaignResultsTimeUnit(value);
              // Reset the value when switching between hours and days
              setShareRecurringCampaignResultsValue(value === 'hours' ? 0.5 : 1);
            }}
          >
            <Option value="hours">Hours</Option>
            <Option value="days">Days</Option>
          </Select>
          <br />
          {getShareResultsHelperTextIfNeeded(
            initialSendDate,
            initialSendTime,
            shareRecurringCampaignResultsValue,
            shareRecurringCampaignResultsTimeUnit
          )}
          <br />
          <br />
        </>
      );
    }
  }

  // create formatted string for frequency option picker
  let frequencyToRunValueFormatted = "";
  if (frequencyToRunValue == "ONE_TIME") {
    frequencyToRunValueFormatted = "Date and time to send";
  } else if (
    [
      "NEW_USERS_DAILY",
      "NEW_USERS_7_DAYS",
      "NEW_USERS_30_DAYS",
      "NEW_USERS_60_DAYS",
      "NEW_USERS_90_DAYS",
    ].includes(frequencyToRunValue)
  ) {
    frequencyToRunValueFormatted = "Time to send";
  } else {
    frequencyToRunValueFormatted = "First date and time to send";
  }

  // campaign itself
  const createNewCampaign = (
    <Modal
      width={"1100px"}
      forceRender // pre-render children
      title={isEditingCampaign ? "Edit Campaign" : "Create New Campaign"}
      open={isModalOpen}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          form="campaignForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={saveButtonIsLoading}
        >
          Save
        </Button>,
      ]}
    >
      {/* -------- campaign form itself -------- */}
      <>
        <Form
          form={form}
          id="campaignForm"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          {...formItemLayout}
        >
          <Row gutter={16}>
            <Col span={12}>
              <span style={{ fontStyle: ["italic"] }}>
                {isEditingCampaign
                  ? `Note: all times picked below are shown in your admin\'s timezone (${tzForCampaign})`
                  : `Note: all times picked below will be in your timezone (${moment.tz.guess()})`}
              </span>
              <br />
              <br />
              <h4>Campaign name:</h4>
              <Form.Item
                noStyle
                rules={[
                  {
                    required: true,
                    whitespace: false,
                  },
                ]}
              >
                <Input
                  value={campaignName}
                  onChange={(e) => {
                    setCampaignName(e.target.value);
                  }}
                  placeholder="Name your campaign"
                />
              </Form.Item>
              <br />
              <br />
              <h4>Form to use:</h4>
              <Select
                showSearch
                value={cbFormId}
                style={{ width: "100%" }}
                onChange={(formId) => {
                  setCbFormId(formId);
                }}
                filterOption={filterOptionFunction}
                options={formSelectOptions}
              />
              <br />
              <br />
              <h4>Frequency to send:</h4>
              <Select
                style={{ width: "100%" }}
                onChange={(frequency) => {
                  setFrequencyToRunValue(frequency);
                }}
                options={getFrequencyToSendStaticOptions()}
                value={frequencyToRunValue}
              />
              <br />
              <br />

              <h4>{frequencyToRunValueFormatted}:</h4>

              {/* only show date for frequency if user has selected survey to run once or on a recurring basis */}
              {[
                "NEW_USERS_DAILY",
                "NEW_USERS_7_DAYS",
                "NEW_USERS_30_DAYS",
                "NEW_USERS_60_DAYS",
                "NEW_USERS_90_DAYS",
              ].includes(frequencyToRunValue) ? null : (
                <DatePicker
                  value={initialSendDate}
                  style={{ marginRight: "10px" }}
                  onChange={(date) => {
                    setInitialSendDate(date);
                  }}
                />
              )}
              <TimePicker
                value={initialSendTime}
                minuteStep={30}
                allowClear={false}
                use12Hours={true}
                onChange={(date) => {
                  const result = roundToLastHalfHour(date);
                  if (result.adjusted) {
                    setIsInvalidTimeModalOpen(true);
                  }
                  setInitialSendTime(result.date);
                }}
                format={timePickerFormat}
              />
              <Modal
                title="Invalid Time"
                open={isInvalidTimeModalOpen}
                cancelButtonProps={{ hidden: true }}
                closable={false} // This will remove the close "x" button
                onOk={() => {
                  setIsInvalidTimeModalOpen(false);
                }}
              >
                <p>
                  CultureBot only supports campaign times on the hour or on a
                  half hour. The selected time has been adjusted back to the
                  nearest hour or half hour mark.
                </p>
              </Modal>

              {[
                "ONE_WEEK",
                "TWO_WEEKS",
                "FOUR_WEEKS",
                "EIGHT_WEEKS",
                "TWELVE_WEEKS",
              ].includes(frequencyToRunValue) &&
                initialSendDate && (
                  <>
                    <br />
                    <Text italic>
                      Recurring surveys will be sent every X{" "}
                      {initialSendDate.format("dddd")}s
                    </Text>
                  </>
                )}
              <br />
              <br />

              {/* reminder to those who have not completed */}
              <>
                <h4>
                  Send a reminder to users who have not completed the form:
                </h4>
                <Radio.Group
                  onChange={(e) => {
                    setShouldSendAReminderValue(e.target.value);
                  }}
                  value={shouldSendAReminderValue}
                >
                  <Radio value={"no"}>No</Radio>
                  <Radio value={"yes"}>Yes</Radio>
                </Radio.Group>
                <br />
                <br />
              </>

              {shouldSendAReminderValue == "yes" && (
                <>
                  <h4>
                    {frequencyToRunValue == "ONE_TIME"
                      ? "Date to remind (same time as above)"
                      : "Days after survey send to remind (same time as above)"}
                  </h4>
                  {frequencyToRunValue == "ONE_TIME" ? (
                    <DatePicker
                      value={dateToSendReminderValue}
                      onChange={(date) => {
                        setDateToSendReminderValue(date);
                      }}
                    />
                  ) : (
                    <>
                      <InputNumber
                        min={1}
                        max={31}
                        value={daysAfterToSendReminderValue}
                        onChange={(value) => {
                          setDaysAfterToSendReminderValue(value);
                        }}
                      />
                      {getReminderHelperTextIfNeeded(
                        initialSendDate,
                        daysAfterToSendReminderValue
                      )}
                    </>
                  )}{" "}
                  {/* TODO base min max on how long the interval is */}
                  <br />
                  <br />
                </>
              )}
              <h4>Message to send with survey (optional):</h4>
              <span style={{ fontSize: '10px', color: 'gray', display: 'block', paddingBottom: '10px' }}>
                - To reference channels, use the name of the channel plus a hashtag (for example: #general)
                <br />- To add hyperlinks, use the syntax: &lt;http://www.example.com|this message is a link&gt;
                <br />- To add bold text, use the syntax: <strong>*this message is bold*</strong>
              </span>
              <TextArea
                rows={4}
                value={messageWithSurveyValue}
                onChange={(e) => {
                  setMessageWithSurveyValue(e.currentTarget.value);
                }}
                placeholder="Message to prepend to survey"
                maxLength={2500}
              />
              <br />
              <br />
              <h4>Custom Banner Image URL (optional):</h4>
              <span style={{ fontSize: '10px', color: 'gray', display: 'block', paddingBottom: '10px', marginTop: '-5px' }}>
                This is the image that will be displayed when the survey is sent. Use our
                <a href="/app/graphics_library" target="_blank" style={{ color: 'blue' }}> graphics library </a> 
                to create a custom image or upload your own. If missing, the default CultureBot banner will be used.
              </span>
              <Input
                value={bannerImageUrl}
                onChange={(e) => setBannerImageUrl(e.target.value)}
                placeholder="Enter the URL of the banner image"
              />
              <br />
              <br />
              <Checkbox
                checked={sendAsAdmin}
                onChange={(e) => setSendAsAdmin(e.target.checked)}
              >
                Send survey as main admin instead of as CultureBot?
              </Checkbox>
              {sendAsAdmin && (
                <span style={{ fontSize: '10px', color: 'gray', display: 'block', paddingTop: '5px' }}>
                  ⚠️ Note: please use this carefully: this will create a new group message with each person it sends to (if you send the survey via DMs and not to a public channel).
                </span>
              )}
              <br />
              <br />
            </Col>
            <Col span={1}></Col>
            <Col span={11}>
              {/* audience to send to is only an option if user hasn't selected a frequency of new_users_daily */}
              {[
                "NEW_USERS_DAILY",
                "NEW_USERS_7_DAYS",
                "NEW_USERS_30_DAYS",
                "NEW_USERS_60_DAYS",
                "NEW_USERS_90_DAYS",
              ].includes(frequencyToRunValue) ? null : (
                <>
                  <h4>Audience to send to:
                  <br />
                  <span style={{'font-size': '10px', 'font-weight': 'normal'}}>Connect your <a href={`/app/hris_setup`}>HRIS</a> to send to employees based on their department, location, and more. You can also sync employee demographic info via the 'employee info' tab of the <a href={`/app/experiences`}>Experiences</a> page.</span>
                  </h4>
                  <Select
                    style={{ width: "100%" }}
                    onChange={setAudienceSelectionValue}
                    options={[
                      {
                        value: "ALL_SLACK_USERS",
                        label: "Direct message all Slack users",
                      },
                      {
                        value: "USERS_IN_CHANNEL",
                        label:
                          "Direct message all users in a single Slack channel",
                      },
                      {
                        value: "SPECIFIC_USERS",
                        label: "Direct message specific users by name",
                      },
                      {
                        value: "USERS_BY_GROUP",
                        label:
                          "Direct message all users who match a certain group/department",
                      },
                      {
                        value: "USERS_BY_WORK_LOCATION",
                        label:
                          "Direct message all users who match a certain work location",
                      },
                      {
                        value: "CHANNEL",
                        label:
                          "Send publicly to a single Slack channel",
                      }
                    ]}
                    value={audienceSelectionValue}
                  />
                  <br />
                  <br />
                  {conversationSelectorArea}
                </>
              )}

              <Card
                className="sharingOptionsCard"
                title="Survey result options"
              >
                <h4>Answers will be:</h4>
                <Radio.Group
                  onChange={(e) => {
                    setAnswerVisiblityValue(e.target.value);
                  }}
                  value={answerVisiblityValue}
                >
                  <Radio value={"public"}>Public (results with names)</Radio>
                  <Radio value={"anonymous"}>
                    Anonymous (results with no names)
                  </Radio>
                </Radio.Group>
                <br />
                <br />

                <h4>Share results:</h4>
                <Select
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setShareResultsTypeValue(value);
                  }}
                  options={[
                    {
                      value: "AFTER_EACH_RESPONSE",
                      label: "After each response",
                    },
                    {
                      value: "AFTER_CERTAIN_TIME",
                      label: "After certain time",
                    },
                  ]}
                  value={shareResultsTypeValue}
                />
                <br />
                <br />

                {whenToShareResultsSection}

                {/* {shareResultsTypeValue == "AFTER_EACH_RESPONSE" && (
                  <>
                    <h4>
                      Send as "spotlight" graphic (showcasing each response):
                    </h4>
                    <Radio.Group
                      value={spotlightValue}
                      onChange={(e) => {
                        setSpotlightValue(e.target.value);
                      }}
                    >
                      <Radio value={"yes"}>Yes</Radio>
                      <Radio value={"no"}>No (sends a link)</Radio>
                    </Radio.Group>
                    <br />
                    <br />
                  </>
                )} */}

                <h4>Send to:</h4>
                <Select
                  style={{ width: "100%" }}
                  value={whereToSendValue}
                  onChange={setWhereToSendValue}
                  options={[
                    {
                      value: "ALL_ADMINS_VIA_DM",
                      label:
                        "CultureBot admins via DM (private results link, for admins only)",
                    },
                    {
                      value: "SELECTED_CHANNEL",
                      label: "Channel",
                    },
                    {
                      value: "SPECIFIC_USERS_VIA_DM",
                      label: "Specific users via DM",
                    },
                  ]}
                />
                <br />
                <br />
                {/* if channel is selected, show single picker with channels listed, else if users selected, show multi-picker with all users */}
                {["SELECTED_CHANNEL", "SPECIFIC_USERS_VIA_DM"].includes(
                  whereToSendValue
                ) && (
                  <>
                    <h4>
                      {whereToSendValue == "SELECTED_CHANNEL"
                        ? "Channel to share results to (public link all users can click and view): "
                        : "Users to share results with: "}
                    </h4>
                    <Select
                      showSearch
                      mode={
                        whereToSendValue == "SELECTED_CHANNEL"
                          ? "single"
                          : "multiple"
                      }
                      style={{ width: "100%" }}
                      onChange={
                        whereToSendValue == "SELECTED_CHANNEL"
                          ? setShareChannelValue
                          : setShareWithUsersValue
                      }
                      value={
                        whereToSendValue == "SELECTED_CHANNEL"
                          ? shareChannelValue
                          : shareWithUsersValue
                      }
                      filterOption={filterOptionFunction}
                    >
                      {(
                        whereToSendValue == "SELECTED_CHANNEL"
                          ? getSlackChannelsQuery.data
                          : getSlackUsersQuery.data
                      )
                        ? (whereToSendValue == "SELECTED_CHANNEL"
                            ? getSlackChannelsQuery
                            : getSlackUsersQuery
                          ).data.conversations.map((conversation) => {
                            return (
                              <Option
                                key={conversation.id}
                                value={conversation.id}
                                label={
                                  whereToSendValue == "SELECTED_CHANNEL"
                                    ? conversation.name
                                    : conversation.real_name
                                }
                              >
                                {whereToSendValue == "SELECTED_CHANNEL"
                                  ? conversation.name
                                  : conversation.real_name}
                              </Option>
                            );
                          })
                        : []}
                    </Select>
                    <br />
                    <br />
                  </>
                )}

                <h4>Offer reward points to users who complete this survey?
                <br />
                <span style={{'font-size': '10px', 'font-weight': 'normal'}}>Points are redeemable for gifts that you've setup.</span>
                </h4>
                
                <Radio.Group
                  onChange={(e) => {
                    setShouldSendPeerRewardPoints(e.target.value);
                  }}
                  value={shouldSendPeerRewardPoints}
                >
                  <Radio value={"no"}>No</Radio>
                  <Radio value={"yes"}>Yes</Radio>
                </Radio.Group>
                <br />
                
                {shouldSendPeerRewardPoints == "yes" ? (

                  <InputNumber
                    style={{ width: "100px", "marginTop": "10px" }}
                    placeholder="0"
                    min="0"
                    step="1"
                    value={rewardPointsOffered}
                    onChange={(value) => {
                      setRewardPointsOffered(value);
                    }}
                  />
                ) : null}

              </Card>
              {errorMessages.length ? (
                <>
                  <br></br>
                  <Alert
                    description={
                      <>
                        <strong>The following errors occurred:</strong>
                        {errorMessages.map((message) => {
                          return <div>• {message}</div>;
                        })}
                      </>
                    }
                    type="error"
                  />
                  <br />
                </>
              ) : null}
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );

  const hasAtLeastOneForm = !!getCBFormsQuery.data.cbForms.length;

  return (
    <>
      {campaignIdResultsToShow ? (
        <CampaignResultsTable
          campaignId={campaignIdResultsToShow}
          setCampaignIdResultsToShow={setCampaignIdResultsToShow}
        />
      ) : (
        <>
          {createNewCampaign}
          <Space wrap>
            <Tooltip
              placement="right"
              title={
                !hasAtLeastOneForm
                  ? "A form/survey must be created in order to use campaigns"
                  : null
              }
            >
              <Button
                type="primary"
                shape="round"
                disabled={!hasAtLeastOneForm}
                icon={<PlusOutlined />}
                onClick={() => {
                  clearAllStates();
                  setIsModalOpen(true);
                }}
              >
                Create New Campaign
              </Button>
            </Tooltip>
          </Space>
          <div style={{ marginTop: "30px" }}></div>
          <CBCampaignTable
            editClickedOnCampaign={(clickedId) =>
              editCBCampaignClicked(clickedId)
            }
            viewCampaignResults={(clickedId) =>
              viewCBCampaignResults(clickedId)
            }
            cloneCampaign={(clickedId) => cloneCBCampaignClicked(clickedId)}
            pauseCampaign={(clickedId, pausedStatus) => {
              pauseCBCampaign(clickedId, pausedStatus);
            }}
            deleteClickedOnId={(clickedId) => {
              deleteCBCampaignClicked(clickedId);
            }}
          />
        </>
      )}
    </>
  );
}
